import React, { useEffect, useState } from "react";
import {
  Avatar,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Popover,
  Box,
  CircularProgress,
} from "@mui/material";
import { colors } from "../../Utils/colorUtils";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/painPal-logo.svg";
import Education from "../../assets/sidebar/education.svg";
import Message from "../../assets/sidebar/message.svg";
import Patients from "../../assets/sidebar/Patients.svg";
import Setting from "../../assets/sidebar/setting.png";
import Treatment from "../../assets/sidebar/treatment.svg";
import Logout from "../../assets/sidebar/logout.svg";
import emitter from "../../Utils/eventEmitter";

function Sidebar() {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    avatar: "",
  });

  const fetchProfile = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/auth/get-doctor-profile/${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      const data = responseData.data;
      setProfile({
        name: `${data.first_name} ${data.last_name}`,
        email: data.email,
        avatar: data.profile_picture || "",
      });
    } catch (error) {
      console.error("Error fetching profile:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
    const handleProfileUpdated = () => {
      fetchProfile();
    };
    emitter.on("profileUpdated", handleProfileUpdated);
    return () => {
      emitter.off("profileUpdated", handleProfileUpdated);
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "logout-popover" : undefined;

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
    handleClose();
  };

  return (
    <Drawer
      sx={{
        width: 230,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
          backgroundColor: colors.background,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          style={{
            padding: "20px 16px 0 20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{
              width: "auto",
              height: "36px",
              marginRight: "10px",
            }}
          />
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontWeight: "500",
              fontSize: "18px",
              color: colors.textPrimary,
            }}
          >
            PainPal
          </Typography>
        </div>
        <div
          style={{
            flexGrow: 1,
            paddingTop: "16px",
            paddingLeft: "14px",
            paddingRight: "14px",
          }}
        >
          <List>
            <ListItemButton
              component={Link}
              to="/"
              sx={{
                "&:hover": {
                  borderRadius: "10px",
                },
                marginTop: "5px",
                backgroundColor:
                  location.pathname === "/" ? "#f4f4f4" : "inherit",
                borderRadius: location.pathname === "/" ? "10px" : "inherit",
              }}
            >
              <ListItemIcon sx={{ minWidth: "34px" }}>
                <img src={Patients} alt="Patients" style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color: colors.darkBluePrimary,
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Patients
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/treatment"
              sx={{
                "&:hover": {
                  borderRadius: "10px",
                },
                marginTop: "5px",
                backgroundColor:
                  location.pathname === "/treatment" ? "#f4f4f4" : "inherit",
                borderRadius:
                  location.pathname === "/treatment" ? "10px" : "inherit",
              }}
            >
              <ListItemIcon sx={{ minWidth: "34px" }}>
                <img
                  src={Treatment}
                  alt="Treatment Plans"
                  style={{ width: "24px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color: colors.darkBluePrimary,
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Treatment Plans
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/education"
              sx={{
                "&:hover": {
                  borderRadius: "10px",
                },
                marginTop: "5px",
                backgroundColor:
                  location.pathname === "/education" ? "#f4f4f4" : "inherit",
                borderRadius:
                  location.pathname === "/education" ? "10px" : "inherit",
              }}
            >
              <ListItemIcon sx={{ minWidth: "34px" }}>
                <img
                  src={Education}
                  alt="Education"
                  style={{ width: "24px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color: colors.darkBluePrimary,
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Education
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/message"
              sx={{
                "&:hover": {
                  borderRadius: "10px",
                },
                marginTop: "5px",
                backgroundColor:
                  location.pathname === "/message" ? "#f4f4f4" : "inherit",
                borderRadius:
                  location.pathname === "/message" ? "10px" : "inherit",
              }}
            >
              <ListItemIcon sx={{ minWidth: "34px" }}>
                <img src={Message} alt="Messages" style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color: colors.darkBluePrimary,
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Messages
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/team"
              sx={{
                "&:hover": {
                  borderRadius: "10px",
                },
                marginTop: "5px",
                backgroundColor:
                  location.pathname === "/team" ? "#f4f4f4" : "inherit",
                borderRadius:
                  location.pathname === "/team" ? "10px" : "inherit",
              }}
            >
              <ListItemIcon sx={{ minWidth: "34px" }}>
                <img src={Patients} alt="Team" style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color: colors.darkBluePrimary,
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Team
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/settings"
              sx={{
                "&:hover": {
                  borderRadius: "10px",
                },
                marginTop: "5px",
                backgroundColor:
                  location.pathname === "/settings" ? "#f4f4f4" : "inherit",
                borderRadius:
                  location.pathname === "/settings" ? "10px" : "inherit",
              }}
            >
              <ListItemIcon sx={{ minWidth: "34px" }}>
                <img src={Setting} alt="Settings" style={{ width: "24px" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color: colors.darkBluePrimary,
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Settings
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
        </div>
        <div style={{ padding: 16 }}>
          <div
            style={{
              borderTop: `1px solid ${colors.borderColor}`,
              display: "flex",
              alignItems: "center",
              paddingTop: 20,
              paddingBottom: 10,
            }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#7F56D9", marginRight: 1 }} />
            ) : (
              <>
                <Avatar
                  alt={profile.name}
                  src={profile.avatar}
                  sx={{ width: 30, height: 30, marginRight: 1 }}
                />
                <div style={{ flexGrow: 1 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: colors.textPrimary,
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    {profile.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "10px", color: colors.textSecondary }}
                  >
                    {profile.email}
                  </Typography>
                </div>
              </>
            )}

            <Button onClick={handleClick} sx={{ minWidth: 0, padding: 0 }}>
              <img
                src={Logout}
                alt="Logout"
                style={{
                  width: "14px",
                  height: "14px",
                  cursor: "pointer",
                  marginLeft: "24px",
                }}
              />
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  marginTop: "-20px",
                  marginLeft: "24px",
                },
              }}
            >
              <Typography
                sx={{
                  p: 2,
                  fontSize: "12px",
                  fontWeight: "400",
                  color: colors.textSecondary,
                }}
              >
                Are you sure you want to logout?
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 1,
                  gap: "8px",
                }}
              >
                <Button
                  onClick={handleLogout}
                  variant="contained"
                  sx={{
                    width: "100%",
                    backgroundColor: colors.violetPrimary,
                    fontSize: "12px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: colors.violetSecondary,
                    },
                  }}
                >
                  Logout
                </Button>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  sx={{
                    width: "100%",
                    backgroundColor: colors.secondary,
                    color: colors.primary,
                    borderColor: colors.borderPrimary,
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Popover>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Sidebar;
