import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  useMediaQuery,
  Grid,
  Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { styled, useTheme } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";
import SearchInput from "./ProfileSearchInput";
import ProfilePhotoSection from "./ProfilePhotoSection";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PropTypes from "prop-types";
import emailProfileIcon from "../../../assets/emailProfileIcon.svg";
import PersonalInfo from "./PersonalInfo";
import PpSnackbar from "../../../Utils/PpSnackbar";

const Label = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#344054",
  marginBottom: "6px",
});

const StyledTextField = styled(TextField)({
  marginBottom: "24px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D0D5DD",
      borderRadius: "10px",
    },
  },
});

const StyledSelect = styled(Select)({
  marginBottom: "24px",
  borderRadius: "10px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D0D5DD",
  },
});

const SubLabel = styled(Typography)({
  fontSize: "14px",
  color: "#667085",
  marginBottom: "6px",
});

const SectionTitle = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "28px",
  color: colors.textPrimary,
});

const DividerStyled = styled(Divider)({
  borderBottomWidth: "1px",
  borderColor: colors.textFourth,
  marginY: 2,
});

const ButtonStyled = styled(Button)(({ variant }) => ({
  backgroundColor:
    variant === "cancel" ? colors.secondary : colors.violetPrimary,
  height: "40px",
  color: variant === "cancel" ? "#363F72" : "#ffffff",
  textTransform: "none",
  padding: "0 20px",
  borderRadius: "10px",
  fontWeight: 600,
  border: "1px solid #D0D5DD",
  fontSize: "inherit",
  "&:hover": {
    backgroundColor:
      variant === "cancel" ? colors.lightGray : colors.violetSecondary,
  },
}));

const StyledPhoneInput = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "10px",
  padding: "8px 14px",
  marginBottom: "30px",
  "& .PhoneInputCountry": {
    marginRight: theme.spacing(1),
  },
  "& input": {
    ...theme.typography.body2,
    border: "none",
    background: "none",
    outline: "none",
    padding: 0,
    flex: 1,
  },
}));

const ErrorMessage = styled(Typography)({
  color: "red",
  fontSize: "10px",
  marginTop: "-20px",
  marginBottom: "10px",
});

const FieldSection = ({ label, subtitle, fields = [], errors = {} }) => (
  <Grid item xs={12} container sx={{ marginTop: "40px", flexDirection: "row" }}>
    <Grid
      item
      xs={12}
      md={2}
      container
      sx={{ marginBottom: { xs: "10px", md: 0 } }}
    >
      <Label>{label}</Label>
      {subtitle && <SubLabel>{subtitle}</SubLabel>}
    </Grid>
    <Grid item xs={12} md={6}>
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={2}>
        {fields.map((field, idx) =>
          field.component ? (
            <Box key={idx} width="100%">
              {field.component}
              {errors[field.name] && (
                <ErrorMessage>{errors[field.name]}</ErrorMessage>
              )}
            </Box>
          ) : (
            <Box key={idx} width="100%">
              <StyledTextField
                fullWidth
                size="small"
                placeholder={field.placeholder}
                InputProps={field.InputProps}
                onChange={field.onChange}
                value={field.value || ""}
                error={!!errors[field.name]}
              />
              {errors[field.name] && (
                <ErrorMessage>{errors[field.name]}</ErrorMessage>
              )}
            </Box>
          ),
        )}
      </Box>
    </Grid>
  </Grid>
);

FieldSection.propTypes = {
  label: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      InputProps: PropTypes.object,
      onChange: PropTypes.func,
      component: PropTypes.node,
    }),
  ).isRequired,
  errors: PropTypes.object.isRequired,
};

const ProfileForm = ({ fetchPatientProfile }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    age: "",
    profilePicture: "",
    gender: "male",
    assignedTo: "",
    checkUpFrequency: "",
    country_code: "",
  });

  const [errors, setErrors] = useState({});
  const [userId, setUserId] = useState("");
  const { patientId } = useParams();
  const token = localStorage.getItem("token");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchPatientProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/patient/get-patient-profile/${patientId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        const data = await response.json();
        setUserId(data.patient.user_id);
        setFormData((prevData) => ({
          ...prevData,
          firstName: data.patient.user.first_name || "",
          lastName: data.patient.user.last_name || "",
          email: data.patient.user.email || "",
          phoneNumber: data.patient.user.phone_number || "",
          profilePicture:
            prevData.profile_picture || data.patient.user.profile_picture || "",
          gender: data.patient.user.gender || "male",
          assignedTo: data.patient.assigned_to || "",
          checkUpFrequency: data.patient.check_up_frequency || "",
          country_code: data.patient.user.country_code || "+91",
          age: prevData.age || data.patient.user.age || "",
        }));
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    };

    fetchPatientProfile();
  }, []);

  const handleSubmit = async () => {
    const submitData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      age: formData.age,
      profilePicture: formData.profilePicture,
      gender: formData.gender,
      assignedTo: formData.assignedTo,
      checkUpFrequency: formData.checkUpFrequency,
      country_code: formData.country_code,
    };
    console.log("Form data before submit:", submitData);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/auth/update-patient-by-doctor/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(submitData),
        },
      );
      if (response.status === 200) {
        setSnackbarOpen(true);
        fetchPatientProfile();
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleInputChange = (field, value) => {
    console.log(`Setting ${field} to ${value}`);
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleImageUpload = (imageUrl) => {
    console.log("Uploaded Image URL:", imageUrl);
    setFormData((prevData) => ({
      ...prevData,
      profilePicture: imageUrl,
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const sections = [
    {
      label: "Name",
      fields: [
        {
          name: "firstName",
          placeholder: "Oliva",
          value: formData.firstName,
          onChange: (e) => handleInputChange("firstName", e.target.value),
        },
        {
          name: "lastName",
          placeholder: "Rhye",
          value: formData.lastName,
          onChange: (e) => handleInputChange("lastName", e.target.value),
        },
      ],
    },
    {
      label: "Email address",
      fields: [
        {
          name: "email",
          placeholder: "Enter your email",
          value: formData.email,
          InputProps: {
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ color: "#667085" }}>
                  <img src={emailProfileIcon} alt="emailProfileIcon" />
                </span>
              </InputAdornment>
            ),
          },
        },
      ],
    },
    {
      label: "Phone number",
      fields: [
        {
          name: "phoneNumber",
          value: formData.phoneNumber,
          onChange: (value) => handleInputChange("phoneNumber", value),
          component: (
            <StyledPhoneInput>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                value={formData.phoneNumber}
                onChange={(value) => handleInputChange("phoneNumber", value)}
                inputComponent="input"
              />
            </StyledPhoneInput>
          ),
        },
      ],
    },
    {
      label: "Age",
      fields: [
        {
          name: "age",
          placeholder: "Enter age",
          value: formData.age,
          onChange: (e) => handleInputChange("age", e.target.value),
        },
      ],
    },
    {
      label: "Your photo",
      fields: [
        {
          name: "profilePicture",
          component: (
            <ProfilePhotoSection
              onImageUpload={handleImageUpload}
              initialImage={formData.profilePicture}
            />
          ),
        },
      ],
      subtitle: "This will be displayed on your profile.",
    },
    {
      label: "Gender",
      fields: [
        {
          name: "gender",
          component: (
            <StyledSelect
              fullWidth
              size="small"
              value={formData.gender}
              onChange={(e) => handleInputChange("gender", e.target.value)}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">other</MenuItem>
            </StyledSelect>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <PersonalInfo handleSubmit={handleSubmit} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            {sections.map((section, index) => (
              <React.Fragment key={index}>
                <FieldSection
                  label={section.label}
                  subtitle={section.subtitle}
                  fields={section.fields}
                  errors={errors}
                />
                <DividerStyled />
              </React.Fragment>
            ))}
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: "20px",
                marginTop: isMobile ? "0px" : "20px",
              }}
            >
              <Grid item xs={12} sm={12} md={10} sx={{ marginTop: "20px" }}>
                <SectionTitle>Clinician</SectionTitle>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "row",
                  justifyContent: isMobile ? "space-between" : "flex-end",
                  alignItems: "center",
                  mt: isMobile ? 2 : 0,
                }}
              >
                <ButtonStyled
                  variant="cancel"
                  sx={{
                    width: isMobile ? "48%" : "auto",
                    marginRight: isMobile ? "0px" : "20px",
                  }}
                >
                  Cancel
                </ButtonStyled>
                <ButtonStyled
                  variant="save"
                  sx={{ width: isMobile ? "48%" : "auto" }}
                >
                  Save
                </ButtonStyled>
              </Grid>
            </Grid>
            <DividerStyled />
            <FieldSection
              label="Assigned to"
              fields={[
                {
                  name: "assignedTo",
                  component: (
                    <SearchInput
                      fullWidth
                      options={["John Doe", "Jane Smith", "Alice Johnson"]}
                      placeholder="Search for team members"
                      onSelect={(option) =>
                        handleInputChange("assignedTo", option)
                      }
                    />
                  ),
                },
              ]}
              errors={errors}
            />
            <DividerStyled />
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: "20px",
                marginTop: isMobile ? "0px" : "20px",
              }}
            >
              <Grid item xs={12} sm={12} md={10} sx={{ marginTop: "20px" }}>
                <SectionTitle>Patient Settings</SectionTitle>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "row",
                  justifyContent: isMobile ? "space-between" : "flex-end",
                  alignItems: "center",
                  mt: isMobile ? 2 : 0,
                }}
              >
                <ButtonStyled
                  variant="cancel"
                  sx={{
                    width: isMobile ? "48%" : "auto",
                    marginRight: isMobile ? "0px" : "20px",
                  }}
                >
                  Cancel
                </ButtonStyled>
                <ButtonStyled
                  variant="save"
                  sx={{ width: isMobile ? "48%" : "auto" }}
                >
                  Save
                </ButtonStyled>
              </Grid>
            </Grid>
            <DividerStyled />
            <FieldSection
              label="Check-up frequency"
              fields={[
                {
                  name: "checkUpFrequency",
                  component: (
                    <StyledSelect
                      fullWidth
                      size="small"
                      value={formData.checkUpFrequency}
                      onChange={(e) =>
                        handleInputChange("checkUpFrequency", e.target.value)
                      }
                    >
                      <MenuItem value="Monthly">Monthly</MenuItem>
                      <MenuItem value="Quarterly">Quarterly</MenuItem>
                      <MenuItem value="Yearly">Yearly</MenuItem>
                    </StyledSelect>
                  ),
                },
              ]}
              errors={errors}
            />
          </Box>
        </Grid>
      </Grid>
      <PpSnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        message="Profile updated successfully"
      />
    </>
  );
};

ProfileForm.propTypes = {
  fetchPatientProfile: PropTypes.func.isRequired,
};

export default ProfileForm;
