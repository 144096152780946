import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const calculateDaysSince = (date) => {
  if (!date) return 0;
  const today = new Date();
  const lastActiveDate = new Date(date);
  if (isNaN(lastActiveDate.getTime())) return 0;
  const timeDiff = today - lastActiveDate;
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  return daysDiff;
};

const getDotStyle = (status) => {
  let backgroundColor;
  const normalizedStatus = status ? status.toLowerCase() : "inactive";

  if (normalizedStatus === "active") {
    backgroundColor = "#2E90FA";
  } else if (normalizedStatus === "inactive") {
    backgroundColor = "#F79009";
  } else {
    backgroundColor = "transparent";
  }

  return {
    display: "inline-block",
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor,
    marginRight: 8,
  };
};

const getStatusDotStyle = (status) => {
  let backgroundColor;
  const normalizedStatus = status ? status.toLowerCase() : "inactive";

  switch (normalizedStatus) {
    case "active":
      backgroundColor = "#2E90FA";
      break;
    case "inactive":
      backgroundColor = "#F79009";
      break;
    default:
      backgroundColor = "transparent";
  }

  return {
    display: "inline-block",
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor,
    marginRight: 8,
  };
};

function PatientTable({ filteredPatients }) {
  const navigate = useNavigate();
  const handleRowClick = (id) => {
    localStorage.setItem("uniquePatientId", id);
    navigate(`/patient/overview/${id}`);
  };

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Table
        sx={{
          minWidth: "1000px",
          whiteSpace: "nowrap",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Executed for
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Email
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Last Active
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Last 7 avg Pain
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Last 7 day Treatment
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Treatment
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredPatients.map((patient) => (
            <TableRow
              key={patient.id}
              onClick={() => handleRowClick(patient.id)}
              sx={{ cursor: "pointer" }}
            >
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  borderBottom: "1px solid #EAECF0",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={patient.profile_picture || null}
                    alt={`${patient.firstName} ${patient.lastName}`}
                    sx={{
                      bgcolor: patient.profile_picture
                        ? "transparent"
                        : "#7F56D9",
                      color: "#fff",
                    }}
                  >
                    {!patient.profile_picture &&
                    patient.firstName &&
                    patient.lastName
                      ? `${patient.firstName[0].toUpperCase()}${patient.lastName[0].toUpperCase()}`
                      : null}
                  </Avatar>
                  <Box sx={{ ml: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#101828",
                      }}
                    >
                      {patient.firstName} {patient.lastName}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#475467",
                }}
              >
                {patient.email}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#475467",
                }}
              >
                {calculateDaysSince(patient.lastActive)}
              </TableCell>

              <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#475467",
                }}
              >
                {patient.last7AvgPain}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#475467",
                }}
              >
                {patient.last7DayTreatment}
              </TableCell>
              <TableCell sx={{ borderBottom: "1px solid #EAECF0" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      borderRadius: "50px",
                      border: "2px solid #B2DDFF",
                      padding: "2px 5px",
                      background: "#EFF8FF",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      color: "#175CD3",
                    }}
                  >
                    <Box style={getDotStyle(patient.treatment)} />
                    {patient.treatment}
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "2px solid #D0D5DD",
                      padding: "2px 5px",
                      background: "transparent",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      color: "#344054",
                    }}
                  >
                    <Box style={getStatusDotStyle(patient.status)} />
                    {patient.status}
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

PatientTable.propTypes = {
  filteredPatients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      executedBy: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      lastActive: PropTypes.string.isRequired,
      last7AvgPain: PropTypes.string.isRequired,
      last7DayTreatment: PropTypes.string.isRequired,
      treatment: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      profile_picture: PropTypes.string,
    }),
  ).isRequired,
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default PatientTable;
