import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Typography, Chip, Box } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";

const CustomGrid = styled(Grid)({
  padding: "0px",
});

const ImageContainer = styled(Grid)(({ theme }) => ({
  borderRadius: "12px",
  background: "#FFF",
  height: "400px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  paddingBottom: 0,
  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
}));

const getStatusDotStyle = (status) => {
  let backgroundColor;
  switch (status.toLowerCase()) {
    case "active":
      backgroundColor = "#2E90FA";
      break;
    case "inactive":
      backgroundColor = "#F79009";
      break;
    default:
      backgroundColor = "transparent";
  }
  return {
    display: "inline-block",
    width: 6,
    height: 6,
    borderRadius: "50%",
    backgroundColor,
    marginRight: 8,
  };
};

const VideoWrapper = styled("div")(({ theme }) => ({
  borderRadius: "12px",
  height: "400px",
  overflow: "hidden",
  width: "100%",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
}));

const StyledVideo = styled("video")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const ImageTextContainer = styled(Grid)({
  paddingTop: "10px",
  display: "flex",
});

const TitleTypographyMain = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  color: colors.textPrimary,
  padding: "10px 0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const TitleTypography = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: colors.textSecondary,
  padding: "10px 0",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const CustomDivider = styled(Divider)({
  backgroundColor: "#EAECF0",
  margin: "0 -16px",
});

const ConditionChip = styled(Chip)({
  margin: "4px",
  backgroundColor: colors.secondary,
  border: `1px solid ${colors.borderPrimary}`,
  borderRadius: "10px",
  color: colors.textTertiary,
});

const TagsContainer = styled(Grid)({
  display: "flex",
  flexWrap: "nowrap",
  overflowX: "auto",
  paddingBottom: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
});

function SingleTreatmentCard({ images, onCardClick }) {
  if (!Array.isArray(images)) {
    console.error("images should be an array");
    return <div>No image data available</div>;
  }

  return (
    <CustomGrid
      container
      spacing={2}
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        padding: { xs: "60px 0px 10px", sm: "20px 0px 40px" },
      }}
    >
      {images.length > 0 ? (
        images.map((image, index) => {
          const {
            url = "https://via.placeholder.com/400",
            title = "No Title",
            exercises = "No exercises",
            owner = "Unknown Owner",
            status = "No status",
            tags = ["No tags"],
            id,
          } = image;

          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={id || index}
              sx={{ marginTop: { xs: "20px", md: "20px" }, cursor: "pointer" }}
              onClick={() => onCardClick(id, image)}
            >
              <ImageContainer>
                <VideoWrapper>
                  <StyledVideo src={url} autoPlay loop muted loading="lazy" />
                </VideoWrapper>
                <ImageTextContainer container spacing={1}>
                  <Grid item xs={12}>
                    <TitleTypographyMain>{title}</TitleTypographyMain>
                  </Grid>
                </ImageTextContainer>
                <CustomDivider />
                <ImageTextContainer container spacing={1}>
                  <Grid item xs={3}>
                    <TitleTypography>Exercises</TitleTypography>
                  </Grid>
                  <Grid item xs={9}>
                    <TitleTypography>{exercises}</TitleTypography>
                  </Grid>
                </ImageTextContainer>
                <CustomDivider />
                <ImageTextContainer container spacing={1}>
                  <Grid item xs={3}>
                    <TitleTypography>Owner</TitleTypography>
                  </Grid>
                  <Grid item xs={9}>
                    <TitleTypography>{owner}</TitleTypography>
                  </Grid>
                </ImageTextContainer>
                <CustomDivider />
                <ImageTextContainer container spacing={1}>
                  <Grid item xs={3}>
                    <TitleTypography>Status</TitleTypography>
                  </Grid>
                  <Grid item xs={9}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #D0D5DD",
                          padding: "2px 5px",
                          background: "transparent",
                          textAlign: "center",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          color: "#344054",
                          mt: 1,
                        }}
                      >
                        <Box style={getStatusDotStyle(status)} />
                        {status}
                      </Box>
                    </Box>
                  </Grid>
                </ImageTextContainer>
                <CustomDivider />
                <ImageTextContainer container spacing={1}>
                  <Grid item xs={3}>
                    <TitleTypography>Tags</TitleTypography>
                  </Grid>
                  <Grid item xs={9}>
                    <TagsContainer container>
                      {tags.map((tag, index) => (
                        <ConditionChip key={index} label={tag} />
                      ))}
                    </TagsContainer>
                  </Grid>
                </ImageTextContainer>
                <CustomDivider />
              </ImageContainer>
            </Grid>
          );
        })
      ) : (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            No Treatments found
          </Typography>
        </Grid>
      )}
    </CustomGrid>
  );
}

SingleTreatmentCard.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      exercises: PropTypes.string,
      owner: PropTypes.string,
      status: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      id: PropTypes.string,
    }),
  ).isRequired,
  onCardClick: PropTypes.func.isRequired,
};

export default SingleTreatmentCard;
