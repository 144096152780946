import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import HistoryTabContent from "./HistoryTabContent";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

function HistoryMain() {
  return (
    <>
      <CustomGrid>
        <HistoryTabContent />
      </CustomGrid>
    </>
  );
}

export default HistoryMain;
