import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Avatar,
  List,
  ListItemAvatar,
  Badge,
  useMediaQuery,
} from "@mui/material";
import searchIcon from "../../assets/search-lg.svg";
import writePencilIcon from "../../assets/writePencilIcon.svg";
import { colors } from "../../Utils/colorUtils";

function UsersSidebarMessage({ onChatSelect, patientsState, lastMessages }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  const formatRelativeTime = (timestamp) => {
    const now = new Date();
    const messageTime = new Date(timestamp);
    const diffInMinutes = Math.floor((now - messageTime) / 1000 / 60);
    if (diffInMinutes < 1) return "Just now";
    if (diffInMinutes < 60) return `${diffInMinutes} min ago`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hr ago`;
    const diffInDays = Math.floor(diffInHours / 24);
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  };

  return (
    <Box
      sx={{
        width: isMobile ? "100%" : "360px",
        bgcolor: "#fff",
        borderRight: isMobile ? "none" : `1px solid #e0e0e0`,
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        marginBottom: "100px",
      }}
    >
      <Box
        px={2}
        py={1.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{
              color: colors.textPrimary,
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Messages
          </Typography>
          <Badge
            badgeContent={patientsState?.length}
            sx={{
              ml: 3,
              "& .MuiBadge-badge": {
                fontSize: 10,
                border: `1px solid ${colors.borderColor}`,
                height: 25,
                width: 30,
                borderRadius: "8px",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                padding: "0 6px",
                bgcolor: "white",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            border: `1px solid ${colors.borderColor}`,
            height: 35,
            width: 35,
            borderRadius: "8px",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <img src={writePencilIcon} alt="writePencilIcon" />
        </Box>
      </Box>
      <Box px={2} py={1.5}>
        <TextField
          placeholder="Search"
          variant="outlined"
          value={""}
          sx={{
            width: { xs: "100%" },
            height: 44,
            backgroundColor: colors.background,
            "& .MuiInputBase-input::placeholder": {
              color: "#667085",
              opacity: 1,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={searchIcon}
                  alt="Search"
                  style={{ width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
            style: {
              borderRadius: "10px",
              color: "#667085",
              height: "100%",
              padding: "0 14px",
            },
          }}
          InputLabelProps={{
            style: { color: "#667085" },
          }}
        />
      </Box>
      <List
        sx={{ flexGrow: 1, overflow: "auto", p: 0, paddingBottom: "100px" }}
      >
        {patientsState?.map((patient) => {
          const fullName = `${patient.firstName} ${patient.lastName}`;
          const avatarSrc = patient.profile_picture;
          const status = patient.status || "inactive";

          // Check if there is a last message for this patient, then get the text and timestamp
          const lastMessageText =
            lastMessages[patient.id]?.text || "No messages yet";
          const lastMessageTimestamp = lastMessages[patient.id]?.timestamp;

          // Calculate the relative time only if timestamp exists
          const lastMessageTime = lastMessageTimestamp
            ? formatRelativeTime(lastMessageTimestamp)
            : "";

          return (
            <Box
              key={patient.id}
              sx={{
                py: 1.5,
                px: 2,
                borderBottom: 1,
                borderColor: "divider",
                "&:hover": { bgcolor: "#f9fafb" },
                cursor: "pointer",
              }}
              onClick={() => onChatSelect(patient.id)}
            >
              <Box display="flex" alignItems="flex-start">
                <ListItemAvatar sx={{ minWidth: "unset", marginRight: "4px" }}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="dot"
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor:
                          status === "active" ? "#22c55e" : "#f87171",
                        boxShadow: "0 0 0 2px #fff",
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                      },
                    }}
                  >
                    <Avatar src={avatarSrc} sx={{ width: 44, height: 44 }} />
                  </Badge>
                </ListItemAvatar>
                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    ml: 1,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography
                      sx={{
                        color: "#344054",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {fullName}
                    </Typography>
                    {lastMessageTime && (
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#475467",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "20px",
                          marginLeft: "auto",
                        }}
                      >
                        {lastMessageTime}
                      </Typography>
                    )}
                  </Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: "#475467",
                      fontSize: "14px",
                      fontWeight: 400,
                      mb: 0.25,
                      lineHeight: 1.5,
                      display: "block",
                    }}
                  >
                    {patient.email}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  ml: "10px",
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    color: "#475467",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{ fontWeight: 500, color: "#475467" }}
                  >
                    You:
                  </Typography>{" "}
                  {lastMessageText}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </List>
    </Box>
  );
}

UsersSidebarMessage.propTypes = {
  onChatSelect: PropTypes.func.isRequired,
  patientsState: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      status: PropTypes.string,
      profile_picture: PropTypes.string,
    }),
  ).isRequired,
  lastMessages: PropTypes.objectOf(
    PropTypes.shape({
      text: PropTypes.string,
      timestamp: PropTypes.string,
      sender: PropTypes.string,
    }),
  ).isRequired,
};

export default UsersSidebarMessage;
