import React from "react";
import { Card, CardContent, Typography, Chip, Grid } from "@mui/material";
import { colors } from "../../../Utils/colorUtils";
import arrowUp from "../../../assets/arrowUp.svg";
import arrowDown from "../../../assets/arrowDown.svg";
import { styled } from "@mui/system";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

const statusCardsData = [
  {
    title: "Pain Rating",
    value: "6.5hs",
    change: "10",
    changeType: "increase",
  },
  {
    title: "Treatment adherence",
    value: "66%",
    change: "2",
    changeType: "decrease",
  },
  {
    title: "Diary Logs",
    value: "10",
    change: "2",
    changeType: "decrease",
  },
];

function OverViewStatusCard() {
  return (
    <Grid container spacing={3} sx={{ marginTop: "20px" }}>
      {statusCardsData.map((card, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              marginTop: index !== 0 ? "20px" : "0px",
            },
          })}
        >
          <Card
            sx={{
              borderRadius: "12px",
              boxShadow:
                "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: "120px",
              width: "100%",
            }}
          >
            <CardContent>
              <CustomGrid container sx={{ justifyContent: "space-between" }}>
                <CustomGrid>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginBottom: "12px",
                      color: colors.textSecondary,
                      fontWeight: "500",
                    }}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontSize: "24px",
                      color: colors.textPrimary,
                      fontWeight: "600",
                    }}
                  >
                    {card.value}
                  </Typography>
                </CustomGrid>
                <CustomGrid sx={{ paddingTop: "14px" }}>
                  <Chip
                    icon={
                      card.changeType === "increase" ? (
                        <img
                          src={arrowUp}
                          style={{ marginRight: "2px" }}
                          alt="plus"
                        />
                      ) : (
                        <img
                          src={arrowDown}
                          style={{ marginRight: "2px" }}
                          alt="plus"
                        />
                      )
                    }
                    label={`${card.change}%`}
                    sx={{
                      marginTop: "8px",
                      backgroundColor:
                        card.changeType === "increase" ? "#F0FDF9" : "#FEF3F2",
                      color:
                        card.changeType === "increase" ? "#107569" : "#B42318",
                    }}
                  />
                </CustomGrid>
              </CustomGrid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default OverViewStatusCard;
