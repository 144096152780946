import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import Select from "react-select";
import { colors } from "../../Utils/colorUtils";
import Flag from "react-world-flags";
import { countries } from "../../Utils/countryUtils";
import UploadComponent from "./UploadComponent";
import BioSection from "./BioSection";

const SettingsForm = ({ profileData, onSave, onImageUpload }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [professional_role, setProfessionalRole] = useState("");
  const [country, setCountry] = useState(null);
  const [bio, setBio] = useState("");
  const [fontStyle, setFontStyle] = useState("Regular");
  useEffect(() => {
    if (profileData) {
      setName(profileData.first_name || "");
      setSurname(profileData.last_name || "");
      setEmail(profileData.email || "");
      setProfessionalRole(profileData.professional_role || "");
      setBio(profileData.bio || "");
      if (profileData.country) {
        const countryOption = countries.find(
          (c) => c.label === profileData.country,
        );
        setCountry(countryOption || null);
      }
    }
  }, [profileData]);
  const customSingleValue = ({ data }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Flag
        code={data.code}
        style={{ width: 20, height: 20, borderRadius: "50%", marginRight: 10 }}
      />
      {data.label}
    </div>
  );

  const handleSave = () => {
    const formData = {
      firstName: name,
      lastName: surname,
      professionalRole: professional_role,
      country: country ? country.label : null,
      bio,
    };
    onSave(formData);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "auto" }, mb: { xs: 2, md: 0 } }}>
          <Typography
            sx={{
              fontWeight: 600,
              color: colors.textPrimary,
              fontSize: "18px",
            }}
          >
            Personal info
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              color: colors.textSecondary,
              fontSize: "14px",
            }}
          >
            Update your photo and personal details here.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", md: "flex-end" },
            gap: 1,
            width: "100%",
            maxWidth: { xs: "100%", md: "auto" },
          }}
        >
          {["Cancel", "Save"].map((text, index) => (
            <Button
              key={index}
              variant={index === 1 ? "contained" : "outlined"}
              sx={{
                width: { xs: "48%", md: "auto" },
                borderRadius: "8px",
                borderColor: index === 1 ? "transparent" : "#E0E0E0",
                background: index === 1 ? "#7F56D9" : "transparent",
                color: index === 1 ? "#FFFFFF" : "#B0B0B0",
                textTransform: "none",
                height: "40px",
                fontSize: "14px",
                "&:hover": {
                  background: index === 1 ? "#6941C6" : "transparent",
                  borderColor: index === 1 ? "transparent" : "#E0E0E0",
                  color: index === 1 ? "#FFFFFF" : "#7F56D9",
                },
              }}
              onClick={index === 1 ? handleSave : null}
            >
              {text}
            </Button>
          ))}
        </Box>
      </Box>
      <Divider
        sx={{
          width: "auto",
          height: "2px",
          mt: 2,
          background: "var(--Colors-Border-border-secondary, #EAECF0)",
        }}
      />
      <Box sx={{ mt: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: colors.textPrimary,
                fontSize: "14px",
              }}
            >
              Name
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              sx={{
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                  "& fieldset": {
                    borderColor: "#D0D5DD",
                    borderRadius: "10px",
                  },
                  "& input": {
                    height: "100%",
                    padding: "0 14px",
                    lineHeight: "48px",
                  },
                },
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              sx={{
                borderRadius: "10px",
                marginTop: { xs: "30px", md: "0px" },
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                  "& fieldset": {
                    borderColor: "#D0D5DD",
                    borderRadius: "10px",
                  },
                  "& input": {
                    height: "100%",
                    padding: "0 14px",
                    lineHeight: "48px",
                  },
                },
              }}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, background: "#EAECF0", height: "2px" }} />
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: colors.textPrimary,
                fontSize: "14px",
              }}
            >
              Email
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              sx={{
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                  "& fieldset": {
                    borderColor: "#D0D5DD",
                    borderRadius: "10px",
                  },
                  "& input": {
                    height: "100%",
                    padding: "0 14px",
                    lineHeight: "48px",
                  },
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, background: "#EAECF0", height: "2px" }} />
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: colors.textPrimary,
                fontSize: "14px",
              }}
            >
              Your Photo
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                fontWeight: 400,
                color: colors.textSecondary,
                fontSize: "14px",
              }}
            >
              This will be displayed on your profile.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <UploadComponent
              onFileChange={onImageUpload}
              initialImage={profileData?.profile_picture}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, background: "#EAECF0", height: "2px" }} />
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: colors.textPrimary,
                fontSize: "14px",
              }}
            >
              Role
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              sx={{
                borderRadius: "10px",
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                  "& fieldset": {
                    borderColor: "#D0D5DD",
                    borderRadius: "10px",
                  },
                  "& input": {
                    height: "100%",
                    padding: "0 14px",
                    lineHeight: "48px",
                  },
                },
              }}
              value={professional_role}
              onChange={(e) => setProfessionalRole(e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, background: "#EAECF0", height: "2px" }} />
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: colors.textPrimary,
                fontSize: "14px",
              }}
            >
              Country
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              styles={{ borderRadius: "10px", height: "40px" }}
              options={countries}
              value={country}
              onChange={setCountry}
              components={{
                SingleValue: customSingleValue,
              }}
              isSearchable
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, background: "#EAECF0", height: "2px" }} />
        <BioSection
          bio={bio}
          setBio={setBio}
          fontStyle={fontStyle}
          setFontStyle={setFontStyle}
        />
        <Divider sx={{ mt: 2, background: "#EAECF0", height: "2px" }} />
        <Divider sx={{ mt: 2, background: "#EAECF0", height: "2px" }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 2,
            marginBottom: {
              xs: "100px",
            },
          }}
        >
          <Button
            variant="outlined"
            sx={{
              mr: 1,
              borderRadius: "8px",
              borderColor: "#E0E0E0",
              color: "#B0B0B0",
              textTransform: "none",
              height: "40px",
              fontSize: "14px",
              "&:hover": {
                borderColor: "#E0E0E0",
                color: "#7F56D9",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            sx={{
              borderRadius: "8px",
              background: "#7F56D9",
              color: "#FFFFFF",
              textTransform: "none",
              height: "40px",
              fontSize: "14px",
              "&:hover": {
                background: "#6941C6",
              },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

SettingsForm.propTypes = {
  profileData: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
};

export default SettingsForm;
