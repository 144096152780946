import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  TextField,
  List,
  ListItem,
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { colors } from "../../../../Utils/colorUtils";
import searchImage from "../../../../assets/search-lg.svg";
import dots from "../../../../assets/9Dots.svg";
import axios from "axios";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.borderPrimary,
    },
    "&:hover fieldset": {
      borderColor: colors.borderPrimary,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.borderPrimary,
      outline: "none",
    },
    borderRadius: 8,
    backgroundColor: colors.secondary,
    width: "auto",
    height: "40px",
    padding: "10px 14px",
    fontWeight: 400,
    color: colors.textFourth,
    fontSize: "16px",
    outline: "none",
  },
}));

const SearchAdornment = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginRight: "8px",
}));

function TreatmentEditLeftContainer({
  onImageDragStart,
  images,
  onSearch,
  loading,
  isFiltering,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredImages, setFilteredImages] = useState(images);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetchingMore, setFetchingMore] = useState(false);
  const videoRefs = useRef([]);

  useEffect(() => {
    const filterImages = async () => {
      if (onSearch && typeof onSearch === "function") {
        await onSearch(searchQuery);
      }
      setFilteredImages(images);
    };
    filterImages();
  }, [searchQuery, onSearch, images]);

  useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, filteredImages.length);
  }, [filteredImages]);

  useEffect(() => {
    const fetchMoreImages = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/exercise/get-exercises?page=${currentPage + 1}&pageSize=10`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.data.success) {
          const newImages = response.data.data.map((exercise) => ({
            id: exercise.id,
            title: exercise.exercise_name,
            videoSrc: exercise.exercise_thumbnail || exercise.exercise_video,
          }));

          setFilteredImages((prev) => [...prev, ...newImages]);
          setCurrentPage((prev) => prev + 1);
        }
      } catch (error) {
        console.error("Error fetching exercises:", error);
      } finally {
        setFetchingMore(false);
      }
    };

    if (fetchingMore) {
      const timeoutId = setTimeout(() => {
        fetchMoreImages();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }

    if (filteredImages.length < 7 && !fetchingMore) {
      setFetchingMore(true);
    }
  }, [fetchingMore, currentPage, filteredImages.length]);

  useEffect(() => {
    if (filteredImages.length < 7 && !fetchingMore) {
      setFetchingMore(true);
    }
  }, [filteredImages.length]);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 10 && !fetchingMore) {
      setFetchingMore(true);
    }
  };

  const handleDragStart = (event, image) => {
    onImageDragStart(event, image);
  };

  const handleVideoLoad = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].currentTime = 0.1;
    }
  };

  return (
    <Container disableGutters>
      <Box
        sx={{
          padding: "1rem",
          maxHeight: "calc(90vh - 20px)",
          overflowY: "auto",
          border: "1px solid #EAECF0",
          borderRadius: "0.5rem",
        }}
        onScroll={handleScroll}
      >
        <StyledTextField
          size="small"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <SearchAdornment>
                <img src={searchImage} alt="search" />
              </SearchAdornment>
            ),
          }}
          sx={{
            width: "100%",
            mb: isMobile ? 2 : 0,
          }}
        />

        {loading || isFiltering ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <CircularProgress sx={{ color: "#7F56D9" }} />
          </Box>
        ) : (
          <List>
            {filteredImages.map((image, index) => (
              <motion.div
                key={image.id}
                draggable
                onDragStart={(event) => handleDragStart(event, image)}
                style={{ marginBottom: "0.5rem" }}
              >
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "0.3rem",
                    position: "relative",
                    padding: "0.625rem",
                    border: "1px solid #EAECF0",
                    boxShadow: "0px 1px 2px 0px #1018280D",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      flexShrink: 0,
                      width: "80px",
                      height: "48px",
                      borderRadius: "0.6rem",
                      overflow: "hidden",
                      border: "1px solid #F2F4F7",
                    }}
                  >
                    {image.videoSrc &&
                      (image.videoSrc.endsWith(".jpg") ? (
                        <img
                          src={image.videoSrc}
                          alt={image.title}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onError={(e) => {
                            console.error("Error loading image:", e);
                            e.target.onerror = null;
                            e.target.src = "fallbackImage.jpg";
                          }}
                        />
                      ) : image.videoSrc.endsWith(".mp4") ? (
                        <video
                          ref={(el) => (videoRefs.current[index] = el)}
                          src={image.videoSrc}
                          muted
                          loop
                          playsInline
                          preload="auto"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onLoadedData={() =>
                            console.log("Video loaded successfully")
                          }
                          onError={(e) =>
                            console.error("Error loading video:", e)
                          }
                        />
                      ) : null)}
                  </Box>
                  <Typography
                    sx={{
                      flexGrow: 1,
                      ml: "0.625rem",
                      fontWeight: 600,
                      fontSize: "0.875rem",
                    }}
                  >
                    {image.title}
                  </Typography>
                  <Box
                    component="img"
                    src={dots}
                    alt="Icon"
                    sx={{
                      flexShrink: 0,
                      width: "15px",
                      height: "15px",
                      ml: "0.625rem",
                    }}
                  />
                </ListItem>
              </motion.div>
            ))}
          </List>
        )}

        {fetchingMore && (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Container>
  );
}

TreatmentEditLeftContainer.propTypes = {
  onImageDragStart: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isFiltering: PropTypes.bool.isRequired,
};

export default TreatmentEditLeftContainer;
