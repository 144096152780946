import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import { styled } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

const VideoContainer = styled(Grid)(({ theme }) => ({
  borderRadius: "12px",
  background: "#FFF",
  height: "600px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  paddingBottom: 0,
  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
}));

const VideoWrapper = styled("div")(({ theme }) => ({
  borderRadius: "12px",
  height: "600px",
  overflow: "hidden",
  width: "70%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "auto",
  },
}));

const VideoTextContainer = styled(Grid)(({ theme }) => ({
  paddingTop: "10px",
  display: "flex",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  color: colors.textSecondary,
  padding: "10px 20px",
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: "#EAECF0",
  margin: "0 -16px",
}));

function VlogSingleVideo({ video }) {
  return (
    <CustomGrid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: {
          xs: "center",
          sm: "space-between",
        },
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        padding: {
          xs: "60px 0px 10px",
          sm: "60px 0px 250px",
        },
      }}
    >
      <Grid item xs={12} sm={6} md={12}>
        <VideoContainer>
          <VideoWrapper>
            <ReactPlayer url={video.url} width="100%" height="100%" controls />
          </VideoWrapper>
          <VideoTextContainer container spacing={1}>
            <Grid item xs={2}>
              <TitleTypography>Exercise</TitleTypography>
            </Grid>
            <Grid item xs={10}>
              <TitleTypography>{video.title}</TitleTypography>
            </Grid>
          </VideoTextContainer>
          <CustomDivider />
          <VideoTextContainer container spacing={1}>
            <Grid item xs={2}>
              <TitleTypography>Date</TitleTypography>
            </Grid>
            <Grid item xs={10}>
              <TitleTypography>{video.date}</TitleTypography>
            </Grid>
          </VideoTextContainer>
          <CustomDivider />
        </VideoContainer>
      </Grid>
    </CustomGrid>
  );
}

VlogSingleVideo.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }).isRequired,
};

export default VlogSingleVideo;
