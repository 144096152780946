import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import MessageHeader from "./MessageHeader";
import ChatArea from "./ChatArea";
import ChatMessageInputArea from "./ChatMessageInputArea";

const WEBSOCKET_URL = "wss://your-websocket-url";

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "calc(100vh - 200px)",
  [theme.breakpoints.down("md")]: {
    height: "100vh",
  },
}));

function MessagesMain() {
  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "No rush though — we still have to wait for Lana's designs.",
      sender: "Katherine Moss",
      timestamp: new Date("2024-08-18T11:44:00").getTime(),
      date: "2024-08-18",
    },
    {
      id: 2,
      text: "No rush though — we still have to wait for Lana's designs.",
      sender: "Katherine Moss",
      timestamp: new Date("2024-08-21T11:44:00").getTime(),
      date: "2024-08-21",
    },
    {
      id: 3,
      text: "Sure thing, I'll have a look today. They're looking great!",
      sender: "You",
      timestamp: new Date("2024-08-22T12:00:00").getTime(),
      date: "2024-08-22",
    },
    {
      id: 4,
      text: "Hey Olivia, can you please review the latest design when you can?",
      sender: "Katherine Moss",
      timestamp: new Date("2024-08-22T14:20:00").getTime(),
      date: "2024-08-22",
    },
  ]);

  const [loadingMessage, setLoadingMessage] = useState(null);
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const socket = new WebSocket(WEBSOCKET_URL);

    socket.onmessage = (event) => {
      const incomingMessage = JSON.parse(event.data);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages.length + 1,
          text: incomingMessage.text,
          sender: incomingMessage.sender,
          timestamp: new Date().getTime(),
          date: new Date().toISOString().split("T")[0],
        },
      ]);
    };

    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close();
    };
  }, []);

  const handleSendMessage = (newMessage) => {
    const sentTime = new Date();
    const userMessageId = messages.length + 1;

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        ...newMessage,
        id: userMessageId,
        timestamp: sentTime.getTime(),
      },
    ]);

    setTimeout(() => {
      setLoadingMessage({
        id: userMessageId + 1,
        sender: "Katherine Moss",
        timestamp: new Date().getTime(),
        date: new Date().toISOString().split("T")[0],
      });
    }, 1000);
    setTimeout(() => {
      setLoadingMessage(null);
      const reply = generateIntelligentReply(newMessage.text);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: userMessageId + 1,
          text: reply,
          sender: "Katherine Moss",
          timestamp: new Date().getTime(),
          date: new Date().toISOString().split("T")[0],
        },
      ]);
    }, 4000);
  };

  return (
    <MainContainer>
      <MessageHeader isOnline={isOnline} />
      <ChatArea
        messages={messages}
        loadingMessage={loadingMessage}
        isOnline={isOnline}
      />
      <ChatMessageInputArea onSendMessage={handleSendMessage} />
    </MainContainer>
  );
}

export default MessagesMain;

const generateIntelligentReply = (userMessage) => {
  const responses = {
    greeting: [
      "Hi there! How can I assist you today?",
      "Hello! What's on your mind?",
    ],
    design: [
      "I’m on it. The designs are coming along nicely!",
      "Don't worry, the design team is working hard.",
    ],
    feedback: [
      "Thanks for the feedback! I'll review that today.",
      "Appreciate the input, I'll pass it on.",
    ],
    default: [
      "Got it! I'll get back to you on that.",
      "Thanks, I'll take a look and let you know.",
    ],
  };

  const lowerCaseMessage = userMessage.toLowerCase();

  if (lowerCaseMessage.includes("hello") || lowerCaseMessage.includes("hi")) {
    return getRandomReply(responses.greeting);
  } else if (lowerCaseMessage.includes("design")) {
    return getRandomReply(responses.design);
  } else if (
    lowerCaseMessage.includes("feedback") ||
    lowerCaseMessage.includes("review")
  ) {
    return getRandomReply(responses.feedback);
  } else {
    return getRandomReply(responses.default);
  }
};

const getRandomReply = (responseArray) => {
  const randomIndex = Math.floor(Math.random() * responseArray.length);
  return responseArray[randomIndex];
};
