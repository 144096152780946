import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import OverviewSleepChart from "../../../Components/PatientOverview Components/OverViewComponents/OverviewSleepChart";
import OverViewStatusCard from "../../../Components/PatientOverview Components/OverViewComponents/OverViewStatusCard";
import OverViewDiaryLogsChart from "../../../Components/PatientOverview Components/OverViewComponents/OverViewDiaryLogsChart";
import OverviewStepCountChart from "../../../Components/PatientOverview Components/OverViewComponents/OverviewStepCountChart";
import OverViewTreatmentAdherenceChart from "../../../Components/PatientOverview Components/OverViewComponents/OverViewTreatmentAdherenceChart";
import RightNameCard from "../../../Components/PatientOverview Components/OverViewComponents/OverViewRightNameCard";
import OverViewDates from "../../../Components/PatientOverview Components/OverViewComponents/OverViewDates";
import OverViewInfo from "../../../Components/PatientOverview Components/OverViewComponents/OverViewInfo";
import PropTypes from "prop-types";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

function OverViewMain({ formData, loading }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid item xs={12} md={9}>
        <CustomGrid>
          <OverViewDates />
        </CustomGrid>
        <OverViewStatusCard />
        <CustomGrid item xs={12}>
          <OverViewInfo />
        </CustomGrid>
        <CustomGrid item xs={12}>
          <OverViewTreatmentAdherenceChart />
        </CustomGrid>
        <CustomGrid item xs={12}>
          <OverviewStepCountChart />
        </CustomGrid>
        <CustomGrid item xs={12}>
          <OverViewDiaryLogsChart />
        </CustomGrid>
        <CustomGrid item xs={12}>
          <OverviewSleepChart />
        </CustomGrid>
      </Grid>
      <Grid item xs={12} md={3}>
        <RightNameCard formData={formData} loading={loading} />
      </Grid>
    </>
  );
}
OverViewMain.propTypes = {
  formData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};
export default OverViewMain;
