import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Avatar,
  Typography,
  Chip,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { colors } from "../../../Utils/colorUtils";
import dummyVideo from "../../../assets/dummy.mp4";
import { useParams } from "react-router-dom";

const StyledCard = styled(Card)({
  maxWidth: 400,
  margin: "44px auto",
  borderRadius: "10px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
  backgroundColor: "#FFFFFF",
  border: "1px solid #E0E0E0",
});

const StyledAvatar = styled(Avatar)({
  width: 54,
  height: 54,
  marginRight: 10,
});

const SectionTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: "14px",
  color: colors.textSecondary,
  marginBottom: 8,
  marginTop: 16,
});

const ConditionChip = styled(Chip)({
  margin: "4px",
  backgroundColor: colors.secondary,
  border: `1px solid ${colors.borderPrimary}`,
  borderRadius: "10px",
  color: colors.textTertiary,
});

const TreatmentPlanCard = styled(Card)({
  display: "flex",
  padding: 16,
  marginTop: 16,
  backgroundColor: colors.secondary,
  borderRadius: "10px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
  border: `1px solid ${colors.borderColor}`,
});

const CenteredBox = styled(Box)({
  marginTop: "28px",
  width: "100%",
  height: "100%",
});

const RightNameCard = ({ formData, loading }) => {
  const { patientId } = useParams();
  const [imageData, setImageData] = useState([]);
  const [error, setError] = useState(null);

  const fetchTreatmentData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plan-assignment/get-assignments/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      const assignments = response.data.data;
      if (assignments.length > 0) {
        setImageData(assignments[0]);
      }
    } catch (error) {
      console.error("Error fetching treatment data:", error);
      setError("Failed to fetch data.");
    }
  };
  useEffect(() => {
    fetchTreatmentData();
  }, []);
  const imageUrl =
    imageData?.treatmentPlan?.url ||
    "https://plus.unsplash.com/premium_photo-1663127710106-786eb7c45433?q=80&w=2854&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  console.log(imageData?.length);
  return (
    <StyledCard>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          {loading ? (
            <></>
          ) : (
            <StyledAvatar
              sx={{
                border: "2px solid white",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              }}
              alt={`${formData.firstName} ${formData.lastName}`}
              src={formData.profile || null}
            >
              {!formData.profile && formData.firstName && formData.lastName
                ? `${formData.firstName[0].toUpperCase()}${formData.lastName[0].toUpperCase()}`
                : null}
            </StyledAvatar>
          )}
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                color: colors.textPrimary,
              }}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: "#7F56D9" }} />
              ) : (
                `${formData.firstName} ${formData.lastName}`
              )}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: colors.textSecondary,
              }}
            >
              {loading ? "..." : formData.email}
            </Typography>
          </Box>
        </Box>
        <SectionTitle>About</SectionTitle>
        <Typography
          mb={2}
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            color: colors.textSecondary,
          }}
        >
          {loading
            ? "Loading..."
            : "Persistent sciatica, characterised by radiating pain from the lower back..."}
        </Typography>

        <SectionTitle variant="body2" color="textSecondary">
          Condition
        </SectionTitle>
        <Grid container>
          {[
            "Sciatica",
            "Slipped Disk",
            "Shooting Pains",
            "Limited Mobility",
            "Tight Hamstrings",
          ].map((condition) => (
            <ConditionChip
              key={condition}
              label={loading ? "..." : condition}
            />
          ))}
        </Grid>

        {imageData.length === 0 ? (
          ""
        ) : (
          <>
            <SectionTitle variant="body2" color="textSecondary">
              Treatment Plan
            </SectionTitle>

            <TreatmentPlanCard>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CenteredBox
                    sx={{
                      height: "80px",
                      backgroundColor: "black",
                      borderRadius: "10px",
                      marginTop: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="asd"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  </CenteredBox>
                </Grid>

                <Grid item xs={6}>
                  <CenteredBox>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "12px",
                        color: colors.textTertiary,
                      }}
                    >
                      {loading
                        ? "Loading..."
                        : imageData?.treatmentPlan?.treatment_plan_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: colors.textSecondary,
                      }}
                    >
                      {loading ? "..." : "5 exercises"}
                    </Typography>
                  </CenteredBox>
                </Grid>
              </Grid>
            </TreatmentPlanCard>
          </>
        )}
      </CardContent>
    </StyledCard>
  );
};

RightNameCard.propTypes = {
  formData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RightNameCard;
