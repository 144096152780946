import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Box, Typography, Divider } from "@mui/material";

const data = [
  { day: "Mon", low: 2, medium: 3, high: 5 },
  { day: "Tue", low: 4, medium: 2, high: 2 },
  { day: "Wed", low: 1, medium: 1, high: 2 },
  { day: "Thu", low: 3, medium: 2, high: 3 },
  { day: "Fri", low: 2, medium: 2, high: 1 },
  { day: "Sat", low: 3, medium: 3, high: 4 },
  { day: "Sun", low: 2, medium: 2, high: 2 },
];

const OverviewSleepChart = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#ffffff",
        borderRadius: 2,
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        overflow: "hidden",
        marginTop: "30px",
        marginBottom: "30px",
      }}
    >
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Sleep
        </Typography>
      </Box>
      <Divider
        sx={{
          borderBottom: "2px solid #EAECF0",
          mx: -3,
        }}
      />
      <Box sx={{ p: 3, position: "relative" }}>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "40px" },
              position: "relative",
              height: { xs: "20px", md: "auto" },
              mb: { xs: 2, md: 0 },
            }}
          >
            <Typography
              variant="body2"
              sx={{
                position: { xs: "relative", md: "absolute" },
                top: { xs: "auto", md: "50%" },
                left: { xs: "auto", md: 0 },
                transform: {
                  xs: "none",
                  md: "rotate(-90deg) translateX(-50%)",
                },
                transformOrigin: "0 0",
                color: "#666",
                fontSize: "0.75rem",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Time of day
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={data}
                barSize={30}
                margin={{ left: 10, right: 10, top: 10, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis
                  dataKey="day"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: "#666", fontSize: 12 }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: "#666", fontSize: 12 }}
                  ticks={[0, 2, 4, 6, 8, 10]}
                  tickFormatter={(value) => `${value}hrs`}
                  domain={[0, "dataMax"]} // Ensures the domain starts at 0 and goes to the maximum value in the data
                />
                <Bar
                  dataKey="low"
                  stackId="a"
                  fill="#6941C6"
                  radius={[0, 0, 0, 0]}
                />
                <Bar
                  dataKey="medium"
                  stackId="a"
                  fill="#9E77ED"
                  radius={[0, 0, 0, 0]}
                />
                <Bar
                  dataKey="high"
                  stackId="a"
                  fill="#EAECF0"
                  radius={[4, 4, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#666",
            mt: 2,
            fontSize: "0.75rem",
          }}
        >
          7 days
        </Typography>
      </Box>
    </Box>
  );
};

export default OverviewSleepChart;
