import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Avatar, Typography, Divider, Grid } from "@mui/material";
import { styled } from "@mui/system";
import ChatLoader from "./ChatLoader";

const MessageContainer = styled(Box)(({ theme, isUser }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: isUser ? "flex-end" : "flex-start",
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(1),
  },
}));

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: 8,
  borderTopLeftRadius: isUser ? "8px" : "0px",
  borderTopRightRadius: isUser ? "0px" : "8px",
  border: "1px solid #EAECF0",
  backgroundColor: isUser ? "#9747FF" : "#F9FAFB",
  color: isUser ? "#FFFFFF" : "#344054",
  maxWidth: "50%",
  minWidth: "80px",
  width: "auto",
  marginLeft: isUser ? "0px" : "48px",
  [theme.breakpoints.down("md")]: {
    maxWidth: "90%",
    marginLeft: isUser ? "0px" : "16px",
  },
}));

const TimeStamp = styled(Typography)(({ theme, isUser }) => ({
  fontSize: "12px",
  color: "#475467",
  fontWeight: "400",
  paddingBottom: "6px",
  alignSelf: isUser ? "flex-end" : "flex-start",
}));

const SenderName = styled(Typography)({
  fontSize: "14px",
  fontWeight: 500,
  color: "#344054",
  marginBottom: "4px",
});

const DateDivider = styled(Divider)({
  margin: "16px 0",
  color: "#667085",
  fontSize: "12px",
  "&::before, &::after": {
    borderColor: "#E4E7EC",
  },
});

const AvatarContainer = styled("div")({
  position: "relative",
  display: "inline-block",
});

const ActiveIndicator = styled("div")(({ backgroundColor }) => ({
  width: "12px",
  height: "12px",
  backgroundColor: backgroundColor || "#22C55E",
  borderRadius: "50%",
  border: "2px solid white",
  position: "absolute",
  bottom: "4px",
  right: "4px",
}));

function ChatArea({ messages, loadingMessage, isOnline }) {
  const [bubbleWidths, setBubbleWidths] = useState({});
  const [currentTime, setCurrentTime] = useState(Date.now());
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, loadingMessage]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const formatMessageTime = (timestamp) => {
    const messageTime = new Date(timestamp);
    const timeDiff = currentTime - messageTime.getTime();

    if (timeDiff < 10000) {
      return "Just now";
    } else {
      return messageTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  };

  const formatDate = (dateString) => {
    const messageDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);
    messageDate.setHours(0, 0, 0, 0);

    if (messageDate.getTime() === today.getTime()) {
      return "Today";
    } else if (messageDate.getTime() === yesterday.getTime()) {
      return "Yesterday";
    } else {
      return messageDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  };

  const renderMessage = (message, isLoading = false) => {
    const isUser = message.sender === "You";

    return (
      <MessageContainer isUser={isUser}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: isUser ? "flex-end" : "flex-start",
            marginBottom: "4px",
            width: "100%",
            background: "#F9FAFB",
          }}
        >
          {isUser ? (
            <Grid
              id={`grid-${message.id}`}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
                justifyContent: "space-between",
                width: bubbleWidths[message.id] || "auto",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "4px",
                }}
              >
                <SenderName>{message.sender}</SenderName>
              </Grid>
              <Grid>
                <TimeStamp>{formatMessageTime(message.timestamp)}</TimeStamp>
              </Grid>
            </Grid>
          ) : (
            <Grid
              id={`grid-${message.id}`}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
                justifyContent: "space-between",
                width: `calc(${bubbleWidths[message.id]} + 46px)` || "auto",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "4px",
                }}
              >
                <AvatarContainer>
                  <Avatar
                    sx={{ width: 40, height: 40, marginRight: 1 }}
                    src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80"
                  />
                  <ActiveIndicator
                    backgroundColor={isOnline ? "#22C55E" : "#D14343"}
                  />
                </AvatarContainer>
                <SenderName>{message.sender}</SenderName>
              </Grid>
              <Grid>
                <TimeStamp>{formatMessageTime(message.timestamp)}</TimeStamp>
              </Grid>
            </Grid>
          )}
        </Box>
        <MessageBubble id={`bubble-${message.id}`} isUser={isUser}>
          {isLoading ? <ChatLoader /> : message.text}
        </MessageBubble>
      </MessageContainer>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        overflowY: "auto",
        height: "100%",
        background: "#F9FAFB",
      }}
    >
      {messages.length > 0 &&
        messages.map((message, index) => (
          <React.Fragment key={message.id}>
            {(index === 0 ||
              new Date(message.timestamp).toDateString() !==
                new Date(messages[index - 1].timestamp).toDateString()) && (
              <DateDivider>{formatDate(message.timestamp)}</DateDivider>
            )}
            {renderMessage(message)}
          </React.Fragment>
        ))}
      {loadingMessage && renderMessage(loadingMessage, true)}
      <div ref={messagesEndRef} />
    </Box>
  );
}

ChatArea.propTypes = {
  messages: PropTypes.array.isRequired,
  loadingMessage: PropTypes.object,
  isOnline: PropTypes.bool.isRequired,
};

export default ChatArea;
