import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Typography,
  Grid,
  IconButton,
  Switch,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useNavigate, useParams } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import shareRight from "../../../assets/shareRight.svg";
import EducationArticleUploadText from "./EducationArticleUploadText";
import { styled } from "@mui/material/styles";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { colors } from "../../../Utils/colorUtils";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D0D5DD",
      borderRadius: "10px",
    },
  },
});

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 22,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(16px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark"
            ? colors.violetPrimary
            : colors.violetPrimary,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgba(0, 35, 11, 0.2)",
    width: 22,
    height: 22,
    borderRadius: 11,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#39393D" : "#E9E9EA",
    boxSizing: "border-box",
  },
}));

const EducationNewArticle = () => {
  const [articleData, setArticleData] = useState({
    name: "",
    postBody: "",
    authorName: "",
    authorDiscipline: "",
    mainImage: "",
    authorImage: "",
    publish: false,
    tags: [""],
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_BACKEND_URI}/api/v1/articles/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const article = response.data.data;
          setArticleData({
            name: article.name,
            postBody: article.post_body,
            authorName: article.author_name,
            authorDiscipline: article.author_discipline,
            mainImage: article.main_image,
            authorImage: article.author_image,
            publish: article.publish,
            tags: article.tags || [""],
          });
          setIsPublished(article.publish);
        })
        .catch((error) => {
          console.error("Error fetching article:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, token]);

  const validateForm = () => {
    let errors = {};

    if (!articleData.name.trim()) {
      errors.name = "Name is required.";
    }

    if (!articleData.postBody.trim()) {
      errors.postBody = "Post Body is required.";
    }

    if (
      articleData.tags.length === 0 ||
      articleData.tags.some((tag) => !tag.trim())
    ) {
      errors.tags = "At least one valid tag is required.";
    }

    if (!articleData.authorName.trim()) {
      errors.authorName = "Author Name is required.";
    }

    if (!articleData.authorDiscipline.trim()) {
      errors.authorDiscipline = "Discipline is required.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = () => {
    if (!validateForm()) {
      return;
    }

    const docId = localStorage.getItem("docId");

    const url = id
      ? `${process.env.REACT_APP_BACKEND_URI}/api/v1/articles/update-article/${id}`
      : `${process.env.REACT_APP_BACKEND_URI}/api/v1/articles/create-article`;

    const method = id ? "put" : "post";

    axios({
      method: method,
      url: url,
      data: {
        ...articleData,
        postBody: articleData.postBody,
        doctorId: docId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        navigate("/education");
      })
      .catch((error) => {
        console.error("Error saving article:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticleData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleTagChange = (index, value) => {
    const newTags = [...articleData.tags];
    if (newTags.includes(value.trim())) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        tags: "Duplicate tags are not allowed.",
      }));
      return;
    }

    newTags[index] = value;
    setArticleData((prevData) => ({
      ...prevData,
      tags: newTags,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      tags: "",
    }));
  };

  const addTag = () => {
    if (articleData.tags.length < 3) {
      setArticleData((prevData) => ({
        ...prevData,
        tags: [...prevData.tags, ""],
      }));
    }
  };

  const removeTag = (index) => {
    const newTags = articleData.tags.filter((_, i) => i !== index);
    setArticleData((prevData) => ({
      ...prevData,
      tags: newTags,
    }));
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    if (id) {
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/articles/delete-article/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          navigate("/education");
        })
        .catch((error) => {
          console.error("Error deleting article:", error);
        });
    }
    handleCloseDeleteDialog();
  };

  const handleFileUpload = async (file, imageType) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/uploadToGcp/upload-file/article`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      );
      const imageUrl = response.data.url;
      setArticleData((prevData) => ({ ...prevData, [imageType]: imageUrl }));
    } catch (error) {
      console.error("Error uploading the image:", error);
    }
  };

  const customToolbar = [
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
  ];

  const backButton = () => {
    navigate("/education");
  };

  return (
    <Box sx={{ paddingX: 3, paddingBottom: { xs: "120px" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: { xs: "100%", sm: "auto" },
            mb: { xs: 2, sm: 0 },
          }}
        >
          <Button
            onClick={() => {
              localStorage.setItem("tabValue", "1");
              navigate("/education");
            }}
            sx={{
              mr: { xs: 0, sm: 2 },
              borderRadius: "1px",
              borderColor: "white",
              color: "#7A5AF8",
              textTransform: "none",
              height: "40px",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            <ArrowBackRoundedIcon /> &nbsp; Back to Education
          </Button>

          <Box
            sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center" }}
          >
            <Typography sx={{ mr: 1, fontWeight: 600 }} color="textSecondary">
              Status:
            </Typography>
            <Box
              sx={{
                backgroundColor: isPublished ? "#E6FFFA" : "#FFF5F5",
                padding: "0 8px",
                borderRadius: "999px",
                height: "22px",
                border: `1px solid ${isPublished ? "#99F6E0" : "#FECACA"}`,
                color: isPublished ? "#107569" : "#B91C1C",
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "18px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircleIcon
                sx={{
                  width: 15,
                  height: 10,
                  color: isPublished ? "#107569" : "#B91C1C",
                  mr: 1,
                }}
              />
              {isPublished ? "Published" : "Unpublished"}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Box
            sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}
          >
            <Typography sx={{ mr: 1, fontWeight: 600 }} color="textSecondary">
              Status:
            </Typography>
            <Box
              sx={{
                backgroundColor: isPublished ? "#E6FFFA" : "#FFF5F5",
                padding: "0 8px",
                borderRadius: "999px",
                height: "22px",
                border: `1px solid ${isPublished ? "#99F6E0" : "#FECACA"}`,
                color: isPublished ? "#107569" : "#B91C1C",
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "18px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircleIcon
                sx={{
                  width: 15,
                  height: 10,
                  color: isPublished ? "#107569" : "#B91C1C",
                  mr: 1,
                }}
              />
              {isPublished ? "Published" : "Unpublished"}
            </Box>
          </Box>
          <Button
            variant="outlined"
            sx={{
              mr: { xs: 0, sm: 1 },
              borderRadius: "8px",
              borderColor: "#E0E0E0",
              color: "black",
              textTransform: "none",
              height: "40px",
              fontSize: "14px",
              flex: { xs: 1, sm: "none" },
            }}
            onClick={backButton}
          >
            Close
          </Button>
          <Button
            sx={{
              borderRadius: "8px",
              backgroundColor: "#7A5AF8",
              color: "#FFFFFF",
              textTransform: "none",
              height: "40px",
              fontSize: "14px",
              flex: { xs: 1, sm: "none" },
              "&:hover": {
                backgroundColor: colors.violetSecondary,
              },
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Divider
        sx={{
          mt: 2,
          background: "#EAECF0",
        }}
      />

      <Grid container spacing={2} style={{ marginTop: 10, paddingTop: 10 }}>
        <Grid item xs={12} sm={6} lg={3} />
        <Grid item xs={12} sm={6} lg={6}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "24px",
              color: colors.textPrimary,
            }}
          >
            Article
          </Typography>
          <Typography
            gutterBottom
            sx={{
              mt: 3,
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
            }}
          >
            Name
          </Typography>
          <CustomTextField
            name="name"
            value={articleData.name}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            error={!!validationErrors.name}
            helperText={validationErrors.name}
          />
          <Typography
            gutterBottom
            sx={{
              mt: 3,
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
            }}
          >
            Post Body
          </Typography>
          <CKEditor
            editor={ClassicEditor}
            config={{ toolbar: customToolbar }}
            data={articleData.postBody}
            onChange={(event, editor) => {
              const data = editor.getData();
              setArticleData((prevData) => ({
                ...prevData,
                postBody: data,
              }));

              setValidationErrors((prevErrors) => ({
                ...prevErrors,
                postBody: "",
              }));
            }}
          />
          {validationErrors.postBody && (
            <Typography color="error" variant="body2">
              {validationErrors.postBody}
            </Typography>
          )}

          <Typography
            variant="body1"
            gutterBottom
            sx={{
              mt: 3,
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
            }}
          >
            Tags (Max 3)
          </Typography>
          {articleData.tags.map((tag, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <CustomTextField
                value={tag}
                onChange={(e) => handleTagChange(index, e.target.value)}
                variant="outlined"
                placeholder="Enter tag"
                sx={{ width: "90%" }}
              />
              {articleData.tags.length > 1 && (
                <IconButton onClick={() => removeTag(index)}>
                  <RemoveCircleOutlineIcon color="error" />
                </IconButton>
              )}
            </Box>
          ))}
          {validationErrors.tags && (
            <Typography variant="body2" color="error">
              {validationErrors.tags}
            </Typography>
          )}
          {articleData.tags.length < 3 && (
            <Button
              variant="outlined"
              onClick={addTag}
              sx={{
                mb: 2,
                borderRadius: "8px",
                borderColor: "#E0E0E0",
                textTransform: "none",
              }}
            >
              <AddCircleOutlineIcon /> Add Tag
            </Button>
          )}

          <Typography
            variant="body1"
            gutterBottom
            sx={{
              mt: 3,
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
            }}
          >
            Main Image
          </Typography>
          <EducationArticleUploadText
            imageSrc={articleData.mainImage}
            onImageUpload={(file) => handleFileUpload(file, "mainImage")}
          />
          <Divider sx={{ mt: 2, background: "#EAECF0" }} />
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              mt: 3,
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
            }}
          >
            Author
          </Typography>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} sm={6}>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: colors.textPrimary,
                }}
              >
                Name
              </Typography>
              <CustomTextField
                name="authorName"
                value={articleData.authorName}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                error={!!validationErrors.authorName}
                helperText={validationErrors.authorName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: colors.textPrimary,
                }}
              >
                Discipline
              </Typography>
              <CustomTextField
                name="authorDiscipline"
                value={articleData.authorDiscipline}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                error={!!validationErrors.authorDiscipline}
                helperText={validationErrors.authorDiscipline}
              />
            </Grid>
          </Grid>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              mt: 3,
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
            }}
          >
            Author Photo
          </Typography>
          <EducationArticleUploadText
            imageSrc={articleData.authorImage}
            onImageUpload={(file) => handleFileUpload(file, "authorImage")}
          />
          <Divider sx={{ mt: 2, background: "#EAECF0" }} />
          {id && (
            <Button
              variant="outlined"
              onClick={handleOpenDeleteDialog}
              sx={{
                mr: 2,
                mt: 2,
                mb: 5,
                borderRadius: "8px",
                borderColor: "#E0E0E0",
                color: "black",
                textTransform: "none",
                height: "40px",
                fontSize: "14px",
              }}
            >
              <DeleteOutlineRoundedIcon /> Delete Article
            </Button>
          )}
        </Grid>
        <Grid item xs={1} />

        <Grid item xs={12} sm={6} lg={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                color: colors.textPrimary,
              }}
            >
              Settings
            </Typography>
            <Button
              variant="outlined"
              sx={{
                mr: 2,
                borderRadius: "1px",
                borderColor: "white",
                textTransform: "none",
                height: "40px",
                fontWeight: 600,
                fontSize: "14px",
                color: colors.textSecondary,
                "&:hover": {
                  border: "none",
                  bgcolor: "none",
                  outline: "none",
                },
              }}
            >
              Share
              <img
                src={shareRight}
                alt="shareRight"
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </Box>

          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
            }}
          >
            Publish
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: colors.textSecondary,
                mr: 2,
                flex: 1,
              }}
            >
              Share to patients using AI recommendations.
            </Typography>
            <CustomSwitch
              checked={articleData.publish}
              onChange={() =>
                setArticleData((prevData) => ({
                  ...prevData,
                  publish: !prevData.publish,
                }))
              }
            />
          </Box>
          <Divider sx={{ mt: 2, background: "#EAECF0" }} />
          {/* <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
              mt: 2,
            }}
          >
            Privacy
          </Typography> */}
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: colors.textSecondary,
                mr: 2,
                flex: 1,
              }}
            >
              Enable shareable link even when the article is not published.
            </Typography>
            <CustomSwitch />
          </Box> */}
        </Grid>
      </Grid>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            color: colors.textSecondary,
            paddingBottom: "5px",
          }}
        >
          Remove article
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: "12px",
              color: colors.textSecondary,
              fontWeight: 400,
            }}
          >
            Are you sure you want to remove this article?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "10px" }}>
          <Button
            onClick={handleCloseDeleteDialog}
            style={{
              marginRight: "10px",
              borderRadius: "8px",
              width: "70px",
              color: "black",
              height: "32px",
              fontWeight: 400,
              borderColor: "#667085",
              border: "1px solid black",
              textTransform: "none",
              fontSize: "12px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            style={{
              borderRadius: "8px",
              width: "70px",
              height: "32px",
              backgroundColor: "#7f56d9",
              border: "none",
              color: "#fff",
              fontWeight: 400,
              textTransform: "none",
              fontSize: "12px",
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EducationNewArticle;
