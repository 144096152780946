import React, { useRef, useState, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import DeleteIcons from "../../../assets/deleteicon.svg";
import Refresh from "../../../assets/refresh-cw-02.svg";
import link from "../../../assets/link-external-01.svg";
import galleryIcon from "../../../assets/galleryIcon.svg";

const UploadBox = styled(Box)(({ theme }) => ({
  border: "1px solid #D0D5DD",
  borderRadius: "12px",
  width: "100%",
  maxWidth: "auto",
  minHeight: "100px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
  "&.dragOver": {
    backgroundColor: "#F9FAFB",
    borderColor: "#7F56D9",
  },
}));

const FileInput = styled("input")({
  display: "none",
});

const AddIconWrapper = styled(Box)(({ theme }) => ({
  height: 30,
  width: 30,
  borderRadius: "10px",
  textAlign: "center",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
}));

const EducationArticleUploadText = ({ imageSrc, onImageUpload }) => {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(imageSrc || null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (imageSrc) {
      setImage(imageSrc);
    }
  }, [imageSrc]);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleFile = async (file) => {
    if (file) {
      setIsUploading(true);
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageUrl = e.target.result;
          setImage(imageUrl);
          onImageUpload(file);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error processing image", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleReplaceImage = () => {
    setImage(null);
    handleUploadClick();
  };

  const handleRemoveImage = () => {
    setImage(null);
    onImageUpload(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragOver(false);

    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  return (
    <>
      {!image ? (
        <UploadBox
          className={isDragOver ? "dragOver" : ""}
          onClick={handleUploadClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <FileInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/svg+xml,image/png,image/jpeg,image/gif"
          />
          <AddIconWrapper>
            <img src={galleryIcon} alt="galleryIcon" />
          </AddIconWrapper>
          <Typography
            sx={{
              color: "#667085",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              mt: "8px",
              textAlign: "center",
            }}
          >
            <span style={{ color: "#7F56D9", fontWeight: 600 }}>
              Click to upload
            </span>{" "}
            or drag and drop
          </Typography>
          {isUploading && <Typography>Uploading...</Typography>}
        </UploadBox>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          border="1px solid #D0D5DD"
          borderRadius="12px"
          padding="8px"
          mt={2}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <img
              src={image}
              alt="Uploaded"
              style={{
                width: "100px",
                height: "auto",
                objectFit: "cover",
                borderRadius: "4px",
                marginRight: "10px",
              }}
            />
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Uploaded Image
              </Typography>
              <Typography variant="caption" sx={{ color: "#475467" }}>
                Dimensions: 1636 × 864
              </Typography>
            </Box>
          </Box>
          <button
            onClick={handleRemoveImage}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
            }}
            aria-label="Remove Image"
          >
            <img
              src={link}
              alt="Remove"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
            />
          </button>
        </Box>
      )}
      {image && (
        <Box display="flex" justifyContent="flex-start" mt={1}>
          <Button
            variant="outlined"
            sx={{
              color: "#344054",
              border: "1px solid #D0D5DD",
              textTransform: "none",
              marginRight: "8px",
              borderRadius: "2px",
              fontWeight: 700,
            }}
            onClick={handleReplaceImage}
          >
            <img
              src={Refresh}
              alt="Replace"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
            />
            Replace
          </Button>
          <Button
            variant="outlined"
            onClick={handleRemoveImage}
            sx={{
              color: "#344054",
              border: "1px solid #D0D5DD",
              textTransform: "none",
              marginRight: "8px",
              fontWeight: 700,
            }}
          >
            <img
              src={DeleteIcons}
              alt="Delete"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
            />
            Delete
          </Button>
        </Box>
      )}
    </>
  );
};

EducationArticleUploadText.propTypes = {
  imageSrc: PropTypes.string,
  onImageUpload: PropTypes.func.isRequired,
};

export default EducationArticleUploadText;
