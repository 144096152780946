import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { Box, Typography, Button, useMediaQuery, Dialog } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";
import UploadImage from "../../../assets/upload.svg";
import DocumentTable from "./DocumentTable";
import DocumentOverViewUtils from "./DocumentOverViewUtils";
import UploadPopUpDoc from "./UploadPopUpDoc";
import { useParams } from "react-router-dom";
import PpSnackbar from "../../../Utils/PpSnackbar";

const MainContainer = styled(Box)(({ theme }) => ({
  border: "1px solid #EAECF0",
  borderRadius: 12,
  backgroundColor: "#fff",
  boxShadow:
    "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
  overflow: "hidden",
  marginTop: "30px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: 8,
  backgroundColor: colors.secondary,
  color: colors.textTertiary,
  border: `1px solid ${colors.borderPrimary}`,
  height: "40px",
  padding: "10px 14px",
  boxShadow: "none",
  fontWeight: "400",
  fontSize: "14px",
}));

const StyledDivider = styled(Box)({
  borderBottom: "1px solid #EAECF0",
  margin: "0 -24px",
});

const API_BASE_URL = `${process.env.REACT_APP_BACKEND_URI}`;
const TOKEN = localStorage.getItem("token");

function DocumentMain() {
  const { patientId } = useParams();
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [profile, setProfile] = useState({
    id: "",
  });
  const [viewMode, setViewMode] = useState("all");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    filterFilesByViewMode();
  }, [viewMode, files]);

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/documents/patient/${patientId}`,
        {
          headers: { Authorization: `Bearer ${TOKEN}` },
        },
      );
      if (response.data && response.data.success) {
        const mappedFiles = response.data.data.map((file) => {
          const decodedFileName = decodeURIComponent(file.file_name);
          const fileName = decodedFileName.split("-").pop();
          return {
            id: file.id,
            name: fileName,
            size: `${(file.file_size / 1024).toFixed(2)} KB`,
            dateUploaded: new Date(file.createdAt).toLocaleDateString(),
            lastUpdated: new Date(file.updatedAt).toLocaleDateString(),
            uploadedBy: `${file.doctor.user.first_name} ${file.doctor.user.last_name}`,
            doctorId: file.doctor.user.id,
            email: `${file.doctor.user.email}`,
            profile_picture: `${file.doctor.user.profile_picture}`,
            type: file.file_name.endsWith(".pdf") ? "PDF" : "Image",
          };
        });
        setFiles(mappedFiles);
        setFilteredFiles(mappedFiles);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      setSnackbarMessage("Failed to fetch documents");
      setSnackbarOpen(true);
    }
  };

  const handleSearch = (searchTerm) => {
    const searchResults = _.filter(files, (file) => {
      const lowerSearchTerm = searchTerm.toLowerCase();
      return (
        file.name.toLowerCase().includes(lowerSearchTerm) ||
        file.uploadedBy.toLowerCase().includes(lowerSearchTerm) ||
        file.email.toLowerCase().includes(lowerSearchTerm)
      );
    });

    setFilteredFiles(searchResults);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileUpload = async (fileUrls) => {
    try {
      if (selectedFile) {
        const response = await axios.put(
          `${API_BASE_URL}/api/v1/documents/${selectedFile.id}`,
          {
            file: fileUrls[0],
            fileName: fileUrls[0].split("/").pop(),
            fileSize: await getFileSize(fileUrls[0]),
          },
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
            },
          },
        );
        if (response.data && response.data.success) {
          setSnackbarMessage("File updated successfully");
          setSnackbarOpen(true);
        }
      } else {
        const uploadPromises = fileUrls.map(async (fileUrl) => {
          const fileName = fileUrl.split("/").pop();
          const fileSize = await getFileSize(fileUrl);

          return axios.post(
            `${API_BASE_URL}/api/v1/documents/upload-document`,
            {
              file: fileUrl,
              fileName: fileName,
              fileSize: fileSize,
              patientId: patientId,
            },
            {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
              },
            },
          );
        });

        await Promise.all(uploadPromises);
        setSnackbarMessage("Files uploaded successfully");
        setSnackbarOpen(true);
      }

      fetchDocuments();
      handleClose();
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading/updating files:", error);
      setSnackbarMessage(
        error.response?.data?.message || "Failed to upload/update files",
      );
      setSnackbarOpen(true);
    }
  };

  const getFileSize = async (url) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      return response.headers["content-length"]
        ? parseInt(response.headers["content-length"], 10)
        : response.data.size;
    } catch (error) {
      console.error("Error getting file size:", error);
      return 0;
    }
  };

  const handleEdit = (file) => {
    setSelectedFile(file);
    setShowUploadDialog(true);
  };

  const handleDialogClose = () => {
    setShowUploadDialog(false);
    setSelectedFile(null);
  };

  const handleDeleteFile = async (fileToDelete) => {
    try {
      await axios.delete(
        `${API_BASE_URL}/api/v1/documents/${fileToDelete.id}`,
        {
          headers: { Authorization: `Bearer ${TOKEN}` },
        },
      );
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/documents/patient/${patientId}`,
        {
          headers: { Authorization: `Bearer ${TOKEN}` },
        },
      );
      if (response.data && response.data.success) {
        const mappedFiles = response.data.data.map((file) => {
          const decodedFileName = decodeURIComponent(file.file_name);
          const fileName = decodedFileName.split("-").pop();
          return {
            id: file.id,
            name: fileName,
            size: `${(file.file_size / 1024).toFixed(2)} KB`,
            dateUploaded: new Date(file.createdAt).toLocaleDateString(),
            lastUpdated: new Date(file.updatedAt).toLocaleDateString(),
            uploadedBy: `${file.doctor.user.first_name} ${file.doctor.user.last_name}`,
            profile_picture: `${file.doctor.user.profile_picture}`,
            email: `${file.doctor.user.email}`,
            type: file.file_name.endsWith(".pdf") ? "PDF" : "Image",
          };
        });
        setFiles(mappedFiles);
        setFilteredFiles(mappedFiles);
        setSnackbarMessage("File deleted successfully");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      setSnackbarMessage("Failed to delete file");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchProfile = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");

    if (!token || !userId) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/auth/get-doctor-profile/${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      const data = responseData.data;
      setProfile({
        id: `${data.id}`,
      });
    } catch (error) {
      console.error("Error fetching profile:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const filterFilesByViewMode = () => {
    if (viewMode === "all") {
      setFilteredFiles(files);
    } else if (viewMode === "your") {
      setFilteredFiles(files.filter((file) => file.doctorId === profile.id));
    } else if (viewMode === "shared") {
      setFilteredFiles(files.filter((file) => file.doctorId !== profile.id));
    }
  };

  const handleViewModeChange = (newMode) => {
    setViewMode(newMode);
  };

  return (
    <>
      <MainContainer>
        <Box sx={{ padding: 3, paddingBottom: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: isMobile ? "flex-start" : "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              mb: 0,
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontWeight: "600",
                color: colors.textPrimary,
                fontSize: "18px",
                width: isMobile ? "100%" : "auto",
                mb: isMobile ? 1 : 0,
              }}
            >
              Attached files
            </Typography>
            {isMobile && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mb: isMobile ? 2 : 3,
                  fontWeight: "400",
                  color: colors.textSecondary,
                  fontSize: "14px",
                  width: isMobile ? "100%" : "auto",
                }}
              >
                Files and assets that have been attached to this project.
              </Typography>
            )}
            <StyledButton
              onClick={handleOpen}
              sx={{
                width: isMobile ? "100%" : "auto",
                mt: 0,
                mb: isMobile ? 2 : 0,
              }}
            >
              <img src={UploadImage} alt="uploading" />
              &nbsp;Upload Document
            </StyledButton>
          </Box>
          {!isMobile && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                mb: 3,
                fontWeight: "400",
                color: colors.textSecondary,
                fontSize: "14px",
              }}
            >
              Files and assets that have been attached to this project.
            </Typography>
          )}
          <StyledDivider />
          <DocumentOverViewUtils
            onSearch={handleSearch}
            onViewModeChange={handleViewModeChange}
          />
          <StyledDivider />
        </Box>
        <DocumentTable
          files={filteredFiles}
          onEdit={handleEdit}
          onDelete={handleDeleteFile}
        />
      </MainContainer>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <UploadPopUpDoc
          open={open}
          onClose={handleClose}
          onFileUpload={handleFileUpload}
          selectedFile={null}
          selectedFileId={selectedFile ? selectedFile.id : null}
        />
      </Dialog>
      <Dialog
        open={showUploadDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <UploadPopUpDoc
          open={showUploadDialog}
          onClose={handleDialogClose}
          onFileUpload={handleFileUpload}
          selectedFile={selectedFile}
          selectedFileId={selectedFile ? selectedFile.id : null}
        />
      </Dialog>
      <PpSnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
}

export default DocumentMain;
