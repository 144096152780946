import React from "react";
import {
  Typography,
  Box,
  Grid,
  Container,
  Divider,
  RadioGroup,
  FormControlLabel,
  TextField,
  Radio,
} from "@mui/material";
import { colors } from "../../Utils/colorUtils";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const DividerStyled = styled(Divider)({
  borderBottomWidth: "2px",
  borderColor: colors.borderColor,
  marginTop: "0px",
  marginBottom: "0px",
});

const CustomRadio = styled(Radio)(({ theme }) => ({
  "& .MuiSvgIcon-root": { display: "none" },
  "& .MuiTouchRipple-root": { display: "none" },
  "& .Mui-checked": { color: colors.violetPrimary },
}));

const PainLevelSection = ({
  title,
  description,
  selectedPainLevel,
  handlePainLevelChange,
}) => (
  <Grid container spacing={2} alignItems="center" sx={{ mt: 2, mb: 4 }}>
    <Grid item xs={5}>
      <Typography
        sx={{ fontSize: "14px", fontWeight: 600, color: colors.textTertiary }}
      >
        {title}
      </Typography>
      <Typography
        sx={{ fontSize: "14px", fontWeight: 400, color: colors.textSecondary }}
      >
        {description}
      </Typography>
    </Grid>
    <Grid item xs={7}>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {Array.from({ length: 10 }, (_, i) => i + 1).map((level) => (
          <Box
            key={level}
            sx={{
              width: "10%",
              height: "50px",
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
              backgroundColor:
                selectedPainLevel === level
                  ? colors.violetPrimary
                  : "transparent",
              border: `1px solid ${selectedPainLevel === level ? colors.violetPrimary : "#D0D5DD"}`,
              color: selectedPainLevel === level ? "white" : "black",
              padding: "8px 0",
              "&:hover": { backgroundColor: colors.violetSecondary },
              "&:first-of-type": {
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
              },
              "&:last-of-type": {
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
              },
            }}
            onClick={() => handlePainLevelChange(level)}
          >
            {level}
          </Box>
        ))}
      </Box>
    </Grid>
  </Grid>
);

PainLevelSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  selectedPainLevel: PropTypes.number.isRequired,
  handlePainLevelChange: PropTypes.func.isRequired,
};

const RadioOptions = ({ Medication, handleInputChange }) => (
  <RadioGroup
    row
    name="medication"
    value={Medication}
    onChange={(e) => handleInputChange("Medication", e.target.value)}
    sx={{ width: "100%", justifyContent: "space-between" }}
  >
    {["yes", "no"].map((option) => (
      <FormControlLabel
        key={option}
        value={option}
        control={<CustomRadio />}
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 16px",
              borderRadius: "8px",
              border:
                Medication === option
                  ? `2px solid ${colors.violetPrimary}`
                  : "1px solid #D0D5DD",
              backgroundColor:
                Medication === option ? "#F9F5FF" : "transparent",
              width: "100%",
              height: "50px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: colors.textPrimary,
              }}
            >
              {option === "yes" ? "Yes" : "No"}
            </Typography>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                border:
                  Medication === option
                    ? `2px solid ${colors.violetPrimary}`
                    : "1px solid #D0D5DD",
                borderRadius: "50%",
                width: "16px",
                height: "16px",
                backgroundColor:
                  Medication === option ? colors.violetPrimary : "transparent",
              }}
            >
              {Medication === option && (
                <Box
                  sx={{
                    width: "8px",
                    height: "8px",
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                  }}
                />
              )}
            </Box>
          </Box>
        }
        sx={{
          margin: 0,
          width: "50%",
          "& .MuiFormControlLabel-label": {
            width: "100%",
          },
        }}
      />
    ))}
  </RadioGroup>
);

RadioOptions.propTypes = {
  Medication: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

const TextareaWithCounter = ({ label, text, maxChars, handleInputChange }) => (
  <>
    <TextField
      multiline
      fullWidth
      rows={8}
      value={text}
      onChange={(e) => handleInputChange(label, e.target.value)}
      variant="outlined"
      inputProps={{ maxLength: maxChars }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          borderColor: colors.borderPrimary,
        },
      }}
    />
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: 400,
        color: colors.textSecondary,
        mt: 1,
      }}
    >
      {maxChars - text.length} characters left
    </Typography>
  </>
);

TextareaWithCounter.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  maxChars: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

const SsNotesPageTwo = ({
  overallHealthLevel,
  workImpactLevel,
  stressLevel,
  treatmentResponseLevel,
  dietLevel,
  Medication,
  feedbackText,
  clinicianText,
  handlePainLevelChange,
  handleInputChange,
}) => {
  const maxChars = 300;

  return (
    <Container
      sx={{
        flexGrow: 1,
        overflowY: "auto",
        mt: 10,
        mb: 8,
        maxWidth: "100% !important",
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
      disableGutters
    >
      <PainLevelSection
        title="Overall Health"
        description="How have you been feeling overall since our last visit?"
        selectedPainLevel={overallHealthLevel}
        handlePainLevelChange={(level) =>
          handlePainLevelChange("overallHealthLevel", level)
        }
      />
      <DividerStyled />
      <Grid container spacing={2} alignItems="center" sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: colors.textTertiary,
            }}
          >
            Medication
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: colors.textSecondary,
            }}
          >
            Are you currently taking any medications? Have there been any
            changes to your medication regimen?
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <RadioOptions
            Medication={Medication}
            handleInputChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <DividerStyled />
      <PainLevelSection
        title="Work Impact"
        description="Has your pain impacted your ability to work or perform tasks at your job?"
        selectedPainLevel={workImpactLevel}
        handlePainLevelChange={(level) =>
          handlePainLevelChange("workImpactLevel", level)
        }
      />
      <DividerStyled />
      <PainLevelSection
        title="Stress Levels"
        description="How would you rate your current stress levels?"
        selectedPainLevel={stressLevel}
        handlePainLevelChange={(level) =>
          handlePainLevelChange("stressLevel", level)
        }
      />
      <DividerStyled />
      <PainLevelSection
        title="Treatment Response"
        description="How do you feel after completing your treatment or exercise sessions?"
        selectedPainLevel={treatmentResponseLevel}
        handlePainLevelChange={(level) =>
          handlePainLevelChange("treatmentResponseLevel", level)
        }
      />
      <DividerStyled />
      <PainLevelSection
        title="Diet"
        description="Have there been any changes in your diet that you think might be affecting your pain?"
        selectedPainLevel={dietLevel}
        handlePainLevelChange={(level) =>
          handlePainLevelChange("dietLevel", level)
        }
      />
      <DividerStyled />
      <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: colors.textTertiary,
            }}
          >
            Patient Feedback
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: colors.textSecondary,
            }}
          >
            Is there anything specific you would like to discuss or feedback
            you’d like to provide about your treatment plan?
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <TextareaWithCounter
            label="feedbackText"
            text={feedbackText}
            maxChars={maxChars}
            handleInputChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <DividerStyled />
      <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: colors.textTertiary,
            }}
          >
            Clinician’s Thoughts
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: colors.textSecondary,
            }}
          >
            Any thoughts or additional feedback from the clinician?
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <TextareaWithCounter
            label="clinicianText"
            text={clinicianText}
            maxChars={maxChars}
            handleInputChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

SsNotesPageTwo.propTypes = {
  overallHealthLevel: PropTypes.number.isRequired,
  workImpactLevel: PropTypes.number.isRequired,
  stressLevel: PropTypes.number.isRequired,
  treatmentResponseLevel: PropTypes.number.isRequired,
  dietLevel: PropTypes.number.isRequired,
  Medication: PropTypes.string.isRequired,
  feedbackText: PropTypes.string.isRequired,
  clinicianText: PropTypes.string.isRequired,
  handlePainLevelChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default SsNotesPageTwo;
