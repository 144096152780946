import React from "react";
import SettingsMain from "../../Components/Setting Components/SettingsMain";
import MainSideBar from "../../Components/Sidebar Component/MainSideBar";
import { Grid, Box } from "@mui/material";

function SettingsScreen() {
  return (
    <Box
      sx={{
        display: {
          md: "flex",
        },

        height: {
          xs: "100vh",
          md: "100vh",
        },
        overflow: "hidden",
      }}
      className="container-fluid"
    >
      <MainSideBar />
      <Grid
        className="col-12 col-md-10"
        sx={{
          height: "100%",
          padding: {
            xs: "16px",
            md: "16px 32px",
          },
          paddingBottom: {
            xs: "100px",
            md: "30px",
          },
          overflowY: {
            xs: "auto",
            md: "hidden",
          },
        }}
      >
        <SettingsMain />
      </Grid>
    </Box>
  );
}

export default SettingsScreen;
