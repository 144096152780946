import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  Grid,
  Divider,
  Avatar,
  Snackbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import axios from "axios";
import { useParams } from "react-router-dom";
import dots from "../../../assets/9Dots.svg";
import dustbin from "../../../assets/dustbin.svg";
import pencilIconppl from "../../../assets/pencilIconppl.svg";
import QuizEditDialog from "./QuizEditDialog";
import { colors } from "../../../Utils/colorUtils";

const NumberBox = styled(Box)({
  backgroundColor: "#7C4DFF",
  color: "#fff",
  borderRadius: "4px",
  padding: "4px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "8px",
});

const DragHandle = styled(Box)({
  width: "100%",
  height: "100%",
  backgroundColor: "#F9FAFB",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderLeft: "1px solid #EAECF0",
});

const QuestionCard = () => {
  const { id: quizId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  useEffect(() => {
    fetchQuizQuestions();
  }, [quizId]);

  const fetchQuizQuestions = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/questions/quizzes/${quizId}/questions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setQuestions(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setQuestions([]);
      } else {
        console.error("Error fetching quiz questions:", error);
      }
    }
  };

  const handleClickOpen = async (questionId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/questions/${questionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data && response.data.data) {
        const questionData = response.data.data;

        setEditingQuestion({
          id: questionData.id,
          question: questionData.question || "",
          supporting_text: questionData.supporting_text || "",
          options: Array.isArray(questionData.options)
            ? questionData.options.map((option) => ({
                optionText: option.optionText || "",
                isCorrect: option.isCorrect || false,
              }))
            : [],
          image: questionData.question_image || null,
        });

        setOpen(true);
      } else {
        console.error("Unexpected response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching question data:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEditingQuestion(null);
  };

  const handleSave = async (updatedQuestion) => {
    try {
      const token = localStorage.getItem("token");
      const url = editingQuestion?.id
        ? `${process.env.REACT_APP_BACKEND_URI}/api/v1/questions/${editingQuestion.id}`
        : `${process.env.REACT_APP_BACKEND_URI}/api/v1/questions/quizzes/${quizId}/questions`;
      const method = editingQuestion?.id ? "put" : "post";

      await axios({
        method,
        url,
        data: {
          question: updatedQuestion.questionTitle,
          questionImage: updatedQuestion.image,
          supportingText: updatedQuestion.supportingText,
          options: updatedQuestion.options.map((option) => ({
            optionText: option.optionText,
            isCorrect: option.isCorrect || false,
          })),
        },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      await fetchQuizQuestions();
      handleClose();
      showSnackbar(editingQuestion ? "Question updated!" : "Question added!");
    } catch (error) {
      console.error("Error saving question:", error);
    }
  };

  const addQuestion = () => {
    setEditingQuestion(null);
    setOpen(true);
  };

  const confirmRemoveQuestion = (questionId) => {
    setSelectedQuestionId(questionId);
    setOpenDeleteDialog(true);
  };

  const handleRemoveQuestion = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/questions/${selectedQuestionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      await fetchQuizQuestions();
      showSnackbar("Question deleted!");
      handleClose();
      handleCloseDeleteDialog();
    } catch (error) {
      console.error("Error removing question:", error);
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedQuestionId(null);
  };

  return (
    <Box>
      {questions.length === 0 ? (
        <Typography>No questions available</Typography>
      ) : (
        questions.map((question, index) => (
          <Card
            key={question.id || index}
            variant="outlined"
            sx={{ width: "100%", margin: "auto", borderRadius: 2, mb: 2 }}
          >
            <CardContent
              sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}
            >
              <Grid container>
                <Grid item xs={9}>
                  <Box sx={{ p: 2 }}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <NumberBox sx={{ width: 32, height: 32 }}>
                        <Typography
                          variant="h6"
                          sx={{ fontSize: 16, fontWeight: 600 }}
                        >
                          {index + 1}
                        </Typography>
                      </NumberBox>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: 16, fontWeight: 600 }}
                      >
                        {question.question || "Untitled Question"}
                      </Typography>
                    </Box>

                    <RadioGroup>
                      {Array.isArray(question.options) &&
                        question.options.map((option, optionIndex) => (
                          <FormControlLabel
                            key={optionIndex}
                            value={option.optionText}
                            control={<Radio checked={option.isCorrect} />}
                            label={
                              option.optionText || `Option ${optionIndex + 1}`
                            }
                          />
                        ))}
                    </RadioGroup>

                    <Box display="flex" justifyContent="space-between" mt={2}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          borderColor: "#D6BBFB",
                          color: "#6941C6",
                          textTransform: "none",
                        }}
                        onClick={() => handleClickOpen(question.id)}
                      >
                        <img
                          src={pencilIconppl}
                          alt="edit"
                          style={{ marginRight: "8px" }}
                        />
                        Edit
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  {question.question_image && (
                    <Box
                      mb={2}
                      mt={2}
                      mr={2}
                      display="flex"
                      justifyContent="center"
                      sx={{ borderRadius: "10px" }}
                    >
                      <img
                        src={question.question_image}
                        alt="Question"
                        style={{
                          width: "100px",
                          height: "60px",
                          objectFit: "fill",
                          borderRadius: "10px",
                        }}
                      />
                    </Box>
                  )}

                  <Button
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#667085",
                      fontSize: "14px",
                      textTransform: "none",
                      padding: 0,
                      minWidth: "auto",
                      mt: 2,
                    }}
                    onClick={() => confirmRemoveQuestion(question.id)}
                  >
                    <img
                      src={dustbin}
                      alt="remove"
                      style={{
                        marginRight: "8px",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                    Remove
                  </Button>
                </Grid>

                <Grid item xs={1} sx={{ display: "flex" }}>
                  <DragHandle>
                    <img src={dots} alt="drag" style={{ marginRight: "8px" }} />
                  </DragHandle>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      )}
      <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 2 }}>
        <Divider sx={{ flex: 1, height: 2, background: "#EAECF0" }} />
        <Avatar
          onClick={addQuestion}
          sx={{
            background: "white",
            border: "2px solid #98A2B3",
            mx: 1,
            width: 20,
            height: 20,
            color: "#98A2B3",
            cursor: "pointer",
            fontSize: 16,
          }}
        >
          +
        </Avatar>
        <Divider sx={{ flex: 1, height: 2, background: "#EAECF0" }} />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={
          <Box display="flex" alignItems="center">
            <CheckCircleIcon sx={{ marginRight: "8px", color: "#fff" }} />{" "}
            {snackbarMessage}
          </Box>
        }
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: colors.violetPrimary,
            color: "#fff",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          },
        }}
      />
      <QuizEditDialog
        open={open}
        handleClose={handleClose}
        questionData={editingQuestion}
        onSave={handleSave}
        onDelete={() => confirmRemoveQuestion(editingQuestion?.id)}
      />
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            color: colors.textSecondary,
            paddingBottom: "5px",
          }}
        >
          Remove Question
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: "12px",
              color: colors.textSecondary,
              fontWeight: 400,
            }}
          >
            Are you sure you want to remove this question?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "10px" }}>
          <Button
            onClick={handleCloseDeleteDialog}
            style={{
              marginRight: "10px",
              borderRadius: "8px",
              width: "70px",
              color: "black",
              height: "32px",
              fontWeight: 400,
              borderColor: "#667085",
              border: "1px solid black",
              textTransform: "none",
              fontSize: "12px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveQuestion}
            style={{
              borderRadius: "8px",
              width: "70px",
              height: "32px",
              backgroundColor: "#7f56d9",
              border: "none",
              color: "#fff",
              fontWeight: 400,
              textTransform: "none",
              fontSize: "12px",
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QuestionCard;
