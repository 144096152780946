import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, useMediaQuery, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatArea from "./ChatArea";
import ChatMessageInputArea from "./ChatMessageInputArea";
import UsersSidebarMessage from "./UsersSidebarMessage";
import MessageHeader from "./MessageHeader";
import ChatEmptyView from "./ChatEmptyView";
import PubNub from "pubnub";

const MESSAGES_PER_FETCH = 50;

function MessageMain() {
  const docId = localStorage.getItem("docId");
  const [patientsState, setPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [channel, setChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const flatListRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const messageIdsRef = useRef(new Set());
  const [lastMessages, setLastMessages] = useState({});
  const [messagesCache, setMessagesCache] = useState({});

  const pubnub = useRef(
    new PubNub({
      publishKey: "pub-c-d0845792-b88a-470e-8f39-87fdd0405725",
      subscribeKey: "sub-c-f09e3b4c-2556-4dd9-9025-c0fffa5dc648",
      userId: docId || "defaultUserId",
    }),
  ).current;

  useEffect(() => {
    if (messages.length > 0 && selectedChatId) {
      setLastMessages((prevLastMessages) => ({
        ...prevLastMessages,
        [selectedChatId]: messages[messages.length - 1],
      }));
    }
  }, [messages, selectedChatId]);

  const fetchPatients = async () => {
    const token = localStorage.getItem("token");
    if (!docId || !token) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/patient/${docId}?page=${currentPage + 1}&pageSize=10`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (response.ok) {
        const data = await response.json();
        const formattedPatients = data.patients.map((patient) => ({
          id: patient.id,
          firstName: patient.user.first_name,
          lastName: patient.user.last_name,
          email: patient.user.email,
          profile_picture: patient.user.profile_picture,
        }));
        setPatients(formattedPatients);
      }
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  useEffect(() => {
    fetchPatients();
  }, [currentPage]);

  const loadChatHistory = useCallback(async () => {
    if (!channel) return;

    let allMessages = [];
    let start = undefined;
    let fetchedAllMessages = false;

    while (!fetchedAllMessages) {
      try {
        const result = await pubnub.fetchMessages({
          channels: [channel],
          count: MESSAGES_PER_FETCH,
          start,
        });

        if (result?.channels?.[channel]) {
          const batchMessages = result.channels[channel]
            .filter((msg) => msg.message.type === "message" || msg.file)
            .map((msg) => ({
              ...msg.message,
              file: msg.file,
              timestamp: msg.timetoken
                ? new Date(msg.timetoken / 10000).toISOString()
                : new Date().toISOString(),
            }));

          if (batchMessages.length < MESSAGES_PER_FETCH) {
            fetchedAllMessages = true;
          } else {
            start = result.channels[channel][0].timetoken;
          }
          allMessages = [...batchMessages, ...allMessages];
        } else {
          fetchedAllMessages = true;
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
        fetchedAllMessages = true;
      }
    }
    const uniqueMessages = allMessages.filter((msg) => {
      if (!messageIdsRef.current.has(msg.id)) {
        messageIdsRef.current.add(msg.id);
        return true;
      }
      return false;
    });

    setMessages(uniqueMessages);
  }, [channel]);
  // -------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (!channel) return;

    const listener = {
      message: (event) => {
        const { message, file } = event;
        const messageId = message?.id || `backup-${Date.now()}`;

        if (message.type === "message" || file) {
          if (!messageIdsRef.current.has(messageId)) {
            messageIdsRef.current.add(messageId);
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                ...message,
                file,
                id: messageId,
                timestamp: event.timetoken
                  ? new Date(event.timetoken / 10000).toISOString()
                  : new Date().toISOString(),
              },
            ]);
          }
        }
      },
    };

    pubnub.addListener(listener);
    pubnub.subscribe({ channels: [channel], withPresence: true });

    loadChatHistory();

    return () => {
      setMessagesCache((prevCache) => ({
        ...prevCache,
        [selectedChatId]: messages,
      }));
      pubnub.removeListener(listener);
      pubnub.unsubscribe({ channels: [channel] });
      setMessages([]);
      messageIdsRef.current.clear();
    };
  }, [channel, loadChatHistory, selectedChatId]);

  const handleSendMessage = async (messageText) => {
    const trimmedMessage = messageText?.trim();
    if (!trimmedMessage || !channel) return;

    const messageData = {
      text: trimmedMessage,
      sender: pubnub.getUUID(),
      timestamp: new Date().toISOString(),
      id: `msg-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      type: "message",
    };

    try {
      await pubnub.publish({
        channel,
        message: messageData,
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleSendFile = async (file) => {
    if (!channel || !file) return;

    try {
      const result = await pubnub.sendFile({
        channel,
        file,
        message: { text: `File: ${file.name}` },
      });

      // Ensure `result.file` and `result.file.url` are defined before using them
      const fileUrl = result.file && result.file.url ? result.file.url : "";

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: `File: ${file.name}`,
          file: { name: file.name, url: fileUrl },
          timestamp: new Date().toISOString(),
          sender: pubnub.getUUID(),
        },
      ]);
    } catch (error) {
      console.error("Error sending file:", error);
    }
  };

  const handleChatSelection = (chatId) => {
    const selectedPatient = patientsState.find(
      (patient) => patient.id === chatId,
    );
    if (selectedPatient) {
      const newChannel = [selectedPatient.id, docId].sort().join("-");
      setSelectedChatId(chatId);
      setMessages(messagesCache[chatId] || []); // Retrieve cached messages if available
      setChannel(newChannel);
      setIsTyping(false);
      messageIdsRef.current.clear();
    }
  };

  const selectedChat = patientsState.find((chat) => chat.id === selectedChatId);
  const selectedUserName = selectedChat
    ? `${selectedChat.firstName} ${selectedChat.lastName}`
    : "Select a chat";
  const selectedAvatar = selectedChat ? selectedChat.profile_picture : "";
  const isUserOnline = selectedChat ? selectedChat.status === "active" : false;

  return (
    <Box display="flex" height="100vh" overflow="hidden">
      <Box
        width={isMobile ? "100%" : "360px"}
        borderRight="1px solid #E4E7EC"
        display={isMobile && selectedChatId ? "none" : "flex"}
        flexDirection="column"
        overflow="auto"
        flexShrink={0}
      >
        <UsersSidebarMessage
          patientsState={patientsState}
          lastMessages={lastMessages}
          onChatSelect={handleChatSelection}
        />
      </Box>
      {(!isMobile || selectedChatId) && (
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          height="100vh"
          sx={{ paddingBottom: isMobile ? "62px" : "0px" }}
        >
          {selectedChatId ? (
            <>
              <Box
                flex="0 0 auto"
                borderBottom="1px solid #E4E7EC"
                position="relative"
              >
                {isMobile && (
                  <IconButton
                    onClick={() => setSelectedChatId(null)}
                    sx={{ position: "absolute", left: 8, top: 8 }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <MessageHeader
                  userName={selectedUserName}
                  avatar={selectedAvatar}
                  isOnline={isUserOnline}
                  isTyping={isTyping}
                />
              </Box>
              <Box flex="1 1 auto" overflow="auto" bgcolor="#F9FAFB">
                <ChatArea
                  messages={messages}
                  avatar={selectedAvatar}
                  isOnline={isUserOnline}
                  isTyping={isTyping}
                  selectedUserName={selectedUserName}
                  ref={flatListRef}
                />
              </Box>
              <Box flex="0 0 auto" bgcolor="white" p={2}>
                <ChatMessageInputArea
                  pubnub={pubnub}
                  channel={channel}
                  onSendMessage={handleSendMessage}
                  onSendFile={handleSendFile}
                />
              </Box>
            </>
          ) : (
            !isMobile && <ChatEmptyView />
          )}
        </Box>
      )}
    </Box>
  );
}

export default MessageMain;
