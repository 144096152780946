import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import { colors } from "../../../Utils/colorUtils";

const StyledHeaderCell = styled(TableCell)({
  borderBottom: "1px solid #EBEDF1",
  padding: "16px 24px",
  fontWeight: "500",
  color: colors.textSecondary,
  fontSize: "12px",
});

const StyledTableCell = styled(TableCell)({
  borderBottom: "1px solid #EBEDF1",
  padding: "16px 24px",
  fontWeight: "400",
  color: colors.textSecondary,
  fontSize: "14px",
});

const UploadedByTableCell = styled(StyledTableCell)({
  width: "20%",
});

const ActionTableCell = styled(StyledTableCell)(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.down("sm")]: {
    width: "auto",
  },
}));

const TableHeader = styled(TableHead)({
  "& th": {
    backgroundColor: "#F9FAFB",
  },
});

const TableContainerStyled = styled(TableContainer)({
  boxShadow: "none",
});

const StyledAvatar = styled(Avatar)({
  width: 44,
  height: 44,
  marginRight: 10,
});

function DocumentTable({ files, onEdit, onDelete }) {
  return (
    <TableContainerStyled>
      <Table sx={{ minWidth: 650 }}>
        <TableHeader>
          <TableRow>
            <StyledHeaderCell padding="checkbox">
              <input type="checkbox" style={{ borderRadius: 4 }} />
            </StyledHeaderCell>
            <StyledHeaderCell>File name</StyledHeaderCell>
            <StyledHeaderCell align="right">File size</StyledHeaderCell>
            <StyledHeaderCell align="right">Date uploaded</StyledHeaderCell>
            <StyledHeaderCell align="right">Last updated</StyledHeaderCell>
            <UploadedByTableCell>Uploaded by</UploadedByTableCell>
            <ActionTableCell align="right"></ActionTableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {files.length === 0 ? (
            <TableRow>
              <StyledTableCell colSpan={7} align="center">
                <Typography
                  variant="body2"
                  sx={{ color: colors.textSecondary, padding: "20px 0" }}
                >
                  No file found.
                </Typography>
              </StyledTableCell>
            </TableRow>
          ) : (
            files.map((row) => (
              <TableRow key={row.id}>
                <StyledTableCell padding="checkbox">
                  <input type="checkbox" style={{ borderRadius: 4 }} />
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {row.type === "PDF" ? (
                      <PictureAsPdfIcon sx={{ color: "#D32F2F", mr: 1 }} />
                    ) : (
                      <ImageIcon sx={{ color: "#1976D2", mr: 1 }} />
                    )}
                    {row.name}
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="right">{row.size}</StyledTableCell>
                <StyledTableCell align="right">
                  {row.dateUploaded}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.lastUpdated}
                </StyledTableCell>
                <UploadedByTableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <StyledAvatar
                      sx={{
                        border: "2px solid white",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      }}
                      alt={`${row.firstName} ${row.lastName}`}
                      src={row.profile_picture || null}
                    >
                      {!row.profile && row.firstName && row.lastName
                        ? `${row.firstName[0].toUpperCase()}${row.lastName[0].toUpperCase()}`
                        : null}
                    </StyledAvatar>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: colors.textPrimary,
                        }}
                      >
                        <span> {row.uploadedBy}</span>
                        <br />
                        <span style={{ fontSize: "12px" }}> {row.email}</span>
                      </Typography>
                    </Box>
                  </Box>
                </UploadedByTableCell>
                <ActionTableCell align="right">
                  <Button
                    size="small"
                    onClick={() => onDelete(row)}
                    sx={{
                      mr: 1,
                      border: "none",
                      color: colors.textSecondary,
                      textTransform: "none",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    size="small"
                    onClick={() => onEdit(row)}
                    sx={{
                      mr: 1,
                      border: "none",
                      color: colors.violetSecondary,
                      textTransform: "none",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    Edit
                  </Button>
                </ActionTableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainerStyled>
  );
}

DocumentTable.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      dateUploaded: PropTypes.string.isRequired,
      lastUpdated: PropTypes.string.isRequired,
      uploadedBy: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DocumentTable;
