import React, { useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Box,
  IconButton,
  Divider,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { colors } from "../../Utils/colorUtils";
import add from "../../assets/add2.svg";
import addIconplus from "../../assets/addIconplus.svg";
import addIconNo from "../../assets/addIconNo.svg";
import PatientInvitationPopUp from "./PatientInvitationPopUp";
import NewPatientPopUp from "./NewPatientPopUp";
import { useNavigate } from "react-router-dom";

const AddNewPatient = ({ open, onClose, addNewPatient }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [inviteOption, setInviteOption] = useState("invite");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [invitationLink, setInvitationLink] = useState("");
  const [assignTo, setAssignTo] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    assignTo: "",
  });
  const [newPatientName, setNewPatientName] = useState("");
  const [newPatientId, setNewPatientId] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateName = (name) => {
    const nameRegex = /^[A-Za-z]+$/;
    return nameRegex.test(name);
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      assignTo: "",
    };

    if (!firstName.trim() || !validateName(firstName)) {
      newErrors.firstName = "First Name must be valid and non-empty";
      isValid = false;
    }

    if (!lastName.trim() || !validateName(lastName)) {
      newErrors.lastName = "Last Name must be valid and non-empty";
      isValid = false;
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = "Email is invalid";
      isValid = false;
    }

    if (!assignTo.trim()) {
      newErrors.assignTo = "Assign to is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAddPatientClick = async () => {
    if (validateFields()) {
      const newPatient = {
        firstName,
        lastName,
        email,
        isInvited: inviteOption === "invite",
      };

      const token = localStorage.getItem("token");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/patient/register-by-doctor`,
          newPatient,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const newPatientId = response.data.patient.id;
        localStorage.setItem("patientId", newPatientId);
        addNewPatient(response.data);
        setNewPatientName(`${firstName} ${lastName}`);
        setNewPatientId(newPatientId);
        setInvitationLink(response.data.patient.invitationLink);
        setPopupOpen(true);
        onClose();
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === "Email already exists"
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "A user with this email already exists",
          }));
        } else {
          console.error(
            "Failed to add patient:",
            error.response || error.message,
          );
        }
      }
    }
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    navigate(`/patient/overview/${newPatientId}`);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };

  const handleInviteOptionChange = (event) => {
    setInviteOption(event.target.value);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={false}
        PaperProps={{
          sx: {
            width: "500px",
            maxWidth: "100%",
          },
        }}
      >
        <Box
          sx={{
            padding: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              marginRight: "8px",
            }}
          >
            <Box
              sx={{
                border: `1px solid ${colors.borderColor}`,
                height: 30,
                width: 30,
                borderRadius: "10px",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={add}
                alt="Add"
                sx={{
                  width: 18,
                  height: 18,
                }}
              />
            </Box>
            <DialogTitle
              sx={{
                padding: 0,
                fontSize: "18px",
                fontWeight: "600",
                color: colors.textPrimary,
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              Add new patient
            </DialogTitle>
          </Box>
          <IconButton onClick={onClose} sx={{ color: colors.iconColor }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
        <DialogContent>
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontSize: "14px",
              fontWeight: "600",
              color: colors.textTertiary,
            }}
          >
            Patient Details
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography
              sx={{ mb: 1, fontSize: "14px", color: colors.textPrimary }}
            >
              First Name
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={firstName}
              onChange={handleInputChange(setFirstName)}
              error={!!errors.firstName}
              helperText={errors.firstName}
              name="firstName"
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: "14px",
                  borderRadius: "8px",
                },
              }}
              FormHelperTextProps={{
                sx: {
                  marginTop: "5px",
                  marginLeft: "2px",
                  fontSize: "10px",
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography
              sx={{ mb: 1, fontSize: "14px", color: colors.textPrimary }}
            >
              Last Name
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={handleInputChange(setLastName)}
              error={!!errors.lastName}
              helperText={errors.lastName}
              name="lastName"
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: "14px",
                  borderRadius: "8px",
                },
              }}
              FormHelperTextProps={{
                sx: {
                  marginTop: "5px",
                  marginLeft: "2px",
                  fontSize: "10px",
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography
              sx={{ mb: 1, fontSize: "14px", color: colors.textPrimary }}
            >
              Email
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={email}
              onChange={handleInputChange(setEmail)}
              error={!!errors.email}
              helperText={errors.email}
              name="email"
              InputProps={{
                style: {
                  height: "40px",
                  fontSize: "14px",
                  borderRadius: "8px",
                },
              }}
              FormHelperTextProps={{
                sx: {
                  marginTop: "5px",
                  marginLeft: "2px",
                  fontSize: "10px",
                },
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <Typography
              sx={{ mb: 1, fontSize: "14px", color: colors.textPrimary }}
            >
              Assign to
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={assignTo}
              onChange={handleInputChange(setAssignTo)}
              error={!!errors.assignTo}
              helperText={errors.assignTo}
              name="assignTo"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: colors.iconColor }} />
                  </InputAdornment>
                ),
                style: {
                  height: "40px",
                  fontSize: "14px",
                  borderRadius: "8px",
                },
              }}
              FormHelperTextProps={{
                sx: {
                  marginTop: "5px",
                  marginLeft: "2px",
                  fontSize: "10px",
                },
              }}
            />
          </FormControl>
          <Typography
            variant="h6"
            sx={{ mb: 1, fontSize: "14px", fontWeight: "600" }}
          >
            Send Invitation
          </Typography>
          <RadioGroup value={inviteOption} onChange={handleInviteOptionChange}>
            <FormControlLabel
              value="invite"
              control={
                <Radio
                  sx={{
                    color: "#7F56D9",
                    "&.Mui-checked": {
                      color: "#7F56D9",
                    },
                    position: "absolute",
                    right: 12,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
              }
              label={
                <Box sx={{ display: "flex", alignItems: "flex-start", pr: 5 }}>
                  <Box
                    sx={{
                      height: "40px",
                      width: "40px",
                      background: "#F4EBFF",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 2,
                      flexShrink: 0,
                    }}
                  >
                    <Box
                      component="img"
                      src={addIconplus}
                      alt="Invite"
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: colors.textPrimary,
                      }}
                    >
                      Invite to the app
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: colors.textSecondary,
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Your patient will be invited to onboard themselves to our
                      mobile app.
                    </Typography>
                  </Box>
                </Box>
              }
              sx={{
                marginBottom: "12px",
                padding: "12px",
                border: `1px solid ${colors.primary}`,
                borderRadius: "10px",
                position: "relative",
                "& .MuiFormControlLabel-label": { width: "100%" },
              }}
            />
            <FormControlLabel
              value="no-invite"
              control={
                <Radio
                  sx={{
                    color: "#7F56D9",
                    "&.Mui-checked": {
                      color: "#7F56D9",
                    },
                    position: "absolute",
                    right: 12,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
              }
              label={
                <Box sx={{ display: "flex", alignItems: "flex-start", pr: 5 }}>
                  <Box
                    sx={{
                      height: "50px",
                      width: "50px",
                      background: "#F4EBFF",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 2,
                      flexShrink: 0,
                    }}
                  >
                    <Box
                      component="img"
                      src={addIconNo}
                      alt="No Invite"
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: colors.textPrimary,
                      }}
                    >
                      Do not invite to the app
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: colors.textSecondary,
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Your patient will be created but will not be invited to
                      the app yet.
                    </Typography>
                  </Box>
                </Box>
              }
              sx={{
                padding: "12px",
                border: `1px solid ${colors.borderColor}`,
                borderRadius: "10px",
                position: "relative",
                "& .MuiFormControlLabel-label": { width: "100%" },
              }}
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              borderRadius: "10px",
              backgroundColor: colors.violetPrimary,
              color: colors.secondary,
              height: 40,
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "none",
              "&:hover": {
                backgroundColor: colors.violetSecondary,
              },
            }}
            onClick={handleAddPatientClick}
          >
            Add Patient
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={popupOpen} onClose={handleClosePopup} maxWidth="sm">
        {inviteOption === "invite" ? (
          <PatientInvitationPopUp
            setPopupOpen={setPopupOpen}
            invitationLink={invitationLink}
            patientName={newPatientName}
            patientId={newPatientId}
          />
        ) : (
          <NewPatientPopUp
            setPopupOpen={setPopupOpen}
            patientName={newPatientName}
          />
        )}
      </Dialog>
    </>
  );
};

AddNewPatient.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addNewPatient: PropTypes.func.isRequired,
};

export default AddNewPatient;
