import React, { useEffect, useRef } from "react";
import { Box, Avatar, Typography, Divider } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";

const MessageContainer = styled(Box)(({ theme, isUser }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: isUser ? "flex-end" : "flex-start",
  marginBottom: theme.spacing(2),
  width: "100%",
}));

const MessageHeader = styled(Box)(({ theme, isUser }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: isUser ? "flex-end" : "flex-start",
  width: "100%",
  marginBottom: "4px",
}));

const MessageContent = styled(Box)(({ theme, isUser }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "80%",
  [theme.breakpoints.down("md")]: {
    maxWidth: "90%",
  },
}));

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: 8,
  borderTopLeftRadius: isUser ? "8px" : "0px",
  borderTopRightRadius: isUser ? "0px" : "8px",
  border: "1px solid #EAECF0",
  backgroundColor: isUser ? "#9747FF" : "#F9FAFB",
  color: isUser ? "#FFFFFF" : "#344054",
  minWidth: "auto",
  maxWidth: "400px",
  wordWrap: "break-word",
  overflowWrap: "break-word",
}));

const TimeStamp = styled(Typography)(({ theme, isUser }) => ({
  fontSize: "12px",
  color: "#475467",
  fontWeight: "400",
  marginTop: "4px",
  alignSelf: "flex-end",
}));

const SenderName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  color: "#344054",
  marginRight: "8px",
});

const AvatarContainer = styled("div")({
  position: "relative",
  display: "inline-block",
  marginRight: "8px",
});

const ActiveIndicator = styled("div")(({ backgroundColor }) => ({
  width: "12px",
  height: "12px",
  backgroundColor: backgroundColor || "#22C55E",
  borderRadius: "50%",
  border: "2px solid white",
  position: "absolute",
  bottom: "0",
  right: "0",
}));

const DateDivider = styled(Divider)({
  margin: "16px 0",
  color: "#667085",
  fontSize: "12px",
  "&::before, &::after": {
    borderColor: "#E4E7EC",
  },
});

const ChatArea = React.forwardRef(
  ({ messages, isOnline, avatar, isTyping, selectedUserName }, ref) => {
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
      scrollToBottom();
    }, [messages, isTyping]);

    const formatDate = (dateString) => {
      const messageDate = new Date(dateString);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      today.setHours(0, 0, 0, 0);
      yesterday.setHours(0, 0, 0, 0);
      messageDate.setHours(0, 0, 0, 0);

      if (messageDate.getTime() === today.getTime()) {
        return "Today";
      } else if (messageDate.getTime() === yesterday.getTime()) {
        return "Yesterday";
      } else {
        return messageDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      }
    };

    const renderMessageContent = (msg, isUser) => {
      if (msg.file) {
        const isPDF = msg.file.name.toLowerCase().endsWith(".pdf");
        const isImage = /\.(jpg|jpeg|png|gif)$/i.test(msg.file.name);

        return (
          <a
            href={msg.file.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px 12px",
                border: "1px solid",
                borderColor: isUser ? "#9747FF" : "#D0D5DD",
                borderRadius: "8px",
                backgroundColor: isUser ? "#7F56D9" : "#FFFFFF",
                color: isUser ? "#FFFFFF" : "#344054",
                maxWidth: "100%",
                cursor: "pointer",
              }}
            >
              {isPDF ? (
                <PictureAsPdfIcon
                  sx={{
                    color: isUser ? "#FFFFFF" : "#D32F2F",
                    fontSize: 40,
                    marginRight: "8px",
                  }}
                />
              ) : isImage ? (
                <ImageIcon
                  sx={{
                    color: isUser ? "#FFFFFF" : "#4A90E2",
                    fontSize: 40,
                    marginRight: "8px",
                  }}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    backgroundColor: isUser ? "#FFFFFF" : "#344054",
                    marginRight: "8px",
                  }}
                >
                  ?
                </Avatar>
              )}
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" noWrap>
                  {msg.file.name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {msg.file.size
                    ? `${(msg.file.size / 1024 / 1024).toFixed(1)} MB`
                    : "Unknown Size"}
                </Typography>
              </Box>
            </Box>
          </a>
        );
      } else {
        return <Typography>{msg.text}</Typography>;
      }
    };

    const renderMessage = (message, isUser) => {
      const avatarSrc = isUser ? avatar : message.avatar || avatar;
      const displayName = isUser
        ? "You"
        : message.senderName || selectedUserName;

      const formatTimestamp = (timestamp) => {
        const messageDate = new Date(timestamp);
        return messageDate.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      };

      return (
        <MessageContainer isUser={isUser}>
          {!isUser && (
            <MessageHeader isUser={isUser}>
              <AvatarContainer>
                <Avatar sx={{ width: 40, height: 40 }} src={avatarSrc} />
                <ActiveIndicator
                  backgroundColor={isOnline ? "#22C55E" : "#D14343"}
                />
              </AvatarContainer>
              <SenderName>{displayName}</SenderName>
            </MessageHeader>
          )}
          <MessageContent isUser={isUser}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              {isUser && <SenderName>{displayName}</SenderName>}
              <TimeStamp isUser={isUser}>
                {formatTimestamp(message.timestamp)}
              </TimeStamp>
            </Box>
            <MessageBubble isUser={isUser}>
              {renderMessageContent(message, isUser)}
            </MessageBubble>
          </MessageContent>
        </MessageContainer>
      );
    };

    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          overflowY: "auto",
          height: "100%",
          background: "#F9FAFB",
        }}
      >
        {messages.length > 0 &&
          messages.map((message, index) => {
            const isUser = message.sender === localStorage.getItem("docId");
            return (
              <React.Fragment key={message.id || `message-${index}`}>
                {(index === 0 ||
                  new Date(message.timestamp).toDateString() !==
                    new Date(messages[index - 1].timestamp).toDateString()) && (
                  <DateDivider>{formatDate(message.timestamp)}</DateDivider>
                )}
                {renderMessage(message, isUser)}
              </React.Fragment>
            );
          })}
        <div ref={messagesEndRef} />
        {isTyping && (
          <MessageContainer isUser={false}>
            <MessageHeader isUser={false}>
              <AvatarContainer>
                <Avatar sx={{ width: 40, height: 40 }} src={avatar} />
                <ActiveIndicator
                  backgroundColor={isOnline ? "#22C55E" : "#D14343"}
                />
              </AvatarContainer>
            </MessageHeader>
            <MessageContent isUser={false}>
              <MessageBubble isUser={false}>
                <Typography sx={{ color: "#999" }}>
                  {selectedUserName || "User"} is typing...
                </Typography>
              </MessageBubble>
            </MessageContent>
          </MessageContainer>
        )}
      </Box>
    );
  },
);

ChatArea.displayName = "ChatArea";

ChatArea.propTypes = {
  messages: PropTypes.array.isRequired,
  isOnline: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  isTyping: PropTypes.bool.isRequired,
  selectedUserName: PropTypes.string.isRequired,
};

export default ChatArea;
