import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import TreatmentEditLeftContainer from "./TreatmentEditLeftContainer";
import TreatmentEditRightContainer from "./TreatmentEditRightContainer";
import TreatmentEditHeaderContainer from "./TreatmentEditHeader";
import { Grid, Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import PpSnackbar from "../../../../Utils/PpSnackbar";

function TreatmentEditMain({
  treatmentId,
  onClose,
  setTabValue,
  setIsEditingTreatment,
  setSelectedImage,
  setTreatmentId,
}) {
  const navigate = useNavigate();
  const [allExercises, setAllExercises] = useState([]);
  const [leftImages, setLeftImages] = useState([]);
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(1);
  const [title, setTitle] = useState("Treatment Name");
  const [description, setDescription] = useState("Treatment Description");
  const [tags, setTags] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      await fetchAllExercises();
      if (treatmentId) {
        await fetchTreatmentData(treatmentId);
        await fetchTreatmentDetails(treatmentId);
      } else {
        setDays([{ id: 1, treatments: [] }]);
        setSelectedDay(1);
      }
      await filterExercises();
      setLoading(false);
    };

    fetchAllData();
  }, [treatmentId]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    handler();
    return () => {
      handler.cancel();
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedSearchQuery !== "") {
      searchExercises(debouncedSearchQuery);
    } else {
      filterExercises();
    }
  }, [debouncedSearchQuery, selectedDay, days]);

  const fetchAllExercises = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/exercise/get-exercises?page=1&pageSize=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        const exercises = response.data.data;
        const formattedExercises = exercises.map((exercise, index) => ({
          id: exercise.id,
          title: exercise.exercise_name,
          videoSrc: exercise.exercise_video,
          originalIndex: index,
        }));
        setAllExercises(formattedExercises);
      }
    } catch (error) {
      console.error("Error fetching exercises:", error);
    }
  };

  const fetchTreatmentData = async (treatmentId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plans/get-a-treatmentplan/${treatmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        const { treatment_plan_name, treatment_description, tags } =
          response.data.data;
        setTitle(treatment_plan_name);
        setDescription(treatment_description);
        setTags(tags.map((tag, index) => ({ id: index + 1, name: tag })));
      }
    } catch (error) {
      console.error("Error fetching treatment data:", error);
    }
  };

  const fetchTreatmentDetails = async (treatmentId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/get-treatments/${treatmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        const treatments = response.data.data;
        const groupedByDay = groupTreatmentsByDay(treatments);
        const filteredDays = filterAndRenumberDays(groupedByDay);
        setDays(filteredDays);
      } else {
        console.error("Failed to fetch treatment details");
      }
    } catch (error) {
      console.error("Error fetching treatment details:", error);
    }
  };

  const groupTreatmentsByDay = (treatments) => {
    const grouped = treatments.reduce((acc, treatment) => {
      const day = treatment.day;
      if (!acc[day]) {
        acc[day] = { id: day, treatments: [] };
      }
      acc[day].treatments.push({
        id: treatment.exercise_id,
        treatmentId: treatment.id,
        title: treatment.content,
        videoSrc: treatment.treatment_image,
        lightIntensity: {
          reps: treatment.light_intensity_reps,
          holds: treatment.light_intensity_holds,
          rests: treatment.light_intensity_rests_for,
          sets: treatment.light_intensity_sets_for,
        },
        mediumIntensity: {
          reps: treatment.medium_intensity_reps,
          holds: treatment.medium_intensity_holds,
          rests: treatment.medium_intensity_rests_for,
          sets: treatment.medium_intensity_sets_for,
        },
        hardIntensity: {
          reps: treatment.hard_intensity_reps,
          holds: treatment.hard_intensity_holds,
          rests: treatment.hard_intensity_rests_for,
          sets: treatment.hard_intensity_sets_for,
        },
      });
      return acc;
    }, {});

    return Object.keys(grouped).map((key) => grouped[key]);
  };

  const searchExercises = async (query) => {
    setIsFiltering(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/exercise/search-exercises?search=${query}&page=1&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.exercises) {
        const exercises = response.data.exercises;
        const formattedExercises = exercises.map((exercise, index) => ({
          id: exercise.id,
          title: exercise.exercise_name,
          videoSrc: exercise.exercise_video,
          originalIndex: index,
        }));
        setAllExercises(formattedExercises);
        await filterExercises(formattedExercises);
      } else {
        console.error("Failed to search exercises");
      }
    } catch (error) {
      console.error("Error searching exercises:", error);
    } finally {
      setIsFiltering(false);
    }
  };

  const filterAndRenumberDays = (days) => {
    return days
      .filter((day) => day.treatments.length > 0)
      .map((day, index) => ({ ...day, id: index + 1 }));
  };

  const filterExercises = async (exercisesToFilter = allExercises) => {
    setIsFiltering(true);
    const selectedDayTreatments =
      days.find((day) => day.id === selectedDay)?.treatments || [];
    const assignedExerciseIds = new Set(
      selectedDayTreatments.map((treatment) => treatment.id),
    );
    const filteredImages = exercisesToFilter.filter(
      (exercise) => !assignedExerciseIds.has(exercise.id),
    );
    setLeftImages(filteredImages);
    setIsFiltering(false);
  };

  const handleDragStart = (event, image) => {
    event.dataTransfer.setData("text/plain", JSON.stringify(image));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const imageData = event.dataTransfer.getData("text/plain");
    if (imageData) {
      const image = JSON.parse(imageData);
      const sequence =
        days.find((day) => day.id === selectedDay)?.treatments.length + 1;

      setDays((prevDays) =>
        prevDays.map((day) => {
          if (day.id === selectedDay) {
            return {
              ...day,
              treatments: [...day.treatments, { ...image, sequence }],
            };
          }
          return day;
        }),
      );
      setDroppedItems((prevItems) => [
        ...prevItems,
        {
          ...image,
          day: selectedDay,
          sequence: sequence,
        },
      ]);
      filterExercises();
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemoveFromDay = (imageToRemove) => {
    setDays((prevDays) =>
      prevDays.map((day) => {
        if (day.id === selectedDay) {
          return {
            ...day,
            treatments: day.treatments.filter(
              (treatment) => treatment.id !== imageToRemove.id,
            ),
          };
        }
        return day;
      }),
    );
    filterExercises();
  };

  const addDay = () => {
    const newDay = { id: days.length + 1, treatments: [] };
    setDays([...days, newDay]);
  };

  const switchDay = (dayId) => {
    setSelectedDay(dayId);
    filterExercises();
  };

  const validateForm = () => {
    let isValid = true;
    if (!title.trim() || title === "Treatment Name") {
      setSnackbarMessage("Title is required");
      setSnackbarOpen(true);
      isValid = false;
    }

    if (!description.trim() || description === "Treatment Description") {
      setSnackbarMessage("Description is required");
      setSnackbarOpen(true);
      isValid = false;
    }

    return isValid;
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const createOrUpdateTreatmentPlan = async () => {
    if (!validateForm()) return;

    const token = localStorage.getItem("token");
    const treatmentPlanData = {
      treatmentPlanName: title,
      treatmentDescription: description,
      tags: tags.map((tag) => tag.name),
      status: "inactive",
    };
    try {
      let response;
      let newTreatmentId = treatmentId;

      const filteredDays = filterAndRenumberDays(days);

      if (treatmentId) {
        response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plans/update/${treatmentId}`,
          treatmentPlanData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plans/create`,
          treatmentPlanData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.data.success) {
          newTreatmentId = response.data.data.id;
          setTreatmentId(newTreatmentId);
        }
      }
      if (response.data.success) {
        console.log("Treatment plan saved successfully:", response.data);
        if (droppedItems.length > 0) {
          await submitTreatments(newTreatmentId, filteredDays);
        }
        navigate("/treatment");
      } else {
        console.error("Failed to save treatment plan");
      }
    } catch (error) {
      console.error("Error saving treatment plan:", error);
    }
  };

  const submitTreatments = async (newTreatmentId, filteredDays) => {
    const token = localStorage.getItem("token");
    if (!newTreatmentId) {
      console.error("Treatment ID is not defined");
      return;
    }

    const treatmentData = {
      treatmentPlansId: newTreatmentId,
      treatments: droppedItems.map((item) => ({
        treatmentImage: item.videoSrc,
        exerciseId: item.id,
        lightIntensityReps: "10",
        lightIntensityHolds: "5",
        lightIntensityRestsFor: "30",
        lightIntensitySetsFor: "3",
        mediumIntensityReps: "8",
        mediumIntensityHolds: "5",
        mediumIntensityRestsFor: "30",
        mediumIntensitySetsFor: "3",
        hardIntensityReps: "6",
        hardIntensityHolds: "5",
        hardIntensityRestsFor: "30",
        hardIntensitySetsFor: "3",
        content: item.title,
        instructions: "exercise instructions",
        label: ["a", "b", "c"],
        day: item.day,
        sequence: item.sequence,
      })),
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/create`,
        treatmentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        console.log("Treatments created successfully:", response.data);
        setDroppedItems([]);
      } else {
        console.error("Failed to create treatments");
      }
    } catch (error) {
      console.error("Error creating treatments:", error);
    }
  };

  const deleteDayFromPlan = async (treatmentPlansId, dayToDelete) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/remove-day`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: {
            treatmentPlansId,
            dayToDelete,
          },
        },
      );
      if (response.data.success) {
        console.log("Day deleted successfully:", response.data);
      } else {
        console.error("Failed to delete day");
      }
    } catch (error) {
      console.error("Error deleting day:", error);
    }
  };

  const removeDay = async (dayToDelete) => {
    setLoading(true);
    await deleteDayFromPlan(treatmentId, dayToDelete);
    setDays((prevDays) => {
      const updatedDays = prevDays.filter((day) => day.id !== dayToDelete);
      const renumberedDays = updatedDays.map((day) => {
        if (day.id > dayToDelete) {
          return { ...day, id: day.id - 1 };
        }
        return day;
      });
      if (renumberedDays.length > 0) {
        if (dayToDelete === selectedDay && dayToDelete > 1) {
          setSelectedDay(dayToDelete - 1);
        } else if (dayToDelete === selectedDay && dayToDelete === 1) {
          setSelectedDay(renumberedDays[0].id);
        } else if (dayToDelete < selectedDay) {
          setSelectedDay(selectedDay - 1);
        }
      } else {
        setSelectedDay(null);
      }
      return renumberedDays;
    });
    setDroppedItems((prevItems) =>
      prevItems
        .filter((item) => item.day !== dayToDelete)
        .map((item) => {
          if (item.day > dayToDelete) {
            return { ...item, day: item.day - 1 };
          }
          return item;
        }),
    );
    setLoading(false);
    filterExercises();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TreatmentEditHeaderContainer
          createOrUpdateTreatmentPlan={createOrUpdateTreatmentPlan}
          submitTreatments={submitTreatments}
          treatmentId={treatmentId}
          droppedItems={droppedItems}
          onClose={onClose}
          setTabValue={setTabValue}
          setIsEditingTreatment={setIsEditingTreatment}
          setSelectedImage={setSelectedImage}
          fetchTreatmentData={fetchTreatmentData}
        />
        {loading || isFiltering ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <CircularProgress sx={{ color: "#7F56D9" }} />
          </Box>
        ) : (
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid item xs={12} md={4} sx={{ padding: "8px" }}>
              <TreatmentEditLeftContainer
                onImageDragStart={handleDragStart}
                images={leftImages}
                selectedDay={selectedDay}
                days={days}
                loading={loading}
                isFiltering={isFiltering}
                onSearch={setSearchQuery}
              />
            </Grid>
            <Grid item xs={12} md={8} sx={{ padding: "8px" }}>
              <Grid onDragOver={handleDragOver} onDrop={handleDrop}>
                <TreatmentEditRightContainer
                  days={days}
                  selectedDay={selectedDay}
                  setDays={setDays}
                  onAddDay={addDay}
                  onSwitchDay={switchDay}
                  onRemoveImage={handleRemoveFromDay}
                  onRemoveDay={removeDay}
                  title={title}
                  treatmentId={treatmentId}
                  setTitle={setTitle}
                  description={description}
                  setDescription={setDescription}
                  tags={tags}
                  setTags={setTags}
                  fetchTreatmentData={fetchTreatmentData}
                  loading={loading || isFiltering}
                  droppedItems={droppedItems}
                  setDroppedItems={setDroppedItems}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      <PpSnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </>
  );
}

TreatmentEditMain.propTypes = {
  treatmentId: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setTabValue: PropTypes.func.isRequired,
  setIsEditingTreatment: PropTypes.func.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
  setTreatmentId: PropTypes.func.isRequired,
};

export default TreatmentEditMain;
