import React, { useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Popover,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import FilterListIcon from "@mui/icons-material/FilterList";
import searchIcon from "../../../assets/search-lg.svg";
import add from "../../../assets/plusAddIcon_dark.svg";
import { colors } from "../../../Utils/colorUtils";
import TreatmentPlanModal from "./TreatmentPlanModal";

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  height: "44px",
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    color: "#667085",
    height: "100%",
    padding: "0 14px",
  },
  "& .MuiInputAdornment-root img": {
    width: "20px",
    height: "20px",
  },
  "& .MuiInputLabel-root": {
    color: "#667085",
  },
}));

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: colors.background,
  color: colors.textPrimary,
  borderColor: colors.borderPrimary,
  borderRadius: "10px",
  height: "40px",
  width: "100%",
  textTransform: "none",
  margin: "5px 0",
  [theme.breakpoints.up("md")]: {
    width: "auto",
    margin: "0px",
  },
}));

function TreatmentSearchFilterBox({
  onApplyFilters,
  patientId,
  handleNewAssignment,
}) {
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(null);
  const [status, setStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = window.innerWidth < 600;
  const [openModal, setOpenModal] = useState(false);

  const handleFilterPopoverOpen = (event) => {
    setFilterPopoverOpen(event.currentTarget);
  };

  const handleFilterPopoverClose = () => {
    setFilterPopoverOpen(null);
  };

  const handleApplyFilters = () => {
    onApplyFilters(status, searchQuery);
    handleFilterPopoverClose();
  };

  const handleReset = () => {
    setStatus("");
    setSearchQuery("");
    onApplyFilters("", "");
    handleFilterPopoverClose();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = (wasAssigned = false) => {
    setOpenModal(false);
  };

  const handleAssignTreatmentPlans = (selectedPlans) => {
    handleNewAssignment();
    handleCloseModal();
  };

  return (
    <>
      <CustomGrid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            sm: "space-between",
          },
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          padding: {
            xs: "60px 0px 10px",
            sm: "40px 0px 0px 0px",
          },
        }}
      >
        <Grid item xs={12} sm={12} md={3}>
          <StyledTextField
            placeholder="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="Search" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "flex-end",
          }}
        >
          <StyledButton
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={handleFilterPopoverOpen}
            sx={{
              marginRight: { xs: "0", md: "10px" },
              marginTop: { xs: "10px", sm: "10px", md: "0px" },
              textTransform: "none",
            }}
          >
            Filter
          </StyledButton>
          <StyledButton
            variant="outlined"
            onClick={handleOpenModal}
            startIcon={<img src={add} alt="Add" />}
          >
            Treatment Plan
          </StyledButton>
        </Grid>
      </CustomGrid>

      <Popover
        open={Boolean(filterPopoverOpen)}
        anchorEl={filterPopoverOpen}
        onClose={handleFilterPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            marginTop: 10,
            borderRadius: 10,
            width: isMobile ? "100%" : "auto",
          },
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: "500",
              color: colors.textPrimary,
              fontSize: "14px",
            }}
          >
            Filters
          </Typography>
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status"
              sx={{
                borderRadius: "10px",
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 0 }}>
            <Button
              variant="outlined"
              sx={{
                marginTop: "10px",
                backgroundColor: colors.background,
                color: colors.textPrimary,
                borderColor: colors.borderPrimary,
                borderRadius: "10px",
                height: 40,
                fontSize: "14px",
                fontWeight: "600",
                width: "48%",
                textTransform: "none",
              }}
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              onClick={handleApplyFilters}
              sx={{
                marginTop: "10px",
                backgroundColor: colors.violetPrimary,
                color: "#ffffff",
                borderRadius: "10px",
                height: 40,
                fontSize: "14px",
                fontWeight: "600",
                width: "48%",
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>

      <TreatmentPlanModal
        open={openModal}
        onClose={handleCloseModal}
        onAssign={handleAssignTreatmentPlans}
        patientId={patientId}
      />
    </>
  );
}

TreatmentSearchFilterBox.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  handleNewAssignment: PropTypes.func.isRequired,
};

export default TreatmentSearchFilterBox;
