import React from "react";
import PropTypes from "prop-types";
import { Snackbar, Box, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "./colorUtils";

function PpSnackbar({ snackbarOpen, handleCloseSnackbar, message }) {
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={
          <Box display="flex" alignItems="center">
            <CheckCircleIcon sx={{ marginRight: "8px", color: "#fff" }} />{" "}
            {message}
          </Box>
        }
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: colors.violetPrimary,
            color: "#fff",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          },
        }}
      />
    </>
  );
}

PpSnackbar.propTypes = {
  snackbarOpen: PropTypes.bool.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default PpSnackbar;
