import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors } from "../../Utils/colorUtils";
import writePencilIcon from "../../assets/writePencilIcon.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#F9FAFB",
    textAlign: "center",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      height: "60vh",
      marginBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },
  },
  content: {
    maxWidth: "400px",
    margin: "0 auto",
    boxShadow: "none",
  },
  icon: {
    fontSize: "48px",
    color: "#9CA3AF",
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: "14px",
    fontWeight: 400,
    color: colors.textSecondary,
    marginBottom: theme.spacing(3),
  },
}));

function ChatEmptyView() {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Grid container justifyContent="center" mb={2}>
          <img
            src={writePencilIcon}
            alt="upload-cloud-02"
            className="box-shadow rounded-md"
            style={{
              border: "1px solid #EAECF0",
              width: "40px",
              height: "40px",
              padding: "10px",
              borderRadius: "10px",
              background: colors.secondary,
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
          />
        </Grid>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            color: colors.textPrimary,
            mb: 1,
          }}
        >
          Messages
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: colors.textSecondary,
            mb: 2,
          }}
        >
          Select a conversation from the list to start chatting. If you dont see
          any messages, try starting a new conversation by clicking the pencil
          icon.
        </Typography>
      </Box>
    </Box>
  );
}

export default ChatEmptyView;
