import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import ProfileForm from "./ProfileForm";
import PropTypes from "prop-types";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

function ProfileMain({ fetchPatientProfile }) {
  return (
    <CustomGrid>
      <ProfileForm fetchPatientProfile={fetchPatientProfile} />
    </CustomGrid>
  );
}

ProfileMain.propTypes = {
  fetchPatientProfile: PropTypes.func.isRequired,
};

export default ProfileMain;
