import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import { styled } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

const VideoContainer = styled(Grid)(({ theme }) => ({
  borderRadius: "12px",
  background: "#FFF",
  height: "250px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  marginTop: "20px",
}));

const VideoWrapper = styled("div")(({ theme }) => ({
  borderRadius: "12px",
  height: "170px",
  width: "100%",
  overflow: "hidden",
}));

const VideoTextContainer = styled(Grid)(({ theme }) => ({
  paddingTop: "10px",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: colors.textPrimary,
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "20px",
  cursor: "pointer",
}));

const DateTypography = styled(Typography)(({ theme }) => ({
  color: colors.textSecondary,
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
}));

function VlogVideo({ onVideoClick, videos }) {
  if (!Array.isArray(videos)) return null;

  return (
    <CustomGrid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: {
          xs: "center",
          sm: "flex-start",
        },
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        padding: {
          xs: "60px 0px 10px",
          sm: "60px 0px 250px",
        },
      }}
    >
      {videos.map((video, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <VideoContainer>
            <VideoWrapper onClick={() => onVideoClick(video)}>
              <ReactPlayer
                url={video.url}
                width="100%"
                height="100%"
                controls
              />
            </VideoWrapper>
            <VideoTextContainer>
              <TitleTypography
                variant="subtitle1"
                onClick={() => onVideoClick(video)}
              >
                {video.title}
              </TitleTypography>
              <DateTypography variant="body2">{video.date}</DateTypography>
            </VideoTextContainer>
          </VideoContainer>
        </Grid>
      ))}
    </CustomGrid>
  );
}

VlogVideo.propTypes = {
  onVideoClick: PropTypes.func.isRequired,
  videos: PropTypes.array.isRequired,
};

export default VlogVideo;
