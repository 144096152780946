import React from "react";
import {
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Popover,
} from "@mui/material";
import { styled } from "@mui/system";
import calender from "../../../assets/calander.svg";
import { colors } from "../../../Utils/colorUtils";
import DatePicker from "./DatePicker";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

const CustomPopover = styled(Popover)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    "& .MuiPopover-paper": {
      transform: "translateX(-370px) !important",
      marginTop: "20px",
    },
  },
}));

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: colors.textTertiary,
  borderRadius: "10px",
  height: 40,
  padding: "10px 20px",
  textTransform: "none",
  border: `1px solid ${colors.borderPrimary}`,
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
    height: 60,
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  background: colors.secondary,
  color: colors.textFourth,
  borderRadius: "10px",
  height: 40,
  fontSize: "14px",
  textTransform: "none",
  fontWeight: "600",
  width: "auto",
  padding: "10px 10px",
  border: `1px solid ${colors.borderPrimary}`,
  whiteSpace: "nowrap",
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    marginTop: "20px",
    height: 60,
    width: "100%",
  },
}));

function OverViewDates() {
  const [selected, setSelected] = React.useState("24hours");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event, newSelected) => {
    setSelected(newSelected);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <CustomGrid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: {
          xs: "center",
          sm: "space-between",
        },
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        padding: {
          xs: "60px 0px 10px",
          sm: "60px 0px 10px",
        },
      }}
    >
      <Grid item xs={12} sm={6} md={10}>
        <ToggleButtonGroup
          value={selected}
          exclusive
          onChange={handleChange}
          aria-label="overview dates"
        >
          <CustomToggleButton value="24hours" aria-label="24 hours">
            24 hours
          </CustomToggleButton>
          <CustomToggleButton value="7days" aria-label="7 days">
            7 days
          </CustomToggleButton>
          <CustomToggleButton value="30days" aria-label="30 days">
            30 days
          </CustomToggleButton>
          <CustomToggleButton value="12months" aria-label="12 months">
            12 months
          </CustomToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={2}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <CustomButton aria-describedby={id} onClick={handleClick}>
          <img src={calender} style={{ marginRight: "8px" }} alt="plus" />
          Select dates
        </CustomButton>
        <CustomPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DatePicker onClose={handleClose} />
        </CustomPopover>
      </Grid>
    </CustomGrid>
  );
}

export default OverViewDates;
