import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Popper,
  Paper,
  ClickAwayListener,
  ListItem,
} from "@mui/material";
import { styled } from "@mui/system";

const SearchInputStyled = styled(TextField)({
  width: "100%",
  marginBottom: "24px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D0D5DD",
      borderRadius: "10px",
    },
  },
});

const DropdownMenu = styled(Paper)({
  maxHeight: "200px",
  overflowY: "auto",
  width: "100%",
  position: "absolute",
  zIndex: 2000,
});

const Wrapper = styled("div")({
  position: "relative",
  width: "100%",
});

const SearchInput = ({
  options = [],
  placeholder = "",
  onSelect = () => {},
}) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [anchorEl, setAnchorEl] = useState(null);
  const wrapperRef = useRef(null);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setFilteredOptions(
      options.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase()),
      ),
    );
    setOpen(true);
  };

  const handleSelect = (option) => {
    setInputValue(option);
    setOpen(false);
    onSelect(option);
  };

  return (
    <Wrapper ref={wrapperRef}>
      <SearchInputStyled
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        fullWidth
        size="small"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setOpen(true);
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        style={{
          width: anchorEl ? anchorEl.offsetWidth : undefined,
          zIndex: 2000,
        }}
      >
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <DropdownMenu>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, idx) => (
                <ListItem button key={idx} onClick={() => handleSelect(option)}>
                  {option}
                </ListItem>
              ))
            ) : (
              <ListItem>No results</ListItem>
            )}
          </DropdownMenu>
        </ClickAwayListener>
      </Popper>
    </Wrapper>
  );
};

SearchInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
};

export default SearchInput;
