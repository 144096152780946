import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PropTypes from "prop-types";

function TreatmentTablePagination({
  currentPage,
  itemsPerPage,
  totalItems,
  handlePageChange,
  classes,
}) {
  const totalPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      sx={{
        position: "sticky",
        bottom: 0,
        backgroundColor: "#FFFFFF",
        zIndex: 1,
      }}
    >
      <Typography className={classes.pageInfo}>
        Page {currentPage + 1} of {totalPages}
      </Typography>
      <Box className={classes.paginationButtons}>
        <Button
          onClick={() => handlePageChange(null, currentPage - 1)}
          disabled={currentPage === 0}
          sx={{
            border: "1px solid #D0D5DD",
            color: "#344054",
            borderRadius: "10px",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(null, currentPage + 1)}
          disabled={currentPage >= totalPages - 1}
          sx={{
            border: "1px solid #D0D5DD",
            color: "#344054",
            borderRadius: "10px",
            fontWeight: "600",
            fontSize: "14px",
            marginLeft: "10px",
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

TreatmentTablePagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default TreatmentTablePagination;
