import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Button,
  Typography,
  Checkbox,
  useMediaQuery,
  useTheme,
  InputLabel,
  IconButton,
  Divider,
  InputAdornment,
  Modal,
  Snackbar,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { colors } from "../../Utils/colorUtils";
import Logo from "../../assets/painPal-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentYear } from "../../Utils/dateUtils";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoginScreenImage from "../../assets/login-screen-pain-pal.jpeg";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  position: "relative",
}));

const FormContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(4),
  background: colors.secondary,
  position: "relative",
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundImage: `url(${LoginScreenImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    color: colors.textFourth,
    borderColor: colors.borderPrimary,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.borderPrimary,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.borderPrimary,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.violetPrimary,
  },
  "& .MuiInputBase-input": {
    color: colors.textFourth,
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.violetPrimary,
  },
  "& .Mui-focused .MuiOutlinedInput-input": {
    color: colors.textFourth,
  },
  "& .Mui-focused .MuiInputLabel-root": {
    display: "none",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: colors.violetSecondary,
  },
}));

const Footer = styled(Typography)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  color: colors.textSecondary,
  fontWeight: "400",
  fontSize: "10px",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: colors.secondary,
  padding: theme.spacing(4),
  boxShadow: theme.shadows[5],
  borderRadius: "10px",
  maxWidth: 400,
  width: "100%",
  outline: "none",
}));

const LoginScreen = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotEmailError, setForgotEmailError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSignIn = async () => {
    setEmailError("");
    setPasswordError("");

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError("Password must be at least 6 characters long.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/auth/login/doctor`,
        {
          email,
          password,
        },
      );

      if (response.status === 200 && response.data && response.data.tokens) {
        localStorage.setItem("token", response.data.tokens.refreshToken);
        localStorage.setItem("userId", response.data.user.id);
        if (response.data.user.doctor) {
          localStorage.setItem("docId", response.data.user.doctor.id);
        }

        window.location.href = "/";
        navigate("/");
      } else {
        setError("Login failed. Please try again.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError(
          "An error occurred. Please check your credentials and try again.",
        );
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("user", user);
      const token = await user.getIdToken();
      const { email, photoURL, uid, displayName } = user;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/auth/google-sign-in-admin`,
        {
          email,
          photoUrl: photoURL,
          uid,
          displayName,
        },
      );

      if (response.data.success) {
        const refreshToken = response.data?.tokens?.refreshToken;
        const docId = response.data?.user?.doctor?.id;
        const userId = response.data?.user?.id;
        if (refreshToken) localStorage.setItem("token", refreshToken);
        if (docId) localStorage.setItem("docId", docId);
        if (userId) localStorage.setItem("userId", userId);
        window.location.href = "/";
        navigate("/");
      } else {
        setError(response.data.message);
        console.error("Google sign-in error:", response.data.message);
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Google sign-in failed. Please try again.",
      );
      console.error("Google sign-in error:", error);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleForgotPasswordSubmit = async () => {
    setForgotEmailError("");
    if (!forgotEmail) {
      setForgotEmailError("Email cannot be empty.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/auth/forgot-password`,
        {
          email: forgotEmail,
        },
      );

      if (response.status === 200 && response.data.success) {
        setModalOpen(false);
        setSnackbarMessage("Reset link sent successfully!");
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(
          response.data.message || "Failed to send reset link.",
        );
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage(
        error.response?.data?.message || "An unexpected error occurred.",
      );
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (emailError && validateEmail(e.target.value)) {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (passwordError && validatePassword(e.target.value)) {
      setPasswordError("");
    }
  };

  return (
    <>
      <Container>
        <FormContainer>
          <Box width="100%" maxWidth={400}>
            <Box display="flex" justifyContent="center" mb={2}>
              <img src={Logo} alt="Logo" style={{ marginBottom: "20px" }} />
            </Box>
            <Box display="flex" justifyContent="center" mb={2}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  color: colors.textPrimary,
                  fontWeight: "600",
                  marginBottom: "0px",
                }}
              >
                Welcome back
              </Typography>
            </Box>
            <Typography
              variant="body2"
              align="center"
              mb={2}
              sx={{
                color: colors.textTertiary,
                marginBottom: "30px",
                marginTop: "0px",
                fontWeight: "400",
              }}
            >
              Welcome back! Please enter your details.
            </Typography>

            <Box mb={2}>
              <InputLabel
                sx={{
                  color: colors.textTertiary,
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingBottom: "0.6rem",
                }}
              >
                Email
              </InputLabel>
              <StyledTextField
                fullWidth
                placeholder="Enter your email"
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                value={email}
                onChange={handleEmailChange}
                error={!!emailError}
                helperText={emailError}
                style={{ borderRadius: "10px" }}
              />
            </Box>
            <Box mb={2}>
              <InputLabel
                sx={{
                  color: colors.textTertiary,
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingBottom: "0.6rem",
                }}
              >
                Password
              </InputLabel>
              <StyledTextField
                fullWidth
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                value={password}
                onChange={handlePasswordChange}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? (
                          <VisibilityOff
                            sx={{ height: "16px", width: "16px" }}
                          />
                        ) : (
                          <Visibility sx={{ height: "16px", width: "16px" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {error && (
                <Typography
                  variant="body2"
                  align="center"
                  sx={{
                    color: "red",
                    marginTop: "20px",
                    fontWeight: "400",
                  }}
                >
                  {error}
                </Typography>
              )}
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box display="flex" alignItems="center">
                <Checkbox />
                <Typography
                  variant="body2"
                  sx={{ color: colors.textTertiary, fontWeight: "500" }}
                >
                  Remember for 30 days
                </Typography>
              </Box>
              <Typography
                onClick={handleOpenModal}
                variant="body2"
                sx={{
                  color: colors.violetSecondary,
                  textDecoration: "none",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Forgot password
              </Typography>
            </Box>
            <StyledButton
              fullWidth
              variant="contained"
              size="large"
              sx={{
                backgroundColor: colors.violetPrimary,
                color: colors.secondary,
                borderRadius: "10px",
                textTransform: "none",
              }}
              onClick={handleSignIn}
            >
              Sign in
            </StyledButton>
            <Button
              fullWidth
              variant="outlined"
              startIcon={
                <img
                  src="https://img.icons8.com/color/48/000000/google-logo.png"
                  alt="Google icon"
                  style={{ height: "25px", width: "auto" }}
                />
              }
              sx={{
                mt: 2,
                color: colors.textPrimary,
                borderColor: colors.borderPrimary,
                padding: "10px 0px",
                textTransform: "none",
                fontWeight: "600",
                borderRadius: "10px",
              }}
              onClick={handleGoogleSignIn}
            >
              Sign in with Google
            </Button>
            <Box display="flex" justifyContent="center" mt={3}>
              <Typography
                variant="body2"
                sx={{
                  color: colors.textSecondary,
                  fontWeight: "400",
                  marginBottom: "30px",
                }}
              >
                Don’t have an account?{" "}
                <Link
                  to="/register"
                  variant="body2"
                  sx={{
                    color: colors.violetSecondary,
                    textDecoration: "none",
                    fontWeight: "600",
                  }}
                >
                  Sign up
                </Link>
              </Typography>
            </Box>
          </Box>

          <Footer>
            © {getCurrentYear()} PainPal | All Rights Reserved | Privacy Policy
          </Footer>

          <Modal open={modalOpen} onClose={handleCloseModal}>
            <ModalContent>
              <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: colors.textPrimary,
                }}
              >
                <CloseIcon />
              </IconButton>

              <Typography
                variant="p"
                component="h6"
                sx={{
                  marginBottom: "10px",
                  color: colors.textPrimary,
                  fontWeight: 700,
                }}
              >
                Forgot Password
              </Typography>
              <Divider sx={{ mb: 2, background: "#EAECF0", height: "2px" }} />
              <InputLabel
                sx={{
                  color: colors.textTertiary,
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingBottom: "0.6rem",
                }}
              >
                Email
              </InputLabel>

              <StyledTextField
                fullWidth
                placeholder="Enter your email"
                value={forgotEmail}
                onChange={(e) => setForgotEmail(e.target.value)}
                error={!!forgotEmailError}
                helperText={forgotEmailError}
                style={{ borderRadius: "10px" }}
              />

              <StyledButton
                fullWidth
                variant="contained"
                sx={{
                  marginTop: "20px",
                  backgroundColor: colors.violetPrimary,
                  color: colors.secondary,
                  textTransform: "none",
                }}
                onClick={handleForgotPasswordSubmit}
              >
                Send reset link
              </StyledButton>
            </ModalContent>
          </Modal>
        </FormContainer>
        {isDesktop && <ImageContainer />}
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={
          <Box display="flex" alignItems="center">
            <CheckCircleIcon sx={{ marginRight: "8px", color: "#fff" }} />
            {snackbarMessage}
          </Box>
        }
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: colors.violetPrimary,
            color: "#fff",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          },
        }}
      />
    </>
  );
};

export default LoginScreen;
