import React from "react";
import { styled } from "@mui/system";
import { Divider, useMediaQuery } from "@mui/material";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
} from "@mui/material";
import mailIcon1 from "../../assets/mailIcon1.svg";
import Add from "../../assets/assPlus.svg";
import { colors } from "../../../src/Utils/colorUtils";
import helpCircle from "../../assets/help-circle.svg";

function InviteTeamMembers() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const DividerStyled = styled(Divider)({
    borderBottomWidth: "2px",
    borderColor: colors.borderColor,
    marginTop: "10px",
    marginBottom: "40px",
  });

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2} direction={isMobile ? "column" : "row"}>
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                textAlign: "left",
                color: colors.textTertiary,
              }}
            >
              Invite team members
              <img
                src={helpCircle}
                alt="help-circle"
                style={{ marginLeft: "5px" }}
              />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mb: 2,
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  textAlign: "left",
                  color: colors.textSecondary,
                }}
              >
                Get your projects up and running faster by inviting your team to
                collaborate.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="you@example.com"
              sx={{
                borderRadius: "10px",
                marginBottom: "24px",
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                  "& fieldset": {
                    borderColor: "#D0D5DD",
                    borderRadius: "10px",
                  },
                  "& input": {
                    height: "100%",
                    padding: "0 14px",
                    lineHeight: "48px",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <img
                    src={mailIcon1}
                    alt="mailIcon1"
                    style={{ color: colors.textSecondary }}
                  />
                ),
              }}
            />
            {!isMobile && (
              <Button
                sx={{
                  maxWidth: "auto",
                  backgroundColor: "transparent",
                  color: colors.textSecondary,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  height: "40px",
                }}
              >
                <img
                  src={Add}
                  alt="add"
                  style={{ marginRight: "8px", height: "18px", width: "18px" }}
                />
                Add another
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ textAlign: isMobile ? "left" : "right", alignItems: "right" }}
          >
            {!isMobile && (
              <TextField
                select
                fullWidth
                variant="outlined"
                defaultValue="Read only"
                sx={{
                  borderRadius: "10px",
                  marginBottom: "24px",
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                    "& fieldset": {
                      borderColor: "#D0D5DD",
                      borderRadius: "10px",
                    },
                  },
                  "& .MuiSelect-select": {
                    height: "100%",
                    padding: "0 14px",
                    lineHeight: "48px",
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                <MenuItem value="Read only">Read only</MenuItem>
                <MenuItem value="Editor">Editor</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </TextField>
            )}
            {!isMobile && (
              <Button
                variant="contained"
                sx={{
                  height: "40px",
                  borderRadius: "10px",
                  backgroundColor: "#7F56D9",
                  "&:hover": {
                    backgroundColor: "#6A4AB6",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: "40px",
                  paddingRight: "16px",
                  position: "relative",
                  textTransform: "none",
                  fontSize: "14px",
                  marginLeft: "auto",
                }}
              >
                <img
                  src={mailIcon1}
                  alt="Email Profile Icon"
                  style={{
                    position: "absolute",
                    left: "12px",
                    fill: "#FFFFFF",
                    marginRight: "16px",
                    filter: "brightness(0) invert(1)",
                  }}
                />
                Send Invitation
              </Button>
            )}
          </Grid>
          {isMobile && (
            <>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  defaultValue="Read only"
                  sx={{
                    borderRadius: "10px",
                    marginBottom: "24px",
                    "& .MuiOutlinedInput-root": {
                      height: "48px",
                      "& fieldset": {
                        borderColor: "#D0D5DD",
                        borderRadius: "10px",
                      },
                    },
                    "& .MuiSelect-select": {
                      height: "100%",
                      padding: "0 14px",
                      lineHeight: "48px",
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                >
                  <MenuItem value="Read only">Read only</MenuItem>
                  <MenuItem value="Editor">Editor</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{
                    width: "100%",
                    backgroundColor: "transparent",
                    color: colors.textSecondary,
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: "14px",
                    borderRadius: "8px",
                    padding: "10px 20px",
                    height: "40px",
                    marginBottom: 2,
                  }}
                >
                  <img
                    src={Add}
                    alt="add"
                    style={{
                      marginRight: "8px",
                      height: "18px",
                      width: "18px",
                    }}
                  />
                  Add another
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: "40px",
                    borderRadius: "10px",
                    backgroundColor: "#7F56D9",
                    "&:hover": {
                      backgroundColor: "#6A4AB6",
                    },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: "40px",
                    paddingRight: "16px",
                    position: "relative",
                    textTransform: "none",
                    fontSize: "14px",
                  }}
                >
                  <img
                    src={mailIcon1}
                    alt="Email Profile Icon"
                    style={{
                      position: "absolute",
                      left: "12px",
                      fill: "#FFFFFF",
                      marginRight: "16px",
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                  Send Invitation
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <DividerStyled />
    </>
  );
}

export default InviteTeamMembers;
