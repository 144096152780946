import React from "react";
import PropTypes from "prop-types";
import SingleTreatmentCard from "./SingleTreatmentCard";

function TreatmentCardsContainer({ imageData, filterStatus, onCardClick }) {
  const filteredData = imageData.filter(
    (image) => filterStatus === "" || image.status === filterStatus,
  );

  return (
    <SingleTreatmentCard
      images={filteredData.map((image) => {
        const url =
          image.treatmentPlan?.treatments[0]?.exerciseMaster?.exercise_video ||
          "https://plus.unsplash.com/premium_photo-1663127710106-786eb7c45433?q=80&w=2854&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
        console.log(url);

        return {
          url,
          title: image.treatmentPlan.treatment_plan_name || "No Title",
          exercises:
            image.treatmentPlan.treatment_description || "No exercises",
          owner:
            `${image.doctor.user.first_name} ${image.doctor.user.last_name}` ||
            "Unknown Owner",
          status: image.treatmentPlan.status || "No status",
          tags: image.treatmentPlan.tags || ["No tags"],
          id: image.treatment_plan_id,
        };
      })}
      onCardClick={onCardClick}
    />
  );
}

TreatmentCardsContainer.propTypes = {
  imageData: PropTypes.arrayOf(
    PropTypes.shape({
      treatmentPlan: PropTypes.shape({
        treatment_plan_name: PropTypes.string.isRequired,
        treatment_description: PropTypes.string.isRequired,
        url: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        treatment_plan_id: PropTypes.string.isRequired,
      }).isRequired,
      doctor: PropTypes.shape({
        user: PropTypes.shape({
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      status: PropTypes.string.isRequired,
    }),
  ).isRequired,
  filterStatus: PropTypes.string.isRequired,
  onCardClick: PropTypes.func.isRequired,
};

export default TreatmentCardsContainer;
