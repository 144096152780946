import React from "react";
import PatientMain from "../../Components/PatientsScreen Components/PatientMain";
import MainSideBar from "../../Components/Sidebar Component/MainSideBar";
import { Grid } from "@mui/material";

function HomeScreen() {
  return (
    <Grid
      className="container-fluid"
      sx={{
        height: {
          xs: "auto",
          md: "100vh",
        },
        overflow: "hidden",
      }}
    >
      <Grid className="row" sx={{ height: { xs: "auto", md: "100vh" } }}>
        <MainSideBar />
        <Grid
          className={`col-12 col-md-10`}
          sx={{
            height: { xs: "auto", md: "100vh" },
            padding: {
              xs: "16px",
              md: "16px 32px",
            },
            paddingBottom: {
              xs: "100px",
              md: "30px",
            },
          }}
        >
          <PatientMain />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HomeScreen;
