import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Tabs, Tab, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import VlogMain from "../../Components/PatientOverview Components/VlogComponent/VlogMain";
import ProfileMain from "../../Components/PatientOverview Components/ProfileComponents/ProfileMain";
import HistoryMain from "../../Components/PatientOverview Components/HistoryComponents/HistoryMain";
import BackButton from "../../Components/PatientOverview Components/PatientOverViewHeader/BackButton";
import DocumentMain from "../../Components/PatientOverview Components/DocumentsComponents/DocumentMain";
import MessagesMain from "../../Components/PatientOverview Components/MessagesComponents/MessagesMain";
import OverViewHeader from "../../Components/PatientOverview Components/PatientOverViewHeader/OverViewHeader";
import TreatmentMain from "../../Components/PatientOverview Components/TreatmentPlanComponents/TreatmentMain";
import HistoryRightAICard from "../../Components/PatientOverview Components/HistoryComponents/HistoryRightAICard";
import OverViewMain from "../../Components/PatientOverview Components/OverViewComponents/OverViewMain";
import { useParams } from "react-router-dom";

const CustomGrid = styled(Grid)(() => ({
  padding: "0px",
}));

const Background = styled(Box)({
  backgroundColor: "transparent",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
});

const FixedHeader = styled(Grid)(({ isEditingTreatment }) => ({
  position: "sticky",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 1000,
  backgroundColor: "white",
  padding: isEditingTreatment ? "0px" : "36px 0px 9px 20px",
  boxShadow: `
        0px 4px 6px rgba(127, 86, 217, 0.1), 
        0px 8px 10px rgba(127, 86, 217, 0.08), 
        0px 16px 24px rgba(127, 86, 217, 0.07)`,
}));

const ScrollContent = styled(Box)(({ isEditingTreatment }) => ({
  flexGrow: 1,
  overflowY: "auto",
  height: isEditingTreatment ? "100vh" : "calc(100vh - 200px)",
  padding: isEditingTreatment ? "0px" : "0px",
  backgroundColor: "inherit",
}));

const OverviewContent = ({ formData, loading }) => (
  <Grid
    container
    spacing={3}
    sx={{
      marginTop: "-10px",
      padding: "10px 40px 10px 20px",
      background: "white",
      paddingBottom: { xs: "120px", md: "100px" },
    }}
  >
    <OverViewMain formData={formData} loading={loading} />
  </Grid>
);

OverviewContent.propTypes = {
  formData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const HistoryContent = () => (
  <Grid
    container
    spacing={3}
    sx={{
      marginTop: "-10px",
      padding: "10px 40px 10px 20px",
      background: "white",
      paddingBottom: { xs: "120px", md: "100px" },
    }}
  >
    <Grid item xs={12} md={9}>
      <CustomGrid>
        <HistoryMain />
      </CustomGrid>
    </Grid>
    <Grid item xs={12} md={3}>
      <CustomGrid>
        <HistoryRightAICard />
      </CustomGrid>
    </Grid>
  </Grid>
);

const TreatmentPlanContent = ({
  isEditingTreatment,
  setIsEditingTreatment,
  setTabValue,
}) => (
  <Grid
    container
    spacing={3}
    sx={{
      marginTop: isEditingTreatment ? "0px" : "-10px",
      padding: "0px 20px 0px 20px",
      background: isEditingTreatment ? "white" : "#F9FAFB",
      paddingBottom: isEditingTreatment
        ? { xs: "100px", md: "0px" }
        : { xs: "120px", md: "80px" },
    }}
  >
    <Grid item xs={12} md={12}>
      <CustomGrid>
        <TreatmentMain
          isEditingTreatment={isEditingTreatment}
          setIsEditingTreatment={setIsEditingTreatment}
          setTabValue={setTabValue}
        />
      </CustomGrid>
    </Grid>
  </Grid>
);

const VlogsContent = () => (
  <Grid
    container
    spacing={3}
    sx={{
      marginTop: "-10px",
      padding: "10px 40px 10px 20px",
      background: "#F9FAFB",
      paddingBottom: { xs: "160px", md: "40px" },
    }}
  >
    <Grid item xs={12} md={12}>
      <CustomGrid>
        <VlogMain />
      </CustomGrid>
    </Grid>
  </Grid>
);

const MessagesContent = () => (
  <Grid
    container
    spacing={3}
    sx={{
      marginTop: "0px",
      padding: "0px 0px 10px 0px",
      background: "#F9FAFB",
      paddingBottom: { xs: "100px", md: "40px" },
    }}
  >
    <Grid item xs={12} md={12}>
      <CustomGrid>
        <MessagesMain />
      </CustomGrid>
    </Grid>
  </Grid>
);

const DocumentsContent = () => (
  <Grid
    container
    spacing={3}
    sx={{
      marginTop: "-10px",
      padding: "10px 40px 10px 20px",
      background: "white",
      minHeight: `calc(100vh - 190px)`,
      paddingBottom: { xs: "160px", md: "100px" },
    }}
  >
    <Grid item xs={12} md={12}>
      <CustomGrid>
        <DocumentMain />
      </CustomGrid>
    </Grid>
  </Grid>
);

const ProfileContent = ({ fetchPatientProfile }) => (
  <Grid
    container
    spacing={3}
    sx={{
      marginTop: "-10px",
      padding: "10px 40px 10px 20px",
      background: "white",
      paddingBottom: { xs: "250px", md: "100px" },
    }}
  >
    <Grid item xs={12} md={12}>
      <CustomGrid>
        <ProfileMain fetchPatientProfile={fetchPatientProfile} />
      </CustomGrid>
    </Grid>
  </Grid>
);

ProfileContent.propTypes = {
  fetchPatientProfile: PropTypes.func.isRequired,
};

const PatientOverview = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isEditingTreatment, setIsEditingTreatment] = useState(false);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { patientId } = useParams();
  const token = localStorage.getItem("token");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profile: "",
  });

  const fetchPatientProfile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/patient/get-patient-profile/${patientId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const data = await response.json();
      setFormData((prevData) => ({
        ...prevData,
        firstName: data.patient.user.first_name || "",
        lastName: data.patient.user.last_name || "",
        email: data.patient.user.email || "",
        profile: data.patient.user.profile_picture || "",
      }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };

  useEffect(() => {
    fetchPatientProfile();
  }, []);

  return (
    <Background>
      <FixedHeader
        container
        spacing={0}
        isEditingTreatment={isEditingTreatment}
      >
        {!isEditingTreatment && (
          <>
            <BackButton link="/" name="Back to Patients" />
            <OverViewHeader formData={formData} loading={loading} />
            <Grid item xs={12}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="overview tabs"
                variant={isMobile ? "scrollable" : "standard"}
                scrollButtons={isMobile ? "auto" : false}
                allowScrollButtonsMobile
                sx={{
                  marginTop: "20px",
                  marginBottom: "-10px",
                  borderBottom: 1,
                  borderColor: "divider",
                  "& .MuiTab-root": {
                    color: "#667085",
                    fontWeight: "600",
                    textTransform: "none",
                  },
                  "& .Mui-selected": {
                    color: "#7F56D9 !important",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#7F56D9 !important",
                  },
                }}
              >
                <Tab label="Overview" />
                <Tab label="History" />
                <Tab label="Treatment Plan" />
                <Tab label="Vlogs" />
                <Tab label="Messages" />
                <Tab label="Documents" />
                <Tab label="Profile" />
              </Tabs>
            </Grid>
          </>
        )}
      </FixedHeader>
      <ScrollContent isEditingTreatment={isEditingTreatment}>
        {tabValue === 0 && !isEditingTreatment && (
          <OverviewContent formData={formData} loading={loading} />
        )}
        {tabValue === 1 && !isEditingTreatment && <HistoryContent />}
        {tabValue === 2 && (
          <TreatmentPlanContent
            isEditingTreatment={isEditingTreatment}
            setIsEditingTreatment={setIsEditingTreatment}
            setTabValue={setTabValue}
          />
        )}
        {tabValue === 3 && !isEditingTreatment && <VlogsContent />}
        {tabValue === 4 && !isEditingTreatment && <MessagesContent />}
        {tabValue === 5 && !isEditingTreatment && <DocumentsContent />}
        {tabValue === 6 && !isEditingTreatment && (
          <ProfileContent fetchPatientProfile={fetchPatientProfile} />
        )}
      </ScrollContent>
    </Background>
  );
};

TreatmentPlanContent.propTypes = {
  isEditingTreatment: PropTypes.bool.isRequired,
  setIsEditingTreatment: PropTypes.func.isRequired,
  setTabValue: PropTypes.func.isRequired,
};

export default PatientOverview;
