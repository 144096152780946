import React from "react";
import EducationMain from "../../Components/Education Components/EducationMain";
import MainSideBar from "../../Components/Sidebar Component/MainSideBar";
import { Grid } from "@mui/material";

const EducationScreen = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <MainSideBar />
        <Grid
          className={`col-12 col-md-10`}
          sx={{
            height: "auto",
            padding: {
              // xs: "16px",
              // md: "16px 32px",
            },
            paddingBottom: {
              // xs: "100px",
              // md: "30px",
            },
          }}
        >
          <EducationMain />
        </Grid>
      </div>
    </div>
  );
};

export default EducationScreen;
