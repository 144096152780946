import React from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const data = [
  { day: "Mon", times: [0] },
  { day: "Tue", times: [1, 2] },
  { day: "Wed", times: [] },
  { day: "Thu", times: [2] },
  { day: "Fri", times: [] },
  { day: "Sat", times: [3] },
  { day: "Sun", times: [] },
];

const timeSlots = [
  "12pm - 6am",
  "6am - 12pm",
  "12pm - 6pm",
  "6pm - 12am",
  "12am - 6am",
];

const OverViewDiaryLogsChart = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#ffffff",
        borderRadius: 2,
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        overflow: "hidden",
        marginTop: "30px",
        position: "relative",
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Diary Logs
        </Typography>
      </Box>
      <Divider sx={{ borderBottom: "2px solid #EAECF0" }} />
      <Box sx={{ p: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2} sx={{ position: "relative" }}>
            {!isMobile && (
              <Typography
                variant="body2"
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: -30,
                  transform: "rotate(-90deg)",
                  transformOrigin: "right center",
                  color: "#666",
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                Time of day
              </Typography>
            )}

            {isMobile && (
              <Typography
                variant="body2"
                sx={{
                  color: "#666",
                  fontSize: "0.75rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  mb: 1,
                }}
              >
                Time of day
              </Typography>
            )}

            <Grid
              container
              direction={isMobile ? "row" : "column"}
              sx={{
                height: isMobile ? "auto" : "250px",
                mb: isMobile ? 1 : 0,
              }}
            >
              {timeSlots.map((slot, index) => (
                <Grid
                  item
                  key={index}
                  xs
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#666",
                      fontSize: "0.65rem",
                      textAlign: "right",
                      pr: 1,
                      width: "100%",
                    }}
                  >
                    {slot}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid
              container
              sx={{
                height: isMobile ? "150px" : "250px",
                border: "1px solid #fff",
              }}
            >
              {data.map((day, dayIndex) => (
                <Grid item key={dayIndex} xs sx={{ height: "100%" }}>
                  <Grid container direction="column" sx={{ height: "100%" }}>
                    {[0, 1, 2, 3, 4].map((slotIndex) => (
                      <Box
                        key={slotIndex}
                        sx={{
                          backgroundColor: day.times.includes(slotIndex)
                            ? slotIndex === 1
                              ? "#99F6E0"
                              : "#15B79E"
                            : "#F2F4F7",
                          height: "20%",
                          border: "1px solid #fff",
                        }}
                      />
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              {data.map((day, index) => (
                <Grid item key={index} xs>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "#666",
                      fontSize: "0.65rem",
                    }}
                  >
                    {day.day}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#666",
            mt: 2,
            fontSize: "0.75rem",
          }}
        >
          7 days
        </Typography>
      </Box>
    </Box>
  );
};

export default OverViewDiaryLogsChart;
