import React from "react";
import SessionNotesMain from "../../Components/SessionNotes Components/SessionNotesMain";

function SessionNotesScreen() {
  return (
    <>
      <SessionNotesMain />
    </>
  );
}

export default SessionNotesScreen;
