import React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography, Button, Switch, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import shareRight from "../../../assets/shareRight.svg";
import { colors } from "../../../Utils/colorUtils";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 22,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(16px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: colors.violetPrimary,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgba(0, 35, 11, 0.2)",
    width: 22,
    height: 22,
    borderRadius: 11,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#39393D" : "#E9E9EA",
    boxSizing: "border-box",
  },
}));

function QuizSideBar({ publish, handlePublishToggle }) {
  return (
    <Grid item xs={12} sm={6} lg={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            color: colors.textPrimary,
          }}
        >
          Settings
        </Typography>
        <Button
          variant="outlined"
          sx={{
            mr: 2,
            borderRadius: "1px",
            borderColor: "white",
            textTransform: "none",
            height: "40px",
            fontWeight: 600,
            fontSize: "14px",
            color: colors.textSecondary,
            "&:hover": {
              border: "none",
              bgcolor: "none",
              outline: "none",
            },
          }}
        >
          Share
          <img
            src={shareRight}
            alt="shareRight"
            style={{ marginLeft: "10px" }}
          />
        </Button>
      </Box>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          color: colors.textPrimary,
        }}
      >
        Publish
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            color: colors.textSecondary,
            mr: 2,
            flex: 1,
          }}
        >
          Share to patients using AI recommendations.
        </Typography>
        <CustomSwitch checked={publish} onChange={handlePublishToggle} />
      </Box>
      <Divider sx={{ mt: 2, background: "#EAECF0" }} />
      {/* <Typography
        variant="body1"
        sx={{
          fontWeight: 500,
          fontSize: "14px",
          color: colors.textPrimary,
          mt: 2,
        }}
      >
        Privacy
      </Typography> */}
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            color: colors.textSecondary,
            mr: 2,
            flex: 1,
          }}
        >
          Enable shareable link even when the article is not published.
        </Typography>
        <CustomSwitch />
      </Box> */}
    </Grid>
  );
}
QuizSideBar.propTypes = {
  publish: PropTypes.bool.isRequired,
  handlePublishToggle: PropTypes.func.isRequired,
};

export default QuizSideBar;
