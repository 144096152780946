import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  InputBase,
  IconButton,
  Paper,
  Button,
  Typography,
} from "@mui/material";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EmojiPicker from "emoji-picker-react";
import { styled } from "@mui/system";

const InputAreaContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  paddingTop: "0px",
  paddingBottom: "10px",
  paddingRight: "0px",
  background: "#F9FAFB",
  marginLeft: "0px",
  paddingLeft: "0px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
}));

const HiddenFileInput = styled("input")({
  display: "none",
});

const ChatMessageInputArea = ({
  pubnub,
  channel,
  onSendMessage,
  onSendFile,
}) => {
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [file, setFile] = useState(null);
  const typingTimeout = useRef(null);
  const lastTypingEvent = useRef(Date.now());
  const [isCurrentlyTyping, setIsCurrentlyTyping] = useState(false);
  const fileInputRef = useRef(null);

  const handleTyping = (event) => {
    const inputValue = event.target.value;
    setMessage(inputValue);

    if (!pubnub || !channel) return;

    const now = Date.now();
    const typingInterval = 1000;
    if (!isCurrentlyTyping || now - lastTypingEvent.current > typingInterval) {
      lastTypingEvent.current = now;
      setIsCurrentlyTyping(true);

      pubnub.publish({
        channel: channel,
        message: { type: "typing", sender: pubnub.getUUID() },
      });
      clearTimeout(typingTimeout.current);
      typingTimeout.current = setTimeout(() => {
        pubnub.publish({
          channel: channel,
          message: { type: "stop_typing", sender: pubnub.getUUID() },
        });
        setIsCurrentlyTyping(false);
      }, 2000);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleSend = async () => {
    if (file) {
      // Send the selected file if there is one
      await onSendFile(file);
      setFile(null); // Reset the file after sending
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset input field to allow reselecting the same file
      }
    } else if (message.trim()) {
      // Send the text message if no file is selected
      onSendMessage(message);
      setMessage("");

      if (pubnub && channel) {
        clearTimeout(typingTimeout.current);
        pubnub.publish({
          channel: channel,
          message: { type: "stop_typing", sender: pubnub.getUUID() },
        });
        setIsCurrentlyTyping(false);
      }
    }
  };

  const handleEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
    setShowEmojiPicker(false); // Close emoji picker after selection
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent default to avoid new line
      handleSend();
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  return (
    <InputAreaContainer>
      <Paper
        component="form"
        onSubmit={(e) => e.preventDefault()}
        sx={{
          p: "2px 4px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          borderRadius: "8px",
          boxShadow: "none",
          border: "1px solid #D0D5DD",
          minHeight: "64px",
          backgroundColor: "#FFFFFF",
        }}
      >
        {showEmojiPicker && (
          <Box
            sx={{
              position: "absolute",
              bottom: "60px",
              right: "50px",
              zIndex: 1000,
            }}
          >
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <IconButton
            sx={{ p: "10px", color: "#667085" }}
            onClick={toggleEmojiPicker}
            aria-label="Choose emoji"
          >
            <InsertEmoticonIcon />
          </IconButton>
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              minHeight: "30px",
              maxHeight: "40px",
              overflowY: "auto",
              width: "100%",
              paddingTop: "10px",
              paddingBottom: "10px",
              "& textarea": {
                resize: "none",
              },
            }}
            placeholder="Send a message"
            multiline
            value={message}
            onChange={handleTyping}
            onKeyPress={handleKeyPress}
            aria-label="Message input"
          />
          <HiddenFileInput
            ref={fileInputRef}
            type="file"
            id="file-upload"
            onChange={handleFileChange}
            aria-label="Upload file"
          />
          <IconButton
            component="label"
            htmlFor="file-upload"
            sx={{ p: "10px", color: "#667085" }}
            aria-label="Attach file"
          >
            <AttachFileIcon />
          </IconButton>
          <IconButton
            sx={{ p: "10px", color: "#667085" }}
            aria-label="More options"
          >
            <MoreHorizIcon />
          </IconButton>
        </Box>
        {file && (
          <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {file.name}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setFile(null)}
            >
              Remove
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <Button
            onClick={handleSend}
            variant="contained"
            color="primary"
            sx={{
              height: "40px",
              width: "67px",
              fontSize: "14px",
              fontWeight: "600",
              bgcolor: "#7F56D9",
              color: "#FFFFFF",
              borderRadius: "8px",
              "&:hover": { bgcolor: "#6941C6" },
              ml: 1,
            }}
          >
            Send
          </Button>
        </Box>
      </Paper>
    </InputAreaContainer>
  );
};

ChatMessageInputArea.propTypes = {
  pubnub: PropTypes.object,
  channel: PropTypes.string,
  onSendMessage: PropTypes.func.isRequired,
  onSendFile: PropTypes.func,
};

export default ChatMessageInputArea;
