import React from "react";
import PropTypes from "prop-types";
import { Button, Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors } from "../../Utils/colorUtils";
import add from "../../assets/plusAddIcon.svg";
import heartBeatIcon from "../../assets/heartBeatIcon.svg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75vh",
    backgroundColor: "#F9FAFB",
    borderRadius: "20px",
    textAlign: "center",
    border: `1px solid ${colors.borderColor}`,
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      height: "60vh",
      marginBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      height: "75vh",
    },
  },
  content: {
    maxWidth: "400px",
    margin: "0 auto",
    boxShadow: "none",
  },
  icon: {
    fontSize: "48px",
    color: "#9CA3AF",
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: "14px",
    fontWeight: 400,
    color: colors.textSecondary,
    marginBottom: theme.spacing(3),
  },
}));

function TreatmentEmptyView() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Grid container justifyContent="center" mb={2} sx={{}}>
          <img
            src={heartBeatIcon}
            alt="upload-cloud-02"
            className="box-shadow rounded-md"
            style={{
              border: "1px solid #EAECF0",
              width: "40px",
              height: "40px",
              padding: "10px",
              borderRadius: "10px",
              background: colors.secondary,
              boxShadow: "0px 1px 2px 0px #1018280D",
            }}
          />
        </Grid>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            color: colors.textPrimary,
            mb: 1,
          }}
        >
          Create Your First Treatment Plan
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            color: colors.textSecondary,
            mb: 2,
          }}
        >
          Create personalised treatment plans for your <br /> patients to help
          them on their path to recovery.
        </Typography>
        <Link to="/new-treatment">
          <Button
            variant="contained"
            startIcon={
              <img src={add} alt="Add" style={{ width: 20, height: 20 }} />
            }
            sx={{
              backgroundColor: colors.violetPrimary,
              color: "#ffffff",
              borderRadius: "10px",
              height: 40,
              fontSize: "14px",
              fontWeight: "600",
              textTransform: "none",
              width: { xs: "100%", md: "auto" },
              "&:hover": {
                backgroundColor: colors.violetSecondary,
              },
            }}
          >
            New Plan
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

TreatmentEmptyView.propTypes = {
  handleAddTreatmentClick: PropTypes.func.isRequired,
};

export default TreatmentEmptyView;
