import { Box, Tab, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { colors } from "../../Utils/colorUtils";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ArticleMain from "./Article Components/ArticleMain";
import QuizMain from "./Quiz Components/QuizMain";

function EducationMain() {
  const [tabValue, setTabValue] = useState(
    localStorage.getItem("tabValue") || "1",
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem("tabValue", newValue);
  };

  useEffect(() => {
    const storedTabValue = localStorage.getItem("tabValue");
    if (storedTabValue) {
      setTabValue(storedTabValue);
    }
  }, []);

  return (
    <>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0}
        >
          <Typography
            style={{
              fontWeight: "600",
              color: colors.textPrimary,
              fontSize: "30px",
              marginTop: "20px",
            }}
          >
            Education
          </Typography>
        </Box>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="education tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#7A5AF8",
                },
              }}
            >
              <Tab
                label="Articles"
                value="1"
                sx={{
                  color: "black",
                  textTransform: "none",
                  "&.Mui-selected": { color: "#7A5AF8" },
                }}
              />
              <Tab
                label="Quizzes"
                value="2"
                sx={{
                  color: "black",
                  textTransform: "none",
                  "&.Mui-selected": { color: "#7A5AF8" },
                }}
              />
            </TabList>
          </Box>

          <TabPanel value="1" sx={{ padding: "24px 0px" }}>
            <ArticleMain />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "24px 0px" }}>
            <QuizMain />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default EducationMain;
