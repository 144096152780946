import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
  Grid,
} from "@mui/material";

const NotificationSection = ({ title, description, children }) => (
  <Grid container spacing={4} sx={{ mt: 3 }}>
    <Grid item md={6}>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 0.5 }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
        {description}
      </Typography>
    </Grid>
    <Grid md={6}>{children}</Grid>
    <Grid item md={12}>
      <Divider sx={{ mt: 2, mb: 3, background: "#EAECF0", height: "2px" }} />
    </Grid>
  </Grid>
);

NotificationSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const CheckboxOption = ({ label, description, checked }) => (
  <FormControlLabel
    control={<Checkbox checked={checked} />}
    label={
      <Box>
        <Typography variant="body2">{label}</Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </Box>
    }
    sx={{ alignItems: "flex-start", mb: 1 }}
  />
);

CheckboxOption.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

const RadioOption = ({ label, description, value, checked }) => (
  <FormControlLabel
    value={value}
    control={<Radio checked={checked} />}
    label={
      <Box>
        <Typography variant="body2">{label}</Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {description}
        </Typography>
      </Box>
    }
    sx={{ alignItems: "flex-start", mb: 1 }}
  />
);

RadioOption.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

const ProfileScreenMain = () => {
  return (
    <Box sx={{ maxWidth: "100%", mx: "auto", p: 3 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Email Notifications
      </Typography>
      <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
        Get emails to find out whats going on when you are not online. You can
        turn them off anytime.
      </Typography>
      <Divider sx={{ mt: 2, mb: 3, background: "#EAECF0", height: "2px" }} />

      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <NotificationSection
            title="Notifications from Us"
            description="Receive the latest news, updates, and industry tutorials from us."
          >
            <CheckboxOption
              label="News and Updates"
              description="News about product and feature updates."
              checked={true}
            />
            <CheckboxOption
              label="Tips and Tutorials"
              description="Tips on getting more out of our product."
              checked={true}
            />
            <CheckboxOption
              label="User Research"
              description="Get involved in our beta testing program or participate in paid product user research."
              checked={false}
            />
          </NotificationSection>
        </Grid>
      </Grid>

      <Grid>
        <Grid item xs={12} md={12}>
          <NotificationSection
            title="Comments"
            description="These are notifications for comments on your posts and replies to your comments."
          >
            <RadioGroup defaultValue="all">
              <RadioOption
                value="none"
                label="Do Not Notify Me"
                description=""
                checked={false}
              />
              <RadioOption
                value="mentions"
                label="Mentions Only"
                description="Only notify me if I'm mentioned in a comment."
                checked={false}
              />
              <RadioOption
                value="all"
                label="All Comments"
                description="Notify me for all comments on my posts."
                checked={true}
              />
            </RadioGroup>
          </NotificationSection>
        </Grid>

        <Grid item xs={12} md={6}>
          <NotificationSection
            title="Reminders"
            description="These are notifications to remind you of updates you might have missed."
          >
            <RadioGroup defaultValue="all">
              <RadioOption
                value="none"
                label="Do Not Notify Me"
                description=""
                checked={false}
              />
              <RadioOption
                value="important"
                label="Important Reminders Only"
                description="Only notify me if the reminder is tagged as important."
                checked={false}
              />
              <RadioOption
                value="all"
                label="All Reminders"
                description="Notify me for all reminders."
                checked={true}
              />
            </RadioGroup>
          </NotificationSection>
        </Grid>

        <Grid item xs={12} md={6}>
          <NotificationSection
            title="More Activity About You"
            description="These are notifications for posts on your profile, likes, and other reactions to your posts, and more."
          >
            <RadioGroup defaultValue="none">
              <RadioOption
                value="none"
                label="Do Not Notify Me"
                description=""
                checked={true}
              />
              <RadioOption
                value="all"
                label="All Activity"
                description="Notify me for all other activity."
                checked={false}
              />
            </RadioGroup>
          </NotificationSection>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileScreenMain;
