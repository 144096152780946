import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import chevronLeft from "../../../assets/chevron-left.svg";
import chevronRight from "../../../assets/chevron-right.svg";
import { colors } from "../../../Utils/colorUtils";

const CustomDatePickerWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  width: "100%",
  fontFamily: '"Inter", sans-serif',
  "@media (min-width: 600px)": {
    flexDirection: "row",
    width: "720px",
  },
});
const LeftPanel = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  padding: "20px",
  borderBottom: "1px solid #e0e0e0",
  "@media (min-width: 600px)": {
    flexDirection: "column",
    width: "150px",
    borderBottom: "none",
    borderRight: "1px solid #e0e0e0",
  },
});

const TimeRange = styled(Box)({
  padding: "8px 16px",
  cursor: "pointer",
  color: colors.textTertiary,
  fontWeight: "500",
  fontSize: "14px",
  "@media (min-width: 600px)": {
    padding: "8px 0",
  },
});

const RightPanel = styled(Box)({
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  "@media (min-width: 600px)": {
    flexGrow: 1,
  },
  position: "relative",
});

const CustomHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
  backgroundColor: "white",
});

const CustomButton = styled(Button)({
  background: "none",
  border: "none",
  cursor: "pointer",
  fontSize: "18px",
  color: "#666",
});

const BottomSection = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "20px",
  borderTop: "1px solid #e0e0e0",
  paddingTop: "20px",
  width: "calc(100% + 40px)",
  marginLeft: "-20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  position: "relative",
  "@media (min-width: 600px)": {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const DateRangeBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: "0px",
  "@media (min-width: 600px)": {
    marginBottom: "0",
  },
});

const DateInput = styled(Box)({
  padding: "8px 12px",
  border: `1px solid ${colors.borderColor}`,
  borderRadius: "10px",
  cursor: "pointer",
  fontWeight: "400",
  fontSize: "16px",
  backgroundColor: colors.secondary,
  color: colors.textPrimary,
});

const CancelButton = styled(Button)({
  padding: "8px",
  cursor: "pointer",
  border: `1px solid ${colors.borderColor}`,
  borderRadius: "10px",
  fontWeight: "600",
  fontSize: "14px",
  backgroundColor: colors.secondary,
  color: colors.textPrimary,
  marginRight: "10px",
});

const ApplyButton = styled(Button)({
  padding: "8px 16px",
  border: `none`,
  borderRadius: "10px",
  cursor: "pointer",
  fontWeight: "600",
  fontSize: "14px",
  backgroundColor: colors.violetPrimary,
  color: colors.secondary,
});

const DatePickerWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "& .react-datepicker": {
    border: "none",
    width: "100%",
  },
  "& .react-datepicker__month-container": {
    width: "100%",
    border: "none",
    padding: 0,
  },
  "& .first-date-picker .react-datepicker__month-container::after": {
    content: '""',
    position: "absolute",
    top: "-20px",
    right: 0,
    bottom: "-20px",
    width: "1px",
    backgroundColor: "#e0e0e0",
  },
  "@media (min-width: 600px)": {
    flexDirection: "row",
    "& .react-datepicker__month-container": {
      position: "relative",
    },
    "& .first-date-picker .react-datepicker__month-container::after": {
      content: '""',
      position: "absolute",
      top: "-20px",
      right: 0,
      bottom: "-20px",
      width: "1px",
      backgroundColor: "#e0e0e0",
    },
  },
  "& .react-datepicker__header": {
    backgroundColor: "white",
    borderBottom: "none",
  },
  "& .react-datepicker__day-names, .react-datepicker__week": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .react-datepicker__day-name, .react-datepicker__day": {
    width: "2rem",
    lineHeight: "2rem",
    margin: "0.2rem",
    textAlign: "center",
  },
  "& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range":
    {
      backgroundColor: "#8b5cf6",
      borderRadius: "50%",
      color: "white",
    },
  "& .react-datepicker__day:hover": {
    borderRadius: "50%",
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "transparent",
    color: "black",
  },
  "& .react-datepicker__day--outside-month": {
    color: "#ccc",
  },
});

const CustomDatePicker = ({ onClose }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1)),
  );
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1)),
  );

  const formatDate = (date) => {
    if (!date) return "";
    const options = { month: "short", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleCancel = () => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    onClose();
  };

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    if (selectedEndDate && date > selectedEndDate) {
      setSelectedEndDate(date);
    }
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    if (date >= selectedStartDate) {
      setSelectedEndDate(date);
    }
    setEndDate(date);
  };

  const timeRanges = [
    "Today",
    "Yesterday",
    "This week",
    "Last week",
    "This month",
    "Last month",
    "This year",
    "Last year",
    "All time",
  ];

  return (
    <CustomDatePickerWrapper>
      <LeftPanel>
        {timeRanges.map((range) => (
          <TimeRange key={range}>{range}</TimeRange>
        ))}
      </LeftPanel>
      <RightPanel>
        <DatePickerWrapper>
          <Box className="first-date-picker">
            <DatePicker
              selected={selectedStartDate}
              onChange={handleStartDateChange}
              inline
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <CustomHeader>
                  <CustomButton
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img src={chevronLeft} alt="Previous Month" />
                  </CustomButton>
                  <Typography className="month-year">
                    {date.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    })}
                  </Typography>
                  <CustomButton
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <img src={chevronRight} alt="Next Month" />
                  </CustomButton>
                </CustomHeader>
              )}
            />
          </Box>
          <Box>
            <DatePicker
              selected={selectedEndDate}
              onChange={handleEndDateChange}
              inline
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <CustomHeader>
                  <CustomButton
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img src={chevronLeft} alt="Previous Month" />
                  </CustomButton>
                  <Typography className="month-year">
                    {date.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    })}
                  </Typography>
                  <CustomButton
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <img src={chevronRight} alt="Next Month" />
                  </CustomButton>
                </CustomHeader>
              )}
            />
          </Box>
        </DatePickerWrapper>
        <BottomSection>
          <DateRangeBox>
            <DateInput>
              {formatDate(selectedStartDate)} - {formatDate(selectedEndDate)}
            </DateInput>
          </DateRangeBox>
          <Box>
            <CancelButton onClick={handleCancel}>Cancel</CancelButton>
            <ApplyButton onClick={onClose}>Apply</ApplyButton>
          </Box>
        </BottomSection>
      </RightPanel>
    </CustomDatePickerWrapper>
  );
};

CustomDatePicker.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CustomDatePicker;
