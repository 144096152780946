import React from "react";
import { styled } from "@mui/system";
import { Divider, Grid } from "@mui/material";
import { Box, Typography, Breadcrumbs } from "@mui/material";
import breadcrumbsIcon from "../../../src/assets/breadcrumbButton.svg";
import chervronRight from "../../../src/assets/chevron-right.svg";
import { Link } from "react-router-dom";
import { colors } from "../../Utils/colorUtils";

const DividerStyled = styled(Divider)({
  borderBottomWidth: "2px",
  borderColor: colors.borderColor,
  marginTop: "10px",
  marginBottom: "30px",
});

function TeamHeader() {
  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <Link to="/">
              <img
                src={breadcrumbsIcon}
                alt="Breadcrumb Icon"
                style={{ marginRight: "8px" }}
              />
            </Link>
            <img
              src={chervronRight}
              alt="Chevron right"
              style={{
                width: "10px",
                height: "10px",
                marginRight: "15px",
                position: "relative",
                left: "6px",
              }}
            />
            <Link to="/settings" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  textAlign: "left",
                  marginRight: "8px",
                  display: "flex",
                  alignItems: "center",
                  color: "#475467",
                  textDecoration: "none",
                }}
              >
                Setting
              </Typography>
            </Link>
            <img
              src={chervronRight}
              alt="Chevron right"
              style={{
                width: "10px",
                height: "10px",
                marginRight: "15px",
                position: "relative",
                left: "6px",
              }}
            />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                textAlign: "left",
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
                color: "#475467",
                textDecoration: "none",
              }}
            >
              Teams
            </Typography>
          </Grid>
        </Breadcrumbs>
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Inter",
            fontSize: "30px",
            marginTop: "30px",
            fontWeight: 600,
            textAlign: "left",
            color: colors.textPrimary,
          }}
        >
          Team management
        </Typography>

        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "left",
            marginTop: "10px",
            color: colors.textSecondary,
          }}
        >
          Manage your team members and their account permissions here.
        </Typography>
      </Box>
      <DividerStyled />
    </>
  );
}

export default TeamHeader;
