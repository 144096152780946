import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  TextField,
  List,
  ListItem,
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";
import searchImage from "../../../assets/search-lg.svg";
import dots from "../../../assets/9Dots.svg";
import axios from "axios";

const StyledTextField = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.borderPrimary,
    },
    "&:hover fieldset": {
      borderColor: colors.borderPrimary,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.borderPrimary,
      outline: "none",
    },
    borderRadius: 8,
    backgroundColor: colors.secondary,
    width: "auto",
    height: "40px",
    padding: "10px 14px",
    fontWeight: 400,
    color: colors.textFourth,
    fontSize: "16px",
    outline: "none",
  },
}));

const SearchAdornment = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginRight: "8px",
}));

function NewTreatmentLeftContainer({
  onImageDragStart,
  selectedDay,
  days,
  onSearch,
  loading,
  isFiltering,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [allExercises, setAllExercises] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [fetchingMore, setFetchingMore] = useState(false);
  const videoRefs = useRef([]);

  useEffect(() => {
    fetchAllExercises(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (onSearch && typeof onSearch === "function") {
      onSearch(searchQuery);
    }
  }, [searchQuery, onSearch]);

  useEffect(() => {
    filterExercises();
  }, [days, selectedDay, allExercises]);

  useEffect(() => {
    const fetchMoreExercises = async () => {
      if (fetchingMore) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        fetchAllExercises(currentPage + 1);
        setCurrentPage((prev) => prev + 1);
        setFetchingMore(false);
      }
    };

    fetchMoreExercises();
  }, [fetchingMore, currentPage]);

  useEffect(() => {
    if (filteredImages.length < 7 && !fetchingMore && !loading) {
      setFetchingMore(true);
    }
  }, [filteredImages.length, fetchingMore, loading]);

  const fetchAllExercises = async (page = 1) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/exercise/get-exercises?page=${page}&pageSize=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.success) {
        const exercises = response.data.data;
        const formattedExercises = exercises.map((exercise) => ({
          id: exercise.id,
          title: exercise.exercise_name,
          videoSrc: exercise.exercise_video,
          thumbnail: exercise.exercise_thumbnail,
        }));

        setAllExercises((prevExercises) => {
          const existingIds = new Set(prevExercises.map((ex) => ex.id));
          const newExercises = formattedExercises.filter(
            (exercise) => !existingIds.has(exercise.id),
          );
          return [...prevExercises, ...newExercises];
        });
      }
    } catch (error) {
      console.error("Error fetching exercises:", error);
    }
  };

  const filterExercises = () => {
    const selectedDayTreatments =
      days.find((day) => day.id === selectedDay)?.treatments || [];

    const assignedExerciseIds = new Set(
      selectedDayTreatments.map((treatment) => treatment.id),
    );

    const filtered = allExercises.filter((exercise) => {
      return !assignedExerciseIds.has(exercise.id);
    });

    setFilteredImages(filtered);
  };

  const handleDragStart = (event, image) => {
    onImageDragStart(event, image);
  };

  const handleVideoLoad = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].currentTime = 0.1;
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (
      scrollTop + clientHeight >= scrollHeight - 10 &&
      !loading &&
      !fetchingMore
    ) {
      setFetchingMore(true);
    }
  };

  return (
    <Container disableGutters>
      <Box
        sx={{
          padding: "1rem",
          maxHeight: `calc(90vh - 20px)`,
          overflowY: "auto",
          border: "1px solid #EAECF0",
          borderRadius: "0.5rem",
        }}
        onScroll={handleScroll}
      >
        <StyledTextField
          size="small"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <SearchAdornment>
                <img src={searchImage} alt="search" />
              </SearchAdornment>
            ),
          }}
          sx={{
            width: "100%",
            mb: isMobile ? 2 : 0,
          }}
        />

        {loading || isFiltering ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <CircularProgress sx={{ color: "#7F56D9" }} />
          </Box>
        ) : (
          <List>
            {filteredImages.map((exercise, index) => (
              <motion.div
                key={exercise.id}
                draggable
                onDragStart={(event) => handleDragStart(event, exercise)}
                style={{ marginBottom: "0.5rem" }}
              >
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "0.3rem",
                    position: "relative",
                    padding: "0.625rem",
                    border: "1px solid #EAECF0",
                    boxShadow: "0px 1px 2px 0px #1018280D",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      flexShrink: 0,
                      width: "80px",
                      height: "48px",
                      borderRadius: "0.6rem",
                      overflow: "hidden",
                      border: "1px solid #F2F4F7",
                    }}
                  >
                    {exercise.videoSrc && exercise.videoSrc.endsWith(".mp4") ? (
                      <video
                        ref={(el) => (videoRefs.current[index] = el)}
                        src={exercise.videoSrc}
                        onLoadedMetadata={() => handleVideoLoad(index)}
                        muted
                        playsInline
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : exercise.thumbnail ? (
                      <img
                        src={exercise.thumbnail}
                        alt={exercise.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : null}
                  </Box>
                  <Typography
                    sx={{
                      flexGrow: 1,
                      ml: "0.625rem",
                      fontWeight: 600,
                      fontSize: "0.875rem",
                    }}
                  >
                    {exercise.title}
                  </Typography>
                  <Box
                    component="img"
                    src={dots}
                    alt="Icon"
                    sx={{
                      flexShrink: 0,
                      width: "15px",
                      height: "15px",
                      ml: "0.625rem",
                    }}
                  />
                </ListItem>
              </motion.div>
            ))}
          </List>
        )}

        {fetchingMore && (
          <Box display="flex" justifyContent="center" my={2}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Container>
  );
}

NewTreatmentLeftContainer.propTypes = {
  onImageDragStart: PropTypes.func.isRequired,
  selectedDay: PropTypes.number.isRequired,
  days: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isFiltering: PropTypes.bool.isRequired,
};

export default NewTreatmentLeftContainer;
