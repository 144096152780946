import React from "react";
import NewTreatmentMain from "../../Components/Treatment Components/Add New Treatment Components/NewTreatmentMain";

function NewTreatmentScreen() {
  return (
    <>
      <NewTreatmentMain />
    </>
  );
}

export default NewTreatmentScreen;
