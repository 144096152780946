import React from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, Button } from "react-bootstrap";
import arrowLeftPurple from "../../../assets/arrowLeftPurple.svg";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

function NewTreatmentHeader({
  createOrUpdateTreatmentPlan,
  submitTreatments,
  treatmentId,
  droppedItems,
}) {
  const navigate = useNavigate();

  const handleBreadcrumbClick = () => {
    navigate("/treatment");
  };

  const handleSaveClick = async () => {
    try {
      await createOrUpdateTreatmentPlan(treatmentId);
      if (droppedItems.length > 0) {
        await submitTreatments();
      }
      navigate("/treatment");
    } catch (error) {
      console.error("Error saving treatment plan and treatments:", error);
    }
  };

  return (
    <Navbar bg="white" expand="lg" style={{ padding: "20px 10px" }}>
      <Grid>
        <div className="d-flex align-items-center">
          <button
            style={{
              background: "none",
              border: "none",
              padding: 0,
              margin: 0,
              cursor: "pointer",
              fontWeight: 600,
              fontSize: "14px",
              color: "#7f56d9",
              marginBottom: "0px",
            }}
            aria-label="Go to Overview"
            onClick={handleBreadcrumbClick}
          >
            <img
              src={arrowLeftPurple}
              alt="arrowLeftPurple"
              style={{
                width: "16px",
                height: "16px",
                marginRight: "8px",
                marginBottom: "2px",
              }}
            />
            Back to Treatments
          </button>
        </div>
      </Grid>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-between"
      >
        <Nav className="mr-auto"></Nav>
        <Grid className="d-flex align-items-center">
          <Button
            onClick={handleBreadcrumbClick}
            variant="outline-secondary"
            style={{
              marginRight: "10px",
              borderRadius: "8px",
              width: "70px",
              height: "40px",
              color: "black",
              fontWeight: 600,
              borderColor: "#667085",
              border: "1px solid #D0D5DD",
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleSaveClick}
            variant="primary"
            style={{
              borderRadius: "8px",
              width: "70px",
              height: "40px",
              backgroundColor: "#7f56d9",
              border: "none",
              color: "#fff",
              fontWeight: 600,
              textTransform: "none",
            }}
            disabled={droppedItems.length === 0 && !treatmentId}
          >
            Save
          </Button>
        </Grid>
      </Navbar.Collapse>
    </Navbar>
  );
}

NewTreatmentHeader.propTypes = {
  createOrUpdateTreatmentPlan: PropTypes.func.isRequired,
  submitTreatments: PropTypes.func.isRequired,
  treatmentId: PropTypes.string,
  droppedItems: PropTypes.array.isRequired,
};

export default NewTreatmentHeader;
