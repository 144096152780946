import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";

const CustomCard = styled(Card)(({ theme, index, totalCards }) => ({
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  padding: "20px 0",
  margin: "0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100px",
  border: "1px solid #E0E0E0",
  borderRight: "none",
  borderRadius: 0,
  ...(index === 0 && {
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  }),
  ...(index === totalCards - 1 && {
    borderRight: "1px solid #E0E0E0",
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
  }),
  [theme.breakpoints.down("sm")]: {
    borderBottom: "none",
    borderRight: "1px solid #E0E0E0",
    marginBottom: "10px",
    borderRadius: "10px",
    ...(index === 0 && {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    }),
    ...(index === totalCards - 1 && {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      borderBottom: "1px solid #E0E0E0",
    }),
  },
}));

const CustomCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",

  height: "100%",
  padding: "8px !important",
});

const OverViewInfo = () => {
  const theme = useTheme();
  const cardData = [
    { title: "Most painful week day", value: "Monday" },
    { title: "Localisation", value: "Lower back" },
    { title: "Interference", value: "Medium" },
    { title: "Mood", value: "Good" },
  ];

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        width: "100%",
        paddingTop: "30px",
        margin: 0,
        [theme.breakpoints.down("sm")]: {
          flexWrap: "wrap",
        },
      }}
    >
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={6} md={3} key={index} sx={{ padding: 0 }}>
          <CustomCard index={index} totalCards={cardData.length}>
            <CustomCardContent>
              <Typography
                color="textSecondary"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: colors.textSecondary,
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                {card.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: colors.textPrimary,
                  textAlign: "left",
                  paddingLeft: "20px",
                }}
              >
                {card.value}
              </Typography>
            </CustomCardContent>
          </CustomCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default OverViewInfo;
