import React, { useRef, useState, useEffect } from "react";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { ReactComponent as FileHandIcon } from "../../assets/FileHand.svg";
import add from "../../assets/add2.svg";
import DeleteIcons from "../../assets/deleteicon.svg";
import { colors } from "../../Utils/colorUtils";

const UploadBox = styled(Box)(({ theme }) => ({
  border: "1px solid #D0D5DD",
  borderRadius: "12px",
  width: "100%",
  height: "140px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
  "&.dragOver": {
    backgroundColor: "#F9FAFB",
    borderColor: "#7F56D9",
  },
  [theme.breakpoints.down("sm")]: {
    height: "240px",
    padding: "16px",
    textAlign: "center",
    alignItems: "center",
    paddingTop: "80px",
  },
}));

const FileInput = styled("input")({
  display: "none",
});

const FileHandIconWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "-20px",
  top: "64px",
  [theme.breakpoints.down("sm")]: {
    position: "static",
    marginTop: "16px",
  },
}));

const AddIconWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${colors.borderColor}`,
  height: 30,
  width: 30,
  borderRadius: "10px",
  textAlign: "center",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    margin: "0 auto 16px",
  },
}));

const UploadComponent = ({ onFileChange, initialImage }) => {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (initialImage) {
      setImage(initialImage);
    }
  }, [initialImage]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleFile = async (file) => {
    setIsUploading(true);
    const imageUrl = URL.createObjectURL(file);
    setImage(imageUrl);
    onFileChange(file);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsUploading(false);
  };

  const handleRemoveImage = () => {
    setImage(null);
    onFileChange(null);
  };

  const handleReplaceImage = () => {
    setImage(null);
    onFileChange(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  };

  return (
    <>
      {!image ? (
        <UploadBox
          onClick={handleUploadClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={isDragOver ? "dragOver" : ""}
        >
          <FileInput
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/svg+xml,image/png,image/jpeg,image/gif"
          />
          <AddIconWrapper>
            <Box
              component="img"
              src={add}
              alt="Add"
              sx={{
                width: 18,
                height: 18,
              }}
            />
          </AddIconWrapper>

          {isUploading ? (
            <CircularProgress
              size={40}
              sx={{
                color: "#7F56D9",
              }}
            />
          ) : (
            <>
              <Typography
                sx={{
                  color: "#667085",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  mt: "8px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "#7F56D9", fontWeight: 600 }}>
                  Click to upload
                </span>{" "}
                or drag and drop
              </Typography>
              <Typography
                sx={{
                  color: "#667085",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  mt: "4px",
                  textAlign: "center",
                }}
              >
                SVG, PNG, JPG, or GIF (max. 800x400px)
              </Typography>
              <FileHandIconWrapper>
                <FileHandIcon />
              </FileHandIconWrapper>
            </>
          )}

          {isUploading && (
            <>
              <CircularProgress
                size={24}
                sx={{
                  color: "#6941C6",
                  marginRight: "10px",
                }}
              />
            </>
          )}
        </UploadBox>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            border="1px solid #D0D5DD"
            borderRadius="12px"
            padding="8px"
            mt={2}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <img
                src={image}
                alt="Uploaded"
                style={{
                  width: "100px",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: "4px",
                  marginRight: "10px",
                }}
              />
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Uploaded Image
                </Typography>
              </Box>
            </Box>
            <Button onClick={handleRemoveImage}>
              <img src={DeleteIcons} alt="Remove" />
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-start" mt={1}>
            <Button
              variant="outlined"
              sx={{
                color: "#344054",
                borderColor: "#D0D5DD",
                "&:hover": {
                  borderColor: "#7F56D9",
                },
              }}
              onClick={handleRemoveImage}
              disabled={isUploading}
            >
              Replace Image
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

UploadComponent.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  initialImage: PropTypes.string,
};

export default UploadComponent;
