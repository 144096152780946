import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import HomeScreen from "./Screen/HomeScreen/HomeScreen";
import LoginScreen from "./Screen/LoginScreen/LoginScreen";
import RegisterScreen from "./Screen/RegisterScreen/RegisterScreen";
import TreatmentScreen from "./Screen/TreatmentScreen/TreatmentScreen";
import EducationScreen from "./Screen/EducationScreen/EducationScreen";
import SettingsScreen from "./Screen/SettingsScreen/SettingsScreen";
import MessageScreen from "./Screen/MessageScreen/MessageScreen";
import TeamScreen from "./Screen/TeamScreen/TeamScreen";
import PatientOverview from "./Screen/PatientOverview/PatientOverview";
import ForgotPassword from "./Screen/ForgotPasswordScreen/ForgotPassword";
import NewTreatmentScreen from "./Screen/TreatmentScreen/NewTreatmentScreen";
import EditExercise from "./Screen/TreatmentScreen/EditExercise";
import EducationNewQuiz from "./Components/Education Components/Quiz Components/EducationNewQuiz";
import EducationNewArticle from "./Components/Education Components/Article Components/EducationNewArticle";
import SessionNotesScreen from "./Screen/SessionNotesScreen/SessionNotesScreen";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
  },
});

const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expirationTime = payload.exp * 1000;
    return Date.now() >= expirationTime;
  } catch (error) {
    console.error("Error parsing token:", error);
    return true;
  }
};

const TokenExpirationHandler = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token && isTokenExpired(token)) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    };

    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 60000);

    return () => clearInterval(interval);
  }, [token, navigate]);

  return children;
};

TokenExpirationHandler.propTypes = {
  children: PropTypes.node.isRequired,
};

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const token = localStorage.getItem("token");

  if (!token || isTokenExpired(token)) {
    localStorage.removeItem("token");
    return <Navigate to="/login" />;
  }

  return Element;
};
ProtectedRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
};

const App = () => {
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <TokenExpirationHandler>
          <Routes>
            <Route
              path="/login"
              element={
                token && !isTokenExpired(token) ? (
                  <Navigate to="/" />
                ) : (
                  <LoginScreen />
                )
              }
            />
            <Route
              path="/register"
              element={
                token && !isTokenExpired(token) ? (
                  <Navigate to="/" />
                ) : (
                  <RegisterScreen />
                )
              }
            />
            <Route
              path="/forgot-password/:token"
              element={<ForgotPassword />}
            />
            <Route
              path="/treatment"
              element={<ProtectedRoute element={<TreatmentScreen />} />}
            />
            <Route
              path="/session-notes"
              element={<ProtectedRoute element={<SessionNotesScreen />} />}
            />
            <Route
              path="/new-treatment"
              element={<ProtectedRoute element={<NewTreatmentScreen />} />}
            />
            <Route
              path="/treatment/:id"
              element={<ProtectedRoute element={<NewTreatmentScreen />} />}
            />
            <Route
              path="/patient/overview/:patientId"
              element={<ProtectedRoute element={<PatientOverview />} />}
            />
            <Route
              path="/edit/exercise/:singleTreatmentId"
              element={<ProtectedRoute element={<EditExercise />} />}
            />
            <Route
              path="/education"
              element={<ProtectedRoute element={<EducationScreen />} />}
            />
            <Route
              path="/settings"
              element={<ProtectedRoute element={<SettingsScreen />} />}
            />
            <Route
              path="/message"
              element={<ProtectedRoute element={<MessageScreen />} />}
            />
            <Route
              path="/team"
              element={<ProtectedRoute element={<TeamScreen />} />}
            />
            <Route
              path="/"
              element={<ProtectedRoute element={<HomeScreen />} />}
            />
            <Route
              path="/addArticle"
              element={<ProtectedRoute element={<EducationNewArticle />} />}
            />
            <Route
              path="/article/:id"
              element={<ProtectedRoute element={<EducationNewArticle />} />}
            />
            <Route
              path="/newQuiz"
              element={<ProtectedRoute element={<EducationNewQuiz />} />}
            />
            <Route
              path="/quiz/:id"
              element={<ProtectedRoute element={<EducationNewQuiz />} />}
            />
            <Route
              path="*"
              element={
                <Navigate
                  to={token && !isTokenExpired(token) ? "/" : "/login"}
                />
              }
            />
          </Routes>
        </TokenExpirationHandler>
      </Router>
    </ThemeProvider>
  );
};

export default App;
