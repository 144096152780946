import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  InputLabel,
  LinearProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { initializeApp } from "firebase/app";
import { styled } from "@mui/system";
import { colors } from "../../Utils/colorUtils";
import Logo from "../../assets/painPal-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentYear } from "../../Utils/dateUtils";
import LoginScreenImage from "../../assets/login-screen-pain-pal.jpeg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PpSnackbar from "../../Utils/PpSnackbar";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import axios from "axios";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  position: "relative",
}));

const FormContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(4),
  background: colors.secondary,
  position: "relative",
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundImage: `url(${LoginScreenImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    height: "45px",
    color: colors.textFourth,
    borderColor: colors.borderPrimary,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.borderPrimary,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.borderPrimary,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.violetPrimary,
  },
  "& .MuiInputBase-input": {
    color: colors.textFourth,
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.violetPrimary,
  },
  "& .Mui-focused .MuiOutlinedInput-input": {
    color: colors.textFourth,
  },
  "& .Mui-focused .MuiInputLabel-root": {
    display: "none",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: colors.violetSecondary,
  },
}));

const Footer = styled(Typography)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  color: colors.textSecondary,
  fontWeight: "400",
  fontSize: "10px",
}));

const PasswordStrengthBar = styled(LinearProgress)(({ theme, value }) => ({
  marginTop: theme.spacing(1),
  height: 10,
  borderRadius: 5,
  "& .MuiLinearProgress-bar": {
    backgroundColor:
      value < 33 ? "#f44336" : value < 66 ? "#ff9800" : "#4caf50",
  },
}));

function RegisterScreen() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  const app = initializeApp(firebaseConfig);

  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateName = (name) => {
    const nameRegex = /^[A-Za-z]+$/;
    return nameRegex.test(name);
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 25;
    if (password.match(/[a-z]+/)) strength += 10;
    if (password.match(/[A-Z]+/)) strength += 15;
    if (password.match(/[0-9]+/)) strength += 15;
    if (password.match(/[$@#&!]+/)) strength += 15;
    if (password.length > 20) strength += 20;
    return Math.min(strength, 100);
  };

  const validatePassword = (password) => {
    const strength = calculatePasswordStrength(password);
    setPasswordStrength(strength);
    return strength >= 70;
  };

  useEffect(() => {
    if (password) {
      validatePassword(password);
    }
  }, [password]);

  const handleSignUp = async () => {
    setLoading(true);
    setError("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPasswordError("");

    if (!validateName(firstName)) {
      setFirstNameError(
        "Please enter a valid first name without spaces, numbers, or special characters.",
      );
      setLoading(false);
      return;
    }

    if (!validateName(lastName)) {
      setLastNameError(
        "Please enter a valid last name without spaces, numbers, or special characters.",
      );
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError(
        "Password is not strong enough. Please follow the guidelines.",
      );
      setLoading(false);
      return;
    }

    const requestData = {
      firstName,
      lastName,
      email,
      password,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/auth/register-doctor`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        },
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Registration failed");
      }

      console.log("Registration successful:", responseData);
      setSuccessMessage("User created successfully");
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      setError(error.message);
      console.error("Error during registration:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    if (firstNameError && validateName(e.target.value)) {
      setFirstNameError("");
    }
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    if (lastNameError && validateName(e.target.value)) {
      setLastNameError("");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (emailError && validateEmail(e.target.value)) {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (passwordError && validatePassword(e.target.value)) {
      setPasswordError("");
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const getPasswordStrengthLabel = (strength) => {
    if (strength < 33) return "Weak";
    if (strength < 66) return "Medium";
    return "Strong";
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const { email, photoURL, uid, displayName } = user;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/auth/google-sign-in-admin`,
        {
          email,
          photoUrl: photoURL,
          uid,
          displayName,
        },
      );

      if (response.data.success) {
        const refreshToken = response.data?.tokens?.refreshToken;
        const docId = response.data?.user?.doctor?.id;
        const userId = response.data?.user?.id;
        if (refreshToken) localStorage.setItem("token", refreshToken);
        if (docId) localStorage.setItem("docId", docId);
        if (userId) localStorage.setItem("userId", userId);
        window.location.href = "/";
        navigate("/");
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Google sign-in failed. Please try again.",
      );
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <Container>
      <FormContainer>
        <Box width="100%" maxWidth={400}>
          <Box display="flex" justifyContent="center" mb={0}>
            <img
              src={Logo}
              alt="Logo"
              style={{ marginBottom: "20px", width: "30px", height: "30px" }}
            />
          </Box>
          <Box mb={2}>
            <InputLabel
              sx={{
                color: colors.textTertiary,
                fontSize: "14px",
                fontWeight: "500",
                paddingBottom: "0.6rem",
              }}
            >
              First Name
            </InputLabel>
            <StyledTextField
              fullWidth
              placeholder="Enter your first name"
              variant="outlined"
              value={firstName}
              onChange={handleFirstNameChange}
              error={!!firstNameError}
              helperText={firstNameError}
            />
          </Box>
          <Box mb={2}>
            <InputLabel
              sx={{
                color: colors.textTertiary,
                fontSize: "14px",
                fontWeight: "500",
                paddingBottom: "0.6rem",
              }}
            >
              Last Name
            </InputLabel>
            <StyledTextField
              fullWidth
              placeholder="Enter your last name"
              variant="outlined"
              value={lastName}
              onChange={handleLastNameChange}
              error={!!lastNameError}
              helperText={lastNameError}
            />
          </Box>
          <Box mb={2}>
            <InputLabel
              sx={{
                color: colors.textTertiary,
                fontSize: "14px",
                fontWeight: "500",
                paddingBottom: "0.6rem",
              }}
            >
              Email
            </InputLabel>
            <StyledTextField
              fullWidth
              placeholder="Enter your email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
            />
          </Box>
          <Box mb={2}>
            <InputLabel
              sx={{
                color: colors.textTertiary,
                fontSize: "14px",
                fontWeight: "500",
                paddingBottom: "0.6rem",
              }}
            >
              Password
            </InputLabel>
            <StyledTextField
              fullWidth
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={password}
              onChange={handlePasswordChange}
              error={!!passwordError}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? (
                        <Visibility sx={{ height: "16px", width: "16px" }} />
                      ) : (
                        <VisibilityOff sx={{ height: "16px", width: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <PasswordStrengthBar
              variant="determinate"
              value={passwordStrength}
            />
            <Typography variant="caption" color="textSecondary">
              Password strength: {getPasswordStrengthLabel(passwordStrength)}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              display="block"
              mt={1}
            >
              Strong passwords are at least 8 characters long, include a mix of
              upper and lower case letters, numbers, and symbols.
            </Typography>
          </Box>
          <StyledButton
            fullWidth
            variant="contained"
            size="large"
            sx={{
              backgroundColor: colors.violetPrimary,
              color: colors.secondary,
              borderRadius: "10px",
              textTransform: "none",
            }}
            onClick={handleSignUp}
            disabled={loading}
          >
            {loading ? "Signing up..." : "Sign up"}
          </StyledButton>
          {error && (
            <Typography
              color="error"
              align="center"
              sx={{ fontSize: "12px" }}
              mt={1}
              mb={1}
            >
              {error}
            </Typography>
          )}
          <Button
            fullWidth
            variant="outlined"
            startIcon={
              <img
                src="https://img.icons8.com/color/48/000000/google-logo.png"
                alt="Google icon"
                style={{ height: "25px", width: "auto" }}
              />
            }
            sx={{
              mt: 2,
              color: colors.textPrimary,
              borderColor: colors.borderPrimary,
              padding: "10px 0px",
              textTransform: "none",
              fontWeight: "600",
              borderRadius: "10px",
            }}
            onClick={handleGoogleSignIn}
          >
            Sign up with Google
          </Button>
          <Box display="flex" justifyContent="center" mt={3}>
            <Typography
              variant="body2"
              sx={{
                color: colors.textSecondary,
                fontWeight: "400",
                marginBottom: "30px",
              }}
            >
              Already have an account &nbsp;
              <Link
                to="/login"
                variant="body2"
                sx={{
                  color: colors.violetSecondary,
                  textDecoration: "none",
                  fontWeight: "600",
                }}
              >
                Sign in
              </Link>
            </Typography>
          </Box>
        </Box>
        <Footer>
          © {getCurrentYear()} PainPal | All Rights Reserved | Privacy Policy
        </Footer>
      </FormContainer>
      {isDesktop && <ImageContainer />}
      <PpSnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        message={successMessage}
      />
    </Container>
  );
}

export default RegisterScreen;
