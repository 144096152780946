import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import DeleteIcons from "../../../assets/deleteicon.svg";
import QuizSideBar from "./QuizSideBar";
import QuestionCard from "./QuestionCard";
import { Link, useNavigate, useParams } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { colors } from "../../../Utils/colorUtils";
import axios from "axios";

const DeleteQuizDialog = ({ open, onClose, onDelete }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          fontSize: "14px",
          fontWeight: 600,
          color: colors.textSecondary,
          paddingBottom: "5px",
        }}
      >
        Remove quiz
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontSize: "12px",
            color: colors.textSecondary,
            fontWeight: 400,
          }}
        >
          Are you sure you want to remove this quiz?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: "10px" }}>
        <Button
          onClick={onClose}
          sx={{
            marginRight: "10px",
            borderRadius: "8px",
            width: "70px",
            color: "black",
            height: "32px",
            fontWeight: 400,
            borderColor: "#667085",
            border: "1px solid black",
            textTransform: "none",
            fontSize: "12px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onDelete}
          style={{
            borderRadius: "8px",
            width: "70px",
            height: "32px",
            backgroundColor: "#7f56d9",
            border: "none",
            color: "#fff",
            fontWeight: 400,
            textTransform: "none",
            fontSize: "12px",
          }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};
DeleteQuizDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function EducationNewQuiz() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [quizId, setQuizId] = useState(id || null);
  const [quizData, setQuizData] = useState({
    quizName: "",
    description: "",
    tags: [],
    publish: false,
    privacy: "private",
  });
  const [isPublished, setIsPublished] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (quizId) {
      fetchQuizById(quizId);
    }
  }, [quizId]);

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const fetchQuizById = async (quizId) => {
    try {
      const token = getToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/quiz/quizzes/${quizId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const quiz = response.data.data;

      setQuizData({
        quizName: quiz.quiz_name || "",
        description: quiz.description || "",
        tags: quiz.tags || [],
        publish: quiz.publish || false,
        privacy: quiz.privacy || "private",
      });

      setIsPublished(quiz.publish);
    } catch (error) {
      console.error("Error fetching quiz data:", error);
    }
  };

  const handleSave = async () => {
    setErrorMessage("");
    if (!quizData.quizName.trim() || !quizData.description.trim()) {
      setErrorMessage("Name and Description should not be blank.");
      return;
    }

    if (quizId) {
      await updateQuiz(quizId);
      navigate("/education");
    } else {
      const newQuizId = await createQuiz();
      if (newQuizId) {
        setQuizId(newQuizId);
        navigate(`/quiz/${newQuizId}`);
      }
    }
  };

  const createQuiz = async () => {
    try {
      const token = getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/quiz/quizzes`,
        quizData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data.data.id;
    } catch (error) {
      console.error("Error creating quiz:", error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.error || "Failed to create quiz");
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    }
  };

  const updateQuiz = async (quizId) => {
    try {
      const token = getToken();
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/quiz/quizzes/${quizId}`,
        quizData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      console.log("Quiz updated:", response.data);
    } catch (error) {
      console.error("Error updating quiz:", error);
    }
  };

  const handleDelete = async () => {
    if (!quizId) return;
    try {
      const token = getToken();
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/quiz/quizzes/${quizId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setOpenDeleteDialog(false);
      navigate("/education");
    } catch (error) {
      console.error("Error deleting quiz:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setQuizData({ ...quizData, [name]: value });
    setErrorMessage("");
  };

  const handlePublishToggle = () => {
    setQuizData((prevData) => ({
      ...prevData,
      publish: !prevData.publish,
    }));
    setIsPublished(!quizData.publish);
  };

  const backButton = () => {
    navigate("/education");
  };

  return (
    <Box sx={{ paddingX: 3, paddingBottom: { xs: "120px" } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "stretch", sm: "center" },
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: { xs: 2, sm: 0 },
          }}
        >
          <Button
            component={Link}
            to="/education"
            sx={{
              mr: { xs: 0, sm: 2 },
              borderRadius: "1px",
              borderColor: "white",
              color: "#7A5AF8",
              textTransform: "none",
              height: "40px",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            <ArrowBackRoundedIcon /> &nbsp; Back to Education
          </Button>
          <Box
            sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center" }}
          >
            <Typography sx={{ mr: 1, fontWeight: 600 }} color="textSecondary">
              Status:
            </Typography>
            <Box
              sx={{
                backgroundColor: isPublished ? "#E6FFFA" : "#FFF5F5",
                padding: "0 8px",
                borderRadius: "999px",
                height: "22px",
                border: `1px solid ${isPublished ? "#99F6E0" : "#FECACA"}`,
                color: isPublished ? "#107569" : "#B91C1C",
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "18px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircleIcon
                sx={{
                  width: 15,
                  height: 10,
                  color: isPublished ? "#107569" : "#B91C1C",
                  mr: 1,
                }}
              />
              {isPublished ? "Published" : "Unpublished"}
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Box
            sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}
          >
            <Typography sx={{ mr: 1, fontWeight: 600 }} color="textSecondary">
              Status:
            </Typography>
            <Box
              sx={{
                backgroundColor: isPublished ? "#E6FFFA" : "#FFF5F5",
                padding: "0 8px",
                borderRadius: "999px",
                height: "22px",
                border: `1px solid ${isPublished ? "#99F6E0" : "#FECACA"}`,
                color: isPublished ? "#107569" : "#B91C1C",
                fontFamily: "Inter",
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "18px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircleIcon
                sx={{
                  width: 15,
                  height: 10,
                  color: isPublished ? "#107569" : "#B91C1C",
                  mr: 1,
                }}
              />
              {isPublished ? "Published" : "Unpublished"}
            </Box>
          </Box>
          <Button
            variant="outlined"
            sx={{
              mr: { xs: 0, sm: 1 },
              borderRadius: "8px",
              borderColor: "#E0E0E0",
              color: "black",
              textTransform: "none",
              height: "40px",
              fontSize: "14px",
              flex: { xs: 1, sm: "none" },
            }}
            onClick={backButton}
          >
            Close
          </Button>
          <Button
            sx={{
              borderRadius: "8px",
              backgroundColor: "#7A5AF8",
              color: "#FFFFFF",
              textTransform: "none",
              height: "40px",
              fontSize: "14px",
              flex: { xs: 1, sm: "none" },
              "&:hover": {
                backgroundColor: colors.violetSecondary,
              },
            }}
            onClick={handleSave}
          >
            {quizId ? "Update" : "Save"}
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2} style={{ marginTop: 10, paddingTop: 10 }}>
        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              fontSize: "24px",
              color: colors.textPrimary,
            }}
          >
            Quiz
          </Typography>

          <Typography
            gutterBottom
            sx={{
              mt: 3,
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
            }}
          >
            Name
          </Typography>
          <TextField
            name="quizName"
            value={quizData.quizName}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
          />

          <Typography
            gutterBottom
            sx={{
              mt: 3,
              fontWeight: 500,
              fontSize: "14px",
              color: colors.textPrimary,
            }}
          >
            Description
          </Typography>
          <TextField
            name="description"
            value={quizData.description}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
          />

          <Typography
            gutterBottom
            sx={{
              mt: 1,
              fontWeight: 400,
              fontSize: "14px",
              mb: 2,
            }}
          >
            100 characters left
          </Typography>
          {!quizId && (
            <Typography
              gutterBottom
              sx={{
                mt: 1,
                fontWeight: 400,
                fontSize: "14px",
                mb: 2,
              }}
            >
              Save the Quiz first to add Questions in the quiz
            </Typography>
          )}
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 0, mb: 2 }}>
              {errorMessage}
            </Alert>
          )}
          {quizId && <QuestionCard quizId={quizId} />}

          {quizId && (
            <Button
              variant="outlined"
              sx={{
                mr: 2,
                mt: 2,
                mb: 5,
                borderRadius: "8px",
                borderColor: "#D0D5DD",
                color: "black",
                textTransform: "none",
                height: "40px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => setOpenDeleteDialog(true)}
            >
              <Box
                component="img"
                src={DeleteIcons}
                alt="Delete Icon"
                sx={{
                  width: "20px",
                  height: "20px",
                  mr: 1,
                }}
              />
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Delete Quiz
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid item xs={1} />
        <QuizSideBar
          publish={quizData.publish}
          handlePublishToggle={handlePublishToggle}
        />
      </Grid>
      <DeleteQuizDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
}

export default EducationNewQuiz;
