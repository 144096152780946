import React, { useState } from "react";
import { Box, TextField, Typography, InputAdornment } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import searchIcon from "../../assets/search-lg.svg";
import { colors } from "../../Utils/colorUtils";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.05),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.black, 0.15),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

function SettingsHeader() {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography
          color={colors.textPrimary}
          sx={{
            fontSize: "30px",
            fontWeight: 600,
            width: { xs: "100%", md: "auto" },
            mb: { xs: 2, md: 0 },
          }}
        >
          Settings
        </Typography>
        <Search sx={{ width: { xs: "100%", md: "auto" } }}>
          <TextField
            placeholder="Search"
            variant="outlined"
            value={searchTerm}
            sx={{
              width: { xs: "100%", md: 300 },
              height: 44,
              backgroundColor: "white",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={searchIcon}
                    alt="Search"
                    style={{ width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
              style: {
                borderRadius: "10px",
                color: "#667085",
                height: "100%",
                padding: "0 14px",
              },
            }}
            InputLabelProps={{
              style: { color: "#667085" },
            }}
          />
        </Search>
      </Box>
    </>
  );
}

export default SettingsHeader;
