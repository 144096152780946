import React, { useState } from "react";
import PropTypes from "prop-types";
import { colors } from "../../Utils/colorUtils";
import dotsVertical from "../../assets/dotsVertical.svg";
import {
  Box,
  Avatar,
  Typography,
  Button,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import phoneIcon from "../../assets/phoneIcon.svg";
import { styled } from "@mui/system";

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px 24px 10px 0px",
  borderBottom: "1px solid #E4E7EC",
  margin: "0 0 0 0px",
  paddingLeft: "24px",
  backgroundColor: "white",
  [theme.breakpoints.down("md")]: {
    padding: "10px 16px",
    paddingLeft: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
}));

const AvatarSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    justifyContent: "flex-start",
  },
}));

const ButtonSection = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "16px",
  [theme.breakpoints.up("md")]: {
    gap: "16px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const StatusSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignSelf: "flex-start",
  textAlign: "right",
  marginLeft: "auto",
}));

const MessageHeader = ({ userName, avatar, isOnline, isTyping }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderContainer>
      <AvatarSection>
        <Avatar
          src={avatar}
          sx={{
            width: 40,
            height: 40,
            bgcolor: "#F2F4F7",
            color: "#475467",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          {userName.charAt(0)}
        </Avatar>
        <Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: 1.2,
              color: colors.primary,
            }}
          >
            {isTyping ? "Typing..." : userName}
          </Typography>
          {!isTyping && (
            <Typography sx={{ fontSize: "14px", color: colors.textSecondary }}>
              @{userName.toLowerCase().replace(" ", "")}
            </Typography>
          )}
        </Box>
        <StatusSection>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              borderRadius: "8px",
              padding: "2px 8px",
              border: "1px solid #D0D5DD",
            }}
          >
            <Box
              sx={{
                width: 6,
                height: 6,
                borderRadius: "50%",
                backgroundColor: isOnline ? "#12B76A" : "#F79009",
              }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                color: colors.textTertiary,
              }}
            >
              {isOnline ? "Online" : "Offline"}
            </Typography>
          </Box>
        </StatusSection>
      </AvatarSection>

      <ButtonSection>
        <Button
          startIcon={
            <img src={phoneIcon} alt="Phone" style={{ width: "20px" }} />
          }
          sx={{
            backgroundColor: colors.secondary,
            height: "36px",
            color: "#344054",
            textTransform: "none",
            padding: "0 14px",
            borderRadius: "8px",
            fontWeight: 600,
            fontSize: "14px",
            border: "1px solid #D0D5DD",
            "&:hover": {
              backgroundColor: colors.lightGray,
            },
            width: { xs: "45%", md: "auto" },
          }}
        >
          Call
        </Button>

        <Button
          sx={{
            backgroundColor: colors.violetPrimary,
            height: "36px",
            textTransform: "none",
            padding: "0 14px",
            borderRadius: "8px",
            fontWeight: 600,
            color: colors.secondary,
            fontSize: "14px",
            "&:hover": {
              backgroundColor: colors.violetSecondary,
            },
            width: { xs: "45%", md: "auto" },
          }}
        >
          View profile
        </Button>
        <Grid item>
          <button
            onClick={handleClick}
            style={{
              cursor: "pointer",
              background: "none",
              border: "none",
              padding: 0,
            }}
            aria-label="dots vertical"
          >
            <img src={dotsVertical} alt="dots vertical" />
          </button>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                width: 200,
                maxWidth: "100%",
              },
            }}
          >
            <MenuItem onClick={handleClose}>Share</MenuItem>
            <MenuItem onClick={handleClose}>Report</MenuItem>
            <MenuItem onClick={handleClose}>Remove</MenuItem>
          </Menu>
        </Grid>
      </ButtonSection>
    </HeaderContainer>
  );
};

MessageHeader.propTypes = {
  userName: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  isTyping: PropTypes.bool.isRequired,
};

export default MessageHeader;
