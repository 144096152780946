import React from "react";
import MainSideBar from "../../Components/Sidebar Component/MainSideBar";
import TeamMain from "../../Components/Team Components/TeamMain";
import { Grid } from "@mui/material";

const TeamScreen = () => {
  return (
    <>
      <Grid className="container-fluid" style={{ height: "100vh" }}>
        <Grid className="row h-100">
          <MainSideBar />
          <Grid
            className={`col-12 col-md-10`}
            sx={{
              padding: {
                xs: "16px",
                md: "16px 32px",
              },
            }}
          >
            <TeamMain />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TeamScreen;
