import React from "react";
import { AppBar, Toolbar, IconButton, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../Utils/colorUtils";
import { useNavigate } from "react-router-dom";

const StyledIconButton = styled(IconButton)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "8px 16px",
  border: `1px solid ${colors.borderColor}`,
  borderRadius: "8px",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "#F9FAFB",
  },
});

const StyledCloseIcon = styled(CloseIcon)({
  color: colors.textPrimary,
  marginRight: "8px",
});

function SessionNotesHeader() {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/");
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{ borderBottom: `1px solid ${colors.borderColor}` }}
      >
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Session Notes
          </Typography>
          <StyledIconButton edge="end" onClick={handleClose}>
            <StyledCloseIcon />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: colors.textPrimary,
              }}
            >
              Close
            </Typography>
          </StyledIconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default SessionNotesHeader;
