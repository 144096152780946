import React from "react";
import { Button, Paper } from "@mui/material";
import { colors } from "../../Utils/colorUtils";
import PropTypes from "prop-types";

function SessionNotesFooter({
  onNext,
  onBack,
  isBackDisabled,
  isNextDisabled,
}) {
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          zIndex: 1300,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            width: "64px",
            height: "40px",
            color: colors.textPrimary,
            borderColor: colors.borderPrimary,
            borderRadius: "8px",
            textTransform: "none",
            padding: "10px 14px",
            fontSize: "14px",
            fontWeight: 500,
          }}
          onClick={onBack}
          disabled={isBackDisabled}
        >
          Back
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "64px",
            height: "40px",
            backgroundColor: colors.violetPrimary,
            color: colors.secondary,
            borderRadius: "8px",
            textTransform: "none",
            padding: "10px 14px",
            fontSize: "14px",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: colors.violetSecondary,
            },
          }}
          onClick={onNext}
          disabled={isNextDisabled}
        >
          Next
        </Button>
      </Paper>
    </>
  );
}

SessionNotesFooter.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  isBackDisabled: PropTypes.bool.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
};

export default SessionNotesFooter;
