import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Box, Radio, FormControlLabel, Alert } from "@mui/material";
import closeImage from "../../../assets/x-close.svg";
import DeleteIcons from "../../../assets/deleteicon.svg";
import { colors } from "../../../Utils/colorUtils";
import axios from "axios";
import EducationQuizUploadText from "./EducationQuizUploadText";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const NumberBox = styled(Box)({
  backgroundColor: "#7C4DFF",
  color: "#fff",
  borderRadius: "4px",
  padding: "4px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "8px",
});

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  width: "48px",
  height: "40px",
  padding: "10px 14px",
  gap: theme.spacing(1),
  border:
    "1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)",
  position: "absolute",
  right: 8,
  top: 8,
  borderRadius: "10px",
  background: `url(${closeImage}) no-repeat center`,
  backgroundSize: "20px 20px",
  opacity: 1,
  "&:hover": {
    opacity: 0.8,
  },
}));

export default function QuizEditDialog({
  open,
  handleClose,
  questionData,
  onSave,
  onDelete,
}) {
  const [questionTitle, setQuestionTitle] = React.useState("");
  const [supportingText, setSupportingText] = React.useState("");
  const [options, setOptions] = React.useState([
    { optionText: "Option 1", isCorrect: false },
    { optionText: "Option 2", isCorrect: false },
  ]);
  const [image, setImage] = React.useState(null);
  const [error, setError] = React.useState("");
  const [imageError, setImageError] = React.useState(false);
  const [questionError, setQuestionError] = React.useState(false);
  const [supportingTextError, setSupportingTextError] = React.useState(false);

  React.useEffect(() => {
    if (questionData) {
      setQuestionTitle(questionData.question || "");
      setSupportingText(questionData.supporting_text || "");
      setOptions(
        questionData.options.map((opt) => ({
          optionText: opt.optionText || "",
          isCorrect: opt.isCorrect || false,
        })),
      );
      setImage(questionData.image || null);
    } else {
      resetFields();
    }
  }, [questionData]);

  const resetFields = () => {
    setQuestionTitle("");
    setSupportingText("");
    setOptions([
      { optionText: "Option 1", isCorrect: false },
      { optionText: "Option 2", isCorrect: false },
    ]);
    setImage(null);
    setError("");
    setImageError(false);
    setQuestionError(false);
    setSupportingTextError(false);
  };

  const handleSave = async () => {
    const isCorrectAnswerSelected = options.some((option) => option.isCorrect);

    if (!questionTitle.trim()) {
      setQuestionError(true);
      return;
    }

    if (!image) {
      setImageError(true);
      return;
    }

    if (!supportingText.trim()) {
      setSupportingTextError(true);
      return;
    }

    if (!isCorrectAnswerSelected) {
      setError("You must select at least one correct answer.");
      return;
    }

    onSave({
      questionTitle,
      supportingText,
      options,
      image,
    });

    resetFields();
    handleClose();
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/uploadToGcp/upload-file/article`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const imageUrl = response.data.url;
      setImage(imageUrl);
    } catch (error) {
      console.error("Error uploading the image:", error);
    }
  };

  const handleCancel = () => {
    resetFields();
    handleClose();
  };

  const handleAddOption = () => {
    if (options.length < 3) {
      setOptions([
        ...options,
        { optionText: `Option ${options.length + 1}`, isCorrect: false },
      ]);
    }
  };

  const handleRemoveOption = () => {
    if (options.length > 2) {
      setOptions(options.slice(0, 2));
    }
  };

  const handleCorrectOptionChange = (index) => {
    const updatedOptions = options.map((opt, idx) => ({
      ...opt,
      isCorrect: idx === index,
    }));
    setOptions(updatedOptions);
    setError("");
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <Box display="flex" alignItems="center" mb={1}>
          <NumberBox
            sx={{
              height: "32px",
              width: "32px",
              borderRadius: "12px",
              padding: "2px",
              gap: "5px",
            }}
          >
            <Typography
              variant="h6"
              component="span"
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                textAlign: "left",
              }}
            >
              1
            </Typography>
          </NumberBox>
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "24px",
              color: colors.textPrimary,
              textAlign: "left",
            }}
          >
            Question
          </Typography>
        </Box>
      </DialogTitle>
      <CustomIconButton aria-label="close" onClick={handleClose} />
      <DialogContent dividers>
        <Typography
          sx={{
            color: colors.textTertiary,
            fontSize: "14px",
            fontWeight: 500,
            mb: 1,
          }}
        >
          Question
        </Typography>
        <TextField
          fullWidth
          sx={{
            width: "100%",
            height: "44px",
            outline: "none",
            borderRadius: "var(--radius-md)",
            border: "1px solid transparent",
            "& .MuiOutlinedInput-root": {
              height: "100%",
              borderColor: "#D0D5DD",
              borderRadius: "10px",
            },
            "& .MuiInputBase-input": {
              paddingLeft: "10px",
              outline: "none",
            },
          }}
          variant="outlined"
          value={questionTitle}
          onChange={(e) => {
            setQuestionTitle(e.target.value);
            setQuestionError(false);
          }}
          error={questionError}
          helperText={questionError ? "Question is required" : ""}
          FormHelperTextProps={{
            sx: { marginBottom: "10px" },
          }}
        />

        <Typography
          sx={{
            color: colors.textTertiary,
            fontSize: "14px",
            fontWeight: 500,
            mt: 2,
            mb: 1,
          }}
        >
          Main Image
        </Typography>
        <EducationQuizUploadText imageSrc={image} onImageUpload={setImage} />
        {imageError && (
          <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
            Image is required.
          </Alert>
        )}
        {options.map((option, index) => (
          <Box key={index} display="flex" alignItems="center" mt={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={option.optionText}
              onChange={(e) => {
                const updatedOptions = options.map((opt, idx) =>
                  idx === index ? { ...opt, optionText: e.target.value } : opt,
                );
                setOptions(updatedOptions);
              }}
              sx={{
                width: "500px",
                height: "44px",
                padding: "10px 0px",
                gap: "var(--spacing-md)",
                borderRadius: "10px",
                border:
                  "1px solid var(--Colors-Border-border-primary, #D0D5DD)",
                background: "var(--Colors-Background-bg-primary, #FFFFFF)",
                "& .MuiOutlinedInput-root": {
                  height: "100%",
                  padding: "0",
                  borderColor: "#D0D5DD",
                  borderRadius: "10px",
                  "& fieldset": {
                    border: "none",
                  },
                },
                "& .MuiInputBase-input": {
                  padding: "10px 14px",
                },
              }}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={option.isCorrect}
                  onChange={() => handleCorrectOptionChange(index)}
                  sx={{ marginLeft: "10px" }}
                />
              }
              label="Correct"
              sx={{ marginRight: 0 }}
            />
          </Box>
        ))}
        <Box display="flex" justifyContent="flex-end" mt={1}>
          {options.length < 3 ? (
            <Button
              onClick={handleAddOption}
              sx={{
                color: colors.violetSecondary,
                fontSize: "14px",
                fontWeight: 600,
                textTransform: "none",
              }}
            >
              Add Option
            </Button>
          ) : (
            <IconButton onClick={handleRemoveOption}>
              <img
                src={DeleteIcons}
                alt="Remove"
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "5px",
                }}
              />
            </IconButton>
          )}
        </Box>
        {error && (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
            {error}
          </Alert>
        )}
        <Typography
          sx={{ color: colors.textTertiary, fontSize: "14px", fontWeight: 500 }}
        >
          Supporting Text
        </Typography>
        <Typography
          sx={{
            color: colors.textSecondary,
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          Will be shown after the question has been answered
        </Typography>

        <TextField
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
          value={supportingText}
          onChange={(e) => {
            setSupportingText(e.target.value);
            setSupportingTextError(false);
          }}
          error={supportingTextError}
          helperText={supportingTextError ? "Supporting text is required" : ""}
        />

        <Typography
          variant="body2"
          align="left"
          sx={{
            color: colors.textSecondary,
            fontSize: "14px",
            fontWeight: 400,
            mb: 15,
          }}
        >
          275 characters left
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "16px", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          sx={{
            color: "#344054",
            textTransform: "none",
            border:
              "1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)",
            borderRadius: "10px",
          }}
          onClick={onDelete}
        >
          Delete
        </Button>
        <Box>
          <Button
            variant="outlined"
            sx={{
              border:
                "1px solid var(--Component-colors-Components-Buttons-Secondary-button-secondary-border, #D0D5DD)",
              color: "#344054",
              textTransform: "none",
              marginRight: "8px",
              borderRadius: "10px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            sx={{
              backgroundColor: "#6941C6",
              color: "#fff",
              textTransform: "none",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: colors.violetSecondary,
              },
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  );
}

QuizEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  questionData: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
