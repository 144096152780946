import React from "react";
import starImage from "../../../assets/ai-icon-star.svg";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/system";

const colors = {
  textSecondary: "#6941C6",
  background: "#F9F5FF",
  border: "#7F56D9",
  labelBackground: "#7F56D9",
  labelText: "#FFFFFF",
};

const StyledCard = styled(Card)({
  maxWidth: 400,
  margin: "80px auto",
  borderRadius: "10px",
  backgroundColor: colors.background,
  border: `1px solid ${colors.border}`,
  position: "relative",
  zIndex: "10",
});

const StyledLabel = styled(Box)({
  backgroundColor: colors.labelBackground,
  color: colors.labelText,
  borderRadius: "10px 10px 0 0",
  padding: "4px 12px",
  position: "absolute",
  top: "-28px",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: "20",
  fontWeight: 500,
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const HistoryRightAICard = () => {
  return (
    <Box
      sx={{
        position: "relative",
        margin: "44px auto",
        maxWidth: "400px",
      }}
    >
      <StyledLabel>
        <span>
          <img src={starImage} alt="starImage" /> Ai Summary
        </span>
      </StyledLabel>
      <StyledCard>
        <CardContent>
          <Box sx={{ position: "relative" }}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: colors.textSecondary,
                marginBottom: "8px",
              }}
            >
              Patient expresses significant relief from initial symptoms, with
              only occasional mild discomfort in the lower back.
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: colors.textSecondary,
                marginBottom: "8px",
              }}
            >
              Physiotherapy sessions have increased mobility and reduced pain
              episodes.
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: colors.textSecondary,
                marginBottom: "0px",
              }}
            >
              Plan to taper off direct therapy interventions and focus on
              maintaining activity level independently.
            </Typography>
          </Box>
        </CardContent>
      </StyledCard>
    </Box>
  );
};

export default HistoryRightAICard;
