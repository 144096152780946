import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { useParams } from "react-router-dom";
import { colors } from "../../../Utils/colorUtils";
import TreatmentCardsContainer from "./TreatmentCardsContainer";
import TreatmentSearchFilterBox from "./TreatmentSearchFilterBox";
import { Grid, Box, CircularProgress, Typography } from "@mui/material";
import TreatmentEditMain from "./TreatmentEdit Components/TreatmentEditMain";
const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));
function TreatmentMain({ setIsEditingTreatment, setTabValue }) {
  const [filterStatus, setFilterStatus] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { patientId } = useParams();
  const [treatmentId, setTreatmentId] = useState(null);
  console.log({ treatmentId });
  const fetchTreatmentData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plan-assignment/get-assignments/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      const assignments = response.data.data;
      if (assignments.length > 0) {
        setImageData([assignments[0]]);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching treatment data:", error);
      setError("Failed to fetch data.");
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTreatmentData();
  }, []);
  const handleNewAssignment = async () => {
    await fetchTreatmentData();
  };
  const handleApplyFilters = (status) => {
    setFilterStatus(status);
  };
  const handleCardClick = (treatmentPlanId, image) => {
    setSelectedImage(image);
    setTreatmentId(treatmentPlanId);
    setIsEditingTreatment(true);
  };
  const handleCloseEdit = () => {
    setSelectedImage(null);
    setIsEditingTreatment(false);
    setTreatmentId(null);
  };

  if (loading) {
    return (
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <CircularProgress sx={{ color: colors.violetPrimary }} />
        </Box>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "70vh",
          }}
        >
          <Typography>{error}</Typography>
        </Box>
      </div>
    );
  }
  console.log({ imageData });
  return (
    <>
      <Grid
        container
        style={{
          minHeight: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {selectedImage ? (
          <Grid sx={{ height: "100vh", overflow: "hidden" }}>
            <TreatmentEditMain
              image={selectedImage}
              treatmentId={treatmentId}
              setTabValue={setTabValue}
              onClose={handleCloseEdit}
              setTreatmentId={setTreatmentId}
              setIsEditingTreatment={setIsEditingTreatment}
              setSelectedImage={setSelectedImage}
              imageTitle={selectedImage?.treatmentPlan?.treatment_plan_name}
              fetchTreatmentData={fetchTreatmentData}
            />
          </Grid>
        ) : (
          <Grid container spacing={2} sx={{ flexWrap: "wrap", mt: 1 }}>
            <Grid item xs={12} md={12}>
              <CustomGrid>
                <TreatmentSearchFilterBox
                  onApplyFilters={handleApplyFilters}
                  handleNewAssignment={handleNewAssignment}
                  patientId={patientId}
                />
              </CustomGrid>
              <CustomGrid>
                <TreatmentCardsContainer
                  imageData={imageData}
                  filterStatus={filterStatus}
                  onCardClick={handleCardClick}
                />
              </CustomGrid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

TreatmentMain.propTypes = {
  setIsEditingTreatment: PropTypes.func.isRequired,
  setTabValue: PropTypes.func.isRequired,
};

export default TreatmentMain;
