import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  InputLabel,
  Snackbar,
  IconButton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { colors } from "../../Utils/colorUtils";
import Logo from "../../assets/painPal-logo.svg";
import { getCurrentYear } from "../../Utils/dateUtils";
import LoginScreenImage from "../../assets/login-screen-pain-pal.jpeg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  position: "relative",
}));

const FormContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: theme.spacing(4),
  background: colors.secondary,
  position: "relative",
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundImage: `url(${LoginScreenImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    color: colors.textFourth,
    borderColor: colors.borderPrimary,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.borderPrimary,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.borderPrimary,
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.violetPrimary,
  },
  "& .MuiInputBase-input": {
    color: colors.textFourth,
  },
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .Mui-focused .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: colors.violetPrimary,
  },
  "& .Mui-focused .MuiOutlinedInput-input": {
    color: colors.textFourth,
  },
  "& .Mui-focused .MuiInputLabel-root": {
    display: "none",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: colors.violetSecondary,
  },
}));

const Footer = styled(Typography)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  color: colors.textSecondary,
  fontWeight: "400",
  fontSize: "10px",
}));

function ForgotPassword() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState("");

  const handleResetPassword = async () => {
    setLoading(true);
    setError("");
    setNewPasswordError("");
    setConfirmNewPasswordError("");

    if (!newPassword) {
      setNewPasswordError("Please enter a new password.");
      setLoading(false);
      return;
    }

    if (!confirmNewPassword) {
      setConfirmNewPasswordError("Please confirm your new password.");
      setLoading(false);
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordError("Passwords do not match.");
      setLoading(false);
      return;
    }

    if (newPassword.length < 6) {
      setNewPasswordError("Password must be at least 6 characters long.");
      setLoading(false);
      return;
    }

    if (!token) {
      setError("Invalid or expired token.");
      setLoading(false);
      return;
    }

    const requestData = {
      token,
      newPassword,
      confirmNewPassword,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/auth/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        },
      );
      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.message || "Password reset failed");
      }
      const data = await response.json();
      console.log("Password reset successful:", data);
      setSnackbarMessage("Password reset successful!");
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setError(error.message);
      console.error("Error during password reset:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (newPasswordError) {
      setNewPasswordError("");
    }
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
    if (confirmNewPasswordError) {
      setConfirmNewPasswordError("");
    }
  };

  return (
    <Container>
      <FormContainer>
        <Box width="100%" maxWidth={400}>
          <Box display="flex" justifyContent="center" mb={2}>
            <img src={Logo} alt="Logo" style={{ marginBottom: "20px" }} />
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography
              sx={{
                color: colors.textPrimary,
                fontWeight: "600",
                marginBottom: "20px",
              }}
            >
              Reset Your Password
            </Typography>
          </Box>

          <Box mb={2}>
            <InputLabel
              sx={{
                color: colors.textTertiary,
                fontSize: "14px",
                fontWeight: "500",
                paddingBottom: "0.6rem",
              }}
            >
              New Password
            </InputLabel>
            <StyledTextField
              fullWidth
              placeholder="Enter your new password"
              variant="outlined"
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              error={!!newPasswordError}
              helperText={newPasswordError}
            />
          </Box>
          <Box mb={2}>
            <InputLabel
              sx={{
                color: colors.textTertiary,
                fontSize: "14px",
                fontWeight: "500",
                paddingBottom: "0.6rem",
              }}
            >
              Confirm New Password
            </InputLabel>
            <StyledTextField
              fullWidth
              placeholder="Confirm your new password"
              variant="outlined"
              type="password"
              value={confirmNewPassword}
              onChange={handleConfirmNewPasswordChange}
              error={!!confirmNewPasswordError}
              helperText={confirmNewPasswordError}
            />
          </Box>

          {error && (
            <Typography color="error" align="center" mb={2}>
              {error}
            </Typography>
          )}

          <StyledButton
            fullWidth
            variant="contained"
            size="large"
            sx={{
              backgroundColor: colors.violetPrimary,
              color: colors.secondary,
              borderRadius: "10px",
              textTransform: "none",
            }}
            onClick={handleResetPassword}
            disabled={loading}
          >
            {loading ? "Resetting Password..." : "Reset Password"}
          </StyledButton>
        </Box>
        <Footer>
          © {getCurrentYear()} PainPal | All Rights Reserved | Privacy Policy
        </Footer>
      </FormContainer>
      {isDesktop && <ImageContainer />}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={
          <Box display="flex" alignItems="center">
            <CheckCircleIcon sx={{ marginRight: "8px", color: "#fff" }} />{" "}
            {snackbarMessage}
          </Box>
        }
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: colors.violetPrimary,
            color: "#fff",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          },
        }}
      />
    </Container>
  );
}

export default ForgotPassword;
