import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { Typography, Box, Paper, CircularProgress, Grid } from "@mui/material";
import TreatmentSearchFilterBox from "./TreatmentSearchFilterBox";
import TreatmentEmptyView from "./TreatmentEmptyView";
import TreatmentTable from "./TreatmentTable";
import TreatmentTablePagination from "./TreatmentTablePagination";
import { colors } from "../../Utils/colorUtils";

const useStyles = makeStyles({
  pagination: {
    "& .MuiTablePagination-displayedRows": {
      textAlign: "left",
      width: "90%",
      padding: "0px",
      marginBottom: "0px",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#344054",
    },
  },
});

function TreatmentMain() {
  const itemsPerPage = 10;
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({ status: "" });
  const [tempFilters, setTempFilters] = useState(filters);
  const [treatmentsState, setTreatments] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const filterButtonRef = useRef(null);

  useEffect(() => {
    const fetchTreatmentPlans = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plans/get-all-treatments?page=${currentPage + 1}&pageSize=${itemsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.data.success) {
          const treatments = response.data.data.map((treatment) => ({
            id: treatment.id,
            name: treatment.treatment_plan_name,
            exercises: treatment?.treatments?.length,
            owner: `${treatment.doctor.user.first_name} ${treatment.doctor.user.last_name}`,
            status: "Inactive",
            tags: treatment.tags || [],
          }));
          setTreatments(treatments);
          setTotalPages(response.data.pagination.totalPages);
          setTotalItems(response.data.pagination.totalRecords);
        }
      } catch (error) {
        console.error("Error fetching treatment plans:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTreatmentPlans();
  }, [currentPage, fetchTrigger]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    setCurrentPage(0);
  };

  const handleStatusChange = (event) => {
    setTempFilters((prev) => ({ ...prev, status: event.target.value }));
  };

  const applyFilters = () => {
    setFilters(tempFilters);
    setCurrentPage(0);
    setFetchTrigger((prev) => !prev);
  };

  const resetFilters = () => {
    setTempFilters({ status: "" });
    setFilters({ status: "" });
    setCurrentPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const filteredTreatments = treatmentsState.filter((treatment) => {
    const nameMatch = treatment.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const tagMatch = treatment.tags?.some((tag) =>
      tag.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    const statusMatch =
      filters.status === "" || treatment.status === filters.status;

    return (nameMatch || tagMatch) && statusMatch;
  });

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            color: colors.textPrimary,
            fontSize: "30px",
          }}
        >
          Treatments
        </Typography>
      </Box>
      <TreatmentSearchFilterBox
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        tempFilters={tempFilters}
        setTempFilters={setTempFilters}
        handleStatusChange={handleStatusChange}
        applyFilters={applyFilters}
        resetFilters={resetFilters}
        filterButtonRef={filterButtonRef}
      />
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <CircularProgress sx={{ color: "#7F56D9" }} />
        </Box>
      ) : treatmentsState.length === 0 ? (
        <TreatmentEmptyView />
      ) : (
        <Paper
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: "10px",
            borderColor: "#EAECF0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
            overflowX: "auto",
            width: "100%",
            maxWidth: "100%",
            height: "75vh",
          }}
        >
          {filteredTreatments.length === 0 ? (
            <Typography
              variant="body1"
              sx={{
                padding: 2,
                textAlign: "center",
                color: colors.textPrimary,
              }}
            >
              No treatment found for the applied search.
            </Typography>
          ) : (
            <>
              <Grid>
                <TreatmentTable
                  filteredTreatments={filteredTreatments}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                />
                <TreatmentTablePagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  classes={classes}
                />
              </Grid>
            </>
          )}
        </Paper>
      )}
    </>
  );
}

export default TreatmentMain;
