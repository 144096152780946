import React from "react";
import {
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";
import PropTypes from "prop-types";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

const SectionTitle = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "28px",
  color: colors.textPrimary,
});

const SectionSubtitle = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: colors.textSecondary,
  padding: "10px 0",
});

function PersonalInfo({ handleSubmit }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <CustomGrid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
          padding: {
            xs: "60px 0px 10px",
            sm: "60px 0px 10px",
          },
        }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <SectionTitle>Personal info</SectionTitle>
          <SectionSubtitle>
            Update your photo and personal details here.
          </SectionSubtitle>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{
            display: "flex",
            flexDirection: isMobile ? "row" : "row",
            justifyContent: isMobile ? "space-between" : "flex-end",
            alignItems: "center",
            mt: isMobile ? 2 : 0,
          }}
        >
          <Button
            sx={{
              backgroundColor: colors.secondary,
              height: "40px",
              color: "#363F72",
              textTransform: "none",
              padding: isMobile ? "0" : "0 20px",
              borderRadius: "10px",
              fontWeight: 600,
              border: "1px solid #D0D5DD",
              fontSize: isMobile ? "12px" : "inherit",
              width: isMobile ? "48%" : "auto",
              mb: isMobile ? 0 : 0,
              "&:hover": {
                backgroundColor: colors.lightGray,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.violetPrimary,
              height: "40px",
              textTransform: "none",
              padding: isMobile ? "0" : "0 20px",
              borderRadius: "10px",
              fontWeight: 600,
              ml: isMobile ? 1 : 2,
              fontSize: isMobile ? "12px" : "inherit",
              width: isMobile ? "48%" : "auto",
              "&:hover": {
                backgroundColor: colors.violetSecondary,
              },
            }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              my: 2,
              borderBottomWidth: "1px",
              borderColor: colors.textFourth,
            }}
          />
        </Grid>
      </CustomGrid>
    </>
  );
}

PersonalInfo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default PersonalInfo;
