import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { colors } from "../../Utils/colorUtils";
import helpCircle from "../../assets/help-circle.svg";

function TeamMembersList() {
  const theme = useTheme();
  const teamMembers = [
    {
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
      role: "Admin",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4Jrqe50SvTo4e1748a8oqlB_AVn4QGZyIQA&s",
    },
    {
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
      role: "Admin",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4Jrqe50SvTo4e1748a8oqlB_AVn4QGZyIQA&s",
    },
    {
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
      role: "Read-only",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4Jrqe50SvTo4e1748a8oqlB_AVn4QGZyIQA&s",
    },
    {
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
      role: "Read-only",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4Jrqe50SvTo4e1748a8oqlB_AVn4QGZyIQA&s",
    },
    {
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
      role: "Read-only",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4Jrqe50SvTo4e1748a8oqlB_AVn4QGZyIQA&s",
    },
    {
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
      role: "Read-only",
      avatar:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4Jrqe50SvTo4e1748a8oqlB_AVn4QGZyIQA&s",
    },
  ];

  return (
    <Box
      sx={{
        [theme.breakpoints.down("sm")]: {
          marginBottom: "100px",
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ padding: 2 }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              textAlign: "left",
              color: colors.textTertiary,
            }}
          >
            Team members
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                mb: 2,
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                color: colors.textSecondary,
              }}
            >
              Get your projects up and running faster by inviting your team to
              collaborate.
            </Typography>
          </Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none",
              border: "1px solid #e0e0e0",
              borderRadius: 2,
              flex: 1,
              overflowY: "auto",
              maxHeight: "300px",
            }}
          >
            <Table stickyHeader>
              <TableHead sx={{ backgroundColor: "#F9FAFB" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#475467",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#475467",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                    }}
                  >
                    Role
                    <img
                      src={helpCircle}
                      alt="help-circle"
                      style={{ marginLeft: "5px", marginBottom: "2px" }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#475467",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamMembers.map((member, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          src={member.avatar}
                          alt={member.name}
                          sx={{ width: "40px", height: "40px", mr: 2 }}
                        />
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: "14px",
                              color: colors.textPrimary,
                            }}
                          >
                            {member.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: colors.textSecondary,
                            }}
                          >
                            {member.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: colors.textSecondary,
                        }}
                      >
                        {member.role}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="text"
                        color="error"
                        sx={{
                          mr: 1,
                          border: "none",
                          color: colors.textSecondary,
                          textTransform: "none",
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        sx={{
                          mr: 1,
                          border: "none",
                          color: colors.violetSecondary,
                          textTransform: "none",
                          "&:hover": { backgroundColor: "transparent" },
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TeamMembersList;
