import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import NewTreatmentRightContainer from "./NewTreatmentRightContainer";
import NewTreatmentHeader from "./NewTreatmentHeader";
import NewTreatmentLeftContainer from "./NewTreatmentLeftContainer";
import { Grid, Box, CircularProgress } from "@mui/material";
import _ from "lodash";

function NewTreatmentMain() {
  const navigate = useNavigate();
  const { id: treatmentIdParam } = useParams();
  const [allExercises, setAllExercises] = useState([]);
  const [treatmentId, setTreatmentId] = useState(treatmentIdParam || null);
  const [leftImages, setLeftImages] = useState([]);
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(1);
  const [title, setTitle] = useState("Treatment Name");
  const [description, setDescription] = useState("Treatment Description");
  const [tags, setTags] = useState([]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      await fetchAllExercises(1);
      if (treatmentId) {
        await fetchTreatmentData(treatmentId);
        await fetchTreatmentDetails(treatmentId);
      } else {
        setDays([{ id: 1, treatments: [] }]);
        setSelectedDay(1);
      }
      filterExercises();
      setLoading(false);
    };
    fetchAllData();
  }, [treatmentId]);

  useEffect(() => {
    filterExercises();
  }, [selectedDay, days, allExercises]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    handler();
    return () => {
      handler.cancel();
    };
  }, [searchQuery]);

  useEffect(() => {
    searchExercises(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  const fetchAllExercises = async (page = 1) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/exercise/get-exercises?page=${page}&pageSize=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        const exercises = response.data.data;
        const formattedExercises = exercises.map((exercise) => ({
          id: exercise.id,
          title: exercise.exercise_name,
          videoSrc: exercise.exercise_video,
        }));
        setAllExercises((prevExercises) => {
          const existingIds = new Set(prevExercises.map((ex) => ex.id));
          const newExercises = formattedExercises.filter(
            (exercise) => !existingIds.has(exercise.id),
          );
          return [...prevExercises, ...newExercises];
        });
      }
    } catch (error) {
      console.error("Error fetching exercises:", error);
    }
  };

  const fetchTreatmentData = async (treatmentId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plans/get-a-treatmentplan/${treatmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        const { treatment_plan_name, treatment_description, tags } =
          response.data.data;
        setTitle(treatment_plan_name);
        setDescription(treatment_description);
        setTags(tags.map((tag, index) => ({ id: index + 1, name: tag })));
      }
    } catch (error) {
      console.error("Error fetching treatment data:", error);
    }
  };

  const fetchTreatmentDetails = async (treatmentId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/get-treatments/${treatmentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        const treatments = response.data.data;
        const groupedByDay = groupTreatmentsByDay(treatments);
        const filteredDays = filterAndRenumberDays(groupedByDay);
        setDays(filteredDays);
        filterExercises();
      }
    } catch (error) {
      console.error("Error fetching treatment details:", error);
    }
  };

  const groupTreatmentsByDay = (treatments) => {
    const grouped = treatments.reduce((acc, treatment) => {
      const day = treatment.day;
      if (!acc[day]) {
        acc[day] = { id: day, treatments: [] };
      }
      acc[day].treatments.push({
        id: treatment.exercise_id,
        treatmentId: treatment.id,
        title: treatment.content,
        videoSrc: treatment.treatment_image,
        lightIntensity: {
          reps: treatment.light_intensity_reps,
          holds: treatment.light_intensity_holds,
          rests: treatment.light_intensity_rests_for,
          sets: treatment.light_intensity_sets_for,
        },
        mediumIntensity: {
          reps: treatment.medium_intensity_reps,
          holds: treatment.medium_intensity_holds,
          rests: treatment.medium_intensity_rests_for,
          sets: treatment.medium_intensity_sets_for,
        },
        hardIntensity: {
          reps: treatment.hard_intensity_reps,
          holds: treatment.hard_intensity_holds,
          rests: treatment.hard_intensity_rests_for,
          sets: treatment.hard_intensity_sets_for,
        },
      });
      return acc;
    }, {});
    return Object.keys(grouped).map((key) => grouped[key]);
  };

  const searchExercises = async (query) => {
    if (!query) {
      await fetchAllExercises(1);
      return;
    }
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/exercise/search-exercises?search=${query}&page=1&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.exercises) {
        const exercises = response.data.exercises;
        const formattedExercises = exercises.map((exercise) => ({
          id: exercise.id,
          title: exercise.exercise_name,
          videoSrc: exercise.exercise_video,
        }));
        setAllExercises(formattedExercises);
      }
    } catch (error) {
      console.error("Error searching exercises:", error);
    }
  };

  const handleDragStart = (event, image) => {
    event.dataTransfer.setData("text/plain", JSON.stringify(image));
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const imageData = event.dataTransfer.getData("text/plain");
    if (imageData) {
      const image = JSON.parse(imageData);
      const sequence =
        days.find((day) => day.id === selectedDay)?.treatments.length + 1;
      setDays((prevDays) =>
        prevDays.map((day) => {
          if (day.id === selectedDay) {
            return {
              ...day,
              treatments: [...day.treatments, { ...image, sequence }],
            };
          }
          return day;
        }),
      );
      setLeftImages((prevLeftImages) =>
        prevLeftImages.filter((exercise) => exercise.id !== image.id),
      );
      setDroppedItems((prevItems) => [
        ...prevItems,
        {
          ...image,
          day: selectedDay,
          sequence: sequence,
        },
      ]);
      filterExercises();
    }
  };

  const filterAndRenumberDays = (days) => {
    return days
      .filter((day) => day.treatments.length > 0)
      .map((day, index) => ({ ...day, id: index + 1 }));
  };

  const filterExercises = () => {
    setIsFiltering(true);
    const selectedDayTreatments =
      days.find((day) => day.id === selectedDay)?.treatments || [];
    const assignedExerciseIds = new Set(
      selectedDayTreatments.map((treatment) => treatment.id),
    );
    const filteredImages = allExercises.filter(
      (exercise) => !assignedExerciseIds.has(exercise.id),
    );
    setLeftImages(filteredImages);
    setIsFiltering(false);
  };

  const createOrUpdateTreatmentPlan = async () => {
    const token = localStorage.getItem("token");
    const treatmentPlanData = {
      treatmentPlanName: title,
      treatmentDescription: description,
      tags: tags.map((tag) => tag.name),
      status: "inactive",
    };
    try {
      let response;
      let newTreatmentId = treatmentId;
      const filteredDays = filterAndRenumberDays(days);
      if (treatmentId) {
        response = await axios.put(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plans/update/${treatmentId}`,
          treatmentPlanData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plans/create`,
          treatmentPlanData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.data.success) {
          newTreatmentId = response.data.data.id;
          setTreatmentId(newTreatmentId);
        }
      }
      if (response.data.success) {
        if (droppedItems.length > 0) {
          await submitTreatments(newTreatmentId, filteredDays);
        }
        navigate("/treatment");
      }
    } catch (error) {
      console.error("Error saving treatment plan:", error);
    }
  };

  const submitTreatments = async (newTreatmentId, filteredDays) => {
    const token = localStorage.getItem("token");
    if (!newTreatmentId) {
      return;
    }
    const treatmentData = {
      treatmentPlansId: newTreatmentId,
      treatments: droppedItems.map((item) => ({
        treatmentImage: item.videoSrc,
        exerciseId: item.id,
        lightIntensityReps: "10",
        lightIntensityHolds: "5",
        lightIntensityRestsFor: "30",
        lightIntensitySetsFor: "3",
        mediumIntensityReps: "8",
        mediumIntensityHolds: "5",
        mediumIntensityRestsFor: "30",
        mediumIntensitySetsFor: "3",
        hardIntensityReps: "6",
        hardIntensityHolds: "5",
        hardIntensityRestsFor: "30",
        hardIntensitySetsFor: "3",
        content: item.title,
        instructions: "exercise instructions",
        label: ["shoulder", "leg", "elbow"],
        day: item.day,
        sequence: item.sequence,
      })),
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/create`,
        treatmentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data.success) {
        setDroppedItems([]);
      }
    } catch (error) {
      console.error("Error creating treatments:", error);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemoveFromDay = (imageToRemove) => {
    setDays((prevDays) =>
      prevDays.map((day) => {
        if (day.id === selectedDay) {
          return {
            ...day,
            treatments: day.treatments.filter(
              (treatment) => treatment.id !== imageToRemove.id,
            ),
          };
        }
        return day;
      }),
    );
    filterExercises();
  };

  const addDay = () => {
    const newDay = { id: days.length + 1, treatments: [] };
    setDays([...days, newDay]);
  };

  const switchDay = (dayId) => {
    setSelectedDay(dayId);
    filterExercises();
  };

  const deleteDayFromPlan = async (treatmentPlansId, dayToDelete) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/remove-day`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: {
            treatmentPlansId,
            dayToDelete,
          },
        },
      );
      if (response.data.success) {
        console.log("Day deleted successfully:", response.data);
      }
    } catch (error) {
      console.error("Error deleting day:", error);
    }
  };

  const removeDay = async (dayToDelete) => {
    setLoading(true);
    await deleteDayFromPlan(treatmentId, dayToDelete);
    setDays((prevDays) => {
      const updatedDays = prevDays.filter((day) => day.id !== dayToDelete);
      const renumberedDays = updatedDays.map((day) => {
        if (day.id > dayToDelete) {
          return { ...day, id: day.id - 1 };
        }
        return day;
      });
      if (renumberedDays.length > 0) {
        setSelectedDay(renumberedDays[0].id);
      }
      return renumberedDays;
    });
    setDroppedItems((prevItems) =>
      prevItems
        .filter((item) => item.day !== dayToDelete)
        .map((item) => {
          if (item.day > dayToDelete) {
            return { ...item, day: item.day - 1 };
          }
          return item;
        }),
    );
    setLoading(false);
    filterExercises();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <NewTreatmentHeader
        createOrUpdateTreatmentPlan={createOrUpdateTreatmentPlan}
        submitTreatments={submitTreatments}
        treatmentId={treatmentId}
        droppedItems={droppedItems}
      />
      {loading || isFiltering ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress sx={{ color: "#7F56D9" }} />
        </Box>
      ) : (
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid item xs={12} md={4} sx={{ padding: "8px" }}>
            <NewTreatmentLeftContainer
              onImageDragStart={handleDragStart}
              images={leftImages}
              selectedDay={selectedDay}
              days={days}
              loading={loading}
              isFiltering={isFiltering}
              onSearch={setSearchQuery}
            />
          </Grid>
          <Grid item xs={12} md={8} sx={{ padding: "8px" }}>
            <Grid onDragOver={handleDragOver} onDrop={handleDrop}>
              <NewTreatmentRightContainer
                days={days}
                selectedDay={selectedDay}
                setDays={setDays}
                onAddDay={addDay}
                onSwitchDay={switchDay}
                onRemoveImage={handleRemoveFromDay}
                onRemoveDay={removeDay}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
                tags={tags}
                setTags={setTags}
                fetchTreatmentData={fetchTreatmentData}
                loading={loading || isFiltering}
                droppedItems={droppedItems}
                setDroppedItems={setDroppedItems}
                treatmentId={treatmentId}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

NewTreatmentMain.propTypes = {
  onClose: PropTypes.func.isRequired,
  setTabValue: PropTypes.func.isRequired,
  setIsEditingTreatment: PropTypes.func.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
  imageTitle: PropTypes.string.isRequired,
};

export default NewTreatmentMain;
