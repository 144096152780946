import React, { useRef } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PencilIcon from "../../assets/pencilIconppl.svg";
import PropTypes from "prop-types";
import randomImage from "../../assets/randomImage.svg";

// const getStatusDotStyle = (status) => {
//   let backgroundColor;
//   switch (status) {
//     case "Active":
//       backgroundColor = "#2E90FA";
//       break;
//     case "Inactive":
//       backgroundColor = "#F79009";
//       break;
//     default:
//       backgroundColor = "transparent";
//   }
//   return {
//     display: "inline-block",
//     width: 10,
//     height: 10,
//     borderRadius: "50%",
//     backgroundColor,
//     marginRight: 8,
//   };
// };

function TreatmentTable({ filteredTreatments }) {
  const navigate = useNavigate();
  const videoRefs = useRef([]);

  const handleRowClick = (id) => {
    navigate(`/treatment/${id}`);
  };

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Table
        sx={{
          minWidth: "1000px",
          whiteSpace: "nowrap",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Exercises
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Owner
            </TableCell>
            {/* <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Status
            </TableCell> */}
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "150px",
              }}
            >
              Tags
            </TableCell>
            <TableCell
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "18px",
                textAlign: "left",
                color: "#475467",
                minWidth: "50px",
              }}
            >
              Edit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTreatments.map((treatment, index) => (
            <TableRow
              key={treatment.id}
              hover
              onClick={() => handleRowClick(treatment.id)}
              sx={{ cursor: "pointer" }}
            >
              <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "80px",
                      height: "48px",
                      borderRadius: "0.6rem",
                      border: "1px solid #F2F4F7",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={randomImage}
                      alt=""
                    />
                  </Box>

                  <Box sx={{ ml: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#101828",
                      }}
                    >
                      {treatment.name}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#475467",
                }}
              >
                {treatment.exercises}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#475467",
                }}
              >
                {treatment.owner}
              </TableCell>
              {/* <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      border: "2px solid #D0D5DD",
                      padding: "2px 5px",
                      background: "transparent",
                      textAlign: "center",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      color: "#344054",
                    }}
                  >
                    <Box style={getStatusDotStyle(treatment.status)} />
                    {treatment.status}
                  </Box>
                </Box>
              </TableCell> */}
              <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  color: "#475467",
                }}
              >
                {treatment.tags.map((tag, tagIndex) => (
                  <Box
                    key={tagIndex}
                    sx={{
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "2px 8px",
                      margin: "2px",
                      borderRadius: "7px",
                      fontSize: "11px",
                      background: "#FFFFFF",
                      border: "1px solid #E0E0E0",
                      fontWeight: 600,
                    }}
                  >
                    {tag}
                  </Box>
                ))}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid #EAECF0",
                  textAlign: "center",
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRowClick(treatment.id);
                  }}
                  aria-label="edit"
                  size="small"
                >
                  <img
                    src={PencilIcon}
                    alt="edit"
                    width="16px"
                    height="16px"
                    style={{ filter: "brightness(0) saturate(100%)" }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

TreatmentTable.propTypes = {
  filteredTreatments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      exercises: PropTypes.number.isRequired,
      owner: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default TreatmentTable;
