import React from "react";
import MainSideBar from "../../Components/Sidebar Component/MainSideBar";
import MessageMain from "../../Components/Message Components/MessageMain";

const MessageScreen = () => {
  return (
    <div
      className="container-fluid"
      style={{ height: "100vh", overflow: "hidden" }}
    >
      <div className="row h-100">
        <MainSideBar />
        <div
          className={`col-12 col-md-10`}
          style={{ padding: "0px", height: "100vh" }}
        >
          <MessageMain />
        </div>
      </div>
    </div>
  );
};

export default MessageScreen;
