import React from "react";
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  Popover,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { colors } from "../../Utils/colorUtils";
import searchIcon from "../../assets/search-lg.svg";
import add from "../../assets/add.svg";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function PatientSearchFilterBox({
  searchTerm,
  handleSearchChange,
  tempFilters,
  setTempFilters,
  handleFilterPopoverOpen,
  handleFilterPopoverClose,
  filterPopoverOpen,
  resetFilters,
  applyFilters,
  handleAddPatientClick,
  filterButtonRef,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          mb: 5,
        }}
      >
        <TextField
          placeholder="Search by email or executed by"
          variant="outlined"
          value={searchTerm || ""}
          onChange={(e) => handleSearchChange(e.target.value)}
          sx={{
            width: { xs: "100%", md: 400 },
            height: 44,
            backgroundColor: colors.background,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={searchIcon}
                  alt="Search"
                  style={{ width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
            style: {
              borderRadius: "10px",
              color: "#667085",
              height: "100%",
              padding: "0 14px",
            },
          }}
          InputLabelProps={{
            style: { color: "#667085" },
          }}
        />

        <Box
          sx={{
            display: { xs: "flex", md: "flex" },
            flexDirection: { xs: "row", md: "row" },
            gap: { xs: 2, md: 4 },
            width: { xs: "100%", md: "auto" },
            mt: { xs: 3, md: 0 },
          }}
        >
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            sx={{
              backgroundColor: colors.background,
              color: colors.textPrimary,
              borderColor: colors.borderPrimary,
              borderRadius: "10px",
              height: 40,
              width: { xs: "48%", md: "auto" },
              textTransform: "none",
            }}
            onClick={handleFilterPopoverOpen}
            ref={filterButtonRef}
          >
            Filter
          </Button>
          <Button
            variant="contained"
            startIcon={
              <img src={add} alt="Add" style={{ width: 20, height: 20 }} />
            }
            sx={{
              backgroundColor: colors.violetPrimary,
              color: "#ffffff",
              borderRadius: "10px",
              height: 40,
              fontSize: "14px",
              fontWeight: "600",
              textTransform: "none",
              width: { xs: "48%", md: "auto" },
              "&:hover": {
                backgroundColor: colors.violetSecondary,
              },
            }}
            onClick={handleAddPatientClick}
          >
            Add Patient
          </Button>
        </Box>
      </Box>
      <Popover
        open={Boolean(filterPopoverOpen)}
        anchorEl={filterPopoverOpen}
        onClose={handleFilterPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            marginTop: 10,
            borderRadius: 10,
            width: isMobile ? "100%" : "auto",
          },
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: "500",
              color: colors.textPrimary,
              fontSize: "14px",
            }}
          >
            Filters
          </Typography>
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              value={tempFilters.status}
              onChange={(e) =>
                setTempFilters((prev) => ({ ...prev, status: e.target.value }))
              }
              label="Status"
              sx={{
                borderRadius: "10px",
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Last Active"
            type="date"
            fullWidth
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              height: 40,
              "& .MuiInputBase-root": {
                height: 40,
                borderRadius: "10px",
                "& input": {
                  height: "100%",
                  padding: "0 14px",
                },
              },
            }}
            value={tempFilters.lastActive}
            onChange={(e) =>
              setTempFilters((prev) => ({
                ...prev,
                lastActive: e.target.value,
              }))
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Treatment</InputLabel>
            <Select
              value={tempFilters.treatment}
              onChange={(e) =>
                setTempFilters((prev) => ({
                  ...prev,
                  treatment: e.target.value,
                }))
              }
              label="Treatment"
              sx={{
                borderRadius: "10px",
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="outlined"
              onClick={resetFilters}
              sx={{
                marginTop: "10px",
                backgroundColor: colors.background,
                color: colors.textPrimary,
                borderColor: colors.borderPrimary,
                borderRadius: "10px",
                height: 40,
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              onClick={applyFilters}
              sx={{
                marginTop: "10px",
                backgroundColor: colors.violetPrimary,
                color: "#ffffff",
                borderRadius: "10px",
                height: 40,
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

PatientSearchFilterBox.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  tempFilters: PropTypes.shape({
    status: PropTypes.string,
    lastActive: PropTypes.string,
    treatment: PropTypes.string,
  }).isRequired,
  setTempFilters: PropTypes.func.isRequired,
  handleFilterPopoverOpen: PropTypes.func.isRequired,
  handleFilterPopoverClose: PropTypes.func.isRequired,
  filterPopoverOpen: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  handleAddPatientClick: PropTypes.func.isRequired,
  filterButtonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default PatientSearchFilterBox;
