import React, { useState, useEffect } from "react";
import { Box, Tab, CircularProgress } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import SettingsHeader from "./SettingsHeader";
import SettingsForm from "./SettingsForm";
import axios from "axios";
import PpSnackbar from "../../Utils/PpSnackbar";
import emitter from "../../Utils/eventEmitter";
import ProfileScreenMain from "./Settings Profile Compoents/ProfileScreenMain";

const SettingsMain = () => {
  const [tabValue, setTabValue] = useState("0");
  const [profileData, setProfileData] = useState(null);
  const [profilePicture, setProfilePicture] = useState("");
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    if (!token || !userId) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/auth/get-doctor-profile/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.data.success) {
        setProfileData(response.data.data);
        setProfilePicture(response.data.data.profile_picture || "");
      }
    } catch (error) {
      console.error("Error fetching profile:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/uploadToGcp/upload-file/doctor`,
          formData,
        );
        setProfilePicture(response.data.url);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleSave = async (data) => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const formData = {
        ...data,
        profilePicture,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/auth/update-doctor`,
        formData,
        { headers },
      );

      if (response.data.success) {
        console.log("Profile updated successfully");
        setSnackbarMessage("Profile updated successfully");
        setSnackbarOpen(true);
        fetchProfile();
        emitter.emit("profileUpdated");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setSnackbarMessage("Error updating profile");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white",
        }}
      >
        <SettingsHeader />
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(e, newValue) => setTabValue(newValue)}
              aria-label="settings tabs"
              TabIndicatorProps={{ style: { background: "#7F56D9" } }}
            >
              {["My details", "Profile"].map((label, index) => (
                <Tab
                  key={index}
                  label={label}
                  value={index.toString()}
                  sx={{
                    "&.Mui-selected": { color: "#7F56D9" },
                    color: "black",
                    textTransform: "none",
                  }}
                />
              ))}
            </TabList>
          </Box>
        </TabContext>
      </Box>

      <Box sx={{ flexGrow: 1, overflow: "auto", padding: "16px" }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: "#7F56D9" }} />
          </Box>
        ) : (
          <TabContext value={tabValue}>
            <TabPanel value="0" sx={{ padding: 0 }}>
              {profileData && (
                <SettingsForm
                  profileData={profileData}
                  onSave={handleSave}
                  onImageUpload={handleImageUpload}
                />
              )}
            </TabPanel>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <ProfileScreenMain />
            </TabPanel>
          </TabContext>
        )}
      </Box>

      <PpSnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default SettingsMain;
