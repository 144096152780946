import React from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, Button } from "react-bootstrap";
import Breadcrumb from "../../../../assets/breadcrumbButton.svg";
import ChevronRight from "../../../../../src/assets/chevron-right.svg";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

function TreatmentEditHeader({
  setTabValue,
  setIsEditingTreatment,
  setSelectedImage,
  createOrUpdateTreatmentPlan,
  submitTreatments,
  fetchTreatmentData,
  treatmentId,
  droppedItems = [],
}) {
  const navigate = useNavigate();
  const patientId = localStorage.getItem("uniquePatientId");
  const handleBreadcrumbClick = () => {
    setIsEditingTreatment(false);
    setTabValue(2);
    navigate(`/patient/overview/${patientId}`);
    setSelectedImage(null);
  };

  const handleSaveClick = async () => {
    try {
      await createOrUpdateTreatmentPlan(treatmentId);
      if (droppedItems.length > 0) {
        await submitTreatments(treatmentId);
      }
      setIsEditingTreatment(false);
      setSelectedImage(null);
      await fetchTreatmentData();
      navigate(`/patient/overview/${patientId}`);
    } catch (error) {
      console.error("Error saving treatment:", error);
    }
  };

  return (
    <Navbar bg="white" expand="lg" style={{ padding: "20px 10px" }}>
      <Grid>
        <div className="d-flex align-items-center">
          <button
            onClick={handleBreadcrumbClick}
            style={{
              background: "none",
              border: "none",
              padding: 0,
              margin: 0,
              cursor: "pointer",
            }}
            aria-label="Go to Overview"
          >
            <img
              src={Breadcrumb}
              alt="Breadcrumb Icon"
              style={{
                width: "20px",
                height: "24px",
                marginRight: "8px",
              }}
            />
          </button>
          <img
            src={ChevronRight}
            alt="Chevron Right Icon"
            style={{
              width: "10px",
              height: "10px",
              marginRight: "8px",
            }}
          />

          <h6
            style={{
              fontWeight: 600,
              fontSize: "14px",
              color: "#7f56d9",
              marginBottom: "0px",
            }}
          >
            Treatment Plan
          </h6>
        </div>
      </Grid>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-between"
      >
        <Nav className="mr-auto"></Nav>
        <Grid className="d-flex align-items-center">
          <Button
            onClick={handleBreadcrumbClick}
            variant="outline-secondary"
            style={{
              marginRight: "10px",
              borderRadius: "8px",
              width: "70px",
              height: "40px",
              color: "black",
              fontWeight: 600,
              borderColor: "#667085",
              border: "1px solid #D0D5DD",
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleSaveClick}
            variant="primary"
            style={{
              borderRadius: "8px",
              width: "70px",
              height: "40px",
              backgroundColor: "#7f56d9",
              border: "none",
              color: "#fff",
              fontWeight: 600,
              textTransform: "none",
            }}
            disabled={droppedItems.length === 0 && !treatmentId}
          >
            Save
          </Button>
        </Grid>
      </Navbar.Collapse>
    </Navbar>
  );
}

TreatmentEditHeader.propTypes = {
  setTabValue: PropTypes.func.isRequired,
  setIsEditingTreatment: PropTypes.func.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
  createOrUpdateTreatmentPlan: PropTypes.func.isRequired,
  submitTreatments: PropTypes.func.isRequired,
  treatmentId: PropTypes.string,
  droppedItems: PropTypes.array.isRequired,
  fetchTreatmentData: PropTypes.func.isRequired,
};

export default TreatmentEditHeader;
