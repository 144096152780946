import React from "react";
import EditSingleExercise from "../../Components/Treatment Components/Add New Treatment Components/EditSingleExercise";

function EditExercise() {
  return (
    <>
      <EditSingleExercise />
    </>
  );
}

export default EditExercise;
