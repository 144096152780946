import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Box,
  Typography,
  Button,
  IconButton,
  LinearProgress,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import { colors } from "../../../Utils/colorUtils";
import upload from "../../../assets/upload-cloud-02.svg";
import PpSnackbar from "../../../Utils/PpSnackbar";

function UploadPopUpDoc({
  open,
  onClose,
  onFileUpload,
  selectedFile,
  setSelectedFile,
  selectedFileId,
}) {
  const [files, setFiles] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const [error, setError] = useState("");
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const dropZoneRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (selectedFile) {
      setFiles([selectedFile]);
      setPreviousFiles([selectedFile]);
    } else {
      setFiles([]);
      setPreviousFiles([]);
    }
  }, [selectedFile]);

  useEffect(() => {
    const allUploaded = files.every((file) => file.progress === 100);
    setUploadComplete(allUploaded);
  }, [files]);

  useEffect(() => {
    const dropZone = dropZoneRef.current;
    if (dropZone) {
      dropZone.addEventListener("dragover", handleDragOver);
      dropZone.addEventListener("drop", handleDrop);
      return () => {
        dropZone.removeEventListener("dragover", handleDragOver);
        dropZone.removeEventListener("drop", handleDrop);
      };
    }
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    handleFiles(selectedFiles);
  };

  const handleFiles = async (selectedFiles) => {
    const existingFileNames = files.map((file) => file.name);
    const newFiles = selectedFiles.filter(
      (file) => !existingFileNames.includes(file.name),
    );

    if (newFiles.length === 0) {
      setError("A file with the same name already exists.");
      setSnackbarMessage("A file with the same name already exists.");
      setSnackbarOpen(true);
    } else {
      setError("");
      setIsUploading(true);
      for (const file of newFiles) {
        try {
          const uploadedFile = await uploadFile(file);
          setFiles((prevFiles) => [...prevFiles, uploadedFile]);
        } catch (error) {
          console.error("Error uploading file:", error);
          setError("Failed to upload file. Please try again.");
          setSnackbarMessage("Failed to upload file. Please try again.");
          setSnackbarOpen(true);
        }
      }
      setIsUploading(false);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/uploadToGcp/upload-file/${file.type.startsWith("image") ? "image" : "document"}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setFiles((prevFiles) => {
              const updatedFiles = [...prevFiles];
              const currentIndex = updatedFiles.findIndex(
                (f) => f.name === file.name,
              );
              if (currentIndex !== -1) {
                updatedFiles[currentIndex] = {
                  ...updatedFiles[currentIndex],
                  progress: percentCompleted,
                };
              }
              return updatedFiles;
            });
          },
        },
      );

      return {
        name: file.name,
        type: file.type.split("/")[1].toUpperCase(),
        size: (file.size / (1024 * 1024)).toFixed(1) + " MB",
        progress: 100,
        dateUploaded: new Date().toLocaleDateString(),
        lastUpdated: new Date().toLocaleDateString(),
        uploadedBy: "User",
        fileObject: file,
        url: response.data.url,
      };
    } catch (error) {
      throw error;
    }
  };

  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleAttachFiles = async () => {
    if (files.length === 0) {
      setError("Please select at least one file.");
      setSnackbarMessage("Please select at least one file.");
      setSnackbarOpen(true);
    } else if (!uploadComplete) {
      setError("Please wait until the file upload is complete.");
      setSnackbarMessage("Please wait until the file upload is complete.");
      setSnackbarOpen(true);
    } else {
      const fileUrls = files.map((file) => file.url);
      if (selectedFileId) {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_BACKEND_URI}/api/v1/documents/${selectedFileId}`,
            {
              file: fileUrls[0],
              fileName: files[0].name,
              fileSize: parseInt(files[0].size),
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            },
          );
          if (response.data && response.data.success) {
            onFileUpload(fileUrls);
            setFiles([]);
            setPreviousFiles([]);
            setError("");
            onClose();
            setSnackbarMessage("File updated successfully");
            setSnackbarOpen(true);
          }
        } catch (error) {
          console.error("Error updating file:", error);
          setSnackbarMessage("Failed to update file");
          setSnackbarOpen(true);
        }
      } else {
        onFileUpload(fileUrls);
        setFiles([]);
        setPreviousFiles([]);
        setError("");
        onClose();
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCancel = () => {
    setFiles(previousFiles);
    if (setSelectedFile) {
      setSelectedFile(null);
    }
    onClose();
  };

  const getFileIcon = (fileType) => {
    if (fileType === "PDF") {
      return <PictureAsPdfIcon sx={{ mr: 1, color: "#f44336" }} />;
    } else if (["PNG", "JPG", "JPEG", "GIF"].includes(fileType)) {
      return <ImageIcon sx={{ mr: 1, color: "#4caf50" }} />;
    } else {
      return <InsertDriveFileIcon sx={{ mr: 1, color: "#2196f3" }} />;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 2,
          bgcolor: "#f8f8f8",
          m: isMobile ? 2 : 4,
          width: isMobile ? "calc(100% - 32px)" : "400px",
          maxHeight: "none",
        },
      }}
    >
      <DialogContent sx={{ p: 3 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={0}
        >
          <Typography
            sx={{
              color: colors.textPrimary,
              fontWeight: "600",
              fontSize: "18px",
            }}
          >
            {selectedFile ? "Edit File" : "Upload and attach files"}
          </Typography>
          <IconButton onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          sx={{
            color: colors.textSecondary,
            fontSize: "14px",
            fontWeight: "400",
            mb: 2,
          }}
        >
          {selectedFile
            ? "Edit the details of the selected file."
            : "Drag and drop files here or click to select files from your device."}
        </Typography>
        <Box
          ref={dropZoneRef}
          sx={{
            border: `1px solid ${colors.violetPrimary}`,
            borderRadius: 2,
            p: 3,
            textAlign: "center",
            mb: 2,
            bgcolor: colors.secondary,
            cursor: "pointer",
            position: "relative",
          }}
          onClick={() => fileInputRef.current.click()}
        >
          <input
            type="file"
            accept=".svg,.png,.jpg,.jpeg,.gif,.pdf,.doc,.docx"
            onChange={handleFileSelect}
            ref={fileInputRef}
            style={{ display: "none" }}
            multiple
          />
          {isUploading ? (
            <CircularProgress
              size={40}
              sx={{
                color: "#7F56D9",
              }}
            />
          ) : (
            <>
              <Grid mb={2}>
                <img
                  src={upload}
                  alt="upload-cloud-02"
                  style={{
                    width: "40px",
                    height: "40px",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Typography
                sx={{
                  color: colors.violetSecondary,
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                {selectedFile
                  ? "Change File"
                  : "Click to upload or drag and drop"}
              </Typography>
              <Typography
                sx={{
                  color: colors.textSecondary,
                  fontWeight: "400",
                  fontSize: "12px",
                }}
              >
                SVG, PNG, JPG, GIF, PDF, DOC or DOCX
              </Typography>
            </>
          )}
        </Box>
        {files.map((file, index) => (
          <Box
            key={index}
            sx={{
              mb: 2,
              bgcolor: "transparent",
              p: 1,
              borderRadius: 4,
              border: `1px solid ${colors.borderColor}`,
            }}
          >
            <Box display="flex" alignItems="center" mb={1}>
              {getFileIcon(file.type)}
              <Box flexGrow={1}>
                <Typography variant="body2" fontWeight="bold">
                  {file.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {file.size}
                </Typography>
              </Box>
              <IconButton size="small" onClick={() => handleDelete(index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box display="flex" alignItems="center">
              <LinearProgress
                variant="determinate"
                value={file.progress}
                sx={{
                  height: 8,
                  borderRadius: 2,
                  flexGrow: 1,
                  mr: 1,
                  backgroundColor: colors.borderPrimary,
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: colors.violetPrimary,
                  },
                }}
              />
              <Typography variant="caption" color="text.secondary">
                {file.progress}%
              </Typography>
            </Box>
          </Box>
        ))}
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          mt={3}
          gap={2}
        >
          <Button
            variant="outlined"
            sx={{
              color: "text.secondary",
              borderColor: "text.secondary",
              textTransform: "none",
              width: isMobile ? "100%" : "48%",
              borderRadius: "10px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAttachFiles}
            variant="contained"
            sx={{
              bgcolor: colors.violetPrimary,
              borderRadius: "10px",
              width: isMobile ? "100%" : "48%",
              "&:hover": {
                bgcolor: colors.violetSecondary,
              },
            }}
            disabled={!uploadComplete || files.length === 0}
          >
            {selectedFileId ? "Update File" : "Attach Files"}
          </Button>
        </Box>
        <PpSnackbar
          snackbarOpen={snackbarOpen}
          handleCloseSnackbar={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </DialogContent>
    </Dialog>
  );
}

UploadPopUpDoc.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  selectedFile: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    progress: PropTypes.number,
    dateUploaded: PropTypes.string,
    lastUpdated: PropTypes.string,
    uploadedBy: PropTypes.string,
  }),
  setSelectedFile: PropTypes.func,
  selectedFileId: PropTypes.string,
};

UploadPopUpDoc.defaultProps = {
  selectedFile: null,
  selectedFileId: null,
};

export default UploadPopUpDoc;
