import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import SessionNotesHeader from "./SessionNotesHeader";
import SessionNotesFooter from "./SessionNotesFooter";
import SsNotesPageOne from "./SsNotesPageOne";
import SsNotesPageTwo from "./SsNotesPageTwo";

const SessionNotesMain = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    selectedPainLevel: 6,
    triggerStates: {
      overdoing: false,
      unusual: false,
      stressful: false,
      poorly: false,
      worried: false,
      unwell: false,
      exercising: false,
    },
    overallHealthLevel: 6,
    workImpactLevel: 6,
    stressLevel: 6,
    treatmentResponseLevel: 6,
    dietLevel: 6,
    Medication: "",
    feedbackText: "",
    clinicianText: "",
    InterferenceOne: null,
    InterferenceTwo: "smile",
    clickedParts: [],
  });

  const updateClickedParts = (newClickedParts) => {
    localStorage.setItem("clickedParts", JSON.stringify(newClickedParts));
    setFormData((prevData) => {
      const newData = {
        ...prevData,
        clickedParts: newClickedParts,
      };
      console.log("formData updated:", newData);
      return newData;
    });
  };
  console.log("formData updated:", formData);
  useEffect(() => {
    const storedClickedParts =
      JSON.parse(localStorage.getItem("clickedParts")) || [];
    updateClickedParts(storedClickedParts);
  }, []);

  const handleNext = () => {
    if (currentPage < 2) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFormDataChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTriggerChange = (name, checked) => {
    setFormData((prevData) => ({
      ...prevData,
      triggerStates: {
        ...prevData.triggerStates,
        [name]: checked,
      },
    }));
  };

  const handleSelect = (level) => {
    setFormData((prevData) => ({
      ...prevData,
      InterferenceOne: level,
    }));
  };

  const handleEmojiSelect = (emojiName) => {
    setFormData((prevData) => ({
      ...prevData,
      InterferenceTwo: emojiName,
    }));
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <SessionNotesHeader />
      {currentPage === 1 && (
        <SsNotesPageOne
          selectedPainLevel={formData.selectedPainLevel}
          triggerStates={formData.triggerStates}
          InterferenceOne={formData.InterferenceOne}
          InterferenceTwo={formData.InterferenceTwo}
          clickedParts={formData.clickedParts}
          handlePainLevelChange={(level) =>
            handleFormDataChange("selectedPainLevel", level)
          }
          handleTriggerChange={handleTriggerChange}
          handleSelect={handleSelect}
          handleEmojiSelect={handleEmojiSelect}
          updateClickedParts={updateClickedParts}
        />
      )}
      {currentPage === 2 && (
        <SsNotesPageTwo
          overallHealthLevel={formData.overallHealthLevel}
          workImpactLevel={formData.workImpactLevel}
          stressLevel={formData.stressLevel}
          treatmentResponseLevel={formData.treatmentResponseLevel}
          dietLevel={formData.dietLevel}
          Medication={formData.Medication}
          feedbackText={formData.feedbackText}
          clinicianText={formData.clinicianText}
          handlePainLevelChange={(name, level) =>
            handleFormDataChange(name, level)
          }
          handleInputChange={(name, value) => handleFormDataChange(name, value)}
        />
      )}
      <SessionNotesFooter
        onNext={handleNext}
        onBack={handleBack}
        isBackDisabled={currentPage === 1}
        isNextDisabled={currentPage === 2}
      />
    </Box>
  );
};

export default SessionNotesMain;
