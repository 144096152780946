import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import { styled, useTheme } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";
import plusAddIcon from "../../../assets/plusAddIcon.svg";
import sendIcon from "../../../assets/sendIcon.svg";
import copyIcon from "../../../assets/copy-01.svg";
import axios from "axios";
import { useParams } from "react-router-dom";
import PpSnackbar from "../../../Utils/PpSnackbar";
import CloseIcon from "@mui/icons-material/Close";

const NameGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    "& > *": {
      flex: "0 0 calc(50% - 10px)",
    },
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    "& > *": {
      flex: "unset",
    },
  },
}));

function OverViewHeader({ formData, loading }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { patientId } = useParams();
  const [invitationStatus, setInvitationStatus] = useState(null);
  const [sendButtonText, setSendButtonText] = useState("Send Invite");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [inviteUrl, setInviteUrl] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/invitation/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.success) {
          const { used, patient } = response.data.invitation;
          if (used) {
            setInvitationStatus("none");
          } else {
            setInvitationStatus("pending");
            setSendButtonText("Resend Invite");
            setInviteUrl(patient.invitationLink);
          }
        } else if (response.data.message === "Invitation not found") {
          setInvitationStatus("not-invited");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setInvitationStatus("not-invited");
        } else if (error.response && error.response.status === 401) {
          setInvitationStatus("not-invited");
        } else {
          console.error("Error fetching invitation status:", error);
        }
      });
  }, [patientId]);

  const handleSendInvite = () => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/invitation/send-invite`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then(() => {
        setSendButtonText("Resend Invite");
        setSnackbarMessage("Invite sent successfully!");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Error sending invite:", error);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(inviteUrl);
    setSnackbarMessage("Link copied successfully!");
    setSnackbarOpen(true);
    setOpenModal(false);
  };

  const handleNavigation = () => {
    navigate("/session-notes");
  };

  return (
    <>
      <NameGrid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          paddingRight: "20px",
        }}
      >
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "24px",
              color: colors.textPrimary,
              fontWeight: "700",
              lineHeight: "32px",
              marginRight: "10px",
            }}
          >
            {loading ? (
              <CircularProgress
                size={24}
                sx={{ marginRight: "10px", color: "#7F56D9" }}
              />
            ) : (
              `${formData.firstName} ${formData.lastName}`
            )}
          </Typography>
        </Box>
        {invitationStatus !== "none" && (
          <ButtonContainer sx={isMobile ? { marginTop: "20px" } : {}}>
            {invitationStatus === "pending" && (
              <Button
                sx={{
                  backgroundColor: "#FFFAEB",
                  color: "#B54708",
                  height: "40px",
                  textTransform: "none",
                  padding: "0 10px",
                  borderRadius: "50px",
                  border: "1px solid #FEDF89",
                  fontWeight: 500,
                  fontSize: isMobile ? "12px" : "inherit",
                  "&:hover": {
                    backgroundColor: "#F3E8C3",
                  },
                }}
              >
                Pending Acceptance
              </Button>
            )}
            {invitationStatus === "not-invited" && (
              <Button
                sx={{
                  backgroundColor: "#F8F9FC",
                  color: "#363F72",
                  height: "40px",
                  textTransform: "none",
                  padding: "0px 10px",
                  borderRadius: "50px",
                  border: "1px solid #D5D9EB",
                  fontWeight: 500,
                  fontSize: isMobile ? "12px" : "inherit",
                  "&:hover": {
                    backgroundColor: colors.lightGray,
                  },
                }}
              >
                Not invited
              </Button>
            )}
            <Button
              onClick={handleSendInvite}
              sx={{
                backgroundColor: colors.secondary,
                height: "40px",
                color: "#363F72",
                textTransform: "none",
                padding: isMobile ? "0 15px" : "0 20px",
                borderRadius: "10px",
                fontWeight: 600,
                fontSize: isMobile ? "12px" : "inherit",
                border: "1px solid #D0D5DD",
                "&:hover": {
                  backgroundColor: colors.lightGray,
                },
              }}
            >
              <img src={sendIcon} style={{ marginRight: "8px" }} alt="plus" />
              {sendButtonText}
            </Button>
            <Button
              onClick={handleOpenModal}
              sx={{
                backgroundColor: colors.secondary,
                height: "40px",
                color: "#363F72",
                textTransform: "none",
                padding: isMobile ? "0 15px" : "0 20px",
                borderRadius: "10px",
                fontWeight: 600,
                fontSize: isMobile ? "12px" : "inherit",
                border: "1px solid #D0D5DD",
                "&:hover": {
                  backgroundColor: colors.lightGray,
                },
              }}
            >
              <img src={copyIcon} style={{ marginRight: "8px" }} alt="plus" />
              Share invite link
            </Button>
            <Button
              onClick={handleNavigation}
              sx={{
                backgroundColor: colors.violetPrimary,
                height: "40px",
                textTransform: "none",
                padding: isMobile ? "0 15px" : "0 20px",
                borderRadius: "10px",
                fontWeight: 600,
                color: colors.secondary,
                fontSize: isMobile ? "12px" : "inherit",
                "&:hover": {
                  backgroundColor: colors.violetSecondary,
                },
              }}
            >
              <img
                src={plusAddIcon}
                style={{ marginRight: "8px" }}
                alt="plus"
              />
              Session Notes
            </Button>
          </ButtonContainer>
        )}
      </NameGrid>

      <PpSnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        message={snackbarMessage}
      />
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle
          sx={{
            fontSize: "14px",
            color: colors.textPrimary,
            fontWeight: 600,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Share Invite Link
          <IconButton
            onClick={handleCloseModal}
            sx={{
              padding: 0,
            }}
          >
            <CloseIcon sx={{ fontSize: "20px", color: colors.textPrimary }} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            value={inviteUrl}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleCopyUrl}
                    sx={{
                      padding: 0,
                      marginLeft: "8px",
                    }}
                  >
                    <img src={copyIcon} alt="copy icon" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: colors.borderColor,
                  borderWidth: "1px",
                  borderRadius: "10px",
                },
              },
              marginBottom: "20px",
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

OverViewHeader.propTypes = {
  formData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default OverViewHeader;
