import React, { useState, useRef, useEffect } from "react";
import { Typography, Paper, Box, Grid, CircularProgress } from "@mui/material";
import AddNewPatient from "./AddNewPatient";
import { makeStyles } from "@mui/styles";
import PatientTable from "./PatientTable";
import PatientTablePagination from "./PatientTablePagination";
import PatientSearchFilterBox from "./PatientSearchFilterBox";
import PatientEmptyView from "./PatientEmptyView";

const useStyles = makeStyles({
  pagination: {
    "& .MuiTablePagination-displayedRows": {
      textAlign: "left",
      width: "90%",
      padding: "0px",
      marginBottom: "0px",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: "#344054",
    },
  },
});

function PatientMain() {
  const classes = useStyles();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
  const [filters, setFilters] = useState({
    status: "",
    lastActive: "",
    treatment: "",
  });
  const [tempFilters, setTempFilters] = useState(filters);
  const [patientsState, setPatients] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const filterButtonRef = useRef(null);
  const [isAddPatientOpen, setIsAddPatientOpen] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchPatients = async () => {
    setLoading(true);
    const docId = localStorage.getItem("docId");
    const token = localStorage.getItem("token");

    if (!docId || !token) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/patient/${docId}?page=${currentPage + 1}&pageSize=${itemsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        if (data.patients && Array.isArray(data.patients)) {
          const formattedPatients = data.patients.map((patient) => ({
            id: patient.id,
            firstName: patient.user.first_name,
            lastName: patient.user.last_name,
            email: patient.user.email,
            status: patient.status || "Inactive",
            lastActive: patient.user.last_active || null,
            last7AvgPain: patient.last7AvgPain || 0,
            last7DayTreatment: patient.last7DayTreatment || 0,
            treatment: patient.treatment || "Inactive",
            profile_picture: patient.user.profile_picture,
          }));
          setPatients(formattedPatients);
          setTotalPages(data.pagination.totalPages);
          setTotalItems(data.pagination.total);
        }
      }
    } catch (error) {
      console.error("Error fetching patients:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPatients();
  }, [fetchTrigger, currentPage]);

  const handleAddPatientClick = () => {
    setIsAddPatientOpen(true);
  };

  const handleAddPatientClose = () => {
    setIsAddPatientOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const addNewPatient = (newPatient) => {
    setPatients((prevPatients) => [...prevPatients, newPatient]);
    setFetchTrigger((prev) => !prev);
  };

  const applyFilters = () => {
    setFilters(tempFilters);
    setCurrentPage(0);
    setFilterPopoverOpen(false);
  };

  const resetFilters = () => {
    setTempFilters({ status: "", lastActive: "", treatment: "" });
  };

  const filteredPatients = patientsState.filter((patient) => {
    const fullName = `${patient.firstName} ${patient.lastName}`.toLowerCase();
    const search =
      typeof searchTerm === "string" ? searchTerm.toLowerCase() : "";

    const nameMatch = fullName.includes(search);
    const emailMatch =
      patient.email && patient.email.toLowerCase().includes(search);
    const statusMatch =
      filters.status === "" || patient.status === filters.status;
    const lastActiveMatch =
      filters.lastActive === "" ||
      new Date(patient.lastActive) <= new Date(filters.lastActive);
    const treatmentMatch =
      filters.treatment === "" || patient.treatment === filters.treatment;

    return (
      (nameMatch || emailMatch) &&
      statusMatch &&
      lastActiveMatch &&
      treatmentMatch
    );
  });

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            color: "#101828",
            fontSize: "30px",
          }}
        >
          Patients
        </Typography>
      </Box>
      <PatientSearchFilterBox
        searchTerm={searchTerm}
        handleSearchChange={setSearchTerm}
        tempFilters={tempFilters}
        setTempFilters={setTempFilters}
        handleFilterPopoverOpen={(event) =>
          setFilterPopoverOpen(event.currentTarget)
        }
        handleFilterPopoverClose={() => setFilterPopoverOpen(null)}
        filterPopoverOpen={filterPopoverOpen}
        resetFilters={resetFilters}
        applyFilters={applyFilters}
        handleAddPatientClick={handleAddPatientClick}
        filterButtonRef={filterButtonRef}
      />

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <CircularProgress sx={{ color: "#7F56D9" }} />
        </Box>
      ) : patientsState.length === 0 ? (
        <PatientEmptyView handleAddPatientClick={handleAddPatientClick} />
      ) : (
        <Paper
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: "10px",
            borderColor: "#EAECF0",
            border: "none",
            borderBottom: "1px solid #e0e0e0",
            height: "75vh",
            overflowY: "scroll",
          }}
        >
          {filteredPatients.length === 0 ? (
            <Typography
              variant="body1"
              sx={{
                padding: 2,
                textAlign: "center",
                color: "#101828",
              }}
            >
              No patients found for the applied search.
            </Typography>
          ) : (
            <>
              <Grid>
                <PatientTable
                  filteredPatients={filteredPatients}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                />
                <PatientTablePagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  classes={classes}
                />
              </Grid>
            </>
          )}
        </Paper>
      )}
      <AddNewPatient
        open={isAddPatientOpen}
        onClose={handleAddPatientClose}
        addNewPatient={addNewPatient}
      />
    </>
  );
}

export default PatientMain;
