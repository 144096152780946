import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  CircularProgress,
  Snackbar,
  Divider,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import searchIcon from "../../../assets/search-lg.svg";
import axios from "axios";
import { colors } from "../../../Utils/colorUtils";

function TreatmentPlanModal({ open, onClose, patientId, onAssign }) {
  const [treatmentState, setTreatmentState] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  console.log({ selectedPlans });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    const fetchTreatmentPlans = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plans/get-all-treatments?page=${currentPage}&pageSize=${pageSize}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.data.success) {
          const treatments = response.data.data.map((treatment) => ({
            id: treatment.id,
            name: treatment.treatment_plan_name,
            exercises: 0,
            owner: `${treatment.doctor.user.first_name} ${treatment.doctor.user.last_name}`,
            status: "Inactive",
            tags: treatment.tags || [],
          }));
          setTreatmentState(treatments);
          setHasMore(response.data.data.length === pageSize);
        } else {
          console.error("Failed to fetch treatment plans");
        }
      } catch (error) {
        console.error("Error fetching treatment plans:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTreatmentPlans();
  }, [currentPage]);

  const handleSelectPlan = (planId) => {
    if (selectedPlans.includes(planId)) {
      setSelectedPlans([]);
    } else {
      setSelectedPlans([planId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedPlans([]);
    } else {
      const allPlanIds = treatmentState.map((treatment) => treatment.id);
      setSelectedPlans(allPlanIds);
    }
    setSelectAll(!selectAll);
  };

  const handleAssign = async () => {
    if (selectedPlans.length === 0) {
      return;
    }

    const token = localStorage.getItem("token");

    try {
      const assignmentPromises = selectedPlans.map((treatmentPlanId) =>
        axios.post(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatment-plan-assignment/assign`,
          {
            treatmentPlanId,
            patientId: patientId,
            status: "assigned",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        ),
      );

      await Promise.all(assignmentPromises);
      onAssign();
      onClose();
      setSelectedPlans([]);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error(
          "Conflict: This treatment plan is already assigned to the patient.",
        );
        setErrorMessage(
          "This treatment plan is already assigned to the patient.",
        );
        setSnackbarOpen(true);
      } else {
        console.error("Error assigning treatment plans:", error);
        setErrorMessage(
          "An error occurred while assigning the treatment plans.",
        );
      }
    }
  };

  const filteredTreatmentPlans = treatmentState.filter((treatment) =>
    treatment.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleNextPage = () => {
    if (hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleModalClose = () => {
    setSelectedPlans([]);
    setSelectAll(false);
    setSearchQuery("");
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSelectedPlans([]);
  };

  return (
    <>
      <Dialog open={open} onClose={handleModalClose} fullWidth maxWidth="md">
        <DialogTitle sx={{ padding: "16px 24px", backgroundColor: "#f7f9fc" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <TextField
              placeholder="Search Treatment Plan"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                width: "300px",
                backgroundColor: "#fff",
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                "& .MuiOutlinedInput-root": {
                  borderColor: "#D0D5DD",
                  borderRadius: "10px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="Search" />
                  </InputAdornment>
                ),
                sx: { height: "44px" },
              }}
            />
            <Box>
              <Button
                variant="contained"
                onClick={handleAssign}
                sx={{
                  backgroundColor: colors.violetPrimary,
                  color: "#fff",
                  marginRight: "10px",
                  textTransform: "none",
                  height: "44px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: colors.violetSecondary,
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                Assign
              </Button>
              <Button
                variant="outlined"
                onClick={handleModalClose}
                sx={{
                  color: colors.textSecondary,
                  borderColor: colors.borderPrimary,
                  textTransform: "none",
                  height: "44px",
                  borderRadius: "8px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                    borderColor: colors.violetPrimary,
                  },
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
              }}
            >
              <CircularProgress sx={{ color: colors.violetPrimary }} />
            </Box>
          ) : treatmentState.length === 0 ? (
            <Typography
              variant="body1"
              sx={{
                padding: 2,
                textAlign: "center",
                color: colors.textSecondary,
              }}
            >
              No treatment plans available.
            </Typography>
          ) : (
            <>
              <Table
                sx={{
                  minWidth: 650,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                }}
              >
                <TableHead sx={{ backgroundColor: "#f7f9fc" }}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        sx={{
                          color: colors.violetPrimary,
                          "&.Mui-checked": {
                            color: colors.violetPrimary,
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        color: colors.textPrimary,
                        fontSize: "16px",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        color: colors.textPrimary,
                        fontSize: "16px",
                      }}
                    >
                      Owner
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        color: colors.textPrimary,
                        fontSize: "16px",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTreatmentPlans.map((treatment) => {
                    const isSelected = selectedPlans.includes(treatment.id);

                    return (
                      <TableRow
                        key={treatment.id}
                        hover
                        onClick={() => handleSelectPlan(treatment.id)}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: isSelected
                            ? colors.violetPrimary
                            : "inherit",
                          "&:hover": {
                            backgroundColor: isSelected
                              ? colors.violetSecondary
                              : "#f5f5f5",
                            color: isSelected
                              ? "#000000"
                              : colors.textSecondary,
                            "& .MuiTableCell-root": {
                              color: isSelected
                                ? "#000000 !important"
                                : colors.textSecondary,
                            },
                            "& .MuiCheckbox-root": {
                              color: colors.violetPrimary,
                            },
                          },
                          "& .MuiTableCell-root": {
                            color: isSelected ? "#FFFFFF" : colors.textPrimary,
                          },
                          transition: "background-color 0.3s ease",
                          borderRadius: "12px",
                          marginBottom: "10px",
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            sx={{
                              color: isSelected
                                ? "#FFFFFF"
                                : colors.violetPrimary,
                              "&.Mui-checked": {
                                color: "#FFFFFF",
                              },
                              "&:hover": {
                                color: colors.violetPrimary,
                              },
                            }}
                            readOnly
                          />
                        </TableCell>
                        <TableCell>{treatment.name}</TableCell>
                        <TableCell>{treatment.owner}</TableCell>
                        <TableCell>{treatment.status}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Button
                  variant="outlined"
                  disabled={currentPage === 1}
                  onClick={handlePreviousPage}
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  disabled={!hasMore}
                  onClick={handleNextPage}
                >
                  Next
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
        <Divider sx={{ margin: "10px 0" }} />
        <DialogActions
          sx={{ padding: "16px 24px", backgroundColor: "#f7f9fc" }}
        >
          <Typography sx={{ fontWeight: 500 }}>
            {selectedPlans.length} Treatment Plan(s) Selected
          </Typography>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={errorMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: colors.violetPrimary,
            color: "#fff",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          },
        }}
      />
    </>
  );
}

TreatmentPlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default TreatmentPlanModal;
