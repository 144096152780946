import React from "react";
import TeamHeader from "./TeamHeader";
import InviteTeamMembers from "./InviteTeamMembers";
import TeamMembersList from "./TeamMembersList";

function TeamMain() {
  return (
    <>
      <TeamHeader />
      <InviteTeamMembers />
      <TeamMembersList />
    </>
  );
}

export default TeamMain;
