import React, { useState } from "react";
import {
  Box,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import FilterListIcon from "@mui/icons-material/FilterList";
import { colors } from "../../../Utils/colorUtils";
import searchImage from "../../../assets/search-lg.svg";

const StyledButton = styled(Button)({
  textTransform: "none",
  borderRadius: 8,
  backgroundColor: colors.secondary,
  color: colors.textTertiary,
  border: `1px solid ${colors.borderPrimary}`,
  height: "40px",
  padding: "10px 14px",
  boxShadow: "none",
  fontWeight: "400",
  fontSize: "14px",
});

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: colors.textTertiary,
  height: 40,
  padding: "10px 20px",
  fontWeight: 600,
  fontSize: "14px",
  textTransform: "none",
  border: `1px solid ${colors.borderPrimary}`,
  borderRadius: "10px",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0px",
    height: 60,
    width: "100%",
    borderRadius: "10px",
    "&:first-of-type": {
      borderRadius: "10px",
    },
    "&:last-of-type": {
      borderRadius: "10px",
    },
    "&:not(:first-of-type)": {
      borderLeft: `1px solid ${colors.borderPrimary}`,
      borderRadius: "10px",
    },
  },
}));

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: colors.borderPrimary,
    },
    "&:hover fieldset": {
      borderColor: colors.borderPrimary,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.borderPrimary,
    },
    borderRadius: 8,
    backgroundColor: colors.secondary,
    height: "40px",
    padding: "10px 14px",
    fontWeight: 400,
    color: colors.textFourth,
    fontSize: "16px",
  },
});

const SearchAdornment = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginRight: "8px",
});

function DocumentOverViewUtils({ onSearch, onViewModeChange }) {
  const [viewMode, setViewMode] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleViewChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
      onViewModeChange(newMode);
    }
  };

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    onSearch(term);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "flex-start" : "center",
          mt: 3,
          mb: 3,
          gap: 2,
        }}
      >
        <ToggleButtonGroup
          value={viewMode}
          exclusive
          onChange={handleViewChange}
          aria-label="file view mode"
          sx={{
            flexGrow: 1,
            flexDirection: isMobile ? "column" : "row",
            width: isMobile ? "100%" : "auto",
            gap: isMobile ? 2 : 0,
          }}
        >
          <CustomToggleButton value="all">View all</CustomToggleButton>
          <CustomToggleButton value="your">Your files</CustomToggleButton>
          <CustomToggleButton value="shared">Shared files</CustomToggleButton>
        </ToggleButtonGroup>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-start" : "center",
            gap: 2,
            width: isMobile ? "100%" : "auto",
          }}
        >
          <StyledTextField
            size="small"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <SearchAdornment>
                  <img src={searchImage} alt="search" />
                </SearchAdornment>
              ),
            }}
            sx={{
              width: isMobile ? "100%" : 240,
              mb: isMobile ? 2 : 0,
            }}
          />
          <StyledButton
            variant="outlined"
            startIcon={<FilterListIcon />}
            sx={{
              width: isMobile ? "100%" : "auto",
            }}
          >
            Filters
          </StyledButton>
        </Box>
      </Box>
    </>
  );
}
DocumentOverViewUtils.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onViewModeChange: PropTypes.func.isRequired,
};

export default DocumentOverViewUtils;
