import React from "react";
import { Box } from "@mui/material";

const ChatLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "8px 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "40px",
          height: "16px",
        }}
      >
        <Box
          sx={{
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            backgroundColor: "#475467",
            animation: "dot-flashing 1s infinite alternate",
            animationDelay: "0s",
          }}
        />
        <Box
          sx={{
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            backgroundColor: "#98A2B3",
            animation: "dot-flashing 1s infinite alternate",
            animationDelay: "0.3s",
            marginBottom: "8px",
          }}
        />
        <Box
          sx={{
            width: "6px",
            height: "6px",
            borderRadius: "50%",
            backgroundColor: "#475467",
            animation: "dot-flashing 1s infinite alternate",
            animationDelay: "0.6s",
          }}
        />
      </Box>
      <style>
        {`
          @keyframes dot-flashing {
            0% {
              opacity: 0.3;
            }
            100% {
              opacity: 1;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default ChatLoader;
