import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import upperNav from "../../assets/upperNav.svg";
import copy from "../../assets/copy-01.svg";
import { Link } from "react-router-dom";

function PatientInvitationPopUp({
  setPopupOpen,
  invitationLink,
  patientName,
  patientId,
}) {
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(invitationLink)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
  };

  return (
    <Box
      sx={{
        width: isMobile ? "90%" : 400,
        maxWidth: "100%",
        backgroundColor: "#f8f8f8",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        padding: isMobile ? 1.5 : 2,
        textAlign: "center",
        fontFamily: "Arial, sans-serif",
        position: "relative",
        margin: "auto",
      }}
    >
      <IconButton
        onClick={handleClosePopup}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "#98A2B3",
        }}
      >
        <CloseIcon sx={{ fontSize: 24, fontWeight: "bold" }} />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <img
          src={upperNav}
          alt="Upper Nav"
          style={{
            width: "100%",
            maxWidth: 400,
            height: "auto",
            maxHeight: 120,
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          marginY: 2,
          fontFamily: "Inter, sans-serif",
          fontWeight: 600,
          fontSize: isMobile ? "16px" : "18px",
          lineHeight: "28px",
          color: "#101828",
          textAlign: "center",
        }}
      >
        Invitation Sent to {patientName}
      </Typography>
      <Typography
        sx={{
          marginY: 1,
          fontFamily: "Inter, sans-serif",
          fontWeight: 400,
          fontSize: isMobile ? "12px" : "14px",
          color: "#475467",
          textAlign: "center",
        }}
      >
        Your client will be receiving an invite email shortly,
        {isMobile ? " " : <br />} but you can also copy the invite link below.
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        value={invitationLink}
        InputProps={{
          readOnly: true,
          sx: {
            "& input": {
              textAlign: "center",
              padding: "0",
              height: "100%",
              boxSizing: "border-box",
              lineHeight: "44px",
              fontSize: isMobile ? "12px" : "14px",
            },
          },
        }}
        sx={{
          marginY: 2,
          backgroundColor: "white",
          borderRadius: "10px",
          "& .MuiOutlinedInput-root": {
            height: "44px",
            borderRadius: "10px",
            "& fieldset": {
              borderColor: "#EAECF0",
            },
            "&:hover fieldset": {
              borderColor: "#EAECF0",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#EAECF0",
            },
          },
        }}
      />

      <Button
        sx={{
          width: "100%",
          height: "44px",
          borderRadius: "5px",
          border: "1px solid #D0D5DD",
          padding: "10px",
          backgroundColor: "#FFFFFF",
          color: "#344054",
          fontSize: isMobile ? "14px" : "16px",
          fontWeight: "600",
          textTransform: "none",
          marginBottom: "20px",
          gap: "1px",
          "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        }}
        onClick={handleCopyLink}
      >
        <img src={copy} style={{ marginRight: "5px" }} alt="copy" />
        Share Invite Link
      </Button>

      <Link
        to={`/patient/overview/${patientId}`}
        style={{ textDecoration: "none", width: "100%", display: "block" }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#7d4cdb",
            width: "100%",
            height: "44px",
            borderRadius: "5px",
            fontWeight: "600",
            color: "white",
            padding: "10px 20px",
            fontSize: isMobile ? 14 : 16,
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#693ab7",
            },
          }}
        >
          Open Patient Dashboard
        </Button>
      </Link>
    </Box>
  );
}

PatientInvitationPopUp.propTypes = {
  setPopupOpen: PropTypes.func.isRequired,
  invitationLink: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default PatientInvitationPopUp;
