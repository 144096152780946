import React from "react";
import {
  Typography,
  Box,
  Grid,
  Button,
  Container,
  Card,
  Avatar,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import { colors } from "../../Utils/colorUtils";
import shoesIcon from "../../assets/shoesIcon.svg";
import bowling from "../../assets/bowling.svg";
import infoPoint from "../../assets/info-point.svg";
import fireworks from "../../assets/fireworks.svg";
import sleep from "../../assets/sleep-face.svg";
import worried from "../../assets/Worried.svg";
import unwell from "../../assets/sick-face.svg";
import personOneIcon from "../../assets/personOneIcon.svg";
import personTwoIcon from "../../assets/personTwoIcon.svg";
import personThreeIcon from "../../assets/personthreeIcon.svg";
import { Checkbox as MuiCheckbox } from "@mui/material";
import angryFace from "../../assets/angry-face.svg";
import excitedFace from "../../assets/excited.svg";
import frowningFace from "../../assets/frowning-face.svg";
import sadSweatFace from "../../assets/sad-sweat-face.svg";
import smileFace from "../../assets/smile.svg";
import Body from "./Body";

const DividerStyled = styled(Divider)({
  borderBottomWidth: "2px",
  borderColor: colors.borderColor,
  marginTop: "0px",
  marginBottom: "0px",
});

const interferenceLevels = [
  {
    label: "Low",
    description:
      "Function normally. May still have pain but able to adapt to it.",
    icon: personOneIcon,
  },
  {
    label: "Medium",
    description:
      "Function normally. May still have pain but able to adapt to it.",
    icon: personTwoIcon,
  },
  {
    label: "High",
    description:
      "Function normally. May still have pain but able to adapt to it.",
    icon: personThreeIcon,
  },
];

const emojiOptions = [
  { name: "sad", icon: sadSweatFace },
  { name: "frowning", icon: frowningFace },
  { name: "smile", icon: smileFace },
  { name: "excited", icon: excitedFace },
  { name: "angry", icon: angryFace },
];

const CustomCheckbox = styled((props) => (
  <MuiCheckbox
    disableRipple
    color="default"
    checkedIcon={<span />}
    icon={<span />}
    {...props}
  />
))(({ theme, colors }) => ({
  padding: 0,
  "& .MuiSvgIcon-root": {
    display: "none",
  },
  "& span": {
    borderRadius: 8,
    width: 24,
    height: 24,
    border: `1px solid ${colors.borderColor}`,
    backgroundColor: "transparent",
    "input:hover ~ &": {
      backgroundColor: theme.palette.action.hover,
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: theme.palette.action.disabledBackground,
    },
  },
  "&.Mui-checked": {
    "& span": {
      backgroundColor: colors.violetPrimary,
      "&:before": {
        display: "block",
        width: 24,
        height: 24,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    },
  },
}));

function SsNotesPageOne({
  selectedPainLevel,
  triggerStates,
  handlePainLevelChange,
  handleTriggerChange,
  InterferenceOne,
  handleSelect,
  InterferenceTwo,
  handleEmojiSelect,
}) {
  const triggerOptions = [
    { name: "overdoing", label: "Overdoing an activity", icon: infoPoint },
    { name: "unusual", label: "Something unusual", icon: bowling },
    {
      name: "stressful",
      label: "Having a stressful situation",
      icon: fireworks,
    },
    { name: "poorly", label: "Sleep poorly", icon: sleep },
    { name: "worried", label: "Getting worried", icon: worried },
    { name: "unwell", label: "Feeling unwell", icon: unwell },
    { name: "exercising", label: "Exercising too much", icon: shoesIcon },
  ];

  return (
    <Container
      sx={{
        flexGrow: 1,
        overflowY: "auto",
        mt: 10,
        mb: 8,
        maxWidth: "100% !important",
        width: "100%",
        paddingLeft: "24px",
        paddingRight: "24px",
      }}
      disableGutters
    >
      <Grid container spacing={2} alignItems="center" sx={{ mt: 2, mb: 4 }}>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: colors.textTertiary,
            }}
          >
            Pain
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: colors.textSecondary,
            }}
          >
            Write a short introduction.
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: colors.textSecondary,
                }}
              >
                Mild
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: colors.textSecondary,
                }}
              >
                Severe
              </Typography>
            </Box>
            <Box sx={{ display: "flex", width: "100%" }}>
              {Array.from({ length: 10 }, (_, i) => i + 1).map((level) => (
                <Button
                  key={level}
                  variant={
                    selectedPainLevel === level ? "contained" : "outlined"
                  }
                  onClick={() => handlePainLevelChange(level)}
                  sx={{
                    flexGrow: 1,
                    height: "50px",
                    minWidth: 0,
                    borderRadius: "0px",
                    borderColor: "#D0D5DD",
                    border:
                      selectedPainLevel === level
                        ? "none"
                        : `1px solid #D0D5DD`,
                    color: selectedPainLevel === level ? "white" : "black",
                    backgroundColor:
                      selectedPainLevel === level ? "#7F56D9" : undefined,
                    padding: "8px 0",
                    "&:not(:last-child)": { borderRight: "none" },
                    "&:first-of-type": {
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    },
                    "&:last-of-type": {
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                    },
                    "&:hover": { backgroundColor: colors.violetSecondary },
                  }}
                >
                  {level}
                </Button>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DividerStyled />
      <Grid container spacing={2} sx={{ mt: 6, mb: 3 }}>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: colors.textTertiary,
            }}
          >
            Trigger
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            {triggerOptions.map((trigger) => (
              <Grid item xs={6} key={trigger.name}>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px",
                    border: `1px solid ${colors.borderColor}`,
                    borderRadius: "8px",
                    gap: 2,
                    mb: 2,
                    height: "80px",
                  }}
                  variant="outlined"
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Avatar
                      src={trigger.icon}
                      alt={trigger.label}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        color: colors.textPrimary,
                      }}
                    >
                      {trigger.label}
                    </Typography>
                  </Box>
                  <CustomCheckbox
                    checked={triggerStates[trigger.name]}
                    onChange={(e) =>
                      handleTriggerChange(trigger.name, e.target.checked)
                    }
                    name={trigger.name}
                    colors={colors}
                    sx={{ marginLeft: "auto" }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <DividerStyled />
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: colors.textTertiary,
              mt: 4,
            }}
          >
            Localisation
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Card
            variant="outlined"
            sx={{
              height: "auto",
              mt: 1,
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 5,
            }}
          >
            <Body />
          </Card>
        </Grid>
      </Grid>
      <DividerStyled />
      <Grid container spacing={2} sx={{ mt: 6, mb: 3 }}>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: colors.textTertiary,
            }}
          >
            Interference
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            {interferenceLevels.map((level) => (
              <Grid item xs={6} key={level.label}>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: "12px",
                    border: `1px solid ${InterferenceOne === level.label ? colors.violetPrimary : colors.borderColor}`,
                    borderRadius: "8px",
                    gap: 2,
                    mb: 2,
                    mt: 2,
                    height: "auto",
                    cursor: "pointer",
                    backgroundColor:
                      InterferenceOne === level.label ? "#F9F5FF" : "white",
                  }}
                  onClick={() => handleSelect(level.label)}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
                  >
                    <Avatar
                      src={level.icon}
                      alt={level.label}
                      sx={{ width: 24, height: 24, mt: "4px" }}
                    />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: colors.textPrimary,
                        }}
                      >
                        {level.label}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: colors.textPrimary,
                        }}
                      >
                        {level.description}
                      </Typography>
                    </Box>
                  </Box>
                  <CustomCheckbox
                    checked={InterferenceOne === level.label}
                    onChange={() => handleSelect(level.label)}
                    colors={colors}
                    sx={{ marginLeft: "auto" }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <DividerStyled />
      <Grid container spacing={2} sx={{ mt: 6, mb: 3 }}>
        <Grid item xs={5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: colors.textTertiary,
            }}
          >
            Interference
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Grid item xs={12}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "24px",
                border: `1px solid ${colors.borderColor}`,
                borderRadius: "8px",
                gap: 2,
                mb: 2,
                mt: 2,
                height: "auto",
                cursor: "pointer",
                backgroundColor: "white",
              }}
            >
              <Typography variant="h5" sx={{ mb: 2 }}>
                {InterferenceTwo
                  ? `Feeling ${InterferenceTwo}`
                  : "Select a feeling"}
              </Typography>
              {InterferenceTwo && (
                <Avatar
                  src={
                    emojiOptions.find((emoji) => emoji.name === InterferenceTwo)
                      ?.icon
                  }
                  alt={InterferenceTwo}
                  sx={{ width: 120, height: 120, mb: 4 }}
                />
              )}
              <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                {emojiOptions.map((emoji) => (
                  <Avatar
                    key={emoji.name}
                    src={emoji.icon}
                    alt={emoji.name}
                    sx={{
                      width: 40,
                      height: 40,
                      cursor: "pointer",
                      border:
                        InterferenceTwo === emoji.name
                          ? `2px solid ${colors.violetPrimary}`
                          : "none",
                    }}
                    onClick={() => handleEmojiSelect(emoji.name)}
                  />
                ))}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

SsNotesPageOne.propTypes = {
  selectedPainLevel: PropTypes.number.isRequired,
  triggerStates: PropTypes.object.isRequired,
  handlePainLevelChange: PropTypes.func.isRequired,
  handleTriggerChange: PropTypes.func.isRequired,
  InterferenceOne: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  InterferenceTwo: PropTypes.string,
  handleEmojiSelect: PropTypes.func.isRequired,
};

export default SsNotesPageOne;
