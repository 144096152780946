import React, { useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  Chip,
  List,
  ListItem,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";
import circularDotBorder from "../../../assets/circular-dot-border.svg";
import dotsVertical from "../../../assets/dotsVertical.svg";

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

const ConditionChip = styled(Chip)({
  margin: "4px",
  backgroundColor: colors.secondary,
  border: `1px solid ${colors.borderPrimary}`,
  borderRadius: "10px",
  color: colors.textTertiary,
});

const SectionTitle = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "28px",
  color: colors.textPrimary,
});

const SectionSubtitle = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: colors.textSecondary,
  padding: "10px 0",
});

const ContentText = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: colors.textSecondary,
  padding: "0",
});

const data = {
  historyTitle: "History",
  historyDescription: "Update your photo and personal details here.",
  aboutTitle: "About",
  aboutDescription: "Write a short introduction.",
  aboutContent:
    "Persistent sciatica, characterised by radiating pain from the lower back down the right leg, exacerbated by prolonged sitting and standing. Examination reveals notable lumbar tenderness and reduced flexibility, suggesting lumbar disc involvement.",
  conditionTitle: "Condition",
  conditions: [
    "Sciatica",
    "Slipped Disk",
    "Shooting Pains",
    "Limited Mobility",
    "Tight Hamstrings",
  ],
  comesAcrossTitle: "Comes Across",
  comesAcrossConditions: [
    "Anxious",
    "Nervous",
    "Depressed",
    "Tendency to rest excessively",
  ],
  entries: [
    {
      date: "28 April 2024",
      content:
        "Patient expresses significant relief from initial symptoms, with only occasional mild discomfort in the lower back. Physiotherapy sessions have increased mobility and reduced pain episodes. Plan to taper off direct therapy interventions and focus on maintaining activity level independently.",
    },
    {
      date: "21 April 2024",
      content:
        "Follow-up visit 2: Patient shows noticeable improvement in lower back pain and sciatica; reports decreased pain intensity and frequency. Has successfully integrated walking and core strengthening into daily routine. Encouraged to maintain physical activity and introduced to progressive muscle relaxation techniques.",
    },
    {
      date: "14 April 2024",
      content:
        "Follow-up visit 1: Patient reports a slight improvement in sciatica symptoms following the initiation of prescribed physiotherapy exercises. Compliance with daily stretching routines is good, but still experiences discomfort during long periods of sitting. Advised to continue exercises and consider ergonomic adjustments at work.",
    },
    {
      date: "14 April 2024",
      content:
        "Follow-up visit 1: Patient reports a slight improvement in sciatica symptoms following the initiation of prescribed physiotherapy exercises. Compliance with daily stretching routines is good, but still experiences discomfort during long periods of sitting. Advised to continue exercises and consider ergonomic adjustments at work.",
    },
  ],
};
function HistoryTabContent() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <CustomGrid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: {
            xs: "center",
            sm: "space-between",
          },
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          padding: {
            xs: "60px 0px 10px",
            sm: "60px 0px 10px",
          },
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <SectionTitle>{data.historyTitle}</SectionTitle>
          <SectionSubtitle>{data.historyDescription}</SectionSubtitle>
          <Divider
            sx={{
              my: 2,
              borderBottomWidth: "1px",
              borderColor: colors.textFourth,
            }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={12}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Grid item xs={12} sm={6} md={6} className="gridOne">
            <SectionTitle>{data.aboutTitle}</SectionTitle>
            <SectionSubtitle> {data.aboutDescription}</SectionSubtitle>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="gridTwo">
            <ContentText>{data.aboutContent}</ContentText>
          </Grid>
          <Grid xs={12} sm={12} md={12} sx={{ paddingTop: "10px" }}>
            <Divider
              sx={{
                my: 2,
                borderBottomWidth: "1px",
                borderColor: colors.textFourth,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={12}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Grid item xs={12} sm={6} md={6} className="gridOne">
            <SectionTitle>{data.conditionTitle}</SectionTitle>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className="gridTwo">
            <Grid container>
              {data.conditions.map((condition) => (
                <ConditionChip key={condition} label={condition} />
              ))}
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={12} sx={{ paddingTop: "10px" }}>
            <Divider
              sx={{
                my: 2,
                borderBottomWidth: "1px",
                borderColor: colors.textFourth,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={12}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Grid item xs={12} sm={6} md={6}>
            <SectionTitle>{data.comesAcrossTitle}</SectionTitle>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container>
              {data.comesAcrossConditions.map((condition) => (
                <ConditionChip key={condition} label={condition} />
              ))}
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={12} sx={{ paddingTop: "10px" }}>
            <Divider
              sx={{
                my: 2,
                borderBottomWidth: "1px",
                borderColor: colors.textFourth,
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={6}
          md={12}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "28px",
                  color: colors.textSecondary,
                }}
              >
                History
              </Typography>
            </Grid>
            <Grid item>
              <button
                onClick={handleClick}
                style={{
                  cursor: "pointer",
                  background: "none",
                  border: "none",
                  padding: 0,
                }}
                aria-label="dots vertical"
              >
                <img src={dotsVertical} alt="dots vertical" />
              </button>

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    width: 200,
                    maxWidth: "100%",
                  },
                }}
              >
                <MenuItem onClick={handleClose}>Edit</MenuItem>
                <MenuItem onClick={handleClose}>Delete</MenuItem>
                <MenuItem onClick={handleClose}>Share</MenuItem>
              </Menu>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <List
              sx={{
                width: "100%",
                background: "white",
                py: 0,
                position: "relative",
              }}
            >
              {data.entries.map((entry, index) => (
                <ListItem
                  key={index}
                  sx={{
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "16px 0",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      mb: 1,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: "12px",
                        top: "30px",
                        height: `calc(100% - 36px)`,
                        width: "1px",
                        bgcolor: "#e0e0e0",
                        zIndex: 10,
                      }}
                    />
                    <img
                      src={circularDotBorder}
                      alt="circularDotBorder"
                      style={{
                        zIndex: 20,
                        position: "relative",
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "28px",
                        color: colors.textTertiary,
                        pl: "12px",
                      }}
                    >
                      {entry.date}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      pl: "36px",
                      fontFamily: "Inter",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: colors.textSecondary,
                    }}
                  >
                    {entry.content}
                  </Typography>
                </ListItem>
              ))}
              <Box
                sx={{
                  position: "absolute",
                  left: "12px",
                  top: "30px",
                  height: `calc(100% - 36px)`,
                  width: "2px",
                  bgcolor: "#EAECF0",
                  zIndex: 10,
                }}
              />
            </List>
          </Grid>
        </Grid>
      </CustomGrid>
    </>
  );
}

export default HistoryTabContent;
