import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography, Grid, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import DeleteIcons from "../../../assets/deleteicon.svg";
import imagePlus from "../../../assets/imagePlus.svg";
import Refresh from "../../../assets/refresh-cw-02.svg";

const UploadBox = styled(Box)(({ theme }) => ({
  borderRadius: "10px",
  border: "1px solid #EAECF0",
  display: "flex",
  marginBottom: "30px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: theme.spacing(2),
  color: "#6941C6",
  alignSelf: "stretch",
  cursor: "pointer",
  "& img": {
    marginBottom: theme.spacing(2),
  },
  "&.dragging": {
    borderColor: "#6941C6",
    backgroundColor: "#F9F5FF",
  },
}));

const ClickToUploadText = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",
  color: "#6941C6",
});

const DragAndDropText = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#475467",
});

function ProfilePhotoSection({ onImageUpload, initialImage }) {
  const fileInputRef = useRef(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (initialImage) {
      setUploadedImage(initialImage);
    }
  }, [initialImage]);

  const handleClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/uploadToGcp/upload-file/profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const imageUrl = response.data.url;
      setUploadedImage(imageUrl);
      onImageUpload(imageUrl);
    } catch (error) {
      console.error("Error uploading image", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      uploadFile(file);
    }
  };

  return (
    <>
      {!uploadedImage ? (
        <UploadBox
          className={isDragging ? "dragging" : ""}
          onClick={handleClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept="image/svg+xml,image/png,image/jpeg,image/gif"
          />
          {isUploading ? (
            <Grid sx={{ display: "flex" }}>
              <CircularProgress
                size={20}
                sx={{
                  color: "#6941C6",
                }}
              />
            </Grid>
          ) : (
            <>
              <img src={imagePlus} alt="Upload icon" />
              <Grid sx={{ display: "flex" }}>
                <ClickToUploadText>Click to upload &nbsp; </ClickToUploadText>
                <DragAndDropText>or drag and drop</DragAndDropText>
              </Grid>
            </>
          )}
        </UploadBox>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          border="1px solid #D0D5DD"
          borderRadius="12px"
          padding="8px"
          mt={2}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <img
              src={uploadedImage}
              alt="Uploaded"
              style={{
                width: "100px",
                height: "auto",
                objectFit: "cover",
                borderRadius: "4px",
                marginRight: "10px",
              }}
            />
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Uploaded Image
              </Typography>
            </Box>
          </Box>
          <Button onClick={handleRemoveImage}>
            <img src={DeleteIcons} alt="Remove" />
          </Button>
        </Box>
      )}

      {uploadedImage && (
        <Box display="flex" justifyContent="flex-start" mt={1} mb={2}>
          <Button
            variant="outlined"
            sx={{
              color: "#344054",
              border: "1px solid #D0D5DD",
              textTransform: "none",
              marginRight: "8px",
              borderRadius: "2px",
              fontWeight: 700,
            }}
            onClick={() => fileInputRef.current.click()}
          >
            <img
              src={Refresh}
              alt="Replace"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
            />
            Replace
          </Button>
          <Button
            variant="outlined"
            onClick={handleRemoveImage}
            sx={{
              color: "#344054",
              border: "1px solid #D0D5DD",
              textTransform: "none",
              marginRight: "8px",
              fontWeight: 700,
            }}
          >
            <img
              src={DeleteIcons}
              alt="Delete"
              style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
              }}
            />
            Delete
          </Button>
        </Box>
      )}
    </>
  );
}

ProfilePhotoSection.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
  initialImage: PropTypes.string,
};

export default ProfilePhotoSection;
