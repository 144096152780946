import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import VlogVideo from "./VlogVideo";
import VlogSingleVideo from "./VlogSingleVideo";

function VlogMain() {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const { patientId } = useParams();
  const fetchVideos = async () => {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URI}/api/v1/recordings/get-recordings/${patientId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await response.json();
    const videoData = data.data.map((item) => ({
      url: item.recording_url,
      title: item.treatment.exerciseMaster.exercise_name,
      date: new Date(item.createdAt).toLocaleDateString(),
    }));
    setVideos(videoData);
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  return (
    <Grid
      container
      sx={{
        padding: 0,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        height: { xs: "auto", md: "65vh" },
      }}
    >
      {videos.length === 0 ? (
        <Typography
          variant="p"
          align="center"
          sx={{
            marginTop: 4,
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          No vlogs plans available.
        </Typography>
      ) : selectedVideo ? (
        <VlogSingleVideo video={selectedVideo} />
      ) : (
        <VlogVideo onVideoClick={handleVideoClick} videos={videos} />
      )}
    </Grid>
  );
}

export default VlogMain;
