import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import Add from "../../../../assets/assPlus.svg";
import removeIcon from "../../../../assets/removeIcon.svg";
import { motion } from "framer-motion";
import dots from "../../../../assets/9Dots.svg";
import circularDotBorder from "../../../../assets/dotsVertical.svg";
import squareArrowDownIcon from "../../../../assets/squareArrowDownIcon.svg";
import { colors } from "../../../../Utils/colorUtils";
import { Link } from "react-router-dom";

function TreatmentDays({
  days,
  selectedDay,
  onAddDay,
  onSwitchDay,
  onRemoveDay,
  openDialog,
  handleOpenDialog,
  handleCloseDialog,
  handleRemoveImage,
  videoRefs,
  isMobile,
}) {
  const handleVideoLoad = (index) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index].currentTime = 0.1; // Start at 0.1 seconds to avoid black screen.
    }
  };

  const handleRemoveDay = () => {
    onRemoveDay(selectedDay);
  };

  return (
    <>
      <Grid sx={{ marginBottom: "50px", marginTop: "0px" }}>
        <Divider sx={{ my: 1 }} />
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid container spacing={2}>
            {days.map((day) => (
              <Grid item key={day.id}>
                <Button
                  onClick={() => onSwitchDay(day.id)}
                  sx={{
                    padding: "6px 12px",
                    whiteSpace: "nowrap",
                    maxWidth: 120,
                    backgroundColor:
                      day.id === selectedDay ? "#7f56d9" : "#F9F5FF",
                    color:
                      day.id === selectedDay
                        ? "#FFFFFF"
                        : colors.violetSecondary,
                    textTransform: "none",
                    fontWeight: 600,
                    fontSize: "14px",
                    borderRadius: "8px",
                    "&:hover": {
                      background: colors.violetSecondary,
                      color: colors.secondary,
                    },
                  }}
                >
                  Day {day.id}
                </Button>
              </Grid>
            ))}

            <Grid item>
              <Button
                onClick={onAddDay}
                disabled={days.length >= 7}
                sx={{
                  backgroundColor: "transparent",
                  color: colors.textSecondary,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  borderRadius: "8px",
                  padding: "10px 20px",
                  height: "40px",
                  "&:disabled": {
                    color: colors.textFourth,
                    cursor: "not-allowed",
                  },
                }}
              >
                <img src={Add} alt="add" style={{ marginRight: "8px" }} />
                Day
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              onClick={handleRemoveDay}
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                color: colors.textSecondary,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Remove Day
              <img
                src={removeIcon}
                alt="removeIcon"
                style={{ marginLeft: "8px" }}
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          border:
            days.find((day) => day.id === selectedDay)?.treatments.length === 0
              ? "1px dashed #7F56D9"
              : "1px dashed #EAECF0",
          borderRadius: "14px",
          textAlign: "center",
          padding: 2,
          pt: 4,
          mt: 2,
          background:
            days.find((day) => day.id === selectedDay)?.treatments.length === 0
              ? "#FAF4FE"
              : "white",
        }}
      >
        {days.find((day) => day.id === selectedDay)?.treatments.length === 0 ? (
          <Typography
            mb={1}
            ml={1}
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              color: colors.violetSecondary,
            }}
          >
            <img
              src={squareArrowDownIcon}
              alt="add"
              style={{ marginRight: "8px" }}
            />
            Drag exercises from the left
          </Typography>
        ) : (
          <Grid container direction="column" spacing={2}>
            {days
              .find((day) => day.id === selectedDay)
              ?.treatments.map((treatment, index) => (
                <Grid item key={treatment.id}>
                  <motion.div draggable>
                    <Grid
                      container
                      alignItems="center"
                      sx={{
                        position: "relative",
                        padding: "10px",
                        borderRadius: "14px",
                        backgroundColor: "white",
                        border: "1px solid #EAECF0",
                        boxShadow: "0px 1px 2px 0px #1018280D",
                        overflow: "hidden",
                        margin: "5px 0",
                        flexDirection: isMobile ? "column" : "row",
                      }}
                    >
                      <Box
                        sx={{
                          flexShrink: 0,
                          width: isMobile ? "100%" : "80px",
                          height: isMobile ? "auto" : "50px",
                          borderRadius: "5px",
                          overflow: "hidden",
                        }}
                      >
                        {/* Ensure video or image is displayed */}
                        {treatment.videoSrc.endsWith(".mp4") ? (
                          <video
                            ref={(el) => (videoRefs.current[index] = el)}
                            src={treatment.videoSrc}
                            onLoadedMetadata={() => handleVideoLoad(index)}
                            muted
                            playsInline
                            //autoPlay
                            loop
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src={treatment.videoSrc}
                            alt={treatment.title}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </Box>

                      <Box
                        sx={{
                          flexGrow: 1,
                          ml: isMobile ? 0 : 2,
                          mt: isMobile ? 2 : 0,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: isMobile ? "flex-start" : "flex-start",
                          textAlign: isMobile ? "left" : "left",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            color: colors.textTertiary,
                            marginTop: isMobile ? "10px" : "0px",
                          }}
                        >
                          {treatment.title}
                        </Typography>
                        {/* Intensity details */}
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="left"
                          mt={1}
                          sx={{ width: isMobile ? "100%" : "80%" }}
                        >
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                              }}
                            >
                              <span style={{ color: colors.textTertiary }}>
                                L &nbsp;
                              </span>
                              <span style={{ color: colors.textSecondary }}>
                                {treatment.lightIntensity?.reps || "N/A"} reps –{" "}
                                {treatment.lightIntensity?.sets || "N/A"} sets
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                color: colors.textSecondary,
                              }}
                            >
                              <span style={{ color: colors.textTertiary }}>
                                M &nbsp;
                              </span>
                              <span style={{ color: colors.textSecondary }}>
                                {treatment.mediumIntensity?.reps || "N/A"} reps
                                – {treatment.mediumIntensity?.sets || "N/A"}{" "}
                                sets
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                color: colors.textSecondary,
                                marginRight: isMobile ? "0px" : "16px",
                              }}
                            >
                              <span style={{ color: colors.textTertiary }}>
                                H &nbsp;
                              </span>
                              <span style={{ color: colors.textSecondary }}>
                                {treatment.hardIntensity?.reps || "N/A"} reps –{" "}
                                {treatment.hardIntensity?.sets || "N/A"} sets
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>

                      {/* Three Dots for Options */}
                      <Box
                        sx={{
                          position: "absolute",
                          right: isMobile ? "5px" : "40px",
                          top: isMobile ? "120px" : "-30px",
                          bottom: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          type="button"
                          aria-label="Open dialog"
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            cursor: "pointer",
                            outline: "none",
                          }}
                          onClick={() => handleOpenDialog(treatment)}
                        >
                          <img
                            src={circularDotBorder}
                            alt="Options"
                            style={{ marginRight: isMobile ? "0px" : "30px" }}
                          />
                        </button>
                      </Box>

                      {/* Link for Edit on Larger Screens */}
                      {!isMobile && (
                        <Link to={`/edit/exercise/${treatment.treatmentId}`}>
                          <Box
                            sx={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              bottom: 0,
                              width: "40px",
                              background: "#F8FBFB",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderTopRightRadius: "14px",
                              borderBottomRightRadius: "14px",
                            }}
                          >
                            <Box
                              component="img"
                              src={dots}
                              alt="Icon"
                              sx={{
                                flexShrink: 0,
                                width: "15px",
                                height: "15px",
                              }}
                            />
                          </Box>
                        </Link>
                      )}
                    </Grid>
                  </motion.div>
                </Grid>
              ))}
          </Grid>
        )}
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            color: colors.textSecondary,
            paddingBottom: "5px",
          }}
        >
          Remove Treatment
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: "12px",
              color: colors.textSecondary,
              fontWeight: 400,
            }}
          >
            Are you sure you want to remove this Treatment?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: "10px" }}>
          <Button
            onClick={handleCloseDialog}
            style={{
              marginRight: "10px",
              borderRadius: "8px",
              width: "70px",
              color: "black",
              height: "32px",
              fontWeight: 400,
              borderColor: "#667085",
              border: "1px solid black",
              textTransform: "none",
              fontSize: "12px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemoveImage}
            style={{
              borderRadius: "8px",
              width: "70px",
              height: "32px",
              backgroundColor: "#7f56d9",
              border: "none",
              color: "#fff",
              fontWeight: 400,
              textTransform: "none",
              fontSize: "12px",
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

TreatmentDays.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      treatments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          videoSrc: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  selectedDay: PropTypes.number.isRequired,
  onAddDay: PropTypes.func.isRequired,
  onSwitchDay: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
  videoRefs: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

TreatmentDays.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      treatments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          videoSrc: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  selectedDay: PropTypes.number.isRequired,
  onAddDay: PropTypes.func.isRequired,
  onSwitchDay: PropTypes.func.isRequired,
  onRemoveDay: PropTypes.func.isRequired,
  openDialog: PropTypes.bool.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
  videoRefs: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default TreatmentDays;
