import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, InputBase, IconButton, Paper, Button } from "@mui/material";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EmojiPicker from "emoji-picker-react";
import { styled } from "@mui/system";

const InputAreaContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  paddingTop: "0px",
  paddingBottom: "10px",
  paddingRight: "20px",
  background: "#F9FAFB",
  marginLeft: "20px",
  paddingLeft: "0px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
}));

const ChatMessageInputArea = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
  };

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage({
        text: message,
        sender: "You",
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: new Date().toISOString().split("T")[0],
      });
      setMessage("");
    }
  };

  return (
    <InputAreaContainer>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          borderRadius: "8px",
          boxShadow: "none",
          border: "1px solid #D0D5DD",
          minHeight: "64px",
          backgroundColor: "#FFFFFF",
        }}
      >
        {showEmojiPicker && (
          <Box
            sx={{
              position: "absolute",
              bottom: "60px",
              right: "50px",
              zIndex: 1000,
            }}
          >
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </Box>
        )}
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            minHeight: "30px",
            maxHeight: "40px",
            overflowY: "auto",
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            "& textarea": {
              resize: "none",
            },
          }}
          placeholder="Send a message"
          multiline
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <IconButton
            sx={{ p: "10px", color: "#667085" }}
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          >
            <InsertEmoticonIcon />
          </IconButton>
          <IconButton sx={{ p: "10px", color: "#667085" }}>
            <MoreHorizIcon />
          </IconButton>
          <Button
            onClick={handleSend}
            variant="contained"
            color="primary"
            sx={{
              height: "40px",
              width: "67px",
              fontSize: "14px",
              fontWeight: "600",
              bgcolor: "#7F56D9",
              color: "#FFFFFF",
              borderRadius: "8px",
              "&:hover": { bgcolor: "#6941C6" },
              ml: 1,
            }}
          >
            Send
          </Button>
        </Box>
      </Paper>
    </InputAreaContainer>
  );
};

ChatMessageInputArea.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
};

export default ChatMessageInputArea;
