import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Select, MenuItem } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { colors } from "../../Utils/colorUtils";

const BioSection = ({ bio, setBio, fontStyle, setFontStyle }) => {
  const editorRef = useRef(null);

  const handleFontStyleChange = (event) => {
    const selectedStyle = event.target.value;
    setFontStyle(selectedStyle);

    const editor = editorRef.current;

    if (editor) {
      // Handle "Regular" case: remove both bold and italic styles
      if (selectedStyle === "Regular") {
        if (editor.commands.get("bold").value) {
          editor.execute("bold"); // Remove bold if active
        }
        if (editor.commands.get("italic").value) {
          editor.execute("italic"); // Remove italic if active
        }
      }

      // Handle "Bold" case: toggle bold and disable italic
      if (selectedStyle === "Bold") {
        if (editor.commands.get("italic").value) {
          editor.execute("italic"); // Remove italic if it's active
        }
        if (!editor.commands.get("bold").value) {
          editor.execute("bold"); // Apply bold if not active
        }
      }

      // Handle "Italic" case: toggle italic and disable bold
      if (selectedStyle === "Italic") {
        if (editor.commands.get("bold").value) {
          editor.execute("bold"); // Remove bold if it's active
        }
        if (!editor.commands.get("italic").value) {
          editor.execute("italic"); // Apply italic if not active
        }
      }

      editor.focus(); // Keep focus on editor after style is applied
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    const strippedData = data.replace(/<[^>]*>/g, "");
    if (strippedData.length <= 275) {
      setBio(data);
    } else {
      const truncatedData = strippedData.slice(0, 275);
      editor.setData(truncatedData);
      setBio(truncatedData);
    }
  };

  const customToolbar = [
    "bold",
    "italic",
    "link",
    "|",
    "bulletedList",
    "numberedList",
  ];

  const strippedBio = bio.replace(/<[^>]*>/g, "");
  const remainingChars = 275 - strippedBio.length;

  return (
    <>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={3}>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{
              fontWeight: 600,
              color: colors.textPrimary,
              fontSize: "14px",
            }}
          >
            Bio
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{
              fontWeight: 400,
              color: colors.textSecondary,
              fontSize: "14px",
            }}
          >
            Write a short introduction
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <Select
            value={fontStyle}
            onChange={handleFontStyleChange}
            displayEmpty
            sx={{
              width: "100%",
              borderRadius: "10px",
              mb: { xs: 2, md: 0 },
              "& .MuiOutlinedInput-root": {
                height: "40px",
                "& fieldset": {
                  borderColor: "#D0D5DD",
                  borderRadius: "10px",
                },
                "& input": {
                  height: "100%",
                  padding: "0 14px",
                },
              },
            }}
            fullWidth
          >
            <MenuItem value="Regular">Regular</MenuItem>
            <MenuItem value="Bold">Bold</MenuItem>
            <MenuItem value="Italic">Italic</MenuItem>
          </Select> */}
          <Grid mt={2}>
            <CKEditor
              editor={ClassicEditor}
              data={bio}
              onChange={handleEditorChange}
              config={{
                toolbar: customToolbar,
                placeholder: "Write your bio here...",
              }}
              onReady={(editor) => {
                // Set the CKEditor instance to the editorRef
                editorRef.current = editor;
              }}
            />
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                color: remainingChars >= 0 ? colors.textSecondary : "red",
                mt: 1,
                textAlign: "left",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {remainingChars} characters left
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

BioSection.propTypes = {
  bio: PropTypes.string.isRequired,
  setBio: PropTypes.func.isRequired,
  fontStyle: PropTypes.string.isRequired,
  setFontStyle: PropTypes.func.isRequired,
};

export default BioSection;
