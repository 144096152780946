import React from "react";
import PropTypes from "prop-types";
import leftArrow from "../../../assets/leftArrow.svg";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "../../../Utils/colorUtils";

const BackContainerGrid = styled(Grid)(({ theme }) => ({
  padding: "24px 0",
}));

const CustomGrid = styled(Grid)(({ theme }) => ({
  padding: "0px",
}));

function BackButton(props) {
  return (
    <BackContainerGrid item xs={12}>
      <Link to={props.link} style={{ textDecoration: "none" }}>
        <CustomGrid item display="flex" alignItems="center">
          <img src={leftArrow} style={{ marginRight: "8px" }} alt="leftArrow" />
          <Typography
            sx={{
              color: colors.violetSecondary,
              fontSize: "14px",
              fontWeight: "600",
              cursor: "pointer",
            }}
          >
            {props.name}
          </Typography>
        </CustomGrid>
      </Link>
    </BackContainerGrid>
  );
}

BackButton.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default BackButton;
