import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  styled,
  Popover,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Paper,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import searchIcon from "../../../assets/search-lg.svg";
import { colors } from "../../../Utils/colorUtils";
import axios from "axios";
import debounce from "lodash/debounce";
import QuizEmpty from "./QuizEmpty";

function QuizMain() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [quizzes, setQuizzes] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [privacyFilter, setPrivacyFilter] = useState("");
  const [tempStatusFilter, setTempStatusFilter] = useState("");
  const [tempPrivacyFilter, setTempPrivacyFilter] = useState("");
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const filterButtonRef = useRef(null);
  const navigate = useNavigate();
  const itemsPerPage = 10;

  const fetchAllQuizzes = async (page = 1) => {
    const token = localStorage.getItem("token");
    const docId = localStorage.getItem("docId");
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/quiz/get-quizes/${docId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data && Array.isArray(response.data.data)) {
        setQuizzes(response.data.data);
      } else {
        setQuizzes([]);
      }
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchQuizzes = async (search = "", page = 1) => {
    const token = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/quiz/search-quiz?search=${search}&page=${page}&limit=${itemsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.data && Array.isArray(response.data.quizzes)) {
        setQuizzes(response.data.quizzes);
      } else {
        setQuizzes([]);
      }
    } catch (error) {
      console.error("Error searching quizzes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      fetchAllQuizzes(currentPage);
    }
  }, [currentPage, searchTerm]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      if (term) {
        fetchSearchQuizzes(term, 1);
        setIsSearching(true);
        setCurrentPage(1);
      } else {
        fetchAllQuizzes(1);
        setIsSearching(false);
      }
    }, 500),
    [],
  );

  const handleSearchChange = (e) => {
    const value = e.target.value.trim().toLowerCase();
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const totalPages = quizzes ? Math.ceil(quizzes.length / itemsPerPage) : 0;

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleRowClick = (id) => {
    navigate(`/quiz/${id}`);
  };

  const handleFilterPopoverOpen = (event) => {
    setFilterPopoverOpen(event.currentTarget);
    setTempStatusFilter(statusFilter);
    setTempPrivacyFilter(privacyFilter);
  };

  const handleFilterPopoverClose = () => {
    setFilterPopoverOpen(null);
  };

  const handleApplyFilters = () => {
    setStatusFilter(tempStatusFilter);
    setPrivacyFilter(tempPrivacyFilter);
    setFilterPopoverOpen(null);
    setIsSearching(true);
  };

  const handleResetFilters = () => {
    setTempStatusFilter("");
    setTempPrivacyFilter("");
    setStatusFilter("");
    setPrivacyFilter("");
    setIsSearching(false);
  };

  const filteredQuizzes = (quizzes || []).filter((quiz) => {
    const matchesStatus =
      statusFilter === ""
        ? true
        : statusFilter === "published"
          ? quiz.publish
          : !quiz.publish;

    const matchesPrivacy =
      privacyFilter === "" ? true : quiz.privacy === privacyFilter;

    return matchesStatus && matchesPrivacy;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedQuizzes = filteredQuizzes.slice(
    startIndex,
    startIndex + itemsPerPage,
  );

  const Badge = styled(Box)(({ status, privacy }) => {
    const isPublished = status === true;
    const isPrivate = privacy === "private";
    const isShareable = privacy === "shareable";

    return {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 8px",
      borderRadius: "999px",
      height: "22px",
      border: `1px solid ${
        isPublished
          ? "#99F6E0"
          : isShareable
            ? "#C7D7FE"
            : isPrivate
              ? "#EAECF0"
              : "#EAECF0"
      }`,
      backgroundColor: isPublished
        ? "#F0FDF9"
        : isShareable
          ? "#EEF4FF"
          : isPrivate
            ? "#D5D9EB"
            : "#D5D9EB",
      color: isPublished
        ? "#107569"
        : isShareable
          ? "#3538CD"
          : isPrivate
            ? "#363F72"
            : "#363F72",
      whiteSpace: "nowrap",
    };
  });

  const StyledTableCell = styled(TableCell)({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
  });

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <TextField
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              width: { xs: "100%", md: 400 },
              height: 44,
              backgroundColor: colors.background,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={searchIcon}
                    alt="Search"
                    style={{ width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
              style: {
                borderRadius: "10px",
                color: "#667085",
                height: "100%",
                padding: "0 14px",
              },
            }}
            InputLabelProps={{
              style: { color: "#667085" },
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "row" },
              gap: { xs: 2, md: 2 },
              width: { xs: "100%", md: "auto" },
              mt: { xs: 2, md: 0 },
            }}
          >
            <Button
              variant="outlined"
              startIcon={<FilterListIcon />}
              sx={{
                backgroundColor: colors.background,
                color: colors.textPrimary,
                borderColor: colors.borderPrimary,
                borderRadius: "10px",
                height: 40,
                width: { xs: "48%", md: "auto" },
                textTransform: "none",
              }}
              onClick={handleFilterPopoverOpen}
              ref={filterButtonRef}
            >
              Filter
            </Button>
            <Button
              component={Link}
              to="/newQuiz"
              sx={{
                borderRadius: "8px",
                backgroundColor: "#7A5AF8",
                color: "#FFFFFF",
                textTransform: "none",
                height: "40px",
                width: { xs: "48%", md: "auto" },
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: colors.violetSecondary,
                },
              }}
            >
              <AddIcon /> New Quiz
            </Button>
          </Box>
        </Box>
      </Box>
      <Popover
        open={Boolean(filterPopoverOpen)}
        anchorEl={filterPopoverOpen}
        onClose={handleFilterPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            marginTop: 10,
            borderRadius: 10,
            width: "auto",
          },
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: "500",
              color: colors.textPrimary,
              fontSize: "14px",
            }}
          >
            Filters
          </Typography>
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              value={tempStatusFilter}
              onChange={(e) => setTempStatusFilter(e.target.value)}
              label="Status"
              sx={{
                borderRadius: "10px",
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="published">Published</MenuItem>
              <MenuItem value="unpublished">Unpublished</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Privacy</InputLabel>
            <Select
              value={tempPrivacyFilter}
              onChange={(e) => setTempPrivacyFilter(e.target.value)}
              label="Privacy"
              sx={{
                borderRadius: "10px",
              }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="private">Private</MenuItem>
              <MenuItem value="public">Public</MenuItem>
              <MenuItem value="shareable">Shareable</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="outlined"
              onClick={handleResetFilters}
              sx={{
                marginTop: "10px",
                backgroundColor: colors.background,
                color: colors.textPrimary,
                borderColor: colors.borderPrimary,
                borderRadius: "10px",
                height: 40,
                fontSize: "14px",
                fontWeight: "600",
                marginRight: 2,
              }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              onClick={handleApplyFilters}
              sx={{
                marginTop: "10px",
                backgroundColor: colors.violetPrimary,
                color: "#ffffff",
                borderRadius: "10px",
                height: 40,
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress sx={{ color: "#7F56D9" }} />
        </Box>
      ) : quizzes.length === 0 && !isSearching ? (
        <QuizEmpty />
      ) : (
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <TableContainer sx={{ overflowX: "auto", maxWidth: "100%" }}>
            <Table sx={{ minWidth: 1000 }} aria-label="quiz table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Questions</StyledTableCell>
                  <StyledTableCell>Owner</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Privacy</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedQuizzes.length > 0 ? (
                  paginatedQuizzes.map((quiz, index) => (
                    <TableRow
                      key={index}
                      onClick={() => handleRowClick(quiz.id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {quiz.quiz_name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {quiz.questions?.length || 0}
                      </StyledTableCell>
                      <StyledTableCell>
                        {quiz.doctor?.user?.first_name
                          ? quiz.doctor.user.first_name
                          : "Unknown"}{" "}
                        {quiz.doctor?.user?.last_name || ""}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Badge status={quiz.publish} privacy={quiz.privacy}>
                          {quiz.publish ? "Published" : "Unpublished"}
                        </Badge>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Badge privacy={quiz.privacy}>{quiz.privacy}</Badge>
                      </StyledTableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      sx={{
                        textAlign: "center",
                        padding: "20px",
                        color: "#666",
                      }}
                    >
                      No quizzes found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              borderTop: "1px solid #E5E7EB",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Page {currentPage} of {totalPages}
            </Typography>
            <Box>
              <Button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                sx={{ mr: 1, color: "#374151" }}
              >
                Previous
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                sx={{ color: "#374151" }}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Paper>
      )}
    </>
  );
}

export default QuizMain;
