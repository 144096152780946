import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Chip,
  Paper,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { colors } from "../../../Utils/colorUtils";

const EditSingleExercise = () => {
  const navigate = useNavigate();
  const { singleTreatmentId } = useParams();
  const [exerciseData, setExerciseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lightReps, setLightReps] = useState("");
  const [lightSets, setLightSets] = useState("");
  const [lightHolds, setLightHolds] = useState("");
  const [lightRests, setLightRests] = useState("");
  const [mediumReps, setMediumReps] = useState("");
  const [mediumSets, setMediumSets] = useState("");
  const [mediumHolds, setMediumHolds] = useState("");
  const [mediumRests, setMediumRests] = useState("");
  const [hardReps, setHardReps] = useState("");
  const [hardSets, setHardSets] = useState("");
  const [hardHolds, setHardHolds] = useState("");
  const [hardRests, setHardRests] = useState("");
  const [content, setContent] = useState("");
  const [instructions, setInstructions] = useState("");
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const fetchExerciseData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/get-single-treatment/${singleTreatmentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (response.data.success) {
          const data = response.data.data;
          setExerciseData(data);
          setLightReps(data.light_intensity_reps);
          setLightSets(data.light_intensity_sets_for);
          setLightHolds(data.light_intensity_holds);
          setLightRests(data.light_intensity_rests_for);
          setMediumReps(data.medium_intensity_reps);
          setMediumSets(data.medium_intensity_sets_for);
          setMediumHolds(data.medium_intensity_holds);
          setMediumRests(data.medium_intensity_rests_for);
          setHardReps(data.hard_intensity_reps);
          setHardSets(data.hard_intensity_sets_for);
          setHardHolds(data.hard_intensity_holds);
          setHardRests(data.hard_intensity_rests_for);
          setContent(data.content);
          setInstructions(data.instructions);
          setLabels(data.label);
        }
      } catch (error) {
        console.error("Error fetching exercise data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchExerciseData();
  }, [singleTreatmentId]);

  const handleClose = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    const updateData = {
      lightIntensityReps: lightReps,
      lightIntensityHolds: lightHolds,
      lightIntensityRestsFor: lightRests,
      lightIntensitySetsFor: lightSets,
      mediumIntensityReps: mediumReps,
      mediumIntensityHolds: mediumHolds,
      mediumIntensityRestsFor: mediumRests,
      mediumIntensitySetsFor: mediumSets,
      hardIntensityReps: hardReps,
      hardIntensityHolds: hardHolds,
      hardIntensityRestsFor: hardRests,
      hardIntensitySetsFor: hardSets,
      content: content,
      instructions: instructions,
      label: labels,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/update-single-treatment/${singleTreatmentId}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      );
      if (response.data.success) {
        console.log("Exercise updated successfully");
        navigate(-1);
      } else {
        console.error("Failed to update exercise");
      }
    } catch (error) {
      console.error("Error updating exercise:", error);
    }
  };

  if (loading) {
    return (
      <>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: "#7F56D9" }} />
        </Box>
      </>
    );
  }

  if (!exerciseData) {
    return (
      <>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <CircularProgress sx={{ color: "#7F56D9" }} />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: "#f5f5f5",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        padding: 0,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: 2,
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: 600, color: "#101828" }}
          >
            Exercise
          </Typography>

          <Button
            variant="outline-secondary"
            style={{
              marginRight: "10px",
              borderRadius: "8px",
              width: "auto",
              fontSize: "14px",
              height: "40px",
              color: "black",
              fontWeight: 600,
              borderColor: "#667085",
              border: "1px solid #D0D5DD",
              textTransform: "none",
            }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ fontSize: "20px" }} />
            Close
          </Button>
        </Box>

        <Grid container sx={{ flexGrow: 1, overflow: "auto" }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ p: 3, borderRight: "1px solid #e0e0e0" }}
          >
            <Box
              component="video"
              controls
              src={exerciseData.treatment_image}
              alt="Exercise Video"
              sx={{
                width: "100%",
                height: "auto",
                maxHeight: "300px",
                borderRadius: 4,
                mb: 2,
                mt: 3,
                objectFit: "cover",
              }}
            />
            <Typography
              sx={{ fontSize: "24px", fontWeight: 600, color: "#101828" }}
              gutterBottom
            >
              {content}
            </Typography>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 2 }}>
              {labels?.map((lbl, index) => (
                <Chip
                  key={index}
                  label={lbl}
                  variant="outlined"
                  size="small"
                  sx={{
                    margin: "4px",
                    backgroundColor: colors.secondary,
                    border: `1px solid ${colors.borderPrimary}`,
                    borderRadius: "10px",
                    color: colors.textTertiary,
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                />
              ))}
            </Box>
            <Typography
              sx={{
                color: colors.textTertiary,
                fontSize: "14px",
                fontWeight: 500,
              }}
              gutterBottom
            >
              Instructions
            </Typography>
            <TextField
              variant="outlined"
              multiline
              rows={6}
              fullWidth
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
              sx={{
                bgcolor: "white",
                mb: 2,
                color: colors.textTertiary,
                fontSize: "16px",
                fontWeight: 400,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#D0D5DD",
                    borderRadius: "10px",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ p: 3 }}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 600,
                color: "#101828",
                mt: 2,
              }}
              gutterBottom
            >
              Settings
            </Typography>

            {[
              {
                intensity: "Light Intensity",
                reps: lightReps,
                sets: lightSets,
                holds: lightHolds,
                rests: lightRests,
                setReps: setLightReps,
                setSets: setLightSets,
                setHolds: setLightHolds,
                setRests: setLightRests,
              },
              {
                intensity: "Medium Intensity",
                reps: mediumReps,
                sets: mediumSets,
                holds: mediumHolds,
                rests: mediumRests,
                setReps: setMediumReps,
                setSets: setMediumSets,
                setHolds: setMediumHolds,
                setRests: setMediumRests,
              },
              {
                intensity: "High Intensity",
                reps: hardReps,
                sets: hardSets,
                holds: hardHolds,
                rests: hardRests,
                setReps: setHardReps,
                setSets: setHardSets,
                setHolds: setHardHolds,
                setRests: setHardRests,
              },
            ].map((item, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                <Typography
                  sx={{
                    mt: 2,
                    mb: 2,
                    color: colors.textTertiary,
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {item.intensity}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Reps"
                      value={item.reps}
                      onChange={(e) => item.setReps(e.target.value)}
                      size="small"
                      sx={{
                        mt: 2,
                        mb: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#D0D5DD",
                            borderRadius: "10px",
                            height: "50px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Sets"
                      value={item.sets}
                      onChange={(e) => item.setSets(e.target.value)}
                      size="small"
                      sx={{
                        mt: 2,
                        mb: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#D0D5DD",
                            borderRadius: "10px",
                            height: "50px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Hold for"
                      value={item.holds}
                      onChange={(e) => item.setHolds(e.target.value)}
                      size="small"
                      sx={{
                        mt: 2,
                        mb: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#D0D5DD",
                            borderRadius: "10px",
                            height: "50px",
                          },
                        },
                      }}
                    />
                    <Typography
                      variant="caption"
                      sx={{ mt: 0, color: "#666", display: "block" }}
                    >
                      seconds
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Rest for"
                      value={item.rests}
                      onChange={(e) => item.setRests(e.target.value)}
                      size="small"
                      sx={{
                        mt: 2,
                        mb: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#D0D5DD",
                            borderRadius: "10px",
                            height: "50px",
                          },
                        },
                      }}
                    />
                    <Typography
                      variant="caption"
                      sx={{ mt: 0, color: "#666", display: "block" }}
                    >
                      seconds
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            p: 3,
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <Button
            variant="primary"
            style={{
              borderRadius: "8px",
              width: "70px",
              height: "40px",
              backgroundColor: "#7f56d9",
              border: "none",
              color: "#fff",
              fontWeight: 600,
              textTransform: "none",
            }}
            onClick={handleSave}
          >
            SAVE
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default EditSingleExercise;
