import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import upperNav from "../../assets/upperNav.svg";
import { Link } from "react-router-dom";

function NewPatientPopUp({ setPopupOpen, patientName }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <Box
      sx={{
        width: isMobile ? "90%" : 400,
        maxWidth: "100%",
        backgroundColor: "#f8f8f8",
        borderRadius: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        padding: isMobile ? 1.5 : 2,
        textAlign: "center",
        fontFamily: "Arial, sans-serif",
        position: "relative",
        margin: "auto",
      }}
    >
      <IconButton
        onClick={handleClosePopup}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "#98A2B3",
        }}
      >
        <CloseIcon sx={{ fontSize: 24, fontWeight: "bold" }} />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <img
          src={upperNav}
          alt="Upper Nav"
          style={{
            width: "100%",
            height: "auto",
            maxWidth: 400,
            maxHeight: 120,
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          marginY: 2,
          fontFamily: "Inter, sans-serif",
          fontWeight: 600,
          fontSize: isMobile ? "16px" : "18px",
          lineHeight: "28px",
          color: "#101828",
          textAlign: "center",
        }}
      >
        New patient: {patientName}
      </Typography>
      <Typography
        sx={{
          marginY: 1,
          fontFamily: "Inter, sans-serif",
          fontWeight: 400,
          fontSize: isMobile ? "12px" : "14px",
          color: "#475467",
          textAlign: "center",
        }}
      >
        You&apos;ve added a new patient. You can always send
        {isMobile ? " " : <br />}
        the patient an invitation to the app later.
      </Typography>
      <Link
        to={`/patient/overview/${localStorage.getItem("patientId")}`}
        style={{ textDecoration: "none", width: "100%", display: "block" }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#7d4cdb",
            width: "100%",
            height: "44px",
            marginTop: "20px",
            borderRadius: "5px",
            fontWeight: "600",
            color: "white",
            padding: "10px 20px",
            fontSize: isMobile ? 14 : 16,
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#693ab7",
            },
          }}
        >
          Open Patient Dashboard
        </Button>
      </Link>
    </Box>
  );
}

NewPatientPopUp.propTypes = {
  setPopupOpen: PropTypes.func.isRequired,
  patientName: PropTypes.string.isRequired,
};

export default NewPatientPopUp;
