export const colors = {
  primary: "#000000",
  secondary: "#FFFFFF",
  textPrimary: "#101828",
  textSecondary: "#475467",
  textTertiary: "#344054",
  textFourth: "#667085",
  violetPrimary: "#7F56D9",
  violetSecondary: "#6941C6",
  borderPrimary: "#D0D5DD",
  darkBluePrimary: "#182230",
  iconColor: "#677185",
  borderColor: "#EAECF0",
  lightGray: "#F5F5F5",
};
