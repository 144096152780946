export const countries = [
  { value: "AF", label: "Afghanistan", code: "AF" },
  { value: "AL", label: "Albania", code: "AL" },
  { value: "DZ", label: "Algeria", code: "DZ" },
  { value: "AS", label: "American Samoa", code: "AS" },
  { value: "AD", label: "Andorra", code: "AD" },
  { value: "AO", label: "Angola", code: "AO" },
  { value: "AI", label: "Anguilla", code: "AI" },
  { value: "AQ", label: "Antarctica", code: "AQ" },
  { value: "AG", label: "Antigua and Barbuda", code: "AG" },
  { value: "AR", label: "Argentina", code: "AR" },
  { value: "AM", label: "Armenia", code: "AM" },
  { value: "AW", label: "Aruba", code: "AW" },
  { value: "AU", label: "Australia", code: "AU" },
  { value: "AT", label: "Austria", code: "AT" },
  { value: "AZ", label: "Azerbaijan", code: "AZ" },
  { value: "BS", label: "Bahamas", code: "BS" },
  { value: "BH", label: "Bahrain", code: "BH" },
  { value: "BD", label: "Bangladesh", code: "BD" },
  { value: "BB", label: "Barbados", code: "BB" },
  { value: "BY", label: "Belarus", code: "BY" },
  { value: "BE", label: "Belgium", code: "BE" },
  { value: "BZ", label: "Belize", code: "BZ" },
  { value: "BJ", label: "Benin", code: "BJ" },
  { value: "BM", label: "Bermuda", code: "BM" },
  { value: "BT", label: "Bhutan", code: "BT" },
  { value: "BO", label: "Bolivia", code: "BO" },
  { value: "BQ", label: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
  { value: "BA", label: "Bosnia and Herzegovina", code: "BA" },
  { value: "BW", label: "Botswana", code: "BW" },
  { value: "BV", label: "Bouvet Island", code: "BV" },
  { value: "BR", label: "Brazil", code: "BR" },
  { value: "IO", label: "British Indian Ocean Territory", code: "IO" },
  { value: "BN", label: "Brunei Darussalam", code: "BN" },
  { value: "BG", label: "Bulgaria", code: "BG" },
  { value: "BF", label: "Burkina Faso", code: "BF" },
  { value: "BI", label: "Burundi", code: "BI" },
  { value: "CV", label: "Cabo Verde", code: "CV" },
  { value: "KH", label: "Cambodia", code: "KH" },
  { value: "CM", label: "Cameroon", code: "CM" },
  { value: "CA", label: "Canada", code: "CA" },
  { value: "KY", label: "Cayman Islands", code: "KY" },
  { value: "CF", label: "Central African Republic", code: "CF" },
  { value: "TD", label: "Chad", code: "TD" },
  { value: "CL", label: "Chile", code: "CL" },
  { value: "CN", label: "China", code: "CN" },
  { value: "CX", label: "Christmas Island", code: "CX" },
  { value: "CC", label: "Cocos (Keeling) Islands", code: "CC" },
  { value: "CO", label: "Colombia", code: "CO" },
  { value: "KM", label: "Comoros", code: "KM" },
  { value: "CD", label: "Congo (the Democratic Republic of the)", code: "CD" },
  { value: "CG", label: "Congo", code: "CG" },
  { value: "CK", label: "Cook Islands", code: "CK" },
  { value: "CR", label: "Costa Rica", code: "CR" },
  { value: "HR", label: "Croatia", code: "HR" },
  { value: "CU", label: "Cuba", code: "CU" },
  { value: "CW", label: "Curaçao", code: "CW" },
  { value: "CY", label: "Cyprus", code: "CY" },
  { value: "CZ", label: "Czech Republic", code: "CZ" },
  { value: "DK", label: "Denmark", code: "DK" },
  { value: "DJ", label: "Djibouti", code: "DJ" },
  { value: "DM", label: "Dominica", code: "DM" },
  { value: "DO", label: "Dominican Republic", code: "DO" },
  { value: "EC", label: "Ecuador", code: "EC" },
  { value: "EG", label: "Egypt", code: "EG" },
  { value: "SV", label: "El Salvador", code: "SV" },
  { value: "GQ", label: "Equatorial Guinea", code: "GQ" },
  { value: "ER", label: "Eritrea", code: "ER" },
  { value: "EE", label: "Estonia", code: "EE" },
  { value: "SZ", label: "Eswatini", code: "SZ" },
  { value: "ET", label: "Ethiopia", code: "ET" },
  { value: "FK", label: "Falkland Islands (Malvinas)", code: "FK" },
  { value: "FO", label: "Faroe Islands", code: "FO" },
  { value: "FJ", label: "Fiji", code: "FJ" },
  { value: "FI", label: "Finland", code: "FI" },
  { value: "FR", label: "France", code: "FR" },
  { value: "GF", label: "French Guiana", code: "GF" },
  { value: "PF", label: "French Polynesia", code: "PF" },
  { value: "TF", label: "French Southern Territories", code: "TF" },
  { value: "GA", label: "Gabon", code: "GA" },
  { value: "GM", label: "Gambia", code: "GM" },
  { value: "GE", label: "Georgia", code: "GE" },
  { value: "DE", label: "Germany", code: "DE" },
  { value: "GH", label: "Ghana", code: "GH" },
  { value: "GI", label: "Gibraltar", code: "GI" },
  { value: "GR", label: "Greece", code: "GR" },
  { value: "GL", label: "Greenland", code: "GL" },
  { value: "GD", label: "Grenada", code: "GD" },
  { value: "GP", label: "Guadeloupe", code: "GP" },
  { value: "GU", label: "Guam", code: "GU" },
  { value: "GT", label: "Guatemala", code: "GT" },
  { value: "GG", label: "Guernsey", code: "GG" },
  { value: "GN", label: "Guinea", code: "GN" },
  { value: "GW", label: "Guinea-Bissau", code: "GW" },
  { value: "GY", label: "Guyana", code: "GY" },
  { value: "HT", label: "Haiti", code: "HT" },
  { value: "HM", label: "Heard Island and McDonald Islands", code: "HM" },
  { value: "VA", label: "Holy See", code: "VA" },
  { value: "HN", label: "Honduras", code: "HN" },
  { value: "HK", label: "Hong Kong", code: "HK" },
  { value: "HU", label: "Hungary", code: "HU" },
  { value: "IS", label: "Iceland", code: "IS" },
  { value: "IN", label: "India", code: "IN" },
  { value: "ID", label: "Indonesia", code: "ID" },
  { value: "IR", label: "Iran (Islamic Republic of)", code: "IR" },
  { value: "IQ", label: "Iraq", code: "IQ" },
  { value: "IE", label: "Ireland", code: "IE" },
  { value: "IM", label: "Isle of Man", code: "IM" },
  { value: "IL", label: "Israel", code: "IL" },
  { value: "IT", label: "Italy", code: "IT" },
  { value: "JM", label: "Jamaica", code: "JM" },
  { value: "JP", label: "Japan", code: "JP" },
  { value: "JE", label: "Jersey", code: "JE" },
  { value: "JO", label: "Jordan", code: "JO" },
  { value: "KZ", label: "Kazakhstan", code: "KZ" },
  { value: "KE", label: "Kenya", code: "KE" },
  { value: "KI", label: "Kiribati", code: "KI" },
  { value: "KP", label: "Korea (Democratic People's Republic of)", code: "KP" },
  { value: "KR", label: "Korea, Republic of", code: "KR" },
  { value: "KW", label: "Kuwait", code: "KW" },
  { value: "KG", label: "Kyrgyzstan", code: "KG" },
  { value: "LA", label: "Lao People's Democratic Republic", code: "LA" },
  { value: "LV", label: "Latvia", code: "LV" },
  { value: "LB", label: "Lebanon", code: "LB" },
  { value: "LS", label: "Lesotho", code: "LS" },
  { value: "LR", label: "Liberia", code: "LR" },
  { value: "LY", label: "Libya", code: "LY" },
  { value: "LI", label: "Liechtenstein", code: "LI" },
  { value: "LT", label: "Lithuania", code: "LT" },
  { value: "LU", label: "Luxembourg", code: "LU" },
  { value: "MO", label: "Macao", code: "MO" },
  { value: "MG", label: "Madagascar", code: "MG" },
  { value: "MW", label: "Malawi", code: "MW" },
  { value: "MY", label: "Malaysia", code: "MY" },
  { value: "MV", label: "Maldives", code: "MV" },
  { value: "ML", label: "Mali", code: "ML" },
  { value: "MT", label: "Malta", code: "MT" },
  { value: "MH", label: "Marshall Islands", code: "MH" },
  { value: "MQ", label: "Martinique", code: "MQ" },
  { value: "MR", label: "Mauritania", code: "MR" },
  { value: "MU", label: "Mauritius", code: "MU" },
  { value: "YT", label: "Mayotte", code: "YT" },
  { value: "MX", label: "Mexico", code: "MX" },
  { value: "FM", label: "Micronesia (Federated States of)", code: "FM" },
  { value: "MD", label: "Moldova, Republic of", code: "MD" },
  { value: "MC", label: "Monaco", code: "MC" },
  { value: "MN", label: "Mongolia", code: "MN" },
  { value: "ME", label: "Montenegro", code: "ME" },
  { value: "MS", label: "Montserrat", code: "MS" },
  { value: "MA", label: "Morocco", code: "MA" },
  { value: "MZ", label: "Mozambique", code: "MZ" },
  { value: "MM", label: "Myanmar", code: "MM" },
  { value: "NA", label: "Namibia", code: "NA" },
  { value: "NR", label: "Nauru", code: "NR" },
  { value: "NP", label: "Nepal", code: "NP" },
  { value: "NL", label: "Netherlands", code: "NL" },
  { value: "NC", label: "New Caledonia", code: "NC" },
  { value: "NZ", label: "New Zealand", code: "NZ" },
  { value: "NI", label: "Nicaragua", code: "NI" },
  { value: "NE", label: "Niger", code: "NE" },
  { value: "NG", label: "Nigeria", code: "NG" },
  { value: "NU", label: "Niue", code: "NU" },
  { value: "NF", label: "Norfolk Island", code: "NF" },
  { value: "MK", label: "North Macedonia", code: "MK" },
  { value: "MP", label: "Northern Mariana Islands", code: "MP" },
  { value: "NO", label: "Norway", code: "NO" },
  { value: "OM", label: "Oman", code: "OM" },
  { value: "PK", label: "Pakistan", code: "PK" },
  { value: "PW", label: "Palau", code: "PW" },
  { value: "PS", label: "Palestine, State of", code: "PS" },
  { value: "PA", label: "Panama", code: "PA" },
  { value: "PG", label: "Papua New Guinea", code: "PG" },
  { value: "PY", label: "Paraguay", code: "PY" },
  { value: "PE", label: "Peru", code: "PE" },
  { value: "PH", label: "Philippines", code: "PH" },
  { value: "PN", label: "Pitcairn", code: "PN" },
  { value: "PL", label: "Poland", code: "PL" },
  { value: "PT", label: "Portugal", code: "PT" },
  { value: "PR", label: "Puerto Rico", code: "PR" },
  { value: "QA", label: "Qatar", code: "QA" },
  { value: "RO", label: "Romania", code: "RO" },
  { value: "RU", label: "Russian Federation", code: "RU" },
  { value: "RW", label: "Rwanda", code: "RW" },
  { value: "RE", label: "Réunion", code: "RE" },
  { value: "BL", label: "Saint Barthélemy", code: "BL" },
  {
    value: "SH",
    label: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
  },
  { value: "KN", label: "Saint Kitts and Nevis", code: "KN" },
  { value: "LC", label: "Saint Lucia", code: "LC" },
  { value: "MF", label: "Saint Martin (French part)", code: "MF" },
  { value: "PM", label: "Saint Pierre and Miquelon", code: "PM" },
  { value: "VC", label: "Saint Vincent and the Grenadines", code: "VC" },
  { value: "WS", label: "Samoa", code: "WS" },
  { value: "SM", label: "San Marino", code: "SM" },
  { value: "ST", label: "Sao Tome and Principe", code: "ST" },
  { value: "SA", label: "Saudi Arabia", code: "SA" },
  { value: "SN", label: "Senegal", code: "SN" },
  { value: "RS", label: "Serbia", code: "RS" },
  { value: "SC", label: "Seychelles", code: "SC" },
  { value: "SL", label: "Sierra Leone", code: "SL" },
  { value: "SG", label: "Singapore", code: "SG" },
  { value: "SX", label: "Sint Maarten (Dutch part)", code: "SX" },
  { value: "SK", label: "Slovakia", code: "SK" },
  { value: "SI", label: "Slovenia", code: "SI" },
  { value: "SB", label: "Solomon Islands", code: "SB" },
  { value: "SO", label: "Somalia", code: "SO" },
  { value: "ZA", label: "South Africa", code: "ZA" },
  {
    value: "GS",
    label: "South Georgia and the South Sandwich Islands",
    code: "GS",
  },
  { value: "SS", label: "South Sudan", code: "SS" },
  { value: "ES", label: "Spain", code: "ES" },
  { value: "LK", label: "Sri Lanka", code: "LK" },
  { value: "SD", label: "Sudan", code: "SD" },
  { value: "SR", label: "Suriname", code: "SR" },
  { value: "SJ", label: "Svalbard and Jan Mayen", code: "SJ" },
  { value: "SE", label: "Sweden", code: "SE" },
  { value: "CH", label: "Switzerland", code: "CH" },
  { value: "SY", label: "Syrian Arab Republic", code: "SY" },
  { value: "TW", label: "Taiwan, Province of China", code: "TW" },
  { value: "TJ", label: "Tajikistan", code: "TJ" },
  { value: "TZ", label: "Tanzania, United Republic of", code: "TZ" },
  { value: "TH", label: "Thailand", code: "TH" },
  { value: "TL", label: "Timor-Leste", code: "TL" },
  { value: "TG", label: "Togo", code: "TG" },
  { value: "TK", label: "Tokelau", code: "TK" },
  { value: "TO", label: "Tonga", code: "TO" },
  { value: "TT", label: "Trinidad and Tobago", code: "TT" },
  { value: "TN", label: "Tunisia", code: "TN" },
  { value: "TR", label: "Turkey", code: "TR" },
  { value: "TM", label: "Turkmenistan", code: "TM" },
  { value: "TC", label: "Turks and Caicos Islands", code: "TC" },
  { value: "TV", label: "Tuvalu", code: "TV" },
  { value: "UG", label: "Uganda", code: "UG" },
  { value: "UA", label: "Ukraine", code: "UA" },
  { value: "AE", label: "United Arab Emirates", code: "AE" },
  {
    value: "GB",
    label: "United Kingdom of Great Britain and Northern Ireland",
    code: "GB",
  },
  { value: "UM", label: "United States Minor Outlying Islands", code: "UM" },
  { value: "US", label: "United States of America", code: "US" },
  { value: "UY", label: "Uruguay", code: "UY" },
  { value: "UZ", label: "Uzbekistan", code: "UZ" },
  { value: "VU", label: "Vanuatu", code: "VU" },
  { value: "VE", label: "Venezuela (Bolivarian Republic of)", code: "VE" },
  { value: "VN", label: "Viet Nam", code: "VN" },
  { value: "WF", label: "Wallis and Futuna", code: "WF" },
  { value: "EH", label: "Western Sahara", code: "EH" },
  { value: "YE", label: "Yemen", code: "YE" },
  { value: "ZM", label: "Zambia", code: "ZM" },
  { value: "ZW", label: "Zimbabwe", code: "ZW" },
];
