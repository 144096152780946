import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Button, Typography, TextField } from "@mui/material";
import Add from "../../../../assets/assPlus.svg";
import { colors } from "../../../../Utils/colorUtils";

function TreatmentDetails({
  title,
  setTitle,
  description,
  setDescription,
  tags,
  setTags,
}) {
  const [editTagId, setEditTagId] = useState(null);
  const [tagInputValue, setTagInputValue] = useState("");
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [hasTitleError, setHasTitleError] = useState(false);
  const [hasDescriptionError, setHasDescriptionError] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (editTagId !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editTagId]);

  const addTag = () => {
    const newTag = {
      id: tags.length + 1,
      name: `Exercise ${tags.length + 1}`,
    };
    setTags([...tags, newTag]);
  };

  const handleTagClick = (tagId, tagName) => {
    setEditTagId(tagId);
    setTagInputValue(tagName);
  };

  const handleTagInputChange = (event) => {
    setTagInputValue(event.target.value);
  };

  const handleTagBlur = () => {
    setTags((prevTags) =>
      prevTags.map((tag) =>
        tag.id === editTagId ? { ...tag, name: tagInputValue } : tag,
      ),
    );
    setEditTagId(null);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    if (event.target.value.trim() === "") {
      setHasTitleError(true);
    } else {
      setHasTitleError(false);
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    if (event.target.value.trim() === "") {
      setHasDescriptionError(true);
    } else {
      setHasDescriptionError(false);
    }
  };

  const handleTitleBlur = () => {
    if (title.trim() === "") {
      setTitle("Treatment Name");
      setHasTitleError(true);
    } else {
      setIsEditingTitle(false);
    }
  };

  const handleDescriptionBlur = () => {
    if (description.trim() === "") {
      setDescription("Treatment Description");
      setHasDescriptionError(true);
    } else {
      setIsEditingDescription(false);
    }
  };

  return (
    <>
      {isEditingTitle ? (
        <TextField
          value={title === "Treatment Name" ? "" : title} // Empty if placeholder
          onFocus={() => {
            if (title === "Treatment Name") {
              setTitle(""); // Clear field when clicked
            }
          }}
          onChange={handleTitleChange}
          onBlur={handleTitleBlur}
          fullWidth
          variant="outlined"
          placeholder="Treatment Name"
          error={hasTitleError}
          helperText={hasTitleError ? "Treatment Name cannot be empty" : ""}
          sx={{
            marginBottom: 2,
            "& .MuiInputBase-root": {
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "32px",
              fontFamily: "Inter",
              color:
                title === "Treatment Name"
                  ? colors.textFourth
                  : colors.textPrimary,
            },
          }}
        />
      ) : (
        <Typography
          onClick={() => setIsEditingTitle(true)}
          sx={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "32px",
            fontFamily: "Inter",
            color:
              title === "Treatment Name"
                ? colors.textFourth
                : colors.textPrimary,
            cursor: "pointer",
          }}
        >
          {title}
        </Typography>
      )}

      {isEditingDescription ? (
        <TextField
          value={description === "Treatment Description" ? "" : description} // Show empty string if description is "Treatment Description"
          onFocus={() => {
            if (description === "Treatment Description") {
              setDescription(""); // Clear content on focus if it's "Treatment Description"
            }
          }}
          onChange={handleDescriptionChange}
          onBlur={handleDescriptionBlur}
          fullWidth
          variant="outlined"
          placeholder="Treatment Description" // Placeholder text shows until user types
          multiline
          error={hasDescriptionError}
          helperText={
            hasDescriptionError ? "Treatment Description cannot be empty" : ""
          }
          sx={{
            marginTop: 1,
            marginBottom: 3,
            "& .MuiInputBase-root": {
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              fontFamily: "Inter",
              color:
                description === "Treatment Description"
                  ? colors.textSecondary
                  : colors.textPrimary,
            },
          }}
        />
      ) : (
        <Typography
          onClick={() => setIsEditingDescription(true)}
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            fontFamily: "Inter",
            color: colors.textSecondary,
            marginTop: 1,
            marginBottom: 3,
            cursor: "pointer",
          }}
        >
          {description}
        </Typography>
      )}

      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        {tags.map((tag) => (
          <Grid item key={tag.id}>
            {editTagId === tag.id ? (
              <TextField
                value={tagInputValue}
                onChange={handleTagInputChange}
                onBlur={handleTagBlur}
                inputRef={inputRef}
                sx={{
                  maxWidth: "120px",
                  "& .MuiInputBase-root": {
                    height: "32px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "4px 8px",
                  },
                }}
                variant="outlined"
                size="small"
              />
            ) : (
              <Button
                onClick={() => handleTagClick(tag.id, tag.name)}
                sx={{
                  padding: "6px 12px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  maxWidth: "auto",
                  backgroundColor: "#F9F5FF",
                  color: colors.violetSecondary,
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  borderRadius: "8px",
                }}
              >
                {tag.name}
              </Button>
            )}
          </Grid>
        ))}
        <Grid item>
          <Button
            onClick={addTag}
            disabled={tags.length >= 3}
            sx={{
              width: "100%",
              maxWidth: "auto",
              backgroundColor: "transparent",
              color: colors.textSecondary,
              textTransform: "none",
              fontWeight: 600,
              fontSize: "14px",
              borderRadius: "8px",
              padding: "10px 20px",
              height: "40px",
              "&:disabled": {
                color: colors.textFourth,
                cursor: "not-allowed",
              },
            }}
          >
            <img src={Add} alt="add" style={{ marginRight: "8px" }} /> Add Tag
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

TreatmentDetails.propTypes = {
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setTags: PropTypes.func.isRequired,
};

export default TreatmentDetails;
