import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import TreatmentDetails from "./TreatmentDetails";
import TreatmentDays from "./TreatmentDays";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from "axios";
import { Snackbar, Alert } from "@mui/material";
import {
  Box,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { colors } from "../../../Utils/colorUtils";

function NewTreatmentRightContainer({
  days,
  selectedDay,
  onRemoveDay,
  onAddDay,
  onSwitchDay,
  onRemoveImage,
  title,
  setTitle,
  description,
  setDescription,
  setDroppedItems,
  tags,
  setTags,
  setDays,
  fetchTreatmentData,
  loading,
  treatmentId,
}) {
  const { id: paramsId } = useParams();
  const videoRefs = useRef([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  console.log({ treatmentId });
  console.log(selectedImage?.treatmentId);
  useEffect(() => {
    if (paramsId && !treatmentId) {
      fetchTreatmentData(paramsId);
    }
  }, [paramsId, treatmentId]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (treatment) => {
    setSelectedImage({
      ...treatment,
      treatmentId: treatment.treatmentId,
    });
    setOpenDialog(true);
  };

  const deleteDayFromPlan = async (treatmentPlansId, dayToDelete) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/remove-day`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: {
            treatmentPlansId,
            dayToDelete,
          },
        },
      );
      if (response.data.success) {
        console.log("Day deleted successfully:", response.data);
      } else {
        console.error("Failed to delete day");
      }
    } catch (error) {
      console.error("Error deleting day:", error);
    }
  };

  const handleRemoveImage = async () => {
    if (!selectedImage.treatmentId || !treatmentId) {
      console.error("treatmentId is undefined.");
      setSnackbarMessage(
        "Please save the treatment before removing the exercise.",
      );
      setSnackbarOpen(true);
      setOpenDialog(false);
      return;
    }
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URI}/api/v1/treatments/delete-single-treatment/${selectedImage.treatmentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            day: selectedDay,
            treatmentPlansId: treatmentId,
          },
        },
      );

      if (response.data.success) {
        const updatedDays = days.map((day) => {
          if (day.id === selectedDay) {
            return {
              ...day,
              treatments: day.treatments.filter(
                (treatment) => treatment.id !== selectedImage.id,
              ),
            };
          }
          return day;
        });
        setDays(updatedDays);
        setDroppedItems((prevItems) =>
          prevItems.filter(
            (item) => item.id !== selectedImage.id || item.day !== selectedDay,
          ),
        );
        setOpenDialog(false);
        setSnackbarMessage("Exercise removed successfully");
        setSnackbarOpen(true);
      } else {
        setOpenDialog(false);
        setSnackbarMessage("First save this treatment");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setOpenDialog(false);
      setSnackbarMessage("First save this treatment");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #EAECF0",
          borderRadius: "8px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            padding: 3,
            overflowY: "auto",
            minHeight: `calc(90vh - 20px)`,
            maxHeight: `calc(90vh - 20px)`,
            flex: 1,
          }}
        >
          <TreatmentDetails
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            tags={tags}
            setTags={setTags}
          />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <CircularProgress sx={{ color: "#7F56D9" }} />
            </Box>
          ) : (
            <>
              <TreatmentDays
                days={days}
                selectedDay={selectedDay}
                onAddDay={onAddDay}
                onSwitchDay={onSwitchDay}
                onRemoveImage={onRemoveImage}
                openDialog={openDialog}
                handleOpenDialog={handleOpenDialog}
                handleCloseDialog={handleCloseDialog}
                handleRemoveImage={handleRemoveImage}
                videoRefs={videoRefs}
                onRemoveDay={onRemoveDay}
                isMobile={isMobile}
              />
            </>
          )}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
            sx={{
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
        }
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: colors.violetPrimary,
            color: "#fff",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={
            snackbarMessage === "Exercise removed successfully"
              ? "success"
              : "error"
          }
          icon={<CheckCircleOutlineIcon sx={{ color: "#fff" }} />}
          sx={{
            width: "100%",
            backgroundColor: colors.violetPrimary,
            color: "#fff",
            fontSize: "16px",
            "& .MuiAlert-icon": {
              color: "#fff",
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

NewTreatmentRightContainer.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      treatments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          videoSrc: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  selectedDay: PropTypes.number.isRequired,
  onAddDay: PropTypes.func.isRequired,
  onSwitchDay: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setTags: PropTypes.func.isRequired,
  fetchTreatmentData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onRemoveDay: PropTypes.func.isRequired,
  setDays: PropTypes.func.isRequired,
  droppedItems: PropTypes.array.isRequired,
  setDroppedItems: PropTypes.func.isRequired,
  treatmentId: PropTypes.string,
};

export default NewTreatmentRightContainer;
