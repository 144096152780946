import React, { useState } from "react";
import Sidebar from "../../Components/Sidebar Component/Sidebar";
import { IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/painPal-logo.svg";

function MainSideBar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
      <div className="col-12 d-block d-md-none" style={{ padding: 0 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
          }}
        >
          <IconButton onClick={toggleSidebar}>
            <MenuIcon />
          </IconButton>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: "auto",
                height: "36px",
                marginRight: "10px",
                marginTop: "5px",
              }}
            />
          </div>
        </div>
      </div>
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        onClose={toggleSidebar}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 230,
          },
        }}
      >
        <Sidebar />
      </Drawer>
      <div className={`col-2 d-none d-md-block`} style={{ padding: 0 }}>
        <Sidebar />
      </div>
    </>
  );
}

export default MainSideBar;
